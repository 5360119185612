import { ExerciseSet, Student } from 'api/actions/assign-classroom-homework/assign-classroom-homework-payload';
import { AssignClassroomHomeworkResponse } from 'api/actions/assign-classroom-homework/assign-classroom-homework-response';
import { CreateClassroomResponse } from 'api/actions/create-classroom/create-classroom-response';
import { GetClassroomStudentsResponse } from 'api/actions/get-classroom-students/get-classroom-students-response';
import { GetClassroomsResponse } from 'api/actions/get-classrooms/get-classrooms-response';
import { DispatchedAction } from 'api/dispatch-action';
import { createContext } from 'react';

/**
 * The data about a single classroom.
 */
export type Classroom = GetClassroomsResponse['classrooms'][number];

/**
 * The id of a classroom.
 */
export type ClassroomId = Classroom['classroomId'];

/**
 * The name of a classroom.
 */
export type ClassroomName = Classroom['name'];

/**
 * The grade of a classroom.
 */
export type ClassroomGrade = Classroom['grade'];

/**
 * The data about a student in a classroom.
 */
export type ClassroomStudent = GetClassroomStudentsResponse['students'][number];

/**
 * The type of the id of a homework.
 */
export type HomeworkId = string;

interface ClassroomsContext {
  /**
   * Whether the classrooms are being fetched.
   */
  loading: boolean;

  /**
   * Fetches data about classrooms.
   */
  fetchClassrooms: () => void;

  /**
   * Fetches the students in a classroom.
   */
  fetchClassroomStudents: ({ classroomId }: { classroomId: ClassroomId }) => void;

  /**
   * Fetches new invite code for a classroom.
   */
  fetchNewClassroomInviteCode: ({ classroomId }: { classroomId: ClassroomId }) => void;

  /**
   * Returns the classrooms.
   */
  getClassrooms: () => Classroom[];

  /**
   * Returns the only classroom with the specified id.
   */
  getClassroom: ({ classroomId }: { classroomId: ClassroomId }) => Classroom | undefined;

  /**
   * Returns the invite code for a classroom.
   */
  getClassroomInviteCode: ({ classroomId }: { classroomId: ClassroomId }) => string | undefined;

  /**
   * Creates a new classroom.
   */
  createClassroom: ({
    name,
    grade,
  }: {
    name: ClassroomName;
    grade: ClassroomGrade;
  }) => DispatchedAction<CreateClassroomResponse>;

  /**
   * Returns the students in a classroom.
   */
  getClassroomStudents: ({ classroomId }: { classroomId: ClassroomId }) => ClassroomStudent[] | undefined;

  /**
   * Assigns homework to the classroom
   */
  assignHomework: ({
    classroomId,
    deadline,
    exerciseSet,
    students,
  }: {
    classroomId: ClassroomId;
    deadline: string;
    exerciseSet: ExerciseSet;
    students?: Student[];
  }) => DispatchedAction<AssignClassroomHomeworkResponse>;
}

/**
 * The classrooms context is used to store data about courses.
 */
export default createContext<ClassroomsContext>(undefined!);
