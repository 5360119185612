import SelectInputIcon from 'components/icons/SelectInputIcon';
import SelectInputSelectedIcon from 'components/icons/SelectInputSelectedIcon';
import { _t } from 'lang';

interface Props {
  value: string;
  onChange: (role: string) => void;
}

/**
 * Allows the user to choose a role when registering
 */
export default function UserRoleSelect({ value, onChange }: Props) {
  return (
    <div>
      <div className="flex h-[56px] items-center rounded-[10px] border border-input-outline-shadow bg-white text-[16px] font-medium leading-[19px] shadow-3xl">
        <div
          className="flex h-[100%] w-[50%] cursor-pointer items-center justify-center gap-[8px] border-r border-input-outline-shadow"
          onClick={() => {
            onChange('student');
          }}
        >
          <div
            tabIndex={0}
            onKeyDown={(e) => {
              if (e.key === 'Enter') {
                onChange('student');
              }
            }}
          >
            {value === 'student' ? <SelectInputSelectedIcon /> : <SelectInputIcon />}
          </div>
          <div>{_t('Student')}</div>
        </div>
        <div
          className="flex h-[100%] w-[50%] cursor-pointer items-center justify-center gap-[8px]"
          onClick={() => {
            onChange('teacher');
          }}
        >
          <div
            tabIndex={0}
            onKeyDown={(e) => {
              if (e.key === 'Enter') {
                onChange('teacher');
              }
            }}
          >
            {value === 'teacher' ? <SelectInputSelectedIcon /> : <SelectInputIcon />}
          </div>
          <div>{_t('Teacher')}</div>
        </div>
      </div>
    </div>
  );
}
