import { AxiosInstance } from 'axios';
import { SweetPath } from 'sweet-path';
import dispatchAction from 'api/dispatch-action';

import { RefreshSessionPayload } from 'api/actions/refresh-session/refresh-session-payload';
import { RefreshSessionPathParameters } from 'api/actions/refresh-session/refresh-session-path-parameters';

import { RefreshSessionResponse } from 'api/actions/refresh-session/refresh-session-response';

/**
 * The path of the RefreshSession action.
 */
export const refreshSessionActionPath = new SweetPath<keyof RefreshSessionPathParameters>('/user/session/refresh', {
  path: '{param}',
});

/**
 * Creates a contextualized RefreshSession actions.
 */
export default function createRefreshSessionAction(axios: AxiosInstance) {
  return function refreshSessionAction({ payload }: { payload: RefreshSessionPayload }) {
    const path = refreshSessionActionPath.original;

    return dispatchAction(() =>
      axios.post<RefreshSessionResponse, RefreshSessionResponse, RefreshSessionPayload>(path, payload)
    );
  };
}
