import useApi from 'api/use-api';
import Emoji from 'components/Emoji';
import SetPasswordForm from 'components/forms/SetPasswordForm';
import FormPageLayout from 'layout/form-page-layout/FormPageLayout';
import { _t } from 'lang';
import { Link, useSearchParams } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { RESET_PASSWORD_PAGE_PATH } from 'routes/paths';
import { ACCESS_TOKEN_INVALID_ERROR } from 'api/errors/error-codes';

/**
 * The set password page.
 *
 * @see https://www.notion.so/Set-Password-a9218c93d89d40e88a40b23d45974952
 */
export default function SetPasswordPage() {
  const [loading, setLoading] = useState(true);
  const [valid, setValid] = useState(false);
  const expired = !loading && !valid;

  const [searchParams] = useSearchParams();
  const email = searchParams.get('email') || '';
  const token = searchParams.get('token') || '';

  const { getAction } = useApi();
  const resetPasswordValidateAction = getAction('ResetPasswordValidate');

  useEffect(() => {
    resetPasswordValidateAction({ payload: { email, token } })
      .error(ACCESS_TOKEN_INVALID_ERROR, () => setValid(false))
      .success(() => setValid(true))
      .complete(() => setLoading(false));
  });

  return (
    <FormPageLayout>
      <div className="block p-[24px] pt-[24px] lg:ml-[50%] lg:flex lg:min-h-screen lg:items-center lg:pl-[130px]">
        <div className="lg:w-[370px]">
          {expired ? (
            <div>
              <p className="text-[24px] font-semibold leading-[29px] text-text-dark">
                {_t('Expired link')} <Emoji symbol="😢" label={_t('thumbs-up')} />
              </p>
              <p className="pb-[32px] pt-[8px] text-[14px] font-normal leading-[20px]">
                {_t('Unfortunately, the link you clicked has expired. Please, try to')}{' '}
                <Link
                  className="text-[14px] font-medium leading-[20px] text-green-primary"
                  to={RESET_PASSWORD_PAGE_PATH}
                >
                  {_t('reset your password again')}
                </Link>
                .
              </p>
            </div>
          ) : !loading ? (
            <div>
              <p className="text-[24px] font-semibold leading-[29px] text-text-dark">{_t('New password')}</p>
              <p className="pb-[32px] pt-[8px] text-[14px] font-normal leading-[20px]">
                {_t('To reset your password, input it below.')} <Emoji symbol="👍" label={_t('thumbs-up')} />
              </p>
              <SetPasswordForm email={email} token={token} />
            </div>
          ) : (
            <></>
          )}
        </div>
      </div>
    </FormPageLayout>
  );
}
