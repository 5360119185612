/**
 * Calendar icon.
 */
export default function CalendarIcon() {
  return (
    <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M11.2795 2.31982H2.72049C1.77029 2.31982 1 3.11036 1 4.08553V11.2341C1 12.2092 1.77029 12.9998 2.72049 12.9998H11.2795C12.2297 12.9998 13 12.2092 13 11.2341V4.08553C13 3.11036 12.2297 2.31982 11.2795 2.31982Z"
        stroke="#353E45"
        strokeWidth="1.2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path d="M3.99658 1V3.63998" stroke="#353E45" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M10.0005 1V3.63998" stroke="#353E45" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M1 6H12.6091" stroke="#353E45" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  );
}
