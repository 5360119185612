interface Props {
  fill?: string;
  width?: string;
  height?: string;
}

/**
 * Cross icon.
 */
export default function CrossIcon({ fill = '#738491', width = '18', height = '18' }: Props) {
  return (
    <svg width={width} height={height} viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect
        width="23.2581"
        height="1.93817"
        rx="0.969087"
        transform="matrix(-0.699746 -0.714391 -0.699746 0.714391 17.6309 16.6152)"
        fill={fill}
      />
      <rect
        width="23.2581"
        height="1.93817"
        rx="0.969087"
        transform="matrix(0.699746 -0.714391 0.699746 0.714391 0.369141 16.6152)"
        fill={fill}
      />
    </svg>
  );
}
