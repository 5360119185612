/**
 * Remove filter icon.
 */
export default function RemoveFilterIcon() {
  return (
    <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M4.75745 4.75732L13.2427 13.2426" stroke="#738491" strokeWidth="2" strokeLinecap="round" />
      <path d="M4.75745 13.2427L13.2427 4.75739" stroke="#738491" strokeWidth="2" strokeLinecap="round" />
    </svg>
  );
}
