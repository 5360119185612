import { showNotification } from '@mantine/notifications';
import { _t } from 'lang';
import { debounce } from 'lodash';

/**
 * Displays a notification about an unknown error.
 */
function unknownErrorNotificationImpl() {
  showNotification({
    title: _t('Something went wrong.'),
    message: _t('Sorry, something went wrong. Please, try it again later. Contact us if the problem persists.'),
    color: 'red',
  });
}

/**
 * Displays a notification about an unknown error (debounced).
 */
export const unknownErrorNotification = debounce(unknownErrorNotificationImpl, 200, { maxWait: 750 });

/**
 * Displays a notification about connection error.
 */
export function connectionErrorNotificationImpl() {
  showNotification({
    title: _t('Connection error.'),
    message: _t('Please, check your internet connection and try it again.'),
    color: 'red',
  });
}

/**
 * Displays a notification about connection error (debounced).
 */
export const connectionErrorNotification = debounce(connectionErrorNotificationImpl, 200, { maxWait: 750 });
