import { Button, Group, LoadingOverlay, Textarea } from '@mantine/core';
import { IconTextCaption } from '@tabler/icons-react';
import { HomeworkAssignment } from 'api/actions/get-classroom-student-detail/get-classroom-student-detail-response';
import useApi from 'api/use-api';
import Avatar from 'components/Avatar';
import { _t } from 'lang';
import { noop } from 'lodash';
import { useState } from 'react';

/**
 * Parameters of the HomeworkComments component.
 */
export interface HomeworkCommentsProps {
  assignment: HomeworkAssignment;
  homeworkId: string;
  mode: 'student' | 'teacher';
  studentId?: string;
  classroomId?: string;
  loading?: boolean;
  onCommentAdded?: () => void;
}

/**
 * Displays homework comments.
 */
export default function HomeworkComments({
  assignment,
  homeworkId,
  mode,
  studentId,
  classroomId,
  loading: loadingOutside = false,
  onCommentAdded = noop,
}: HomeworkCommentsProps) {
  const { getAction } = useApi();
  const [text, setText] = useState<string>('');
  const [loading, setLoading] = useState<boolean>(false);

  /**
   * Handles the comment submission.
   */
  const submit = () => {
    setLoading(true);

    if (mode === 'student') {
      const submitStudentHomeworkComment = getAction('SubmitStudentHomeworkComment');

      submitStudentHomeworkComment({ parameters: { homeworkId }, payload: { text } })
        .success(() => {
          setText('');
          onCommentAdded();
        })
        .complete(() => setLoading(false));
    } else {
      const submitClassroomHomeworkComment = getAction('SubmitClassroomHomeworkComment');

      submitClassroomHomeworkComment({
        parameters: { homeworkId, classroomId: classroomId! },
        payload: { text, studentId: studentId! },
      })
        .success(() => {
          setText('');
          onCommentAdded();
        })
        .complete(() => setLoading(false));
    }
  };

  return (
    <div className="relative my-[16px] flex flex-col gap-y-[12px] rounded-[10px] bg-white p-[24px]">
      <LoadingOverlay visible={loading || loadingOutside} />
      <div className="mb-2 flex w-full items-center justify-between">
        <div className="flex items-center gap-x-[12px]">
          <IconTextCaption size={24} />
          <div className="mt-[1px] text-[18px] font-semibold leading-[19px] text-text-dark">
            {_t('Homework comments')}
          </div>
        </div>
      </div>
      <div className="mt-2 flex flex-col gap-y-[12px] empty:mt-0">
        {assignment.comments?.map(({ text, at, by }) => (
          <div
            key={at}
            className="flex flex-col gap-y-[8px] border-b border-b-input-outline-shadow pb-4 last:border-b-0"
          >
            <div className="flex items-center gap-2 pl-1 text-[14px] font-medium leading-[18px]">
              <Avatar size={24} label={by} />
              <span className="font-semibold text-text-dark-2">{by}</span>
            </div>
            <div className="pl-[37px] text-[14px] leading-[18px]">{text}</div>
          </div>
        ))}
      </div>
      <Textarea minRows={3} autosize value={text} onChange={(e) => setText(e.currentTarget.value)} />
      <Group position="right">
        <Button
          className="h-[42px] rounded-[7px] bg-green-primary hover:bg-green-dark focus:bg-green-dark sm:w-[198px]"
          onClick={submit}
        >
          {_t('Add comment')}
        </Button>
      </Group>
    </div>
  );
}
