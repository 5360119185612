/**
 * Tick icon
 */
export default function TickIcon() {
  return (
    <svg width="15" height="12" viewBox="0 0 15 12" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M1 6.75L4.04289 9.79289C4.43342 10.1834 5.06658 10.1834 5.45711 9.79289L13.75 1.5"
        stroke="white"
        strokeWidth="2"
        strokeLinecap="round"
      />
    </svg>
  );
}
