interface Props {
  stroke?: string;
}

/**
 * Arrow up icon.
 */
export default function ArrowUpIcon({ stroke = '#353E45' }: Props) {
  return (
    <svg width="16" height="9" viewBox="0 0 16 9" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M14.6667 7.33325L8.70719 1.37369C8.31666 0.983167 7.6835 0.983167 7.29298 1.37369L1.33342 7.33325"
        stroke={stroke}
        strokeWidth="2"
        strokeLinecap="round"
      />
    </svg>
  );
}
