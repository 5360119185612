import { Button, Modal, ScrollArea } from '@mantine/core';
import useApi from 'api/use-api';
import Assignment from 'components/assignment/Assignment';
import CrossIcon from 'components/icons/CrossIcon';
import TickIcon from 'components/icons/TickIcon';
import Clickable from 'components/misc/Clickable';
import { _t, lang } from 'lang';
import { noop } from 'lodash';
import { Dispatch, SetStateAction, useEffect, useState } from 'react';

interface Props {
  opened: boolean;
  setOpened: Dispatch<SetStateAction<boolean>>;
  exerciseId: number;
  onAddExercise: () => void;
  selected: boolean;
}

/**
 * The exercise detail modal.
 */
export default function ExerciseDetailModal({ opened, setOpened, exerciseId, onAddExercise, selected }: Props) {
  const { getAction } = useApi();
  const createAssignmentAction = getAction('CreateAssignment');

  const [fill, setFill] = useState('#738491');
  const [assignmentId, setAssignmentId] = useState<string | null>(null);

  useEffect(() => {
    if (opened && !assignmentId) {
      createAssignmentAction({
        payload: { exerciseSet: { language: lang, exercises: [{ exerciseId, count: 1 }] } },
      }).success(({ assignmentId }) => setAssignmentId(assignmentId));
    }
  }, [exerciseId, opened]);

  return (
    <Modal
      opened={opened}
      onClose={noop} // this is a required prop
      centered
      size="auto"
      withCloseButton={false}
      styles={{
        root: {
          padding: '0!important',
        },
        body: {
          padding: '0!important',
        },
        modal: {
          padding: '0!important',
        },
      }}
    >
      <div className="flex h-[815px] w-[970px] flex-col rounded-[10px] bg-white">
        <div className="flex h-[90px] items-center justify-between border-b border-b-input-outline-shadow pb-[33px] pl-[39px] pr-[35px] pt-[33px]">
          <div className="flex items-center gap-[9px] text-[20px] font-semibold leading-[24px] text-text-dark">
            {_t('The detail of selected exercise')}
          </div>
          <Clickable
            onClick={() => setOpened(false)}
            className="flex h-[42px] w-[42px] items-center justify-center rounded-[7px] p-[8px] hover:bg-bg-shadow"
            onMouseEnter={() => {
              setFill('#353E45');
            }}
            onMouseLeave={() => {
              setFill('#738491');
            }}
          >
            <CrossIcon fill={fill} />
          </Clickable>
        </div>
        <ScrollArea>
          <div className="min-h-[619px]">
            <div className="ml-auto mr-auto w-[570px] pb-[48px] pt-[48px]">
              {assignmentId ? <Assignment assignmentId={assignmentId} /> : <div>{_t('Loading ...')}</div>}
            </div>
          </div>
        </ScrollArea>
        <div className="flex h-[106px] items-center justify-end gap-[16px] border-t border-t-input-outline-shadow pb-[32px] pl-[46px] pr-[24px] pt-[32px]">
          <Button
            className="h-[42px] w-[198px] rounded-[7px] bg-green-primary-opacity-01 text-green-primary hover:bg-green-primary-opacity-02 focus:bg-green-primary-opacity-02"
            onClick={() => setOpened(false)}
          >
            {_t('Close')}
          </Button>
          {!selected && (
            <Button
              className="h-[42px] w-[198px] rounded-[7px] bg-green-primary"
              onClick={() => {
                setOpened(false);
                onAddExercise();
              }}
            >
              <div className="flex items-center gap-[9px] text-[14px] font-semibold leading-[17px] text-white ">
                <TickIcon />
                {_t('Add exercise')}
              </div>
            </Button>
          )}
        </div>
      </div>
    </Modal>
  );
}
