import NextStepButton from 'pages/courses-module/course-step/main/NextStepButton';
import PreviousStepButton from 'pages/courses-module/course-step/main/PreviousStepButton';
import { useEffect, useState } from 'react';
import CancelIcon from 'components/icons/CancelIcon';
import useCourseStep from 'pages/courses-module/course-step/context/use-course-step';
import CourseStepContent from 'pages/courses-module/course-step/main/content/CourseStepContent';
import { Button } from '@mantine/core';
import { _t } from 'lang';
import { useStudentDashboardLayout } from 'layout/student-dashboard-layout/StudentDashboardLayoutContext';

/**
 * The content of the Student course step.
 */
export default function StudentCourseStepMain() {
  const { hidePhoneHeader, showPhoneHeader } = useStudentDashboardLayout();
  const { currStep, setIsSidebarVisible } = useCourseStep();
  const [isContentVisible, setIsContentVisible] = useState(false);

  /**
   * Shows the content of the step on mobile.
   */
  const showContent = () => {
    setIsContentVisible(true);
    setIsSidebarVisible(false);
    hidePhoneHeader();
  };

  /**
   * Hides the content of the step on mobile.
   */
  const hideContent = () => {
    setIsContentVisible(false);
    setIsSidebarVisible(true);
    showPhoneHeader();
  };

  // Hide content on unmount
  useEffect(() => hideContent, []);

  const hideIfContentVisible = isContentVisible ? 'hidden' : '';
  const hideIfVideoStep = currStep?.type === 'video' ? 'hidden' : '';
  const showIfContentVisible = isContentVisible ? '' : 'hidden';
  const wrapperHeight = isContentVisible ? 'h-auto' : 'h-[56.25vw]';

  return (
    <div
      className={`relative flex items-center justify-between overflow-hidden lg:block lg:h-full lg:items-start lg:overflow-auto lg:bg-white ${wrapperHeight}`}
    >
      {/* Overlay */}
      <div
        className={`absolute inset-0 left-0 top-0 z-20 h-full w-full bg-text-dark-opacity-06 lg:hidden ${hideIfContentVisible} ${hideIfVideoStep}`}
      />

      {/* Previous step chevron button */}
      <PreviousStepButton
        className={`absolute left-0 top-[calc(100%/2-32px)] z-20 lg:sticky lg:left-0 lg:top-[calc(50vh-32px-45px)] ${hideIfContentVisible}`}
        prefix="/student"
      />

      {/* Open course content button */}
      <Button
        className={`absolute left-[calc((100%-140px)/2)] top-[calc((100%-42px)/2)] z-20 h-[42px] w-[140px] border-0 bg-green-primary hover:bg-green-dark active:bg-green-dark lg:hidden ${hideIfContentVisible} ${hideIfVideoStep}`}
        onClick={showContent}
        radius={7}
      >
        {_t('Open')}
      </Button>

      {/* Next step chevron button */}
      <NextStepButton
        className={`absolute right-0 top-[calc(100%/2-32px)] z-20 lg:sticky lg:left-full lg:top-[calc(50vh-32px-45px)] ${hideIfContentVisible}`}
        prefix="/student"
      />

      {/* Course content */}
      {currStep && (
        <div
          className={`left-0 top-0 h-full w-full lg:block ${
            isContentVisible ? 'z-50 w-full overflow-y-auto bg-white pb-[48px] pt-[14px]' : 'absolute'
          } ${!isContentVisible && currStep.type !== 'video' ? 'pb-[14px] pt-[14px] lg:pb-0 lg:pt-0' : ''}`}
        >
          <div
            className={`fixed left-0 top-0 z-40 flex h-[66px] w-full cursor-pointer items-center justify-between bg-white pl-[19px] pr-[16px] shadow-3xl lg:hidden ${showIfContentVisible}`}
            onClick={hideContent}
          >
            <span className="text-[14px] font-medium leading-[17px] text-text-dark">{currStep.title}</span>
            <CancelIcon />
          </div>
          <CourseStepContent key={currStep.stepId} />
        </div>
      )}
    </div>
  );
}
