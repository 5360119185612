import { Drawer, ScrollArea } from '@mantine/core';
import { GetClassroomsResponse } from 'api/actions/get-classrooms/get-classrooms-response';
import ClassroomPackUnpackIcon from 'components/icons/ClassroomPackUnpackIcon';
import DoorIcon from 'components/icons/DoorIcon';
import PlusIcon from 'components/icons/PlusIcon';
import Clickable from 'components/misc/Clickable';
import useClassrooms from 'data/classrooms/use-classrooms';
import { _t } from 'lang';
import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';

interface Props {
  opened: boolean;
  open: () => void;
  close: () => void;
  displayCreateClassroomModal: Dispatch<SetStateAction<boolean>>;
  classroomName: string;
}

/**
 * The drawer allowing to select a classroom.
 */
export default function TeacherDesktopDrawer({
  opened,
  open,
  close,
  displayCreateClassroomModal,
  classroomName,
}: Props) {
  const { getClassrooms } = useClassrooms();
  const [classrooms, setClassrooms] = useState<GetClassroomsResponse['classrooms']>();

  useEffect(() => {
    const classrooms = getClassrooms();
    setClassrooms(classrooms);
  });

  return (
    <Drawer
      opened={opened}
      onClose={close}
      position="right"
      withCloseButton={false}
      size={350}
      styles={{
        overlay: {
          backgroundColor: '#353e45!important',
          opacity: '0.2!important',
        },
      }}
    >
      <div className="flex h-full flex-col justify-between">
        <div className="h-[calc(100vh-90px)]">
          <Clickable
            className="flex h-[90px] border-b border-b-input-outline-shadow pl-[32px] pr-[40px]"
            onClick={close}
          >
            <div className="flex w-full items-center justify-between">
              <span className="text-[24px] font-semibold leading-[29px]">{classroomName}</span>
              <div className="rotate-[-180deg]">
                <ClassroomPackUnpackIcon stroke="#353E45" />
              </div>
            </div>
          </Clickable>
          <ScrollArea>
            <div className="h-[750px] p-[8px]">
              <div className="flex h-[74px] items-center gap-[12px] pl-[28px]">
                <DoorIcon stroke="#738491" />
                <div className="text-[18px] font-medium leading-[22px] text-text-shadow">{_t('My classrooms')}</div>
              </div>
              <div className="flex flex-col gap-[8px]">
                {classrooms?.map((classroom) => (
                  <Link
                    key={classroom.classroomId}
                    className="flex h-[74px] items-center pl-[56px] text-[18px] font-medium leading-[22px] text-text-shadow hover:rounded-[7px] hover:bg-bg-shadow hover:font-semibold hover:text-text-dark"
                    to={`/teacher/classrooms/classroom/${classroom.classroomId}/detail`}
                    state={{ switchingClassroom: true }}
                    onClick={close}
                  >
                    {classroom.name}
                  </Link>
                ))}
              </div>
            </div>
          </ScrollArea>
        </div>
        <div className="relative h-[90px] border-t border-t-input-outline-shadow bg-white p-[8px]">
          <Clickable
            className="flex h-[74px] items-center gap-[12px] pl-[29px]"
            onClick={() => {
              close();
              displayCreateClassroomModal(true);
            }}
          >
            <PlusIcon stroke="#0AB892" />
            <div className="text-[18px] font-medium leading-[22px] text-green-primary">{_t('Create a classroom')}</div>
          </Clickable>
        </div>
      </div>
    </Drawer>
  );
}
