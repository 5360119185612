import { createContext } from 'react';
import { Course } from 'data/course/course-context';
import { GetCourseStepResponse } from 'api/actions/get-course-step/get-course-step-response';
import { Dispatch } from 'react';
import { SetStateAction } from 'react';

/**
 * The current step of a course.
 */
export type CurrentCourseStep = GetCourseStepResponse['step'];

/**
 * The previous step of a course.
 */
export type PreviousCourseStep = GetCourseStepResponse['prevStep'];

/**
 * The next step of a course.
 */
export type NextCourseStep = GetCourseStepResponse['nextStep'];

/**
 * The identifier of a course step.
 */
export type CourseStepId = CurrentCourseStep['stepId'];

/**
 * The context for course step page.
 */
interface CourseStepContext {
  /**
   * The unique identifier of the course.
   */
  course: Course;

  /**
   * The slug of the section.
   */
  sectionSlug: string;

  /**
   * The unique identifier of the course step.
   */
  stepId: number;

  /**
   * Returns the current course step.
   */
  currStep: CurrentCourseStep | undefined;

  /**
   * Returns the next course step.
   */
  nextStep: NextCourseStep | undefined;

  /**
   * Returns the previous course step.
   */
  prevStep: PreviousCourseStep | undefined;

  /**
   * The link to the next course step.
   */
  nextStepLink: string | undefined;

  /**
   * The link to the previous course step.
   */
  prevStepLink: string | undefined;

  /**
   * Whether the sidebar is open.
   */
  isSidebarVisible: boolean;

  /**
   * Sets the sidebar open state.
   */
  setIsSidebarVisible: Dispatch<SetStateAction<boolean>>;
}

/**
 * The session context is used to store the current session.
 *
 * The session context will be provided in <App>.
 */
export default createContext<CourseStepContext>(undefined!);
