import useCourse from 'data/course/use-course';
import { useEffect } from 'react';
import { Navigate, useParams } from 'react-router-dom';
import CourseStepProvider from 'pages/courses-module/course-step/context/course-step-provider';
import BasicPageLayout from 'layout/basic-page-layout/BasicPageLayout';
import CourseStepLayout from 'pages/courses-module/course-step/CourseStepLayout';

/**
 * The page with a course step.
 */
export default function CourseStepPage() {
  let { courseSlug, sectionSlug, stepId } = useParams();
  const { getCourse, fetchCourses, fetchCoursePlan, getCoursePlan } = useCourse();

  useEffect(() => {
    fetchCourses();
    fetchCoursePlan({ courseIdOrSlug: courseSlug! });
  }, [courseSlug]);

  const course = getCourse({ slug: courseSlug });
  const coursePlan = getCoursePlan({ courseIdOrSlug: courseSlug! });

  if (!course || !coursePlan) {
    return <BasicPageLayout hasHeader />;
  }

  if (!stepId || !sectionSlug) {
    return (
      <BasicPageLayout hasHeader>
        <>
          <Navigate
            to={`/course/${course.slug}/${course.nextStep.section.slug}/step/${course.nextStep.stepId}`}
            replace
          />
        </>
      </BasicPageLayout>
    );
  }

  return (
    <CourseStepProvider course={course} sectionSlug={sectionSlug} stepId={Number(stepId)}>
      <BasicPageLayout hasHeader>
        <CourseStepLayout />
      </BasicPageLayout>
    </CourseStepProvider>
  );
}
