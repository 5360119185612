// Presentation module
export const CONTACT_PAGE_PATH = '/contact';
export const HOME_PAGE_PATH = '/';
export const LANDING_PAGE_PATH = '/landing';
export const PRIVACY_POLICY_PAGE_PATH = '/privacy-policy';
export const SLOVAKIA_COMPARISON_PAGE_PATH = '/cesko-slovenske-porovnanie';
export const WEBINARS_PAGE_PATH = '/webinars';
export const WHO_WE_ARE_PAGE_PATH = '/who-we-are';
export const WHO_WE_ARE_PAGE_SECTION_PATH = '/who-we-are/:sectionName';
export const FAQ_PAGE_PATH = '/faq';
export const THANK_YOU_PAGE_PATH = '/thank-you';

// User module
export const CHANGE_PASSWORD_PAGE_PATH = '/my-profile/change-password';
export const LOGIN_PAGE_PATH = '/login';
export const REGISTER_PAGE_PATH = '/register';
export const REGISTER_COURSE_PAGE_PATH = '/register/course';
export const RESET_PASSWORD_PAGE_PATH = '/reset-password';
export const SET_PASSWORD_PAGE_PATH = '/set-password';
export const EXERCISES_PAGE_PATH = '/exercises';
export const EXERCISE_DETAIL_PAGE_PATH = '/exercises/exercise/:exerciseId';

// Courses module
export const COURSE_PAGE_PATH = '/course/:courseSlug';
export const COURSE_STEP_PAGE_PATH = '/course/:courseSlug/:sectionSlug/step/:stepId';

// Teacher module
export const TEACHER_CHANGE_PASSWORD_PAGE_PATH = '/teacher/change-password';
export const CLASSROOMS_PAGE_PATH = '/teacher/classrooms';
export const TEACHER_DASHBOARD_PAGE_PATH = '/teacher/classrooms/classroom/:classroomId/detail';
export const CLASSROOM_HOMEWORK_PAGE_PATH = '/teacher/classrooms/classroom/:classroomId/homework/:tab';
export const CLASSROOM_HOMEWORK_DETAIL_PAGE_PATH =
  '/teacher/classrooms/classroom/:classroomId/homework/homework/:homeworkId';
export const CLASSROOM_STUDENTS_PAGE_PATH = '/teacher/classrooms/classroom/:classroomId/students';
export const STUDENT_DETAIL_PAGE_PATH = '/teacher/classrooms/classroom/:classroomId/students/student/:studentId';
export const CLASSROOM_EXERCISES_PAGE_PATH = '/teacher/classrooms/classroom/:classroomId/exercises';
export const EXPORT_EXERCISES_PAGE_PATH = '/teacher/exercises/export/:assignmentId';
export const CHECK_HOMEWORK_PAGE_PATH =
  '/teacher/classrooms/classroom/:classroomId/homework/homework/:homeworkId/check';

// Student module
export const STUDENT_CHANGE_PASSWORD_PAGE_PATH = '/student/change-password';
export const STUDENT_DASHBOARD_PAGE_PATH = '/student/dashboard';
export const STUDENT_HOMEWORK_PAGE_PATH = '/student/homework/:tab';
export const STUDENT_SOLVE_HOMEWORK_PAGE_PATH = '/student/homework/homework/:homeworkId/solve';

export const STUDENT_COURSE_PAGE_PATH = 'student/course/:courseSlug';
export const STUDENT_COURSE_STEP_PAGE_PATH = 'student/course/:courseSlug/:sectionSlug/step/:stepId';

export const STUDENT_EXERCISES_PAGE_PATH = '/student/exercises';
export const STUDENT_EXERCISE_DETAIL_PAGE_PATH = '/student/exercises/exercise/:exerciseId';

// Private
export const PRIVATE_ALL_EXERCISES_PAGE_PATH = '/private/all-exercises/:lang';
