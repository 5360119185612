/**
 * Large student icon.
 */
export default function StudentLargeIcon() {
  return (
    <svg width="39" height="40" viewBox="0 0 39 40" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx="19.499" cy="10.9375" r="9.4375" stroke="#353E45" strokeWidth="3" />
      <path
        d="M37 38.1875C37 28.5225 29.165 20.6875 19.5 20.6875C9.83502 20.6875 2 28.5225 2 38.1875"
        stroke="#353E45"
        strokeWidth="3"
        strokeLinecap="round"
      />
      <line x1="18.8125" y1="34.5" x2="26.75" y2="34.5" stroke="#353E45" strokeWidth="3" strokeLinecap="round" />
    </svg>
  );
}
