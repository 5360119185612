import { AxiosInstance } from 'axios';
import { SweetPath } from 'sweet-path';
import dispatchAction from 'api/dispatch-action';

import { CreateAssignmentPayload } from 'api/actions/create-assignment/create-assignment-payload';
import { CreateAssignmentPathParameters } from 'api/actions/create-assignment/create-assignment-path-parameters';

import { CreateAssignmentResponse } from 'api/actions/create-assignment/create-assignment-response';

/**
 * The path of the CreateAssignment action.
 */
export const createAssignmentActionPath = new SweetPath<keyof CreateAssignmentPathParameters>('/assignment', {
  path: '{param}',
});

/**
 * Creates a contextualized CreateAssignment actions.
 */
export default function createCreateAssignmentAction(axios: AxiosInstance) {
  return function createAssignmentAction({ payload }: { payload: CreateAssignmentPayload }) {
    const path = createAssignmentActionPath.original;

    return dispatchAction(() =>
      axios.post<CreateAssignmentResponse, CreateAssignmentResponse, CreateAssignmentPayload>(path, payload)
    );
  };
}
