import { AxiosInstance } from 'axios';
import { SweetPath } from 'sweet-path';
import dispatchAction from 'api/dispatch-action';

import { GiveCourseFeedbackPayload } from 'api/actions/give-course-feedback/give-course-feedback-payload';
import { GiveCourseFeedbackPathParameters } from 'api/actions/give-course-feedback/give-course-feedback-path-parameters';

import { GiveCourseFeedbackResponse } from 'api/actions/give-course-feedback/give-course-feedback-response';

/**
 * The path of the GiveCourseFeedback action.
 */
export const giveCourseFeedbackActionPath = new SweetPath<keyof GiveCourseFeedbackPathParameters>(
  '/courses/{courseId}/feedback',
  { path: '{param}' }
);

/**
 * Creates a contextualized GiveCourseFeedback actions.
 */
export default function createGiveCourseFeedbackAction(axios: AxiosInstance) {
  return function giveCourseFeedbackAction({
    parameters,
    payload,
  }: {
    parameters: GiveCourseFeedbackPathParameters;
    payload: GiveCourseFeedbackPayload;
  }) {
    const path = giveCourseFeedbackActionPath.insert(parameters);

    return dispatchAction(() =>
      axios.put<GiveCourseFeedbackResponse, GiveCourseFeedbackResponse, GiveCourseFeedbackPayload>(path, payload)
    );
  };
}
