import TeacherDashboardLayout from 'layout/teacher-dashboard-layout/TeacherDashboardLayout';
import useApi from 'api/use-api';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import StudentDetailContent from 'pages/teacher-module/classroom/students/detail/StudentDetailContent';
import useClassrooms from 'data/classrooms/use-classrooms';
import { GetClassroomStudentDetailResponse } from 'api/actions/get-classroom-student-detail/get-classroom-student-detail-response';

/**
 * Student detail page
 */
export default function StudentDetailPage() {
  const { classroomId, studentId } = useParams();
  const { getAction } = useApi();
  const { getClassroom } = useClassrooms();
  const getClassroomStudentDetailAction = getAction('GetClassroomStudentDetail');
  const [student, setStudent] = useState<GetClassroomStudentDetailResponse['student']>();
  const [homework, setHomework] = useState<GetClassroomStudentDetailResponse['homework']>();
  const [stats, setStats] = useState<GetClassroomStudentDetailResponse['stats']>();
  const classroomName = getClassroom({ classroomId: classroomId! })?.name;

  useEffect(() => {
    getClassroomStudentDetailAction({ parameters: { classroomId: classroomId!, studentId: studentId! } }).success(
      ({ student, homework, stats }) => {
        setStudent(student);
        setHomework(
          homework.sort(({ homework: first }, { homework: second }) =>
            first.homeworkNumber > second.homeworkNumber ? -1 : 1
          )
        );
        setStats(stats);
      }
    );
  }, [studentId]);

  return (
    <TeacherDashboardLayout hasHeader hasSidebar>
      <>
        {student && homework && stats && classroomName && (
          <StudentDetailContent
            studentId={student.userId}
            name={`${student.firstName} ${student.lastName}`}
            homework={homework}
            stats={stats}
            classroomName={classroomName}
          />
        )}
      </>
    </TeacherDashboardLayout>
  );
}
