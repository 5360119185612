import Breadcrumbs from 'components/Breadcrumbs';
import ArrowRightIcon from 'components/icons/ArrowRightIcon';
import ClassroomStatsCard from 'components/ClassroomStatsCard';
import AssignedAssignmentsIcon from 'components/icons/AssignedAssignmentsIcon';
import { _t, _tx } from 'lang';
import ThumbsUpLargeIcon from 'components/icons/ThumbsUpLargeIcon';
import NonSubmittedAssignmentsIcon from 'components/icons/NonSubmittedAssignmentsIcon';
import { Button } from '@mantine/core';
import RedCrossIcon from 'components/icons/RedCrossIcon';
import RemoveStudentModal from 'pages/teacher-module/classroom/students/modals/RemoveStudentModal';
import { useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import { GetClassroomStudentDetailResponse } from 'api/actions/get-classroom-student-detail/get-classroom-student-detail-response';
import Message from 'components/Message';
import IIcon from 'components/icons/IIcon';
import sprintf from 'lang/sprintf';
import StudentHomeworkDetailRow from 'pages/teacher-module/classroom/students/detail/StudentHomeworkDetailRow';
import { noop } from 'lodash';

interface Props {
  studentId: string;
  name: string;
  homework: GetClassroomStudentDetailResponse['homework'];
  stats: GetClassroomStudentDetailResponse['stats'];
  classroomName: string;
}

/**
 * The content of the Student detail page.
 */
export default function StudentDetailContent({ studentId, name, homework, stats, classroomName }: Props) {
  const { classroomId } = useParams();
  const [openedRemoveStudentModal, setOpenedRemoveStudentModal] = useState(false);

  return (
    <div>
      <Breadcrumbs>
        <Link to={`/teacher/classrooms/classroom/${classroomId}/detail`}>{classroomName}</Link>
        <ArrowRightIcon stroke="#738491" />
        <Link to={`/teacher/classrooms/classroom/${classroomId}/students`}>{_t('Students in the classroom')}</Link>
        <ArrowRightIcon stroke="#738491" />
        <div className="font-normal text-text-dark-2">{name}</div>
      </Breadcrumbs>
      <div className="mt-[16px] flex items-center justify-between text-[24px] font-semibold leading-[29px]">
        <div>{name}</div>
        <Button
          className="h-[42px] border bg-white text-red hover:border-red hover:bg-bg-red-menu-item"
          onClick={() => setOpenedRemoveStudentModal(true)}
          radius={7}
        >
          <div className="flex gap-[10px]">
            <RedCrossIcon />
            <div>{_t('Remove student from the classroom')}</div>
          </div>
        </Button>
      </div>
      <div className="flex gap-[16px] pb-[32px] pt-[24px] lg:max-w-[1000px]">
        <ClassroomStatsCard
          icon={<AssignedAssignmentsIcon />}
          title={_tx('Assigned homework', 'Assigned homework', 2)}
          value={`${homework.length} ${_tx('homework', 'homework', homework.length)}`}
          className="w-[33.3%]"
        />
        <ClassroomStatsCard
          icon={<ThumbsUpLargeIcon />}
          title={_t('Average success rate')}
          value={stats.averageSuccessRate ? `${Math.round(stats.averageSuccessRate * 100)} %` : '-'}
          className="w-[33.3%]"
        />
        <ClassroomStatsCard
          icon={<NonSubmittedAssignmentsIcon />}
          title={_t('Did not submit')}
          value={sprintf(_tx('%d homework', '%d homework', stats.numberOfNotSubmitted), stats.numberOfNotSubmitted)}
          className="w-[33.3%]"
        />
      </div>
      <div className="border-b border-b-input-outline-shadow" />
      <div className="flex flex-col gap-[8px] pt-[32px]">
        {homework.length > 0 ? (
          homework.map(({ homework, assignment }) => (
            <StudentHomeworkDetailRow
              key={homework.homeworkId}
              classroomId={classroomId!}
              homeworkId={homework.homeworkId}
              assignmentId={assignment.generated?.assignmentId}
              homeworkNumber={homework.homeworkNumber}
              successRate={assignment.submitted?.evaluation.successRate}
              isActive={!homework.isPastDeadline}
              studentName={name}
            />
          ))
        ) : (
          <Message
            children={
              <div className="flex items-center gap-[11px]">
                <div className="h-[18px] w-[18px]">
                  <IIcon />
                </div>
                <div>{_t('The student has not been assigned a homework yet.')}</div>
              </div>
            }
            type="info"
          />
        )}
      </div>
      <RemoveStudentModal
        studentId={studentId}
        opened={openedRemoveStudentModal}
        setOpened={() => setOpenedRemoveStudentModal(false)}
        setDisplayMessage={noop}
        shouldRedirect={true}
      />
    </div>
  );
}
