import { Button } from '@mantine/core';
import PlusIcon from 'components/icons/PlusIcon';
import { _t } from 'lang';
import TeacherDashboardLayout from 'layout/teacher-dashboard-layout/TeacherDashboardLayout';
import CreateClassroomModal from 'pages/teacher-module/classrooms/modal/CreateClassroomModal';
import { useState } from 'react';
import TeacherMenu from 'layout/teacher-dashboard-layout/parts/sidebar/TeacherMenu';
import { ClassroomsList } from 'pages/teacher-module/classrooms/ClassroomsList';

/**
 * Classrooms page.
 *
 * @see https://www.figma.com/file/SjggpqHVlWxBKa2cIG349A/Math-Exercises?node-id=634%3A8668&t=J3hpSwWIuCcusYbV-0
 */
export default function ClassroomsPage() {
  const [displayCreateClassroomModal, setDisplayCreateClassroomModal] = useState(false);

  return (
    <TeacherDashboardLayout hasHeader>
      <div>
        <TeacherMenu />
        <div className="ml-auto mr-auto lg:max-w-[970px] lg:pb-[130px] lg:pt-[32px]">
          <div className="flex justify-between">
            <div className="flex items-center justify-start">
              <span className="text-[24px] font-semibold leading-[29px]">{_t('Your classrooms')}</span>
            </div>
            <Button
              className="h-[42px] w-[198px] rounded-[7px] bg-green-primary text-[14px] font-semibold leading-[17px] text-white hover:bg-green-dark active:bg-green-dark"
              onClick={() => setDisplayCreateClassroomModal(true)}
            >
              <div className="flex items-center gap-[10px]">
                <PlusIcon />
                <span>{_t('Create classroom')}</span>
              </div>
            </Button>
          </div>
          <ClassroomsList />
          <CreateClassroomModal opened={displayCreateClassroomModal} setOpened={setDisplayCreateClassroomModal} />
        </div>
      </div>
    </TeacherDashboardLayout>
  );
}
