import { useForm } from '@mantine/form';
import { _t } from 'lang';
import createValidator from 'components/forms/validators/create-validator';
import required from 'components/forms/validators/rules/rule-required';
import useApi from 'api/use-api';
import { Button } from '@mantine/core';
import PasswordInput from 'components/forms/inputs/PasswordInput';
import { useNavigate } from 'react-router-dom';
import { LOGIN_PAGE_PATH } from 'routes/paths';
import passwordFormat from 'components/forms/validators/rules/rule-password-format';
import { useState } from 'react';

/**
 * The type of the reset password form data.
 */
interface FormData {
  password: string;
  passwordRepeat: string;
}

/**
 * The parameters of the component.
 */
interface Props {
  email: string;
  token: string;
}

/**
 * The set password form.
 */
export default function SetPasswordForm({ email, token }: Props) {
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const { getAction } = useApi();
  const resetPasswordCommitAction = getAction('ResetPasswordCommit');

  /**
   * The form data.
   */
  const form = useForm<FormData>({
    initialValues: {
      password: '',
      passwordRepeat: '',
    },

    validate: {
      password: createValidator([required, passwordFormat]),
      passwordRepeat: (passwordRepeat, { password }) =>
        passwordRepeat === password ? null : _t('Passwords do not match.'),
    },
  });

  /**
   * Is executed when the form is submitted with valid data.
   */
  function submitForm({ password }: FormData) {
    if (!loading) {
      setLoading(true);

      resetPasswordCommitAction({ payload: { password, email, token } })
        .success(() => navigate(LOGIN_PAGE_PATH, { state: { displayMessage: true } }))
        .complete(() => setLoading(false));
    }
  }

  return (
    <form onSubmit={form.onSubmit(submitForm)}>
      <PasswordInput
        className="text-[14px] font-medium leading-[17px] text-text-dark"
        withAsterisk
        placeholder={_t('Set your new password')}
        label={_t('New password')}
        {...form.getInputProps('password')}
      />

      <PasswordInput
        className="mt-[24px] text-[14px] font-medium leading-[17px] text-text-dark"
        withAsterisk
        placeholder={_t('Repeat your new password')}
        label={_t('New password (repeat)')}
        {...form.getInputProps('passwordRepeat')}
      />

      <div className="pt-[24px]">
        <Button
          disabled={loading}
          className="h-[56px] bg-green-primary text-[18px] font-semibold leading-[22px] hover:bg-green-dark active:bg-green-dark"
          fullWidth
          type="submit"
          radius={10}
        >
          {_t('Update password')}
        </Button>
      </div>
    </form>
  );
}
