interface Props {
  stroke?: string;
  width?: string;
  height?: string;
}

/**
 * Copy Icon
 */
export default function CopyIcon({ stroke = '#738491', width = '28', height = '28' }: Props) {
  return (
    <svg width={width} height={height} viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
      <mask id="path-1-inside-1_631_8147" fill="white">
        <rect x="9" y="3" width="14" height="18" rx="1" />
      </mask>
      <rect
        x="9"
        y="3"
        width="14"
        height="18"
        rx="1"
        stroke={stroke}
        strokeWidth="4"
        mask="url(#path-1-inside-1_631_8147)"
      />
      <path d="M6 9V23C6 23.5523 6.44772 24 7 24H19" stroke={stroke} strokeWidth="2" />
    </svg>
  );
}
