import Assignment from 'components/assignment/Assignment';
import ContinueToNextStepButton from 'pages/courses-module/course-step/main/ContinueToNextStepButton';
import useCourse from 'data/course/use-course';
import useCourseStep from 'pages/courses-module/course-step/context/use-course-step';

/**
 * The parameters of the component.
 */
interface Props {
  assignmentId: string;
}

/**
 * The exercise part of the course.
 */
export default function CourseStepContentExercise({ assignmentId }: Props) {
  const { updateCourseProgress } = useCourse();
  const { course, currStep } = useCourseStep();

  /**
   * Handles the submission of the assignment.
   */
  function onSolved() {
    updateCourseProgress({
      courseId: course.courseId,
      steps: [{ progress: 1, stepId: currStep!.stepId }],
    });
  }

  return (
    <div className="ml-auto mr-auto max-w-[618px] pl-[24px] pr-[24px] lg:pb-[56px] lg:pt-[56px]">
      <Assignment
        assignmentId={assignmentId}
        onSolved={onSolved}
        afterEvaluationContent={<ContinueToNextStepButton className="lg:mt-[32px]" />}
      />
    </div>
  );
}
