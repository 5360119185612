interface Props {
  stroke?: string;
}

/**
 * Home icon for the teacher's sidebar navigation item.
 */
export default function HomeIcon({ stroke = '#535F6A' }: Props) {
  return (
    <div>
      <svg
        width="14"
        height="16"
        viewBox="0 0 14 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className="hidden lg:block"
      >
        <path
          d="M1 7.41421L7 1.41421L13 7.41421L13 15H1L1 7.41421Z"
          stroke={stroke}
          strokeWidth="2"
          strokeLinejoin="round"
        />
        <line x1="9" y1="9" x2="5" y2="9" stroke={stroke} strokeWidth="2" strokeLinecap="round" />
      </svg>
      <svg
        width="20"
        height="22"
        viewBox="0 0 20 22"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className="lg:hidden"
      >
        <path
          d="M18.3334 10.0815V20.6673H1.66675L1.66675 10.0815L10.0001 1.7482L18.3334 10.0815Z"
          stroke={stroke}
          strokeWidth="2"
          strokeLinejoin="round"
        />
        <line x1="13" y1="12" x2="7" y2="12" stroke={stroke} strokeWidth="2" strokeLinecap="round" />
      </svg>
    </div>
  );
}
