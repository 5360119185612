import { _t } from 'lang';

/**
 * Copyright
 */
export default function Copyright() {
  const year = new Date().getFullYear();

  return (
    <div className="flex flex-col pt-[16px] text-[12px] leading-[20px] text-text-registration lg:block lg:flex-row lg:pt-0 lg:text-right">
      <span>&copy; {year} </span>
      <span>Zvědavá mysl, s.r.o. {_t('All rights reserved.')}</span>
    </div>
  );
}
