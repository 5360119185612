import { useBasicPageLayout } from 'layout/basic-page-layout/BasicPageLayoutContext';
import DesktopHeader from 'layout/parts/header/DesktopHeader';
import PhoneHeader from 'layout/parts/header/PhoneHeader';

/**
 * Page header
 */
export default function PageHeader() {
  const { isPhoneHeaderVisible } = useBasicPageLayout();

  return (
    <div>
      <div className={isPhoneHeaderVisible ? 'lg:hidden' : 'hidden'}>
        <PhoneHeader />
      </div>
      <div className="hidden lg:block">
        <DesktopHeader />
      </div>
    </div>
  );
}
