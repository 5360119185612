import NumberInCircle from 'components/NumberInCircle';
import { NavLink } from 'react-router-dom';

interface Props {
  to: string;
  icon: JSX.Element;
  activeIcon: JSX.Element;
  label: string;
  supplementaryItemInfo?: number;
  basePath?: string;
  className?: string;
}

/**
 * The bottom bar menu item.
 */
export default function BottomBarMenuItem({
  to,
  icon,
  activeIcon,
  label,
  supplementaryItemInfo,
  basePath,
  className = '',
}: Props) {
  const currentPath = window.location.pathname;

  return (
    <NavLink to={to}>
      {({ isActive }) => {
        let shouldBeActive = isActive || currentPath.startsWith(to);

        if (basePath) {
          shouldBeActive = shouldBeActive || currentPath.startsWith(basePath);
        }

        const background = shouldBeActive ? 'bg-bg-shadow' : '';

        return (
          <div className={`flex h-[52px] flex-col items-center justify-start rounded-[7px] ${background} ${className}`}>
            <div className="flex h-[32px] w-[32px] items-center justify-center">
              {shouldBeActive ? activeIcon : icon}
            </div>
            {supplementaryItemInfo && (
              // TODO do this better
              <div className="fixed bottom-[32px] left-[42%]">
                <NumberInCircle
                  value={supplementaryItemInfo}
                  className="h-[20px] w-[20px] text-[12px] font-semibold leading-[15px]"
                />
              </div>
            )}
            <div
              className={`pt-[2px] text-center text-[10px] font-semibold leading-[12px] ${
                shouldBeActive ? 'font-semibold text-text-dark' : 'font-medium text-text-dark-2'
              }`}
            >
              {label}
            </div>
          </div>
        );
      }}
    </NavLink>
  );
}
