import { GetExercisesResponse } from 'api/actions/get-exercises/get-exercises-response';
import { _t } from 'lang';
import TeacherExerciseCard from 'pages/teacher-module/classroom/exercises/TeacherExerciseCard';
import { Dispatch, SetStateAction } from 'react';

interface Props {
  exercises?: GetExercisesResponse['exercises'];
  selectedExercises: { variants: number; exerciseId: number }[];
  setSelectedExercises: Dispatch<SetStateAction<{ variants: number; exerciseId: number }[]>>;
}

/**
 * Content of the ClassExercises page.
 */
export default function ClassExercisesContent({ exercises, selectedExercises, setSelectedExercises }: Props) {
  /**
   * Updates the list of selected exercises.
   *
   * @param exerciseId
   * @param variants
   */
  function updateSelectedExercises(exerciseId: number, variants: number) {
    // the exercise was unselected
    if (variants === 0) {
      setSelectedExercises((prev) => [...prev.filter((exercise) => exercise.exerciseId !== exerciseId)]);
      return;
    }

    // there was no such exercise
    if (!selectedExercises.find((exercise) => exercise.exerciseId === exerciseId)) {
      setSelectedExercises((prev) => [...prev, { exerciseId, variants }]);
    } else {
      // there is exercise but the number of variants might have changed
      setSelectedExercises((prev) => [
        ...prev.filter((exercise) => exercise.exerciseId !== exerciseId),
        { exerciseId, variants },
      ]);
    }
  }

  return (
    <div className="grid grid-cols-[100%] gap-[16px] sm:grid-cols-[repeat(auto-fill,minmax(294px,1fr))]">
      {exercises ? (
        exercises.map((exercise, i) => {
          const selected = selectedExercises.find((ex) => ex.exerciseId === exercise.exerciseId);

          const isSelected = !!selected;
          const numberOfVariants = selected?.variants ?? 1;

          return (
            <TeacherExerciseCard
              key={exercise.exerciseId}
              exerciseId={exercise.exerciseId}
              title={exercise.title}
              description={exercise.description}
              selected={isSelected}
              maximalNumberOfVariants={exercise.variantsCount}
              initialNumberOfVariants={numberOfVariants}
              setSelected={updateSelectedExercises}
              type={exercise.exerciseType === 'Numerical' ? _t('Numerical problem') : _t('Word problem')}
              topics={exercise.topics}
            />
          );
        })
      ) : (
        <div>{_t('No exercises found')}</div>
      )}
    </div>
  );
}
