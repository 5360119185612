import { NavLink } from 'react-router-dom';

interface Props {
  to: string;
  icon: JSX.Element;
  activeIcon: JSX.Element;
  basePath?: string;
}

/**
 * The menu item of the collapsed sidebar.
 */
export default function CollapsedSidebarMenuItem({ to, icon, activeIcon, basePath }: Props) {
  const currentPath = window.location.pathname;

  return (
    <NavLink to={to}>
      {({ isActive }) => {
        let shouldBeActive = isActive || currentPath.startsWith(to);

        if (basePath) {
          shouldBeActive = shouldBeActive || currentPath.startsWith(basePath);
        }

        return (
          <div
            className={`flex
                h-[56px] w-[56px] items-center justify-center rounded-[7px]
              hover:bg-bg-shadow ${shouldBeActive ? 'bg-bg-shadow' : ''}`}
          >
            {shouldBeActive ? activeIcon : icon}
          </div>
        );
      }}
    </NavLink>
  );
}
