import { AxiosInstance } from 'axios';
import { SweetPath } from 'sweet-path';
import dispatchAction from 'api/dispatch-action';

import { GetClassroomHomeworkPathParameters } from 'api/actions/get-classroom-homework/get-classroom-homework-path-parameters';

import { GetClassroomHomeworkResponse } from 'api/actions/get-classroom-homework/get-classroom-homework-response';

/**
 * The path of the GetClassroomHomework action.
 */
export const getClassroomHomeworkActionPath = new SweetPath<keyof GetClassroomHomeworkPathParameters>(
  '/classroom/{classroomId}/homework',
  { path: '{param}' }
);

/**
 * Creates a contextualized GetClassroomHomework actions.
 */
export default function createGetClassroomHomeworkAction(axios: AxiosInstance) {
  return function getClassroomHomeworkAction({ parameters }: { parameters: GetClassroomHomeworkPathParameters }) {
    const path = getClassroomHomeworkActionPath.insert(parameters);

    return dispatchAction(() => axios.get<GetClassroomHomeworkResponse, GetClassroomHomeworkResponse>(path));
  };
}
