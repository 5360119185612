import StarRating, { StarRatingType } from 'pages/courses-module/course-step/main/content/overlays/StarRating';
import useApi from 'api/use-api';
import { Link } from 'react-router-dom';
import { useState } from 'react';
import useCourseStep from 'pages/courses-module/course-step/context/use-course-step';
import { _t } from 'lang';

/**
 * Displays the feedback overlay after the video.
 */
export default function FeedbackOverlay() {
  const [stars, setStars] = useState<StarRatingType | undefined>(undefined);
  const { getAction } = useApi();
  const { course, stepId, nextStepLink } = useCourseStep();
  const { courseId } = course;

  /**
   * Updates the number of stars.
   *
   * @param val the new number of stars
   */
  function handleStarChange(val: StarRatingType) {
    setStars(val);

    const giveCourseFeedbackAction = getAction('GiveCourseFeedback');

    giveCourseFeedbackAction({
      parameters: { courseId },
      payload: {
        stars: val,
        stepId,
      },
    });
  }

  return (
    <div>
      <span className="text-[18px] font-semibold leading-[28px] lg:text-[24px] lg:leading-[34px]">
        {_t('How did you like our lecture?')}
      </span>
      <StarRating onChange={handleStarChange} value={stars} />
      {nextStepLink && (
        <Link
          className="ml-auto mr-auto block pt-[29px] text-center text-[14px] font-semibold leading-[17px] text-white-opacity-07 underline lg:pt-[40px]"
          to={nextStepLink}
        >
          <span>{_t('Continue')}</span>
        </Link>
      )}
    </div>
  );
}
