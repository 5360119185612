import { Box, Center, Loader, Stack } from '@mantine/core';
import Assignment from 'components/assignment/Assignment';
import { _t } from 'lang';
import { useEffect, useState } from 'react';
import { Navigate, useParams } from 'react-router-dom';
import { HOME_PAGE_PATH } from 'routes/paths';

/**
 * Page used to export exercises to PDF.
 */
export default function ExportExercisesPage() {
  const { assignmentId } = useParams();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (!loading) {
      window.print();
      window.close();
    }
  }, [loading]);

  if (!assignmentId) {
    return <Navigate to={HOME_PAGE_PATH} />;
  }

  return (
    <>
      {loading && (
        <Center sx={{ position: 'relative', width: '100%', height: '100vh' }}>
          <Stack spacing={16}>
            <Box sx={{ fontSize: '28px', lineHeight: '28px', fontWeight: 500 }}>{_t('Loading ...')}</Box>
            <Center>
              <Loader size={60} color="green" />
            </Center>
          </Stack>
        </Center>
      )}
      <Assignment
        hideSubmitButton
        isPrint
        assignmentId={assignmentId}
        onReady={() => setTimeout(() => setLoading(false), 3000)}
      />
    </>
  );
}
