import { IAssignmentSolution, IExerciseQuestion, IExerciseQuestionAnswer } from 'data/assignment/assignment';
import { AnswersMap } from 'components/assignment/form/create-initial-values';
import { isEmpty } from 'lodash';

/**
 * Parses an answer to a single question.
 */
function parseAnswer(
  question: IExerciseQuestion,
  answer: string | string[] | undefined
): IExerciseQuestionAnswer | undefined {
  if (!answer || isEmpty(answer)) {
    return undefined;
  }

  if (question.resultType === 'Fraction') {
    const [numerator, denominator] = (answer as string).split('/');
    return { numerator, denominator };
  }

  return answer;
}

/**
 * Parses the answers provided by the user into the format expected by the
 * backend.
 */
export default function parseAnswers(questions: IExerciseQuestion[], answers: AnswersMap): IAssignmentSolution {
  return questions.map((question) => ({
    questionId: question.questionId,
    answer: parseAnswer(question, answers[question.questionId]),
  }));
}
