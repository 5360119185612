import { TextInput as MantineTextInput, TextInputProps } from '@mantine/core';
import CorrectIcon from 'components/icons/CorrectIcon';
import IncorrectIcon from 'components/icons/IncorrectIcon';
import useShouldValidate from 'components/forms/inputs/use-should-validate';

interface Props extends TextInputProps {
  isValid: boolean;
}

/**
 * Text input
 */
export default function TextInput({ isValid, error, ...otherProps }: Props) {
  const { onBlur, shouldValidate } = useShouldValidate(otherProps);

  let icon = <></>;

  if (error) {
    icon = <IncorrectIcon />;
  } else if (shouldValidate) {
    icon = isValid ? <CorrectIcon /> : <IncorrectIcon />;
  }

  return (
    <MantineTextInput
      radius={10}
      rightSectionWidth={50}
      {...otherProps}
      error={error}
      rightSection={icon}
      onBlur={onBlur}
    />
  );
}
