/**
 * Hamburger menu icon.
 */
export default function HamburgerMenuIcon() {
  return (
    <svg width="24" height="16" viewBox="0 0 24 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect width="24" height="2" rx="1" fill="#738491" />
      <rect y="14" width="24" height="2" rx="1" fill="#738491" />
    </svg>
  );
}
