interface Props {
  imgSrc: string;
  heading: string;
  description: string | JSX.Element;
}

/**
 * One row in the HelpContent component.
 */
export default function HelpRow({ imgSrc, heading, description }: Props) {
  return (
    <div className="w-full md:grid md:grid-cols-2 md:items-center md:gap-x-[60px]">
      <div className="col-start-2 col-end-3 row-start-1 row-end-2 mb-[16px] md:mb-0">
        <div className="mb-[16px] text-[24px] font-semibold leading-[32px]">{heading}</div>
        <div className="text-[16px] leading-[24px] text-text-shadow">{description}</div>
      </div>
      <img
        className="col-start-1 col-end-2 row-start-1 row-end-2 rounded-[10px] shadow-2xl"
        src={imgSrc}
        alt={heading}
      />
    </div>
  );
}
