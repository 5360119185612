import { Checkbox, CheckboxStylesNames, CheckboxStylesParams, Styles } from '@mantine/core';
import { Dispatch, SetStateAction } from 'react';

/**
 * The styles of the checkbox.
 */
const styles: Styles<CheckboxStylesNames, CheckboxStylesParams> = {
  input: {
    borderColor: '#738491',
    backgroundColor: '#F3F6F8',
    '&:checked': { borderColor: '#0AB892', backgroundColor: '#0AB892' },
    cursor: 'pointer',
  },
  icon: {
    color: 'white',
  },
};

interface Props {
  value: string;
  label?: string;
  checked: boolean;
  setChecked: Dispatch<SetStateAction<string[]>>;
}

/**
 * One row in the Year filters
 */
export default function FilterRow({ value, label = value, checked, setChecked }: Props) {
  const bgColor = checked ? 'bg-bg-shadow' : 'bg-white';
  const fontWeight = checked ? 'font-semibold' : 'font-medium';

  return (
    <div
      className={`flex h-[40px] cursor-pointer items-center gap-[16px] rounded-[7px] pl-[16px] pr-[16px] text-[14px] leading-[17px] hover:bg-bg-shadow hover:font-semibold ${fontWeight} ${bgColor}`}
      onClick={() => setChecked((prev) => (prev.includes(value) ? prev.filter((p) => p !== value) : [...prev, value]))}
    >
      <Checkbox
        size="xs"
        checked={checked}
        onChange={() =>
          setChecked((prev) => (prev.includes(value) ? prev.filter((p) => p !== value) : [...prev, value]))
        }
        onClick={(e) => e.stopPropagation()}
        styles={styles}
      />
      {label.replace(/^./, (ch) => ch.toLocaleUpperCase())}
    </div>
  );
}
