import { _t } from 'lang';
import { ValidationError } from 'components/forms/validators/validation-error';

/**
 * Validates decimal input based on regex and precision.
 */
export default function decimalResult(precision: number): (val: string) => ValidationError | null {
  return function (val: string): ValidationError | null {
    if (!val) {
      return null;
    }

    // minus or plus or nothing
    // digits - at least one
    // OR
    // the same as above AND:
    // dot
    // digits - exactly much as precision states
    const regex = precision === 0 ? /^[-+]?[0-9]+$/ : new RegExp(`^[-+]?[0-9]+([.,][0-9]{1,${precision}})?$`);

    const match = regex.test(val.trim());

    if (!match) {
      return {
        message: _t('Please check the number format.'),
      };
    }

    return null;
  };
}
