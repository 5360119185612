import { GetCoursePlanResponse } from 'api/actions/get-course-plan/get-course-plan-response';
import { GetCoursesResponse } from 'api/actions/get-courses/get-courses-response';
import { DispatchedAction } from 'api/dispatch-action';
import { createContext } from 'react';

/**
 * Data about a single course.
 */
export type Course = GetCoursesResponse['courses'][number];

/**
 * The identifier of a course.
 */
export type CourseId = Course['courseId'];

/**
 * The slug of a course.
 */
export type CourseSlug = Course['slug'];

/**
 * Either the id or the slug of a course.
 */
export type CourseIdOrSlug = CourseId | CourseSlug;

/**
 * The plan of a course.
 */
export type CoursePlan = GetCoursePlanResponse;

/**
 * The course context interface.
 */
interface CourseContext {
  /**
   * Fetches data about courses.
   */
  fetchCourses: () => void;

  /**
   * Fetches the plan of the specified course.
   */
  fetchCoursePlan: ({ courseIdOrSlug }: { courseIdOrSlug: CourseId | CourseSlug }) => void;

  /**
   * Returns the only course with the specified id or slug.
   */
  getCourse: ({ courseId, slug }: { courseId?: CourseId; slug?: CourseSlug }) => Course | undefined;

  /**
   * Returns the plan for the specified course.
   */
  getCoursePlan: ({ courseIdOrSlug }: { courseIdOrSlug: CourseId | CourseSlug }) => CoursePlan | undefined;

  /**
   * Returns the progress of the specified course step.
   */
  getCourseStepProgress: ({ courseIdOrSlug, stepId }: { courseIdOrSlug: CourseIdOrSlug; stepId: number }) => number;

  /**
   * Updates the progress of a course.
   */
  updateCourseProgress: ({
    courseId,
    steps,
  }: {
    courseId: CourseId;
    steps: { stepId: number; progress: number }[];
  }) => DispatchedAction<void>;

  /**
   * Enrolls the user in the specified course.
   */
  enrollInCourse: ({ courseId }: { courseId: CourseId }) => DispatchedAction<void>;
}

/**
 * The course context is used to store data about courses.
 */
export default createContext<CourseContext>(undefined!);
