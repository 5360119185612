interface Props {
  stroke?: string;
  lgWidth?: string;
  lgHeight?: string;
  lgStrokeWidth?: string;
}

/**
 * Pen Icon
 */
export default function PenIcon({ stroke = '#535F6A', lgWidth = '20', lgHeight = '16', lgStrokeWidth = '2' }: Props) {
  return (
    <div>
      <svg
        width={lgWidth}
        height={lgHeight}
        viewBox="0 0 20 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className="hidden lg:block"
      >
        <path
          d="M1 14.4902H16.6098"
          stroke={stroke}
          strokeWidth={lgStrokeWidth}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M14.6823 1.73635L6.19692 10.2255C5.75357 10.6689 5.50684 11.2665 5.50684 11.891V14.4933H8.10911C8.73366 14.4933 9.33121 14.2465 9.77456 13.8032L18.2599 5.31785C19.2468 4.33091 19.2468 2.73099 18.2599 1.7402C17.273 0.753266 15.6731 0.753266 14.6823 1.7402V1.73635Z"
          stroke={stroke}
          strokeWidth={lgStrokeWidth}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M14.2197 2.97852L17.0225 5.77741"
          stroke={stroke}
          strokeWidth={lgStrokeWidth}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
      <svg
        width="26"
        height="21"
        viewBox="0 0 26 21"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className="lg:hidden"
      >
        <path d="M1 19.6523H21.813" stroke={stroke} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
        <path
          d="M19.2432 2.64781L7.92939 13.9667C7.33826 14.5579 7.00928 15.3546 7.00928 16.1873V19.657H10.479C11.3117 19.657 12.1084 19.3281 12.6996 18.7369L24.0134 7.42314C25.3293 6.10723 25.3293 3.97401 24.0134 2.65295C22.6975 1.33704 20.5642 1.33704 19.2432 2.65295V2.64781Z"
          stroke={stroke}
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M18.6265 4.30273L22.3635 8.03459"
          stroke={stroke}
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </div>
  );
}
