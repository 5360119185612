import { Link } from 'react-router-dom';

/**
 * The parameters of the CompareExercise component.
 */
export interface CompareExerciseProps {
  number: string;
  link: string;
}

/**
 * Compares exercise from Monitor and from course.
 */
export default function CompareExercise({ number, link }: CompareExerciseProps) {
  return (
    <div className="grid gap-[24px] lg:grid-cols-[7fr_5fr] lg:gap-[30px]">
      <div className="w-full">
        <div className="mb-[18px] text-[18px] font-semibold leading-[28px] lg:text-[24px] lg:leading-[34px]">
          Monitor 2021 - Príklad {number}
        </div>
        <img
          className="block h-auto w-full rounded-[10px] shadow-[0px_14px_14px_rgba(22,26,29,0.1)]"
          src={`/slovakia-comparison/monitor-${number}.png`}
          alt={`Slovakia comparison - exercise ${number}`}
        />
      </div>
      <div className="w-full">
        <div className="mb-[18px] flex gap-x-[10px] text-[18px] font-semibold leading-[28px] lg:text-[24px] lg:leading-[34px]">
          <div>Náš podobný príklad</div>
          <Link
            to={link}
            className="active:bg-green-primary-opacity-02r flex h-[34px] w-[147px] items-center justify-center rounded-[7px] bg-green-primary-opacity-01 text-[16px] font-semibold leading-[19px] text-green-primary hover:bg-green-primary-opacity-02"
          >
            Zobraziť v kurze
          </Link>
        </div>
        <img
          className="block h-auto w-full rounded-[10px] shadow-[0px_14px_14px_rgba(22,26,29,0.1)]"
          src={`/slovakia-comparison/course-${number}.png`}
          alt={`Slovakia comparison - exercise ${number}`}
        />
      </div>
    </div>
  );
}
