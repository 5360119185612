import { AxiosInstance } from 'axios';
import { SweetPath } from 'sweet-path';
import dispatchAction from 'api/dispatch-action';

import { ChangePasswordPayload } from 'api/actions/change-password/change-password-payload';
import { ChangePasswordPathParameters } from 'api/actions/change-password/change-password-path-parameters';

import { ChangePasswordResponse } from 'api/actions/change-password/change-password-response';

/**
 * The path of the ChangePassword action.
 */
export const changePasswordActionPath = new SweetPath<keyof ChangePasswordPathParameters>('/user/password/change', {
  path: '{param}',
});

/**
 * Creates a contextualized ChangePassword actions.
 */
export default function createChangePasswordAction(axios: AxiosInstance) {
  return function changePasswordAction({ payload }: { payload: ChangePasswordPayload }) {
    const path = changePasswordActionPath.original;

    return dispatchAction(() =>
      axios.post<ChangePasswordResponse, ChangePasswordResponse, ChangePasswordPayload>(path, payload)
    );
  };
}
