import { determineGrammarForm } from 'lang/grammar-form';
import cs from 'lang/translations/cs.json';
import sk from 'lang/translations/sk.json';
import pl from 'lang/translations/pl.json';
import { cs as dateFnsCs, sk as dateFnsSk, pl as dateFnsPl } from 'date-fns/locale';
import { Locale as DateFnsLocale } from 'date-fns';

/**
 * The key of the translation.
 */
type TranslationKey = string;

/**
 * The value of the translation.
 */
type TranslationValue =
  | string
  | {
      one: string;
      few: string;
      many?: string;
      other: string;
    };

/**
 * Translation key-value pairs.
 */
type LanguageDictionary = Record<TranslationKey, TranslationValue>;

/**
 * Mapping of language code to the translation dictionary.
 */
const dictionaries: Record<string, LanguageDictionary> = { cs, sk, pl };

/**
 * Mapping of language code to the date-fns locale.
 */
const dateFnsLocales: Record<string, DateFnsLocale> = { cs: dateFnsCs, sk: dateFnsSk, pl: dateFnsPl };

/**
 * The current language.
 */
export const lang =
  window.document.documentElement.lang === 'NGINX_TMPL_LANG'
    ? window.location.search.match(/lang=([a-z]{2})/)?.[1] ?? 'cs'
    : window.document.documentElement.lang;

/**
 * The currently used date-fns locale.
 */
export const dateFnsLocale = dateFnsLocales[lang] || dateFnsCs;

/**
 * The currently used dictionary.
 */
const dictionary = dictionaries[lang] || {};

/**
 * Translates the given string to the current language.
 */
export function _t(msgid: string): string {
  const translation = dictionary[msgid];

  if (!translation) {
    return msgid;
  }

  if (typeof translation === 'string') {
    return translation;
  }

  return translation.one;
}

/**
 * Translates the given string to the current language considering the given
 * count.
 */
export function _tx(msgid: string, msgidPlural: string, count: number): string {
  const grammarForm = determineGrammarForm(count, lang);
  const translations = dictionary[msgid];

  if (!translations) {
    return count === 1 ? msgid : msgidPlural;
  }

  if (typeof translations === 'string') {
    return count === 1 ? translations : msgidPlural;
  }

  const translation = translations[grammarForm];

  if (!translation) {
    return count === 1 ? msgid : msgidPlural;
  }

  return translation;
}
