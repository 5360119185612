interface Props {
  icon: JSX.Element;
  title: string;
  value: string | number | JSX.Element;
  className?: string;
}

/**
 * Classroom statistics card.
 */
export default function ClassroomStatsCard({ icon, title, value, className = '' }: Props) {
  return (
    <div
      className={`flex items-center gap-[20px] rounded-[10px] bg-white pb-[14px] pl-[16px] pr-[16px] pt-[14px] lg:h-[86px] lg:gap-[32px] lg:pb-[21px] lg:pt-[16px] xl:pl-[24px] xl:pr-[24px] ${className}`}
    >
      {icon}
      <div>
        <div className="text-[14px] font-medium leading-[17px] text-text-shadow">{title}</div>
        <div className="pt-[8px] text-[20px] font-semibold leading-[24px]">{value}</div>
      </div>
    </div>
  );
}
