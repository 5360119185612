import { Button, Modal, Select, TextInput } from '@mantine/core';
import { useForm } from '@mantine/form';
import { CreateClassroomPayload } from 'api/actions/create-classroom/create-classroom-payload';
import createValidator from 'components/forms/validators/create-validator';
import required from 'components/forms/validators/rules/rule-required';
import AddPersonIcon from 'components/icons/AddPersonIcon';
import CrossIcon from 'components/icons/CrossIcon';
import DoorIcon from 'components/icons/DoorIcon';
import PlusIcon from 'components/icons/PlusIcon';
import Clickable from 'components/misc/Clickable';
import useClassrooms from 'data/classrooms/use-classrooms';
import { _t } from 'lang';
import { noop } from 'lodash';
import { Dispatch, SetStateAction, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import getGradeName from 'utils/get-grade-name';

interface Props {
  opened: boolean;
  setOpened: Dispatch<SetStateAction<boolean>>;
}

/**
 * This is the modal that pops up when the user clicks the "Create Classroom" button.
 *
 * @see https://www.figma.com/file/SjggpqHVlWxBKa2cIG349A/Math-Exercises?node-id=631%3A7968&t=J3hpSwWIuCcusYbV-0
 */
export default function CreateClassroomModal({ opened, setOpened }: Props) {
  const { createClassroom } = useClassrooms();
  const [fill, setFill] = useState('#738491');
  const navigate = useNavigate();

  const form = useForm<CreateClassroomPayload>({
    initialValues: {
      grade: 0,
      name: '',
    },

    validate: {
      name: createValidator([required]),
      grade: (grade) => {
        if (grade === 0) {
          return _t('This field is required.');
        }

        return null;
      },
    },
  });

  /**
   * Creates the classroom.
   */
  const handleCreateClassroom = ({ name, grade }: CreateClassroomPayload) => {
    setOpened(false);

    createClassroom({ name, grade: Number(grade) }).success(({ classroomId }) => {
      form.reset();
      navigate(`/teacher/classrooms/classroom/${classroomId}/detail`);
    });
  };

  const grades = Array.from({ length: 12 }, (_, i) => ({ label: getGradeName(i + 1), value: String(i + 1) }));

  return (
    <Modal
      opened={opened}
      onClose={noop}
      centered
      size="auto"
      withCloseButton={false}
      styles={{ modal: { padding: '0!important', borderRadius: '10px!important' } }}
    >
      <form onSubmit={form.onSubmit(handleCreateClassroom)}>
        <div className="w-[570px]">
          <div className="border-b border-b-input-outline-shadow lg:flex lg:items-center lg:justify-between lg:pb-[33px] lg:pl-[24px] lg:pr-[36px] lg:pt-[33px]">
            <span className="text-[20px] font-semibold leading-[24px]">{_t('Create a new classroom')}</span>
            <Clickable
              onClick={() => setOpened(false)}
              className="flex h-[42px] w-[42px] items-center justify-center rounded-[7px] p-[8px] hover:bg-bg-shadow"
              onMouseEnter={() => {
                setFill('#353E45');
              }}
              onMouseLeave={() => {
                setFill('#738491');
              }}
            >
              <CrossIcon fill={fill} />
            </Clickable>
          </div>
          <div className="lg:pb-[32px] lg:pl-[37px] lg:pr-[32px] lg:pt-[32px]">
            <div className="lg:pb-[32px]">
              <div className="flex items-center lg:gap-[14px]">
                <DoorIcon />
                <span className="text-[18px] font-semibold leading-[22px]">{_t('Select grade')}</span>
              </div>
              <span className="pl-[28px] text-[14px] leading-[17px] text-text-dark-2 lg:pt-[4px]">
                {_t('Select grade and classroom name')}
              </span>
              <div className="flex items-center pl-[28px] lg:gap-[16px] lg:pt-[20px]">
                <div className="w-[50%]">
                  <Select
                    data={grades}
                    placeholder={_t('Select grade')}
                    {...form.getInputProps('grade')}
                    styles={{
                      input: { border: '1px solid #D9E0E6', backgroundColor: '#F3F6F8', borderRadius: '7px' },
                    }}
                  />
                </div>
                <div className="w-[50%]">
                  <TextInput
                    placeholder={_t('Classroom name')}
                    type="text"
                    {...form.getInputProps('name')}
                    styles={{
                      input: {
                        marginTop: '0!important',
                        height: '42px',
                        border: '1px solid #D9E0E6',
                        backgroundColor: '#F3F6F8',
                        borderRadius: '7px',
                      },
                    }}
                    className="w-full"
                  />
                </div>
              </div>
            </div>
            <div className="border-t border-t-input-outline-shadow lg:pt-[32px]">
              <div className="flex items-center lg:gap-[11px]">
                <AddPersonIcon />
                <span className="text-[18px] font-semibold leading-[22px]">{_t('Add students to a classroom')}</span>
              </div>
              <div className="pl-[30px] text-[14px] leading-[22px] lg:max-w-[474px] lg:pt-[4px]">
                {_t('After creating a classroom, you can add students to it by sharing the invite link.')}{' '}
                {_t('The students with this link will register and will be added automatically to the classroom.')}{' '}
                {_t('You can add students at any time.')}
              </div>
            </div>
          </div>
          <div className="flex flex-row-reverse border-t border-t-input-outline-shadow lg:pb-[32px] lg:pl-[24px] lg:pr-[24px] lg:pt-[32px]">
            <Button
              className="h-[42px] rounded-[10px] bg-green-primary pb-[13px] pl-[36px] pr-[41px] pt-[12px] text-[14px] font-semibold leading-[17px] hover:bg-green-dark active:bg-green-dark"
              type="submit"
              leftIcon={<PlusIcon />}
              disabled={!form.isValid()}
            >
              {_t('Create classroom')}
            </Button>
          </div>
        </div>
      </form>
    </Modal>
  );
}
