import Emoji from 'components/Emoji';
import ChangePasswordForm from 'components/forms/ChangePasswordForm';
import BasicPageLayout from 'layout/basic-page-layout/BasicPageLayout';
import { _t } from 'lang';
import { useState } from 'react';
import Alert from 'components/Alert';
import CorrectIcon from 'components/icons/CorrectIcon';

/**
 * The change password page.
 *
 * @see https://www.notion.so/Change-Password-2ac184cf4b7043e398e85e27fc00abbd
 */
export default function ChangePasswordPage() {
  const [displayMessage, setDisplayMessage] = useState<boolean>(false);

  return (
    <BasicPageLayout hasHeader hasFooter>
      <div className="flex pb-[64px] pl-[24px] pr-[24px] pt-[24px] lg:pb-0 lg:pt-[50px]">
        <div className="ml-auto mr-auto w-full lg:w-[370px]">
          <Alert icon={<CorrectIcon />} type="success" className={displayMessage ? 'visible' : 'invisible'}>
            <span className="text-[14px] font-medium leading-[20px]">
              {_t('You have successfully changed your password!')}
            </span>
          </Alert>
          <p className="pb-[32px] pt-[40px] text-[24px] font-semibold leading-[29px] text-text-dark">
            {_t('Change password')} <Emoji symbol="👀" label={_t('eyes')} />
          </p>
          <ChangePasswordForm onSuccess={() => setDisplayMessage(true)} />
        </div>
      </div>
    </BasicPageLayout>
  );
}
