import { AxiosInstance } from 'axios';
import { SweetPath } from 'sweet-path';
import dispatchAction from 'api/dispatch-action';

import { GetExercisesPathParameters } from 'api/actions/get-exercises/get-exercises-path-parameters';
import { GetExercisesQueryParameters } from 'api/actions/get-exercises/get-exercises-query-parameters';
import { GetExercisesResponse } from 'api/actions/get-exercises/get-exercises-response';

/**
 * The path of the GetExercises action.
 */
export const getExercisesActionPath = new SweetPath<keyof GetExercisesPathParameters>('/exercise', { path: '{param}' });

/**
 * Creates a contextualized GetExercises actions.
 */
export default function createGetExercisesAction(axios: AxiosInstance) {
  return function getExercisesAction({ query }: { query: GetExercisesQueryParameters }) {
    const path = getExercisesActionPath.original;

    return dispatchAction(() => axios.get<GetExercisesResponse, GetExercisesResponse>(path, { params: query }));
  };
}
