interface Props {
  stroke: string;
  className?: string;
}

/**
 * File icon.
 */
export default function FileIcon({ stroke, className = '' }: Props) {
  return (
    <svg
      className={className}
      width="16"
      height="18"
      viewBox="0 0 16 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <mask id="path-1-inside-1_32_1048" fill="white">
        <rect width="16" height="18" rx="1" />
      </mask>
      <rect width="16" height="18" rx="1" stroke={stroke} strokeWidth="4" mask="url(#path-1-inside-1_32_1048)" />
      <line x1="5" y1="7" x2="9" y2="7" stroke={stroke} strokeWidth="2" strokeLinecap="round" />
      <line x1="5" y1="11" x2="11" y2="11" stroke={stroke} strokeWidth="2" strokeLinecap="round" />
    </svg>
  );
}
