/**
 * NonSubmitted Assignments Icon
 */
export default function NonSubmittedAssignmentsIcon() {
  return (
    <svg width="37" height="42" viewBox="0 0 37 42" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M30 40H3C2.44771 40 2 39.5523 2 39V3C2 2.44772 2.44772 2 3 2H29C29.5523 2 30 2.44772 30 3V16.5"
        stroke="#353E45"
        strokeWidth="3"
        strokeLinecap="round"
      />
      <path d="M8.5 10H21" stroke="#353E45" strokeWidth="3" strokeLinecap="round" />
      <path d="M8.5 19H15.5" stroke="#353E45" strokeWidth="3" strokeLinecap="round" />
      <path d="M25 33L35 23" stroke="#353E45" strokeWidth="3" strokeLinecap="round" />
      <path d="M35 33L25 23" stroke="#353E45" strokeWidth="3" strokeLinecap="round" />
    </svg>
  );
}
