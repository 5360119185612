import { isFunction } from 'lodash';
import { useState } from 'react';

interface Props<TValue> {
  onBlur?: React.FocusEventHandler<HTMLInputElement>;
  value?: TValue;
}

/**
 * Hook to determine whether the input should be validated.
 */
export default function useShouldValidate<TValue>({ onBlur, value }: Props<TValue>) {
  const [shouldValidate, setShouldValidate] = useState(false);

  /**
   * Decorated onBlur function.
   */
  const onBlurDecorated = (...props: any[]) => {
    if (isFunction(onBlur)) {
      onBlur(...props);
    }

    setShouldValidate(!!value);
  };

  return {
    onBlur: onBlurDecorated,
    shouldValidate: shouldValidate && !!value,
  };
}
