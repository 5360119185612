import CorrectIcon from 'components/icons/CorrectIcon';
import CrossIcon from 'components/icons/CrossIcon';
import Clickable from 'components/misc/Clickable';
import { Dispatch, SetStateAction } from 'react';

interface Props {
  text: string;
  link?: JSX.Element;
  setDisplay: Dispatch<SetStateAction<boolean>>;
}

/**
 * Disappearing notification.
 */
export default function DisappearingNotification({ text, link, setDisplay }: Props) {
  return (
    <div className="flex h-[42px] w-[400px] justify-between rounded-[5px] border border-green-primary bg-[#F3FBFA] pl-[19px] pr-[17px] text-green-primary">
      <div className="flex items-center gap-[11px] text-[14px] font-medium leading-[20px]">
        <CorrectIcon />
        {text}
      </div>
      <div className="flex items-center gap-[21px]">
        {link}
        <Clickable onClick={() => setDisplay(false)}>
          <CrossIcon fill="#0AB892" height="16" width="16" />
        </Clickable>
      </div>
    </div>
  );
}
