import { AxiosInstance } from 'axios';
import { SweetPath } from 'sweet-path';
import dispatchAction from 'api/dispatch-action';

import { EnrollInCoursePathParameters } from 'api/actions/enroll-in-course/enroll-in-course-path-parameters';

import { EnrollInCourseResponse } from 'api/actions/enroll-in-course/enroll-in-course-response';

/**
 * The path of the EnrollInCourse action.
 */
export const enrollInCourseActionPath = new SweetPath<keyof EnrollInCoursePathParameters>('/course/{courseId}/enroll', {
  path: '{param}',
});

/**
 * Creates a contextualized EnrollInCourse actions.
 */
export default function createEnrollInCourseAction(axios: AxiosInstance) {
  return function enrollInCourseAction({ parameters }: { parameters: EnrollInCoursePathParameters }) {
    const path = enrollInCourseActionPath.insert(parameters);

    return dispatchAction(() => axios.post<EnrollInCourseResponse, EnrollInCourseResponse>(path));
  };
}
