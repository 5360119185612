import QuestionMarkIcon from 'components/icons/QuestionMarkIcon';
import Clickable from 'components/misc/Clickable';
import TeacherDashboardLayoutProvider from 'layout/teacher-dashboard-layout/TeacherDashboardLayoutProvider';
import TeacherHeader from 'layout/teacher-dashboard-layout/parts/header/TeacherHeader';
import TeacherSidebar from 'layout/teacher-dashboard-layout/parts/sidebar/TeacherSidebar';
import { noop } from 'lodash';
import SmallScreenMessage from 'layout/teacher-dashboard-layout/SmallScreenMessage';

/**
 * The properties of the teachers' dashboard layout.
 */
interface Props {
  hasHeader?: boolean;
  hasSidebar?: boolean;
  children?: JSX.Element | JSX.Element[];
}

/**
 * The layout for the teachers' dashboard page.
 */
export default function TeacherDashboardLayout({ children, hasHeader = true, hasSidebar = false }: Props) {
  const contentPadding = hasHeader ? 'pt-[66px] lg:pt-[90px]' : '';
  const sidebarPadding = hasSidebar ? 'lg:pl-[300px] xl:pl-[358px]' : '';

  return (
    <TeacherDashboardLayoutProvider>
      <>
        <div className="hidden bg-bg-teacher lg:block">
          {hasHeader && <TeacherHeader />}
          <div className={`lg:min-h-screen ${contentPadding}`}>
            {hasSidebar && <TeacherSidebar />}
            <div className={sidebarPadding}>
              <div className="mx-auto max-w-[1568px] lg:pb-[38px] lg:pl-[32px] lg:pr-[32px] lg:pt-[32px] xl:pl-[64px] xl:pr-[64px]">
                {children}
              </div>
            </div>
          </div>
          <Clickable
            className="fixed bottom-[24px] right-[12px] hidden h-[41px] w-[41px] items-center justify-center rounded-full bg-white"
            onClick={noop}
          >
            <QuestionMarkIcon />
          </Clickable>
        </div>
        <SmallScreenMessage />
      </>
    </TeacherDashboardLayoutProvider>
  );
}
