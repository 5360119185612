import { Progress } from '@mantine/core';
import { useInterval } from '@mantine/hooks';
import ClockIcon from 'components/icons/ClockIcon';
import { _t, _tx } from 'lang';
import sprintf from 'lang/sprintf';
import { useEffect, useState } from 'react';

interface Props {
  assigned: Date;
  deadline: Date;
  barHeight?: string;
  shouldTranslate?: boolean;
  remainingTimeClassNames?: string;
  remainsTextClassNames?: string;
}

/**
 * Calculates the remaining time.
 */
function calculateRemainingTime({ assigned, deadline }: { assigned: Date; deadline: Date }) {
  let remainingTime = <></>;
  let seconds = Math.floor((deadline.getTime() - new Date().getTime()) / 1000);
  let minutes = Math.floor(seconds / 60);
  let hours = Math.floor(minutes / 60);
  const days = Math.floor(hours / 24);

  hours = hours - days * 24;
  minutes = minutes - days * 24 * 60 - hours * 60;
  seconds = seconds - days * 24 * 60 * 60 - hours * 60 * 60 - minutes * 60;

  const lower = assigned.getTime();
  const upper = deadline.getTime();
  const total = upper - lower;
  const value = Date.now();
  const progress = Math.max(0, Math.min(1, (value - lower) / total)) * 100; // per cent

  const daysPretty = sprintf(_tx('%d day', '%d days', days), days).replace(/ /g, '\u00A0'); // non-breaking space
  const hoursPretty = sprintf(_tx('%d hour', '%d hours', hours), hours).replace(/ /g, '\u00A0'); // non-breaking space
  const minutesPretty = sprintf(_tx('%d minute', '%d minutes', minutes), minutes).replace(/ /g, '\u00A0'); // non-breaking space

  if (days > 0) {
    remainingTime = (
      <>
        <span>{daysPretty}</span> <span className="font-normal">{hoursPretty}</span>
      </>
    );
  } else if (hours > 0) {
    remainingTime = (
      <>
        <span>{hoursPretty}</span> <span className="font-normal">{minutesPretty}</span>
      </>
    );
  } else {
    remainingTime = <span>{minutesPretty}</span>;
  }

  return { remainingTime, progress };
}

/**
 * Count down timer for homework deadline.
 */
export default function HomeworkRemainingTime({
  assigned,
  deadline,
  barHeight = '2px',
  shouldTranslate = false,
  remainingTimeClassNames = 'text-[12px] leading-[15px]',
  remainsTextClassNames = 'text-[12px] leading-[15px]',
}: Props) {
  const [{ remainingTime, progress }, setProgressBarState] = useState(calculateRemainingTime({ assigned, deadline }));

  /**
   * Updates the remaining time each second.
   */
  const calculateRemainingTimeInterval = useInterval(() => {
    setProgressBarState(calculateRemainingTime({ assigned, deadline }));
  }, 1000);

  useEffect(() => {
    calculateRemainingTimeInterval.start();
    return calculateRemainingTimeInterval.stop;
  }, []);

  return (
    <div className="flex flex-col justify-end">
      <div className="grid grid-cols-[1fr_150px] gap-[5px] lg:flex lg:items-center lg:justify-between lg:gap-[15px]">
        <div className={`text-text-shadow ${remainsTextClassNames}`}>{_t('Remains until deadline')}</div>
        <div className="flex w-[150px] items-center gap-[7px] text-left text-[12px] font-semibold leading-[15px] lg:w-[150px] lg:justify-end">
          <div className="flex h-[20px] w-[20px] items-center justify-center">
            <div className="flex h-[14px] w-[14px] items-center justify-center">
              <ClockIcon />
            </div>
          </div>
          <div className={`flex gap-x-[5px] text-right ${remainingTimeClassNames}`}>{remainingTime}</div>
        </div>
      </div>
      <div className={`${shouldTranslate ? 'translate-y-[5px]' : ''}`}>
        <Progress
          value={progress}
          size="xs"
          styles={{
            bar: { backgroundColor: '#0AB892' },
            root: { height: barHeight, backgroundColor: '#D9E0E6' },
          }}
          className={shouldTranslate ? 'mt-[6px]' : 'mt-[16px]'}
        />
      </div>
    </div>
  );
}
