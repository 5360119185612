import { AxiosInstance } from 'axios';
import { SweetPath } from 'sweet-path';
import dispatchAction from 'api/dispatch-action';

import { UpdateCourseProgressPayload } from 'api/actions/update-course-progress/update-course-progress-payload';
import { UpdateCourseProgressPathParameters } from 'api/actions/update-course-progress/update-course-progress-path-parameters';

import { UpdateCourseProgressResponse } from 'api/actions/update-course-progress/update-course-progress-response';

/**
 * The path of the UpdateCourseProgress action.
 */
export const updateCourseProgressActionPath = new SweetPath<keyof UpdateCourseProgressPathParameters>(
  '/course/{courseId}/progress',
  { path: '{param}' }
);

/**
 * Creates a contextualized UpdateCourseProgress actions.
 */
export default function createUpdateCourseProgressAction(axios: AxiosInstance) {
  return function updateCourseProgressAction({
    parameters,
    payload,
  }: {
    parameters: UpdateCourseProgressPathParameters;
    payload: UpdateCourseProgressPayload;
  }) {
    const path = updateCourseProgressActionPath.insert(parameters);

    return dispatchAction(() =>
      axios.put<UpdateCourseProgressResponse, UpdateCourseProgressResponse, UpdateCourseProgressPayload>(path, payload)
    );
  };
}
