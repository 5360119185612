import { Accordion } from '@mantine/core';
import DurationDisplay from 'components/DurationDisplay';
import MiddleDot from 'components/MiddleDot';
import ProgressText from 'components/ProgressText';

interface Props {
  title: string;
  progress: number;
  duration: number;
  totalSteps: number;
  completedSteps: number;
}

/**
 * The control of the Course Step Sidebar
 */
export default function CourseStepSidebarControl({ title, progress, duration, totalSteps, completedSteps }: Props) {
  return (
    <Accordion.Control>
      <div className="h-fit-content pb-[13px] pl-[32px] pt-[16px]">
        <span className="overflow-visible text-[16px] font-semibold leading-[19px]">{title}</span>
        <div className="flex justify-between overflow-visible pt-[8px] font-normal text-text-shadow">
          <div className="flex gap-[4px] text-[12px] leading-[15px]">
            <span>
              {completedSteps}/{totalSteps}
            </span>
            <MiddleDot />
            <DurationDisplay duration={duration} />
          </div>
          <div className="translate-x-[44px]">
            <ProgressText progress={progress} />
          </div>
        </div>
      </div>
    </Accordion.Control>
  );
}
