import { Styles, TextInput, TextInputProps, TextInputStylesNames } from '@mantine/core';
import EqualSignIcon from 'components/icons/EqualSignIcon';
import { _t } from 'lang';

/**
 * The styles of a numeric input.
 */
const styles: Styles<TextInputStylesNames, Record<string, any>> = {
  input: {
    fontWeight: 500,
    lineHeight: '19px',
    height: '56px',
    marginTop: '10px',
    paddingLeft: '51px!important',
    '&:focus': {
      paddingLeft: '50px!important',
    },
  },
};

/**
 * The parameters of the component.
 */
export interface NumericInputProps extends TextInputProps {
  assignmentIsSolved: boolean;
  isPrint?: boolean;
}

/**
 * Input for questions with Int, Decimal and Fraction result types.
 */
export default function NumericInput({ assignmentIsSolved, isPrint = false, ...props }: NumericInputProps) {
  return (
    <TextInput
      inputMode="decimal"
      placeholder={isPrint ? '' : assignmentIsSolved ? _t('Question was skipped') : _t('Your answer')}
      icon={
        <div className="flex h-[50%] items-center border-r border-r-input-outline-shadow pl-[16px] pr-[16px]">
          <EqualSignIcon />
        </div>
      }
      radius={10}
      styles={styles}
      {...props}
    />
  );
}
