import { AxiosInstance } from 'axios';
import { SweetPath } from 'sweet-path';
import dispatchAction from 'api/dispatch-action';

import { CreateGuestSessionPayload } from 'api/actions/create-guest-session/create-guest-session-payload';
import { CreateGuestSessionPathParameters } from 'api/actions/create-guest-session/create-guest-session-path-parameters';

import { CreateGuestSessionResponse } from 'api/actions/create-guest-session/create-guest-session-response';

/**
 * The path of the CreateGuestSession action.
 */
export const createGuestSessionActionPath = new SweetPath<keyof CreateGuestSessionPathParameters>(
  '/user/guest/create',
  { path: '{param}' }
);

/**
 * Creates a contextualized CreateGuestSession actions.
 */
export default function createCreateGuestSessionAction(axios: AxiosInstance) {
  return function createGuestSessionAction({ payload }: { payload: CreateGuestSessionPayload }) {
    const path = createGuestSessionActionPath.original;

    return dispatchAction(() =>
      axios.post<CreateGuestSessionResponse, CreateGuestSessionResponse, CreateGuestSessionPayload>(path, payload)
    );
  };
}
