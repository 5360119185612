import { AxiosInstance } from 'axios';
import { SweetPath } from 'sweet-path';
import dispatchAction from 'api/dispatch-action';

import { CreateClassroomPayload } from 'api/actions/create-classroom/create-classroom-payload';
import { CreateClassroomPathParameters } from 'api/actions/create-classroom/create-classroom-path-parameters';

import { CreateClassroomResponse } from 'api/actions/create-classroom/create-classroom-response';

/**
 * The path of the CreateClassroom action.
 */
export const createClassroomActionPath = new SweetPath<keyof CreateClassroomPathParameters>('/classroom', {
  path: '{param}',
});

/**
 * Creates a contextualized CreateClassroom actions.
 */
export default function createCreateClassroomAction(axios: AxiosInstance) {
  return function createClassroomAction({ payload }: { payload: CreateClassroomPayload }) {
    const path = createClassroomActionPath.original;

    return dispatchAction(() =>
      axios.post<CreateClassroomResponse, CreateClassroomResponse, CreateClassroomPayload>(path, payload)
    );
  };
}
