import { useTeacherDashboardLayout } from 'layout/teacher-dashboard-layout/TeacherDashboardLayoutContext';
import TeacherPhoneHeader from 'layout/teacher-dashboard-layout/parts/header/TeacherPhoneHeader';
import TeacherDesktopHeader from 'layout/teacher-dashboard-layout/parts/header/TeacherDesktopHeader';

/**
 * The header of the teachers' dashboard page.
 */
export default function TeacherHeader() {
  const { isPhoneHeaderVisible } = useTeacherDashboardLayout();

  return (
    <div>
      <div className={isPhoneHeaderVisible ? 'lg:hidden' : 'hidden'}>
        <TeacherPhoneHeader />
      </div>
      <div className="hidden lg:block">
        <TeacherDesktopHeader />
      </div>
    </div>
  );
}
