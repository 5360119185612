import Avatar from 'components/Avatar';

interface Props {
  name: string;

  email: string;
}
/**
 * User detail
 */
export default function UserDetail({ name, email }: Props) {
  return (
    <div className="flex items-center gap-[16px]">
      <Avatar label={name} size={64} />
      <div className="flex flex-col">
        <span className="text-[16px] font-semibold leading-[19px]">{name}</span>
        <span className="mt-[4px] text-[12px] leading-[15px] text-text-shadow">{email}</span>
      </div>
    </div>
  );
}
