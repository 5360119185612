import useCourse from 'data/course/use-course';
import StudentDashboardLayout from 'layout/student-dashboard-layout/StudentDashboardLayout';
import CourseStepProvider from 'pages/courses-module/course-step/context/course-step-provider';
import { useEffect } from 'react';
import { Navigate, useParams } from 'react-router-dom';
import StudentCourseStepLayout from 'pages/student-module/course-step/StudentCourseStepLayout';

/**
 * StudentCourseStepPage
 */
export default function StudentCourseStepPage() {
  let { courseSlug, sectionSlug, stepId } = useParams();
  const { getCourse, fetchCourses, fetchCoursePlan, getCoursePlan } = useCourse();

  useEffect(() => {
    fetchCourses();
    fetchCoursePlan({ courseIdOrSlug: courseSlug! });
  }, [courseSlug]);

  const course = getCourse({ slug: courseSlug });
  const coursePlan = getCoursePlan({ courseIdOrSlug: courseSlug! });

  if (!course || !coursePlan) {
    return <StudentDashboardLayout hasHeader hasSidebar hasCollapsedSidebar />;
  }

  if (!stepId || !sectionSlug) {
    return (
      <StudentDashboardLayout hasHeader hasSidebar hasCollapsedSidebar>
        <>
          <Navigate
            to={`/student/course/${course.slug}/${course.nextStep.section.slug}/step/${course.nextStep.stepId}`}
          />
        </>
      </StudentDashboardLayout>
    );
  }

  return (
    <CourseStepProvider course={course} sectionSlug={sectionSlug} stepId={Number(stepId)}>
      <StudentDashboardLayout hasHeader hasSidebar hasCollapsedSidebar>
        <StudentCourseStepLayout />
      </StudentDashboardLayout>
    </CourseStepProvider>
  );
}
