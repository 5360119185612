import { Menu } from '@mantine/core';
import { useState } from 'react';

interface Props {
  icon: JSX.Element;
  activeIcon: JSX.Element;
  label: string;
  onClick: () => void;
}

/**
 * The menu item of the menu in the ClassroomRow component.
 */
export default function ClassroomMenuItem({ icon, activeIcon, label, onClick }: Props) {
  const [hover, setHover] = useState(false);

  return (
    <Menu.Item>
      <div
        className="flex h-[32px] items-center gap-[10px] rounded-[2px] pl-[14px] pr-[8px] text-text-dark-2 hover:bg-bg-shadow hover:text-text-dark"
        onMouseEnter={() => setHover(true)}
        onMouseLeave={() => setHover(false)}
        onClick={onClick}
      >
        {hover ? activeIcon : icon}
        <span className="text-[12px] font-medium leading-[15px]">{label}</span>
      </div>
    </Menu.Item>
  );
}
