import ArrowRightIcon from 'components/icons/ArrowRightIcon';
import { useState } from 'react';
import { NavLink } from 'react-router-dom';

interface Props {
  name: string | JSX.Element;
  icon: JSX.Element;
  activeIcon: JSX.Element;
  supplementaryItemInfo?: string | JSX.Element;
  to: string;
  basePath?: string;
}

/**
 * Navigation item in the Teacher Sidebar.
 */
export default function TeacherNavigationItem({ name, icon, activeIcon, supplementaryItemInfo, to, basePath }: Props) {
  const [displayActiveIcon, setDisplayActiveIcon] = useState(false);
  const currentPath = window.location.pathname;

  return (
    <NavLink
      to={to}
      onMouseEnter={() => {
        setDisplayActiveIcon(true);
      }}
      onMouseLeave={() => {
        setDisplayActiveIcon(false);
      }}
    >
      {({ isActive }) => {
        let shouldBeActive = isActive || currentPath.startsWith(to);

        if (basePath) {
          shouldBeActive = shouldBeActive || currentPath.startsWith(basePath);
        }

        return (
          <div
            className={`group flex cursor-pointer items-center justify-between rounded-[7px] pl-[16px] pr-[18px] text-left hover:bg-bg-shadow lg:h-[56px] ${
              shouldBeActive ? 'bg-bg-shadow' : ''
            }`}
          >
            <div className="flex items-center gap-[8px]">
              <div className="flex h-[24px] w-[24px] items-center justify-center">
                {shouldBeActive || displayActiveIcon ? activeIcon : icon}
              </div>
              <div
                className={`text-[16px] leading-[19px] group-hover:text-text-dark ${
                  shouldBeActive ? 'font-semibold text-text-dark' : 'font-medium text-text-dark-2'
                }`}
              >
                {name}
              </div>
            </div>
            <div className="flex items-center gap-[27px]">
              {supplementaryItemInfo && (
                <div className="text-[10px] font-medium leading-[12px] text-text-shadow">{supplementaryItemInfo}</div>
              )}
              <ArrowRightIcon stroke="#738491" />
            </div>
          </div>
        );
      }}
    </NavLink>
  );
}
