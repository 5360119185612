interface Props {
  width?: number;
  height?: number;
  active: boolean;
}

/**
 * Document icon.
 */
export default function DocumentIcon({ width = 12, height = 12, active }: Props) {
  return (
    <svg width={width} height={height} viewBox="0 0 10 12" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect width="10" height="12" rx="1" fill={active ? '#353E45' : '#738491'} />
      <rect x="2" y="3" width="6" height="1" fill="white" />
      <rect x="2" y="7" width="4" height="1" fill="white" />
    </svg>
  );
}
