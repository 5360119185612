import { Menu } from '@mantine/core';
import useApi from 'api/use-api';
import Avatar from 'components/Avatar';
import ArrowDownIcon from 'components/icons/ArrowDownIcon';
import ArrowUpIcon from 'components/icons/ArrowUpIcon';
import LockIcon from 'components/icons/LockIcon';
import LogoutIcon from 'components/icons/LogoutIcon';
import { _t } from 'lang';
import { useState } from 'react';
import { Link } from 'react-router-dom';
import { TEACHER_CHANGE_PASSWORD_PAGE_PATH } from 'routes/paths';

/**
 * The menu of the teachers' dashboard.
 */
export default function TeacherMenu() {
  const [lockIconStroke, setLockIconStroke] = useState<string>('#738491');
  const [logoutIconStroke, setLogoutIconStroke] = useState<string>('#738491');

  const { firstName, lastName, logout } = useApi();
  const [menuOpened, setMenuOpened] = useState(false);

  const fullName = `${firstName} ${lastName}`;

  return (
    <div
      className={`fixed bottom-[32px] lg:left-[16px] xl:left-[32px] ${menuOpened ? 'shadow-webinar-card-shadow' : ''}`}
    >
      <Menu
        classNames={{ dropdown: 'lg:!w-[268px] xl:!w-[294px]' }}
        position="top"
        offset={-5}
        opened={menuOpened}
        onChange={setMenuOpened}
        styles={{
          dropdown: {
            paddingTop: '8px!important',
            paddingLeft: '8!important',
            paddingRight: '8!important',
            paddingBottom: '0!important',
            borderWidth: '0',
            borderRadius: '0!important',
            borderTopLeftRadius: '7px!important',
            borderTopRightRadius: '7px!important',
            backgroundColor: '#fff!important',
            boxShadow: menuOpened ? '7px -7px 10px -7px rgba(22, 26, 29, 0.1)' : '', // TODO do this better
          },
          item: {
            width: '278px',
            marginLeft: 'auto',
            marginRight: 'auto',
            '&:hover': { backgroundColor: '#F3F6F8', color: '#0AB892', borderRadius: '10px' },
          },
        }}
      >
        <Menu.Target>
          <div
            className={`flex cursor-pointer items-center gap-[12px] bg-white pb-[16px] pl-[16px] pr-[33px] pt-[16px] lg:h-[74px] lg:w-[268px] xl:w-[294px] ${
              menuOpened ? 'rounded-0 rounded-b-lg' : 'rounded-[120px]'
            }`}
          >
            <div className="h-[42px] w-[42px] shrink-0">
              <Avatar label={fullName} size={42} />
            </div>
            <div className="w-full">
              <div className="text-[12px] font-medium leading-[15px] text-text-shadow">{_t('Teacher')}</div>
              <div className="flex flex-row items-center justify-between gap-[16px]">
                <div className="text-[16px] font-medium leading-[19px]">{fullName}</div>
                <div>{menuOpened ? <ArrowUpIcon stroke="#738491" /> : <ArrowDownIcon />}</div>
              </div>
            </div>
          </div>
        </Menu.Target>
        <Menu.Dropdown>
          <Menu.Item
            className="pb-[15px] pl-[22px] pr-[22px] pt-[14px] text-[16px] font-medium text-text-shadow hover:text-text-dark-2"
            icon={<LockIcon className="mr-[18px]" stroke={lockIconStroke} />}
            component={Link}
            to={TEACHER_CHANGE_PASSWORD_PAGE_PATH}
            onMouseEnter={() => setLockIconStroke('#353E45')}
            onMouseLeave={() => setLockIconStroke('#738491')}
          >
            {_t('Change password')}
          </Menu.Item>
          <Menu.Item
            className="pb-[15px] pl-[22px] pr-[22px] pt-[14px] text-[16px] font-medium text-text-shadow hover:text-text-dark-2"
            onClick={logout}
            icon={<LogoutIcon className="mr-[14px]" stroke={logoutIconStroke} />}
            onMouseEnter={() => setLogoutIconStroke('#353E45')}
            onMouseLeave={() => setLogoutIconStroke('#738491')}
          >
            {_t('Log out')}
          </Menu.Item>
        </Menu.Dropdown>
      </Menu>
    </div>
  );
}
