import { AxiosInstance } from 'axios';
import { SweetPath } from 'sweet-path';
import dispatchAction from 'api/dispatch-action';

import { ServerTimePathParameters } from 'api/actions/server-time/server-time-path-parameters';

import { ServerTimeResponse } from 'api/actions/server-time/server-time-response';

/**
 * The path of the ServerTime action.
 */
export const serverTimeActionPath = new SweetPath<keyof ServerTimePathParameters>('/server-time', { path: '{param}' });

/**
 * Creates a contextualized ServerTime actions.
 */
export default function createServerTimeAction(axios: AxiosInstance) {
  return function serverTimeAction() {
    const path = serverTimeActionPath.original;

    return dispatchAction(() => axios.get<ServerTimeResponse, ServerTimeResponse>(path));
  };
}
