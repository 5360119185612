import BasicPageLayout from 'layout/basic-page-layout/BasicPageLayout';
import CompareExercise from 'pages/presentation-module/slovakia-comparison/CompareExercise';

/**
 * The page for Slovakia comparison.
 */
export default function SlovakiaComparisonPage() {
  return (
    <BasicPageLayout hasHeader hasFooter>
      <div>
        <div className="flex min-h-[220px] items-center bg-contact-phone bg-cover bg-no-repeat text-white md:bg-contact-desktop">
          <div className="ml-auto mr-auto flex max-w-[718px] flex-col items-center pl-[24px] pr-[24px] text-center">
            <span className="text-[32px] font-semibold leading-[42px] md:text-[44px] md:leading-[54px]">
              Porovnanie s Monitorom 2021
            </span>
            <span className="pt-[8px] text-[16px] font-medium leading-[26px] lg:pt-[12px]">
              Chceme vám ukázať pár príkladov v našom kurze, ktoré sa typovo veľmi podobajú príkladom na Monitore 2021
              (kód testu 1077). Ku každému príkladu sme pridali aj odkaz na miesto v našom kurze, kde sa nachádza.
            </span>
          </div>
        </div>

        <div className="bg-bg-shadow">
          <div className="ml-auto mr-auto max-w-[1218px] pb-[56px] pl-[24px] pr-[24px] pt-[56px] lg:pb-[80px] lg:pt-[80px]">
            <CompareExercise
              number="02"
              link="/course/priprava-na-prijimacky/s12-vzpocty-s-uhly-a-3d-telesa/step/184"
            />
            <div className="mb-[48px] mt-[48px] h-[1px] w-full bg-input-outline-shadow" />
            <CompareExercise number="04" link="/course/priprava-na-prijimacky/s08-slovni-ulohy/step/131" />
            <div className="mb-[48px] mt-[48px] h-[1px] w-full bg-input-outline-shadow" />
            <CompareExercise number="07" link="/course/priprava-na-prijimacky/s08-slovni-ulohy/step/141" />
            <div className="mb-[48px] mt-[48px] h-[1px] w-full bg-input-outline-shadow" />
            <CompareExercise
              number="13"
              link="/course/priprava-na-prijimacky/s05-linearni-rovnice-s-1-neznamou/step/89"
            />
            <div className="mb-[48px] mt-[48px] h-[1px] w-full bg-input-outline-shadow" />
            <CompareExercise number="20" link="/course/priprava-na-prijimacky/s15-slovni-ulohy-na-procenta/step/205" />
            <div className="mb-[48px] mt-[48px] h-[1px] w-full bg-input-outline-shadow" />
            <CompareExercise number="25" link="/course/priprava-na-prijimacky/s08-slovni-ulohy/step/130" />
          </div>
        </div>
      </div>
    </BasicPageLayout>
  );
}
