import { useInterval, useResizeObserver } from '@mantine/hooks';
import useCourse from 'data/course/use-course';
import ReactPlayer from 'react-player';
import useCourseStep from 'pages/courses-module/course-step/context/use-course-step';
import { useEffect, useRef, useState } from 'react';
import VideoOverlay from 'pages/courses-module/course-step/main/content/overlays/VideoOverlay';
import FeedbackOverlay from 'pages/courses-module/course-step/main/content/overlays/FeedbackOverlay';
import computeProgress from 'pages/courses-module/course-step/main/content/video/progress';
import useLocalStorage from 'api/use-local-storage';
import { isFunction, isNumber } from 'lodash';
import computeStartTime from 'pages/courses-module/course-step/main/content/video/start-time';
import { VIDEO_PROGRESS_INTERVAL_SECONDS, WEB_APP_HOST } from 'env';

/**
 * The parameters of the component.
 */
interface Props {
  video: string;
}

/**
 * The video part of the course.
 */
export default function CourseStepContentVideo({ video }: Props) {
  const player = useRef<ReactPlayer>(null);
  const [displayOverlay, setDisplayOverlay] = useState(false);

  // Video volume.
  const [{ volume }, setVolume] = useLocalStorage<{ volume?: number }>('zvedavamysl.video.volume', {
    volume: undefined,
  });
  const [initialVolume] = useState(volume);

  const checkVolumeInterval = useInterval(() => {
    if (player.current) {
      const internalPlayer = player.current.getInternalPlayer();

      if (isFunction(internalPlayer.getVolume)) {
        const volume = internalPlayer.getVolume();

        if (isNumber(volume) && volume >= 0 && volume <= 100) {
          setVolume({ volume });
        }
      }
    }
  }, 1000); // Check the volume every second.

  // Video size.
  const [ref, { width, height }] = useResizeObserver<HTMLInputElement>();
  const idealHeight = (9 / 16) * width;
  const idealWidth = (16 / 9) * height;

  // Course.
  const { updateCourseProgress, getCourseStepProgress } = useCourse();
  const { course, currStep, stepId } = useCourseStep();
  const { courseId } = course;

  const [progress, setProgress] = useState(currStep!.progress);
  const [duration, setDuration] = useState(currStep!.duration);

  const startTime = computeStartTime(duration, progress);

  /**
   * Updates the progress of the user in the course after watching a part of
   * the video.
   */
  function videoProgress({ playedSeconds }: { playedSeconds: number }) {
    setProgress((progress) => {
      // Progress can never go backwards.
      const newProgress = Math.max(progress, computeProgress(duration, playedSeconds));

      updateCourseProgress({
        courseId,
        steps: [{ stepId, progress: newProgress }],
      });

      return newProgress;
    });
  }

  /**
   * Sets the progress to 1 and displays an overlay.
   */
  function videoEnded() {
    videoProgress({ playedSeconds: duration });
    setDisplayOverlay(true);

    if (document.fullscreenElement) {
      document.exitFullscreen();
    }
  }

  // Sets the volume of the video periodically.
  useEffect(() => {
    checkVolumeInterval.start();
    return checkVolumeInterval.stop;
  }, []);

  // Set the progress to the current step's progress. This is needed because
  // the user can manually change the progress in a different component.
  const stepProgress = getCourseStepProgress({ courseIdOrSlug: courseId, stepId });
  useEffect(() => {
    setProgress(stepProgress);
  }, [stepProgress]);

  return (
    <div className="h-full w-full lg:pb-[66px] lg:pl-[48px] lg:pr-[48px] lg:pt-[66px]">
      <div ref={ref} className="flex h-full w-full items-center justify-center">
        <div className="relative lg:max-h-full lg:max-w-full" style={{ width: idealWidth, height: idealHeight }}>
          <ReactPlayer
            url={video}
            className="h-full w-full overflow-hidden lg:rounded-[10px]"
            width="100%"
            height="100%"
            controls
            onProgress={videoProgress}
            onEnded={videoEnded}
            progressInterval={VIDEO_PROGRESS_INTERVAL_SECONDS * 1000}
            volume={initialVolume ? initialVolume / 100 : undefined}
            onDuration={setDuration}
            config={{
              youtube: {
                playerVars: {
                  origin: WEB_APP_HOST,
                  modestbranding: 1,
                  start: startTime,
                  autoplay: 1,
                },
              },
            }}
            ref={player}
          />
          {displayOverlay && (
            <div className="absolute top-0 flex h-full w-full items-center bg-video-overlay lg:rounded-[10px]">
              <div className="ml-auto mr-auto max-w-[271px] text-white lg:max-w-[639px]">
                {currStep?.askForFeedback ? (
                  <FeedbackOverlay />
                ) : (
                  <VideoOverlay hideOverlay={() => setDisplayOverlay(false)} />
                )}
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
