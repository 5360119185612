import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm';
import rehypeKatex from 'rehype-katex';
import remarkMath from 'remark-math';
import 'katex/dist/katex.min.css'; // `rehype-katex` does not import the CSS for you

interface Props {
  text: string;
}

/**
 * Renders markdown text.
 */
export default function MarkdownText({ text }: Props) {
  return (
    <ReactMarkdown
      className="react-markdown inline-block"
      children={text}
      remarkPlugins={[remarkGfm, remarkMath]}
      rehypePlugins={[rehypeKatex]}
    />
  );
}
