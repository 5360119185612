interface Props {
  stroke?: string;
}

/**
 * The pack/unpack icon for classrooms.
 */
export default function ClassroomPackUnpackIcon({ stroke = '#0AB892' }: Props) {
  return (
    <svg width="20" height="12" viewBox="0 0 20 12" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M2 2L9.29289 9.29289C9.68342 9.68342 10.3166 9.68342 10.7071 9.29289L18 2"
        stroke={stroke}
        strokeWidth="3"
        strokeLinecap="round"
      />
    </svg>
  );
}
