import { AxiosInstance } from 'axios';
import { SweetPath } from 'sweet-path';
import dispatchAction from 'api/dispatch-action';

import { GetCoursesPathParameters } from 'api/actions/get-courses/get-courses-path-parameters';

import { GetCoursesResponse } from 'api/actions/get-courses/get-courses-response';

/**
 * The path of the GetCourses action.
 */
export const getCoursesActionPath = new SweetPath<keyof GetCoursesPathParameters>('/course', { path: '{param}' });

/**
 * Creates a contextualized GetCourses actions.
 */
export default function createGetCoursesAction(axios: AxiosInstance) {
  return function getCoursesAction() {
    const path = getCoursesActionPath.original;

    return dispatchAction(() => axios.get<GetCoursesResponse, GetCoursesResponse>(path));
  };
}
