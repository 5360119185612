import { Center, RingProgress } from '@mantine/core';
import { useInterval } from '@mantine/hooks';
import VideoPlayIcon from 'components/icons/VideoPlayIcon';
import { _t } from 'lang';
import { useEffect, useState } from 'react';
import useCourseStep from 'pages/courses-module/course-step/context/use-course-step';
import { Link, Navigate } from 'react-router-dom';
import Clickable from 'components/misc/Clickable';
import VideoPlayPhoneIcon from 'components/icons/VideoPlayPhoneIcon';

interface Props {
  hideOverlay: () => void;
  delay?: number;
  duration?: number;
}

/**
 * The overlay displaying the name of the next section.
 */
export default function VideoOverlay({ hideOverlay, delay = 2000, duration = 5000 }: Props) {
  const [initial] = useState(Date.now());
  const [current, setCurrent] = useState(Date.now());
  const progress = Math.min(Math.max((current - initial - delay) / duration, 0), 1);

  const interval = useInterval(() => setCurrent(Date.now()), 20);
  const { nextStep, nextStepLink } = useCourseStep();

  useEffect(() => {
    interval.start();
    return interval.stop;
  }, []);

  if (progress === 1 && nextStepLink) {
    return <Navigate to={nextStepLink} />;
  }

  return (
    <div className="flex flex-col items-center">
      <div className="flex flex-col items-center">
        <span className="pb-[10px] text-[12px] font-medium leading-[15px] text-white-opacity-08 lg:text-[14px] lg:leading-[17px]">
          {_t('Follows')}:
        </span>
        <span className="text-[16px] font-semibold leading-[19px] text-white lg:text-[24px] lg:leading-[34px]">
          {nextStep?.title}
        </span>
      </div>
      <div className="flex flex-col items-center">
        {nextStepLink && (
          <>
            <div className="lg:hidden">
              <RingProgress
                size={78}
                thickness={2}
                sections={[{ value: progress * 100, color: 'white' }]}
                label={
                  <Center>
                    <Link to={nextStepLink}>
                      <VideoPlayPhoneIcon />
                    </Link>
                  </Center>
                }
              />
            </div>
            <div className="hidden lg:block">
              <RingProgress
                size={120}
                thickness={2}
                sections={[{ value: progress * 100, color: 'white' }]}
                label={
                  <Center>
                    <Link to={nextStepLink}>
                      <VideoPlayIcon />
                    </Link>
                  </Center>
                }
              />
            </div>
          </>
        )}
        <Clickable>
          <div className="text-[14px] leading-[17px] text-white-opacity-08 underline" onClick={hideOverlay}>
            {_t('Cancel')}
          </div>
        </Clickable>
      </div>
    </div>
  );
}
