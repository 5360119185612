import ZvedavaMyslIcon from 'components/icons/ZvedavaMyslIcon';
import { _t, lang } from 'lang';
import { Link } from 'react-router-dom';
import {
  CONTACT_PAGE_PATH,
  EXERCISES_PAGE_PATH,
  FAQ_PAGE_PATH,
  HOME_PAGE_PATH,
  PRIVACY_POLICY_PAGE_PATH,
  WHO_WE_ARE_PAGE_PATH,
} from 'routes/paths';

/**
 * Footer links
 */
export default function FooterLinks() {
  const row1 = 'row-start-1 row-end-2';
  const row2 = 'row-start-2 row-end-3';
  const row3 = 'row-start-3 row-end-4';

  const col1 = 'col-start-1 col-end-2';
  const col2 = 'col-start-2 col-end-3';

  return (
    <div className="flex flex-col text-[14px] font-medium leading-[20px] text-text-shadow">
      <Link className="w-fit" to={HOME_PAGE_PATH}>
        <ZvedavaMyslIcon />
      </Link>
      <div className="mb-[-8px] ml-[-8px] mt-[-8px] pt-[32px] md:grid md:grid-cols-2 lg:gap-x-[64px]">
        {lang === 'cs' && (
          <Link to="/course/priprava-na-prijimacky" className={`${col1} ${row1} block p-[8px] text-text-dark`}>
            <span>{_t('High school admissions')}&nbsp;✍🏼</span>
          </Link>
        )}
        <Link className={`${col1} block p-[8px] text-text-dark`} to={EXERCISES_PAGE_PATH}>
          <span>{_t('Exercises book')}&nbsp;📙</span>
        </Link>
        <Link className={`${col1} block p-[8px] text-text-dark`} to={WHO_WE_ARE_PAGE_PATH}>
          <span>{_t('Who we are')}</span>
        </Link>
        <Link className={`${col2} ${row1} block p-[8px]`} to={FAQ_PAGE_PATH}>
          <span>{_t('Frequently asked questions')}</span>
        </Link>
        <Link className={`${col2} ${row2} block p-[8px]`} to={PRIVACY_POLICY_PAGE_PATH}>
          <span>{_t('Privacy policy')}</span>
        </Link>
        <Link className={`${col2} ${row3} block p-[8px]`} to={CONTACT_PAGE_PATH}>
          <span>{_t('Contact')}</span>
        </Link>
      </div>
    </div>
  );
}
