import useApi from 'api/use-api';
import { _t } from 'lang';
import StudentDashboardLayout from 'layout/student-dashboard-layout/StudentDashboardLayout';
import StudentDashboardContent from 'pages/student-module/dashboard/StudentDashboardContent';
import { isEmpty } from 'lodash';
import HasNoClassroomAssigned from 'pages/student-module/dashboard/HasNoClassroomAssigned';
import StudentHomeworkCard from 'pages/student-module/dashboard/StudentHomeworkCard';
import { useLocation } from 'react-router-dom';
import { showNotification } from '@mantine/notifications';
import useStudentDashboard from 'data/student-dashboard/use-student-dashboard';
import sprintf from 'lang/sprintf';

/**
 * The student dashboard page.
 *
 * @see https://www.figma.com/file/SjggpqHVlWxBKa2cIG349A/Math-Exercises?node-id=913-921&t=Galbe5mORUZiCo4v-0
 */
export default function StudentDashboardPage() {
  const { state } = useLocation();
  const { firstName } = useApi();
  const { ready, getHomework, getStats, getClassrooms } = useStudentDashboard();

  if (!ready) {
    return <StudentDashboardLayout hasHeader hasSidebar />;
  }

  if (!isEmpty(state) && state.showInvalidInviteCodeMessage) {
    window.history.replaceState({}, document.title);

    showNotification({
      title: _t('Invalid invite code'),
      message: _t('The invite code you entered is invalid. Please try again.'),
      color: 'red',
    });
  }

  const mappedHomework = getHomework()
    .sort(({ homework: first }, { homework: second }) => (first.homeworkNumber > second.homeworkNumber ? -1 : 1))
    .map(({ homework, assignment }) => ({
      homework,
      assignment,
      component: (
        <StudentHomeworkCard
          key={homework.homeworkId}
          homeworkId={homework.homeworkId}
          isActive={!homework.isPastDeadline}
          deadline={homework.deadline}
          assigned={homework.assigned.at}
          name={`${_t('Homework')} #${homework.homeworkNumber}`}
          exercises={homework.exerciseSet.exercises}
          successRate={assignment?.submitted?.evaluation.successRate}
          assignmentId={assignment?.generated?.assignmentId}
        />
      ),
    }));

  const finishedHomework = mappedHomework
    .filter(({ homework, assignment }) => homework.isPastDeadline || assignment.submitted?.at !== undefined)
    .map(({ component }) => component);

  const activeHomework = mappedHomework
    .filter(({ homework, assignment }) => !homework.isPastDeadline && assignment.submitted?.at === undefined)
    .map(({ component }) => component);

  const stats = getStats();
  const classrooms = getClassrooms();

  return (
    <StudentDashboardLayout hasHeader hasSidebar>
      <div className="p-[24px] pb-[84px] lg:pb-[64px] lg:pl-[64px] lg:pr-[64px] lg:pt-[39px]">
        <div className="flex flex-col gap-[16px] text-[24px] font-semibold leading-[29px] lg:flex-row lg:items-center lg:justify-between lg:gap-0">
          <div>{sprintf(_t('Hello, %s 👋'), firstName)}</div>
        </div>
        {classrooms && !isEmpty(classrooms) ? (
          <StudentDashboardContent
            activeHomework={activeHomework}
            finishedHomework={finishedHomework}
            successRate={stats.averageSuccessRate}
            numberOfNotSubmitted={stats.numberOfNotSubmitted}
          />
        ) : (
          <HasNoClassroomAssigned />
        )}
      </div>
    </StudentDashboardLayout>
  );
}
