import Alert from 'components/Alert';
import GoBackButton from 'components/buttons/GoBackButton';
import Emoji from 'components/Emoji';
import ResetPasswordForm from 'components/forms/ResetPasswordForm';
import CorrectIcon from 'components/icons/CorrectIcon';
import FormPageLayout from 'layout/form-page-layout/FormPageLayout';
import { _t } from 'lang';
import { useState } from 'react';
import { LOGIN_PAGE_PATH } from 'routes/paths';

/**
 * The reset password page.
 *
 * @see https://www.notion.so/Reset-Password-51b6a37c143940e5878155bf28a77ff2
 */
export default function ResetPasswordPage() {
  const [displayMessage, setDisplayMessage] = useState<boolean>(false);

  return (
    <FormPageLayout>
      <div className="block p-[24px] lg:ml-[50%] lg:flex lg:min-h-screen lg:pl-[130px] lg:pt-[80px]">
        <div className="lg:w-[370px]">
          <Alert icon={<CorrectIcon />} type="success" className={displayMessage ? 'visible' : 'invisible'}>
            <span className="text-[14px] font-medium leading-[20px]">
              {_t('An e-mail to reset your password has been sent to the email address you have inputted.')}
            </span>
          </Alert>
          <GoBackButton path={LOGIN_PAGE_PATH} />
          <p className="pt-[32px] text-[24px] font-semibold leading-[29px] text-text-dark">
            {_t('Forgotten password?')}
          </p>
          <p className="pb-[32px] pt-[8px] text-[14px] font-normal leading-[20px]">
            {_t('No problem!')} <Emoji symbol="😉" label={_t('winking-face')} />{' '}
            {_t('To reset your password input the email address you used when you registered.')}
          </p>
          <ResetPasswordForm onSuccess={() => setDisplayMessage(true)} />
        </div>
      </div>
    </FormPageLayout>
  );
}
