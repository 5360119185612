import AssignmentContent from 'components/assignment/AssignmentContent';
import { IAssignment, IAssignmentEvaluation } from 'data/assignment/assignment';
import { ReactNode, useEffect, useState } from 'react';
import useApi from 'api/use-api';
import { nanoid } from 'nanoid';
import { noop } from 'lodash';

/**
 * The parameters of the component.
 */
interface Props {
  assignmentId: string;
  onReady?: () => void;
  onSolved?: (evaluation: IAssignmentEvaluation | undefined) => void;
  afterEvaluationContent?: ReactNode | ((evaluation: IAssignmentEvaluation) => ReactNode);
  hideSubmitButton?: boolean;
  isPrint?: boolean;
  loader?: ReactNode;
}

/**
 * An assignment is a collection of exercises.
 */
export default function Assignment({
  assignmentId,
  onReady = noop,
  onSolved,
  afterEvaluationContent = null,
  hideSubmitButton = false,
  isPrint = false,
  loader = <></>,
}: Props) {
  const [assignment, setAssignment] = useState<IAssignment | undefined>(undefined);
  const [refreshToken, setRefreshToken] = useState<string>('');

  const { getAction } = useApi();
  const getAssignmentAction = getAction('GetAssignment');

  /**
   * Refreshes the assignment.
   */
  function refresh() {
    setRefreshToken(nanoid());
    setAssignment(undefined);
  }

  useEffect(() => {
    getAssignmentAction({ parameters: { assignmentId } }).success((response) => setAssignment(response));
  }, [assignmentId, refreshToken]);

  useEffect(() => {
    if (assignment) {
      onReady();
    }
  }, [assignment]);

  if (!assignment) {
    return <>{loader}</>;
  }

  return (
    <AssignmentContent
      assignment={assignment}
      refresh={refresh}
      onSolved={onSolved}
      afterEvaluationContent={afterEvaluationContent}
      hideSubmitButton={hideSubmitButton}
      isPrint={isPrint}
    />
  );
}
