import { Checkbox, CheckboxStylesNames, CheckboxStylesParams, Styles } from '@mantine/core';
import { Dispatch, SetStateAction } from 'react';

/**
 * The styles of the checkbox.
 */
const styles: Styles<CheckboxStylesNames, CheckboxStylesParams> = {
  input: {
    borderColor: '#738491',
    '&:checked': { borderColor: '#0AB892', backgroundColor: '#0AB892' },
    cursor: 'pointer',
  },
  icon: {
    color: 'white',
  },
};

interface Props {
  firstName: string;
  lastName: string;
  studentId: string;
  checked: boolean;
  checkedStudents: string[];
  onChange: Dispatch<SetStateAction<string[]>>;
}

/**
 * The student select component.
 */
export default function StudentSelect({ firstName, lastName, studentId, checked, checkedStudents, onChange }: Props) {
  return (
    <div
      className="flex cursor-pointer items-center gap-[16px] rounded-[5px] p-[10px] hover:bg-[#E8EEF2]"
      onClick={() => {
        if (checkedStudents.includes(studentId)) {
          onChange(checkedStudents.filter((p) => p !== studentId));
        } else {
          checkedStudents.push(studentId);
          onChange(checkedStudents);
        }
      }}
    >
      <div className="h-[16px] w-[16px] translate-y-[-2px]">
        <Checkbox
          styles={styles}
          size="xs"
          checked={checked}
          onChange={(e) => {
            e.stopPropagation();
            if (!e.currentTarget.checked) {
              onChange(checkedStudents.filter((p) => p !== studentId));
            } else {
              checkedStudents.push(studentId);
              onChange(checkedStudents);
            }
          }}
        />
      </div>
      <div className="text-[16px] font-medium leading-[19px]">
        {firstName} {lastName}
      </div>
    </div>
  );
}
