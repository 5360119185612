/**
 * The Video play icon but for phone.
 */
export default function VideoPlayPhoneIcon() {
  return (
    <svg width="64" height="64" viewBox="0 0 64 64" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect width="64" height="64" rx="32" fill="#353E45" />
      <path
        d="M28.2235 21.5682C27.2551 20.9056 26.0391 21.7249 26.0391 23.0399V40.9605C26.0391 42.2754 27.2551 43.0948 28.2235 42.4322L41.3184 33.4719C42.2793 32.8144 42.2793 31.1859 41.3184 30.5284L28.2235 21.5682Z"
        fill="white"
      />
    </svg>
  );
}
