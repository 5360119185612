import useApi from 'api/use-api';
import LockIcon from 'components/icons/LockIcon';
import LogoutIcon from 'components/icons/LogoutIcon';
import { _t } from 'lang';
import UserDetail from 'layout/parts/header/UserDetail';
import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { STUDENT_CHANGE_PASSWORD_PAGE_PATH } from 'routes/paths';

interface Props {
  setDisplayMenu: Dispatch<SetStateAction<boolean>>;
}

/**
 * The mobile menu of the students' dashboard.
 */
export default function StudentPhoneMenu({ setDisplayMenu }: Props) {
  const [lockIconStroke, setLockIconStroke] = useState<string>('#738491');
  const [logoutIconStroke, setLogoutIconStroke] = useState<string>('#738491');

  const { firstName, lastName, email, logout } = useApi();

  /**
   * Function to hide the menu.
   */
  const hideMenu = () => setDisplayMenu(false);

  // Set the overflow to hidden when the component is mounted.
  useEffect(() => {
    document.body.style.overflow = 'hidden';
  }, []);

  // Remove the overflow hidden when the component unmounts.
  useEffect(
    () => () => {
      document.body.style.overflow = 'auto';
    },
    []
  );

  return (
    <div
      className="fixed left-0 top-[66px] z-[100] h-[calc(100vh-66px)] w-full bg-white p-[16px]"
      onClick={(e) => {
        e.stopPropagation();
      }}
    >
      <UserDetail email={email} name={`${firstName} ${lastName}`} />
      <div className="flex flex-col gap-[8px] pt-[32px]">
        <div
          className="flex h-[51px] items-center gap-[28px] pl-[22px] text-[16px] font-medium text-text-shadow hover:text-text-dark-2 focus:text-text-dark-2"
          onClick={logout}
          onMouseEnter={() => setLogoutIconStroke('#353E45')}
          onMouseLeave={() => setLogoutIconStroke('#738491')}
        >
          <LogoutIcon className="mr-[14px]" stroke={logoutIconStroke} />
          {_t('Log out')}
        </div>
        <div onClick={hideMenu} className="cursor-pointer">
          <Link
            className="flex h-[51px] items-center gap-[28px] pl-[22px] text-[16px] font-medium text-text-shadow hover:text-text-dark-2 focus:text-text-dark-2"
            to={STUDENT_CHANGE_PASSWORD_PAGE_PATH}
            onMouseEnter={() => setLockIconStroke('#353E45')}
            onMouseLeave={() => setLockIconStroke('#738491')}
          >
            <LockIcon className="mr-[18px]" stroke={lockIconStroke} />
            {_t('Change password')}
          </Link>
        </div>
      </div>
    </div>
  );
}
