/**
 * Video play icon.
 */
export default function VideoPlayIcon() {
  return (
    <svg width="102" height="102" viewBox="0 0 102 102" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect width="102" height="102" rx="51" fill="#353E45" />
      <path
        d="M44.9814 34.374C43.4381 33.318 41.5 34.6238 41.5 36.7195V65.2805C41.5 67.3762 43.4381 68.682 44.9814 67.626L65.8515 53.3455C67.3828 52.2977 67.3828 49.7023 65.8515 48.6545L44.9814 34.374Z"
        fill="white"
      />
    </svg>
  );
}
