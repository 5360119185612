/**
 * Eye icon
 */
export default function EyeIcon() {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M12 15.75C13.1833 15.75 14.1875 15.3375 15.0125 14.5125C15.8375 13.6875 16.25 12.6833 16.25 11.5C16.25 10.3167 15.8375 9.3125 15.0125 8.4875C14.1875 7.6625 13.1833 7.25 12 7.25C10.8167 7.25 9.8125 7.6625 8.9875 8.4875C8.1625 9.3125 7.75 10.3167 7.75 11.5C7.75 12.6833 8.1625 13.6875 8.9875 14.5125C9.8125 15.3375 10.8167 15.75 12 15.75ZM12 14.3C11.2167 14.3 10.5542 14.0292 10.0125 13.4875C9.47083 12.9458 9.2 12.2833 9.2 11.5C9.2 10.7167 9.47083 10.0542 10.0125 9.5125C10.5542 8.97083 11.2167 8.7 12 8.7C12.7833 8.7 13.4458 8.97083 13.9875 9.5125C14.5292 10.0542 14.8 10.7167 14.8 11.5C14.8 12.2833 14.5292 12.9458 13.9875 13.4875C13.4458 14.0292 12.7833 14.3 12 14.3ZM12 19C9.56667 19 7.36667 18.3083 5.4 16.925C3.43333 15.5417 1.96667 13.7333 1 11.5C1.96667 9.26667 3.43333 7.45833 5.4 6.075C7.36667 4.69167 9.56667 4 12 4C14.4333 4 16.6333 4.69167 18.6 6.075C20.5667 7.45833 22.0333 9.26667 23 11.5C22.0333 13.7333 20.5667 15.5417 18.6 16.925C16.6333 18.3083 14.4333 19 12 19Z"
        fill="#4C5863"
      />
    </svg>
  );
}
