import { AxiosInstance } from 'axios';
import { SweetPath } from 'sweet-path';
import dispatchAction from 'api/dispatch-action';

import { GetExerciseTopicsPathParameters } from 'api/actions/get-exercise-topics/get-exercise-topics-path-parameters';
import { GetExerciseTopicsQueryParameters } from 'api/actions/get-exercise-topics/get-exercise-topics-query-parameters';
import { GetExerciseTopicsResponse } from 'api/actions/get-exercise-topics/get-exercise-topics-response';

/**
 * The path of the GetExerciseTopics action.
 */
export const getExerciseTopicsActionPath = new SweetPath<keyof GetExerciseTopicsPathParameters>('/exercise/topic', {
  path: '{param}',
});

/**
 * Creates a contextualized GetExerciseTopics actions.
 */
export default function createGetExerciseTopicsAction(axios: AxiosInstance) {
  return function getExerciseTopicsAction({ query }: { query: GetExerciseTopicsQueryParameters }) {
    const path = getExerciseTopicsActionPath.original;

    return dispatchAction(() =>
      axios.get<GetExerciseTopicsResponse, GetExerciseTopicsResponse>(path, { params: query })
    );
  };
}
