import Emoji from 'components/Emoji';
import { _t, _tx } from 'lang';
import HomeworkDisplay from 'components/HomeworkDisplay';
import NumberInCircle from 'components/NumberInCircle';
import StudentHomeworkCard from 'pages/student-module/dashboard/StudentHomeworkCard';
import useStudentDashboard from 'data/student-dashboard/use-student-dashboard';
import Message from 'components/Message';
import IIcon from 'components/icons/IIcon';

/**
 * The content of the StudentHomework page.
 */
export default function StudentHomeworkContent() {
  const { getHomework } = useStudentDashboard();

  const mappedHomework = getHomework()
    .sort(({ homework: first }, { homework: second }) => (first.homeworkNumber > second.homeworkNumber ? -1 : 1))
    .map(({ homework, assignment }) => ({
      homework,
      assignment,
      component: (
        <StudentHomeworkCard
          key={homework.homeworkId}
          homeworkId={homework.homeworkId}
          isActive={!homework.isPastDeadline}
          deadline={homework.deadline}
          assigned={homework.assigned.at}
          name={`${_t('Homework')} #${homework.homeworkNumber}`}
          exercises={homework.exerciseSet.exercises}
          successRate={assignment?.submitted?.evaluation.successRate}
          assignmentId={assignment?.generated?.assignmentId}
        />
      ),
    }));

  const activeHomework = mappedHomework
    .filter(({ homework, assignment }) => !homework.isPastDeadline && assignment.submitted?.at === undefined)
    .map(({ component }) => component);
  const inactiveHomework = mappedHomework
    .filter(({ homework, assignment }) => homework.isPastDeadline || assignment.submitted?.at !== undefined)
    .map(({ component }) => component);

  return (
    <div className="p-[24px] pb-[84px] lg:pb-[64px] lg:pl-[64px] lg:pr-[64px] lg:pt-[39px]">
      <div className="text-[24px] font-semibold leading-[29px]">
        {_tx('My homework', 'My homework', 2)} <Emoji symbol="🔢" label={_t('input-numbers')} />
      </div>
      <div className="mb-[20px] mt-[16px] lg:mt-[32px]">
        <HomeworkDisplay
          leftTitle={
            <div className="text-center">
              {_tx('New homework', 'New homework', 2)}
              {activeHomework && activeHomework.length > 0 && (
                <div className="ml-[8px] hidden xs:inline-block">
                  <NumberInCircle
                    value={activeHomework.length}
                    className="h-[20px] w-[20px] text-[12px] font-semibold leading-[15px]"
                  />
                </div>
              )}
            </div>
          }
          rightTitle={_tx("Homework's results", "Homework's results", 2)}
          leftNoContentMessage={
            <Message
              type="info"
              children={
                <div className="flex items-center gap-[11px]">
                  <div className="h-[18px] w-[18px]">
                    <IIcon />
                  </div>
                  <div>{_t('You have no homework. You can rest. 😊')}</div>
                </div>
              }
            />
          }
          rightNoContentMessage={
            <Message
              type="info"
              children={
                <div className="flex items-center gap-[11px]">
                  <div className="h-[18px] w-[18px]">
                    <IIcon />
                  </div>
                  <div>{_t("You haven't submitted any homework yet.")}</div>
                </div>
              }
            />
          }
          leftContent={activeHomework}
          rightContent={inactiveHomework}
          leftContentGap="gap-[16px]"
          rightContentGap="gap-[8px]"
          leftPath="/student/homework/active"
          rightPath="/student/homework/all"
        />
      </div>
    </div>
  );
}
