/**
 * The classroom door icon.
 */
export default function ClassroomDoorIcon() {
  return (
    <svg width="188" height="235" viewBox="0 0 188 235" fill="none" xmlns="http://www.w3.org/2000/svg">
      <mask id="path-1-inside-1_634_8788" fill="white">
        <rect width="188" height="235" rx="1" />
      </mask>
      <rect width="188" height="235" rx="1" stroke="#D9E0E6" strokeWidth="44" mask="url(#path-1-inside-1_634_8788)" />
      <line x1="128.5" y1="118.25" x2="177" y2="118.25" stroke="#D9E0E6" strokeWidth="22" strokeLinecap="round" />
    </svg>
  );
}
