import WebinarCard from 'components/webinars/WebinarCard';

/**
 * Czech webinars.
 */
export default function WebinarsCs() {
  return (
    <>
      <WebinarCard
        isInPast={true}
        name="Online cvičebnice - úvod pro učitele"
        lecturers={[{ name: 'Michael', avatar: 'team/michael-bohin.jpg' }]}
        onDay="Čtvrtek, 25.5."
        startsAt="17:00"
        duration="40 minut"
        description="Webinář pro učitele, ve kterém představujeme portál Zvědavá Mysl."
        webinarUrl="https://zoom.us/j/98238129326?pwd=RW13SHR4R24rL1pJcFVMbmNiME51QT09"
        subscribeUrl="https://9599da45.sibforms.com/serve/MUIEAM5yYjeXOWLTW5ydJOuTgfZ4HA7MbVpyTeR4Ixi7UpY5YQrsHYC3V4K5AuzEEUpqO-6w5wo43DN2vbCKc7K1TrHFJ8OS76ADtF1qDe0N6IEbCSWZwqiviEWMa4ykB0KGVKPpcaucako_x9Ej-fxN_Vl5_2zBV3ppdzgvru3Sg1F7bdOPekRyiCgAzVe3FBSUHFG-hT3Ou797"
      />
      <WebinarCard
        isInPast={true}
        name="Online cvičebnice - úvod pro učitele"
        lecturers={[{ name: 'Michael', avatar: 'team/michael-bohin.jpg' }]}
        onDay="Úterý, 23.5."
        startsAt="17:00"
        duration="40 minut"
        description="Webinář pro učitele, ve kterém představujeme portál Zvědavá Mysl."
        webinarUrl="https://zoom.us/j/91421016768?pwd=eHA5S3NhVTFSRmozelNpNDliK0w0UT09"
        subscribeUrl="https://9599da45.sibforms.com/serve/MUIEAGRvHEi3sVmjxte42DrRWKGvIHBUE8KCSXBTadNR7OJFy5EQesjbNv-ezTjVV_Q1DKKh9SJkfn9IXyWrjJYSrAPEKweaDkXe5BLka5uUy8_1b1Mufieuh-E-8XlX4JqE2XW5pO32EaETw-ctBUuj9VkhnZmJglzRh_lXlERVCQlc1mDg-iHg4V7Ksd9dMzqUR1iSr4IZrMPt"
      />
      <WebinarCard
        isInPast={true}
        name="Online cvičebnice - úvod pro učitele"
        lecturers={[{ name: 'Michael', avatar: 'team/michael-bohin.jpg' }]}
        onDay="Středa, 17.5."
        startsAt="17:00"
        duration="40 minut"
        description="Webinář pro učitele, ve kterém představujeme portál Zvědavá Mysl."
        webinarUrl="https://zoom.us/j/95609044930?pwd=b2R4T01jUDFHOFE2aDVybnBPS1ViQT09"
        subscribeUrl="https://9599da45.sibforms.com/serve/MUIEAPwhgXUmB1RHDXrW1lV2zC5HYD6E8MJCeI5WXAsMcqWxIn4Z5CrUlLCoX5MmeBgSZoeoSiCelR_bSVEDShFOylPeSlPipyG5LHmAK5sRzARuBS9C8_TE2QA5wkSNG90GQU7q8FeoFFrZBQ9DuY1KS6QqliU8gjRSYpXBHDOyMKu1gUhOc7u-ZYuhfSomNUto3vB_zllAeZXF"
      />
      <WebinarCard
        isInPast={true}
        name=" Online cvičebnice - úvod pro učitele"
        lecturers={[{ name: 'Michael', avatar: 'team/michael-bohin.jpg' }]}
        onDay="Pondělí, 15.5."
        startsAt="17:00"
        duration="40 minut"
        description="Webinář pro učitele, ve kterém představujeme portál Zvědavá Mysl."
        webinarUrl="https://zoom.us/j/96224201125?pwd=WkJoSjZZTXEzL1FEZFgzbE5MbFhqUT09"
        subscribeUrl="https://9599da45.sibforms.com/serve/MUIEABJ65yDfR8fDJIKTn1-lyrsjGEt0E3v-2utEBuLBs6tvZsqctJZGB6RbTukdqTIy3offdLQMwlG0s2lqeMwCU2i1TMP73nY1o-wmLupaB_MhmgOchuuVnaDf9WrFIp7NQj_Igqil0x8HCYwv6IVgxfp_f7DtfMggQIaE1EHipWkYb6mROVrkGwWLIpLzh0xDFe7O4dAqpkER"
      />
      <WebinarCard
        isInPast={true}
        name="Online cvičebnice - úvod pro učitele"
        lecturers={[{ name: 'Michael', avatar: 'team/michael-bohin.jpg' }]}
        onDay="Pátek, 12.5."
        startsAt="16:00"
        duration="40 minut"
        description="Webinář pro učitele, ve kterém představujeme portál Zvědavá Mysl."
        webinarUrl="https://zoom.us/j/91912632589?pwd=a3RrSllBdjJKNi9CZHNYNWN1Umx5UT09"
        subscribeUrl="https://9599da45.sibforms.com/serve/MUIEAJhhul37uYNKjO-WqUXni3PeNq-6q3rYP-2-Xwb5uV2Vo8fj3YUleM9hfqZ-5_xmqRD-NY3QmIGdYf0wSwiOlJM0cSGsiIAUOh7IMcbkVF4dTLbMlo87RdwbUBGnst672jGgIp1KCKdzkI5PMpFnqewvf18v6ep-bgdkeHU7qLdomtmvL_8ZiXR0jrzMu6KuB_Qt0MqMdAi7"
      />
      <WebinarCard
        name=" Osová souměrnost, mozaiky a posloupnosti 2"
        lecturers={[{ name: 'Willy', avatar: 'team/willy-svoboda.jpg' }]}
        onDay="Čtvrtek, 6.4."
        startsAt="17:00"
        duration="70 minut"
        description=""
        webinarUrl="https://www.youtube.com/watch?v=Elxn69HhVxM"
        recordingUrl="https://www.youtube.com/watch?v=Elxn69HhVxM"
        exercisesUrl="https://zvedavamysl.cz/pdf/priklady-06-04.pdf"
        subscribeUrl="https://9599da45.sibforms.com/serve/MUIEALIvHJlE1VtTyzXSODVQq7sioV_mPmgoK9NBQnVE5wjLcH7AfeWQBZ_99y3WVAu8LhlWxXEt9HkbyAdrUdRfRVIfWzrP9VQ9vVg9Ehg6u9sjt7Tssbv1ySnumsNwe5UGHd9MoEPtFdBLtVL1JSRXeJLNyXnxnuvEElDGi9nkJvEFBchCih-JF0KLI0pOf1wX2w4C6KUp1CYZ"
        isInPast={true}
      />
      <WebinarCard
        name=" Osová souměrnost, mozaiky a posloupnosti 1"
        lecturers={[{ name: 'Willy', avatar: 'team/willy-svoboda.jpg' }]}
        onDay="Úterý, 4.4."
        startsAt="17:00"
        duration="70 minut"
        description=""
        webinarUrl="https://www.youtube.com/watch?v=5nBQhqMLhY0"
        recordingUrl="https://www.youtube.com/watch?v=5nBQhqMLhY0"
        exercisesUrl="https://zvedavamysl.cz/pdf/priklady-04-04.pdf"
        subscribeUrl="https://9599da45.sibforms.com/serve/MUIEALIvHJlE1VtTyzXSODVQq7sioV_mPmgoK9NBQnVE5wjLcH7AfeWQBZ_99y3WVAu8LhlWxXEt9HkbyAdrUdRfRVIfWzrP9VQ9vVg9Ehg6u9sjt7Tssbv1ySnumsNwe5UGHd9MoEPtFdBLtVL1JSRXeJLNyXnxnuvEElDGi9nkJvEFBchCih-JF0KLI0pOf1wX2w4C6KUp1CYZ"
        isInPast={true}
      />
      <WebinarCard
        name=" Počítání se zlomky, slovní úlohy na obvod, obsah a objem"
        lecturers={[{ name: 'Michael', avatar: 'team/michael-bohin.jpg' }]}
        onDay="Úterý, 28.3."
        startsAt="17:00"
        duration="70 minut"
        description=""
        webinarUrl="https://www.youtube.com/watch?v=5ewHk1gMSBo"
        recordingUrl="https://www.youtube.com/watch?v=5ewHk1gMSBo"
        exercisesUrl="https://zvedavamysl.cz/pdf/priklady-28-03.pdf"
        subscribeUrl="https://9599da45.sibforms.com/serve/MUIEALIvHJlE1VtTyzXSODVQq7sioV_mPmgoK9NBQnVE5wjLcH7AfeWQBZ_99y3WVAu8LhlWxXEt9HkbyAdrUdRfRVIfWzrP9VQ9vVg9Ehg6u9sjt7Tssbv1ySnumsNwe5UGHd9MoEPtFdBLtVL1JSRXeJLNyXnxnuvEElDGi9nkJvEFBchCih-JF0KLI0pOf1wX2w4C6KUp1CYZ"
        isInPast={true}
      />
      <WebinarCard
        name=" Rovnice s jednou neznámou, slovní úlohy na procenta"
        lecturers={[{ name: 'Anička', avatar: 'team/anna-yaghobova.jpg' }]}
        onDay="Úterý, 21.3."
        startsAt="17:00"
        duration="50 minut"
        description=""
        webinarUrl="https://www.youtube.com/watch?v=TsbopbQaQvc"
        recordingUrl="https://www.youtube.com/watch?v=TsbopbQaQvc"
        exercisesUrl="https://zvedavamysl.cz/pdf/priklady-21-03.pdf"
        subscribeUrl="https://9599da45.sibforms.com/serve/MUIEALIvHJlE1VtTyzXSODVQq7sioV_mPmgoK9NBQnVE5wjLcH7AfeWQBZ_99y3WVAu8LhlWxXEt9HkbyAdrUdRfRVIfWzrP9VQ9vVg9Ehg6u9sjt7Tssbv1ySnumsNwe5UGHd9MoEPtFdBLtVL1JSRXeJLNyXnxnuvEElDGi9nkJvEFBchCih-JF0KLI0pOf1wX2w4C6KUp1CYZ"
        isInPast={true}
      />
      <WebinarCard
        name=" Geometrie - konstrukční úlohy"
        lecturers={[{ name: 'Honza', avatar: 'team/jan-sedlak.jpg' }]}
        onDay="Úterý, 14.3."
        startsAt="17:00"
        duration="50 minut"
        description=""
        webinarUrl="https://www.youtube.com/watch?v=9FNo_KnkjkM"
        recordingUrl="https://www.youtube.com/watch?v=9FNo_KnkjkM"
        exercisesUrl={['https://zvedavamysl.cz/pdf/priklady-14-03.pdf', 'https://www.geogebra.org/m/drf4mhp8']}
        subscribeUrl="https://9599da45.sibforms.com/serve/MUIEALIvHJlE1VtTyzXSODVQq7sioV_mPmgoK9NBQnVE5wjLcH7AfeWQBZ_99y3WVAu8LhlWxXEt9HkbyAdrUdRfRVIfWzrP9VQ9vVg9Ehg6u9sjt7Tssbv1ySnumsNwe5UGHd9MoEPtFdBLtVL1JSRXeJLNyXnxnuvEElDGi9nkJvEFBchCih-JF0KLI0pOf1wX2w4C6KUp1CYZ"
        isInPast={true}
      />
      <WebinarCard
        name="Organizace webinářů, slovní úlohy"
        lecturers={[{ name: 'Kamil', avatar: 'team/kamil-cinkraut.jpg' }]}
        onDay="Čtvrtek, 9.3."
        startsAt="17:00"
        duration="50 minut"
        description=""
        webinarUrl="https://www.youtube.com/watch?v=jky-0XsuVYo"
        recordingUrl="https://www.youtube.com/watch?v=jky-0XsuVYo"
        exercisesUrl="https://zvedavamysl.cz/pdf/priklady-09-03.pdf"
        subscribeUrl="https://9599da45.sibforms.com/serve/MUIEALIvHJlE1VtTyzXSODVQq7sioV_mPmgoK9NBQnVE5wjLcH7AfeWQBZ_99y3WVAu8LhlWxXEt9HkbyAdrUdRfRVIfWzrP9VQ9vVg9Ehg6u9sjt7Tssbv1ySnumsNwe5UGHd9MoEPtFdBLtVL1JSRXeJLNyXnxnuvEElDGi9nkJvEFBchCih-JF0KLI0pOf1wX2w4C6KUp1CYZ"
        isInPast={true}
      />
    </>
  );
}
