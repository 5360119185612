import useApi from 'api/use-api';
import { Course } from 'data/course/course-context';
import CourseStepContext, {
  CourseStepId,
  CurrentCourseStep,
  NextCourseStep,
  PreviousCourseStep,
} from 'pages/courses-module/course-step/context/course-step-context';
import { useEffect, useState } from 'react';

interface Params {
  course: Course;
  sectionSlug: string;
  stepId: CourseStepId;
  children: React.ReactNode;
}

/**
 * Provides the context for course step page.
 */
export default function CourseStepProvider({ children, course, sectionSlug, stepId }: Params) {
  const [currStep, setCurrStep] = useState<CurrentCourseStep | undefined>(undefined);
  const [nextStep, setNextStep] = useState<NextCourseStep | undefined>(undefined);
  const [prevStep, setPrevStep] = useState<PreviousCourseStep | undefined>(undefined);
  const [isSidebarVisible, setIsSidebarVisible] = useState(true);

  const { getAction } = useApi();
  const getCourseStepAction = getAction('GetCourseStep');

  // Fetches the course step on mount.
  useEffect(() => {
    setCurrStep(undefined);

    getCourseStepAction({ parameters: { courseId: course.courseId, stepId } }).success(
      ({ step, nextStep, prevStep }) => {
        // It is possible that the stepId has changed since the request was made.
        if (step.stepId === stepId) {
          setCurrStep(step);
          setNextStep(nextStep);
          setPrevStep(prevStep);
        }
      }
    );
  }, [course.courseId, stepId]);

  const nextStepLink = nextStep ? `/course/${course.slug}/${nextStep.section.slug}/step/${nextStep.stepId}` : undefined;
  const prevStepLink = prevStep ? `/course/${course.slug}/${prevStep.section.slug}/step/${prevStep.stepId}` : undefined;

  return (
    <CourseStepContext.Provider
      value={{
        course,
        sectionSlug,
        stepId,
        currStep,
        nextStep,
        prevStep,
        nextStepLink,
        prevStepLink,
        isSidebarVisible,
        setIsSidebarVisible,
      }}
    >
      {children}
    </CourseStepContext.Provider>
  );
}
