import { Tooltip } from '@mantine/core';
import { useEffect, useRef, useState } from 'react';

interface Props {
  text: string;
  classNames?: string;
}

/**
 * Text with ellipsis.
 * The truncated text is displayed in the tooltip.
 */
export default function TruncatedText({ text, classNames }: Props) {
  const ref = useRef<HTMLSpanElement>(null);
  const [displayTooltip, setDisplayTooltip] = useState(false);

  /**
   * Whether the text is truncated.
   */
  function isEllipsisActive() {
    if (ref.current === null) {
      setDisplayTooltip(false);
      return;
    }

    setDisplayTooltip(ref.current?.offsetWidth < ref.current?.scrollWidth);
  }

  useEffect(() => {
    isEllipsisActive();
  }, [text]);

  const truncated = (
    <span className={`overflow-hidden text-ellipsis whitespace-nowrap ${classNames}`} ref={ref}>
      {text}
    </span>
  );

  return displayTooltip ? <Tooltip label={text}>{truncated}</Tooltip> : truncated;
}
