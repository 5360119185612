import { usePageLeave, useTimeout } from '@mantine/hooks';
import MarkdownText from 'components/MarkdownText';
import useCourse from 'data/course/use-course';
import useCourseStep from 'pages/courses-module/course-step/context/use-course-step';
import ContinueToNextStepButton from 'pages/courses-module/course-step/main/ContinueToNextStepButton';

/**
 * The parameters of the course step content text.
 */
interface Props {
  text: string;
}

/**
 * The textual part of the course.
 */
export default function CourseStepContentText({ text }: Props) {
  const { course, stepId } = useCourseStep();
  const { updateCourseProgress } = useCourse();

  /**
   * Completes the step.
   */
  const completeStep = () =>
    updateCourseProgress({
      courseId: course.courseId,
      steps: [{ stepId, progress: 1 }],
    });

  const delay = 10 * 1000; // 10 seconds.

  const { clear } = useTimeout(completeStep, delay, { autoInvoke: true });

  usePageLeave(clear);

  return (
    <div className="ml-auto mr-auto max-w-[618px] pl-[24px] pr-[24px] lg:pb-[56px] lg:pt-[56px]">
      <MarkdownText text={text} />
      <div className="cursor-pointer pt-[24px] lg:pt-0" onClick={completeStep}>
        <ContinueToNextStepButton className="mt-[48px] min-w-[240px]" />
      </div>
    </div>
  );
}
