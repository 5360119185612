import StudentIcon from 'components/icons/StudentIcon';
import { _t, _tx } from 'lang';
import sprintf from 'lang/sprintf';

/**
 * The parameters for the SubmittedCountStat component.
 */
export interface SubmittedCountStatProps {
  actual: number;
  total: number;
}

/**
 * Statistic which shows the number of students who have submitted the homework
 * versus the total number of students who were assigned the homework.
 */
export default function SubmittedCountStat({ actual, total }: SubmittedCountStatProps) {
  return (
    <div className="flex items-end gap-[12px] text-[12px] leading-[15px] text-text-shadow">
      <div className="flex items-center gap-[8px] text-text-dark">
        <span className="text-text-shadow">{_t('Submitted')}</span>
        <div className="flex h-[20px] w-[20px] items-center justify-center">
          <div className="flex h-[14px] w-[14px] items-center justify-center">
            <StudentIcon stroke="#353E45" />
          </div>
        </div>
        <div
          dangerouslySetInnerHTML={{
            __html: sprintf(
              // Translators: The first %s is the class name for the font-bold style, the second %d is the number of students who have submitted the homework, and the third %d is the total number of students who were assigned the homework. Eg. "1 of 10 students" where "1" will be bolded.
              _tx('<span class="%s">%d</span> of %d student', '<span class="%s">%d</span> of %d students', total),
              'font-bold',
              actual,
              total
            ),
          }}
        />
      </div>
    </div>
  );
}
