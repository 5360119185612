import { AxiosInstance } from 'axios';
import { SweetPath } from 'sweet-path';
import dispatchAction from 'api/dispatch-action';

import { ValidateClassroomInviteCodePayload } from 'api/actions/validate-classroom-invite-code/validate-classroom-invite-code-payload';
import { ValidateClassroomInviteCodePathParameters } from 'api/actions/validate-classroom-invite-code/validate-classroom-invite-code-path-parameters';

import { ValidateClassroomInviteCodeResponse } from 'api/actions/validate-classroom-invite-code/validate-classroom-invite-code-response';

/**
 * The path of the ValidateClassroomInviteCode action.
 */
export const validateClassroomInviteCodeActionPath = new SweetPath<keyof ValidateClassroomInviteCodePathParameters>(
  '/classroom/invite-code/validate',
  { path: '{param}' }
);

/**
 * Creates a contextualized ValidateClassroomInviteCode actions.
 */
export default function createValidateClassroomInviteCodeAction(axios: AxiosInstance) {
  return function validateClassroomInviteCodeAction({ payload }: { payload: ValidateClassroomInviteCodePayload }) {
    const path = validateClassroomInviteCodeActionPath.original;

    return dispatchAction(() =>
      axios.post<
        ValidateClassroomInviteCodeResponse,
        ValidateClassroomInviteCodeResponse,
        ValidateClassroomInviteCodePayload
      >(path, payload)
    );
  };
}
