import LeftArrowIcon from 'components/icons/LeftArrowIcon';
import RightArrowIcon from 'components/icons/RightArrowIcon';
import { _t } from 'lang';

/**
 * The parameters of the toggle sidebar component.
 */
interface Props {
  isSidebarVisible: boolean;
  onClick: () => void;
}

/**
 * The button which toggles the sidebar.
 */
export default function ToggleSidebar({ isSidebarVisible, onClick }: Props) {
  const position = isSidebarVisible ? 'lg:right-[470px]' : 'lg:right-0';

  const text = isSidebarVisible ? _t('Hide') : _t('Show course content');
  const icon = isSidebarVisible ? <RightArrowIcon /> : <LeftArrowIcon />;

  return (
    <div
      className={`hidden cursor-pointer lg:fixed lg:flex lg:items-center lg:gap-[2px] lg:pr-[18px] lg:pt-[13px] lg:text-[16px] lg:font-semibold lg:leading-[19px] lg:text-text-shadow ${position}`}
      onClick={onClick}
    >
      {text} {icon}
    </div>
  );
}
