import CrossIcon from 'components/icons/CrossIcon';
import HamburgerMenuIcon from 'components/icons/HamburgerMenuIcon';
import ZvedavaMyslIcon from 'components/icons/ZvedavaMyslIcon';
import { useState } from 'react';
import { Link } from 'react-router-dom';
import { HOME_PAGE_PATH } from 'routes/paths';
import PhoneMenu from 'layout/parts/header/PhoneMenu';
import Clickable from 'components/misc/Clickable';

/**
 * Phone header.
 */
export default function PhoneHeader() {
  const [displayMenu, setDisplayMenu] = useState(false);

  const shadow = displayMenu ? '' : 'shadow-3xl';

  return (
    <div>
      <div
        className={`fixed left-0 top-0 z-30 flex h-[66px] w-full items-center justify-between bg-white pb-[18px] pl-[16px] pr-[20px] pt-[19px] ${shadow}`}
      >
        <Link to={HOME_PAGE_PATH}>
          <ZvedavaMyslIcon />
        </Link>
        <Clickable className="mr-[-15px] p-[15px]" onClick={() => setDisplayMenu((display) => !display)}>
          {displayMenu ? <CrossIcon /> : <HamburgerMenuIcon />}
        </Clickable>
      </div>
      {displayMenu && <PhoneMenu setDisplayMenu={setDisplayMenu} />}
    </div>
  );
}
