import { AxiosInstance } from 'axios';
import { SweetPath } from 'sweet-path';
import dispatchAction from 'api/dispatch-action';

import { RegisterPayload } from 'api/actions/register/register-payload';
import { RegisterPathParameters } from 'api/actions/register/register-path-parameters';

import { RegisterResponse } from 'api/actions/register/register-response';

/**
 * The path of the Register action.
 */
export const registerActionPath = new SweetPath<keyof RegisterPathParameters>('/user/register', { path: '{param}' });

/**
 * Creates a contextualized Register actions.
 */
export default function createRegisterAction(axios: AxiosInstance) {
  return function registerAction({ payload }: { payload: RegisterPayload }) {
    const path = registerActionPath.original;

    return dispatchAction(() => axios.post<RegisterResponse, RegisterResponse, RegisterPayload>(path, payload));
  };
}
