import ChevronRightIcon from 'components/icons/ChevronRightIcon';
import NavigateCourseButton from 'components/buttons/NavigateCourseButton';
import useCourseStep from 'pages/courses-module/course-step/context/use-course-step';
import { useState } from 'react';
import { _t } from 'lang';

/**
 * The parameters of the component.
 */
interface Props {
  className?: string;
  prefix?: string;
}

/**
 * Upon click displays the next step in the course.
 */
export default function NextStepButton({ className, prefix = '' }: Props) {
  const { course, nextStep } = useCourseStep();
  const [hover, setHover] = useState<boolean>(false);

  const stepId = nextStep?.stepId;
  const sectionSlug = nextStep?.section.slug;
  const visibility = stepId && sectionSlug ? 'visible' : 'invisible';

  return (
    <div
      className={`h-[64px] lg:sticky lg:left-full lg:right-0 lg:flex lg:max-w-[180px] lg:items-center ${className} ${visibility} ${
        hover && 'lg:rounded-bl-[5px] lg:rounded-tl-[5px] lg:bg-bg-course-button-hover'
      }`}
    >
      {hover && (
        <div className="hidden lg:block lg:h-full lg:w-full lg:overflow-hidden lg:text-ellipsis lg:whitespace-nowrap  lg:pb-[10px] lg:pl-[12px] lg:pr-[20px] lg:pt-[15px] lg:text-white">
          <p className="lg:text-[10px] lg:font-normal lg:leading-[12px]">{_t('Follows')}:</p>
          <span className="lg:text-[12px] lg:font-medium lg:leading-[15px]">{nextStep?.title}</span>
        </div>
      )}
      <div
        onMouseEnter={() => setHover(true)}
        onMouseLeave={() => setHover(false)}
        className="right-0 h-full lg:sticky lg:left-full"
      >
        <NavigateCourseButton
          courseSlug={course.slug}
          sectionSlug={sectionSlug}
          stepId={stepId}
          className="rounded-bl-[5px] rounded-tl-[5px]"
          icon={<ChevronRightIcon />}
          prefix={prefix}
        />
      </div>
    </div>
  );
}
