import { Tooltip } from '@mantine/core';
import { useClipboard } from '@mantine/hooks';
import CopyIcon from 'components/icons/CopyIcon';
import { _t } from 'lang';

interface Props {
  link: string;
  classNames?: string;
}

/**
 * Copy share link component.
 */
export default function CopyShareLink({ link, classNames }: Props) {
  const clipboard = useClipboard({ timeout: 1000 });

  return (
    <div className={`${classNames}`}>
      <div className="flex h-[56px] items-center justify-between rounded-[10px] border border-input-outline-shadow bg-bg-shadow text-[14px] font-semibold leading-[17px] lg:pl-[16px] lg:pr-[21px]">
        <span>{link}</span>
        <Tooltip label={clipboard.copied ? _t('Text copied!') : _t('Copy invite code')} withArrow>
          <div onClick={() => clipboard.copy(link)} className="cursor-pointer">
            <CopyIcon />
          </div>
        </Tooltip>
      </div>
    </div>
  );
}
