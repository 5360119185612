import { isFunction, noop } from 'lodash';
import { NavLink } from 'react-router-dom';

/**
 * Parameters of the HeaderMenuLink component.
 */
interface Props {
  to: string;
  text: string | React.ReactNode | ((isActive: boolean) => string | React.ReactNode);
  hideMenu?: () => void;
}

/**
 * Header menu link.
 */
export default function HeaderMenuLink({ to, text, hideMenu = noop }: Props) {
  return (
    <div
      onClick={hideMenu}
      className="mx-[-8px] cursor-pointer text-[16px] font-medium leading-[19px] text-text-shadow lg:m-[-10px] lg:p-[10px] xl:mx-[-12px]"
    >
      <NavLink className="block w-full px-[8px] py-[12px] xl:px-[12px]" to={to}>
        {({ isActive }) => {
          const activeStyle = isActive ? 'underline text-text-dark' : '';
          return <span className={activeStyle}>{isFunction(text) ? text(isActive) : text}</span>;
        }}
      </NavLink>
    </div>
  );
}
