import { Button, Modal } from '@mantine/core';
import useApi from 'api/use-api';
import CrossIcon from 'components/icons/CrossIcon';
import Clickable from 'components/misc/Clickable';
import useClassrooms from 'data/classrooms/use-classrooms';
import { _t } from 'lang';
import { noop } from 'lodash';
import { Dispatch, SetStateAction, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

interface Props {
  studentId: string;
  opened: boolean;
  setOpened: Dispatch<SetStateAction<boolean>>;
  setDisplayMessage: Dispatch<SetStateAction<boolean>>;
  shouldRedirect?: boolean;
}

/**
 * The modal to remove a student from a classroom.
 *
 * @see https://www.figma.com/file/SjggpqHVlWxBKa2cIG349A/Math-Exercises?node-id=628-4462&t=RiotZo8eDxHvOmBr-0
 */
export default function RemoveStudentModal({ studentId, opened, setOpened, setDisplayMessage, shouldRedirect }: Props) {
  const { classroomId } = useParams();
  const { getAction } = useApi();
  const { fetchClassrooms, fetchClassroomStudents } = useClassrooms();
  const kickClassroomStudentAction = getAction('KickClassroomStudent');
  const [fill, setFill] = useState('#738491');
  const navigate = useNavigate();

  /**
   * Kicks the student out of the classroom.
   */
  function kickTheStudentOut() {
    kickClassroomStudentAction({ parameters: { classroomId: classroomId!, studentId } }).success(() => {
      setOpened(false);
      setDisplayMessage(true);
      fetchClassrooms();
      fetchClassroomStudents({ classroomId: classroomId! });

      if (shouldRedirect) {
        navigate(`/teacher/classrooms/classroom/${classroomId}/students`, { state: { displayMessage: true } });
      }
    });
  }

  return (
    <Modal
      opened={opened}
      onClose={noop} // this is a required prop
      centered
      size="auto"
      withCloseButton={false}
      radius={10}
      styles={{
        root: {
          padding: '0!important',
        },
        body: {
          padding: '0!important',
        },
        modal: {
          padding: '0!important',
        },
      }}
    >
      <div>
        <div className="flex flex-row-reverse justify-items-end rounded-[10px_10px_0_0] bg-bg-shadow bg-remove-student-modal bg-center bg-no-repeat lg:h-[242px] lg:w-[620px] ">
          <div className="pr-[31px] pt-[31px]">
            <Clickable
              onClick={() => setOpened(false)}
              className="flex h-[42px] w-[42px] items-center justify-center rounded-[7px] p-[8px] hover:bg-bg-secondary-button-hover"
              onMouseEnter={() => {
                setFill('#353E45');
              }}
              onMouseLeave={() => {
                setFill('#738491');
              }}
            >
              <CrossIcon fill={fill} />
            </Clickable>
          </div>
        </div>
        <div className="w-[620px] pb-[56px] pl-[64px] pr-[64px] pt-[32px]">
          <div className="text-center text-[24px] font-semibold leading-[29px]">
            {_t('Do you really want to remove the student from the classroom ?')}
          </div>
          <div className="pt-[8px] text-center text-[14px] leading-[22px]">
            {
              _t('After removing a student from the classroom, we recommend that you generate a new invite code for the classroom. This will ensure that the student cannot join the classroom again with their old invite code.') // prettier-ignore
            }
          </div>
          <div className="flex flex-col gap-[10px] lg:pt-[32px]">
            <Button
              className="h-[56px] w-full bg-red text-[18px] font-semibold leading-[22px] text-white hover:bg-red-hover focus:bg-red-hover"
              radius={10}
              onClick={kickTheStudentOut}
            >
              {_t('Yes, remove the student from the classroom')}
            </Button>
            <Button
              className="h-[56px] w-full bg-bg-shadow text-[18px] font-semibold leading-[22px] text-text-shadow hover:bg-input-outline-shadow focus:bg-input-outline-shadow"
              radius={10}
              onClick={() => setOpened(false)}
            >
              {_t('No, I do not want to remove the student')}
            </Button>
          </div>
        </div>
      </div>
    </Modal>
  );
}
