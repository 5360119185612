import { AxiosInstance } from 'axios';
import { SweetPath } from 'sweet-path';
import dispatchAction from 'api/dispatch-action';

import { GetAssignmentPathParameters } from 'api/actions/get-assignment/get-assignment-path-parameters';

import { GetAssignmentResponse } from 'api/actions/get-assignment/get-assignment-response';

/**
 * The path of the GetAssignment action.
 */
export const getAssignmentActionPath = new SweetPath<keyof GetAssignmentPathParameters>('/assignment/{assignmentId}', {
  path: '{param}',
});

/**
 * Creates a contextualized GetAssignment actions.
 */
export default function createGetAssignmentAction(axios: AxiosInstance) {
  return function getAssignmentAction({ parameters }: { parameters: GetAssignmentPathParameters }) {
    const path = getAssignmentActionPath.insert(parameters);

    return dispatchAction(() => axios.get<GetAssignmentResponse, GetAssignmentResponse>(path));
  };
}
