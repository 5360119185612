import useApi from 'api/use-api';
import { useEffect, useState } from 'react';
import StudentDashboardContext, { Classroom, Homework, Stats } from 'data/student-dashboard/student-dashboard-context';

/**
 * Provides the data for the student's dashboard
 */
export default function StudentDashboardProvider({ children }: { children: React.ReactNode }) {
  const { getAction, ready: apiIsReady, role } = useApi();
  const getStudentDashboardAction = getAction('GetStudentDashboard');

  const [ready, setReady] = useState<boolean>(false); // Data fetched at least once.
  const [loading, setLoading] = useState<boolean>(false); // Data is being fetched.

  const [classrooms, setClassrooms] = useState<Classroom[]>([]);
  const [homework, setHomework] = useState<Homework[]>([]);
  const [stats, setStats] = useState<Stats>({ numberOfHomework: 0, numberOfNotSubmitted: 0 });

  /**
   * Fetches the data for the student's dashboard.
   */
  function fetchStudentDashboard(): void {
    if (loading) {
      return; // Prevent multiple requests
    }

    setLoading(true);

    getStudentDashboardAction()
      .success(({ classrooms, homework, stats }) => {
        setClassrooms(classrooms);
        setHomework(homework);
        setStats(stats);
      })
      .complete(() => {
        setReady(true);
        setLoading(false);
      });
  }

  /**
   * Returns the classrooms in which the student is enrolled.
   */
  function getClassrooms(): Classroom[] {
    return classrooms;
  }

  /**
   * Returns the homework the student has assigned.
   */
  function getHomework(): Homework[] {
    return homework;
  }

  /**
   * Returns the active homework the student has assigned.
   */
  function getActiveHomework(): Homework[] {
    return homework.filter(({ homework, assignment }) => !homework.isPastDeadline && !assignment.submitted);
  }

  /**
   * Returns the statistics for the student.
   */
  function getStats(): Stats {
    return stats;
  }

  // Fetch data when session is ready.
  useEffect(() => {
    if (apiIsReady && role === 'student') {
      fetchStudentDashboard();
    }
  }, [apiIsReady, role]);

  // Reset data when user logs out.
  useEffect(() => {
    if (!apiIsReady) {
      setReady(false);
      setLoading(false);
      setClassrooms([]);
      setHomework([]);
      setStats({ numberOfHomework: 0, numberOfNotSubmitted: 0 });
    }
  }, [apiIsReady]);

  // Enable only for students.
  if (role !== 'student') {
    return <>{children}</>;
  }

  return (
    <StudentDashboardContext.Provider
      value={{
        ready,
        loading,
        fetchStudentDashboard,
        getClassrooms,
        getHomework,
        getActiveHomework,
        getStats,
      }}
    >
      {children}
    </StudentDashboardContext.Provider>
  );
}
