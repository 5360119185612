import { Button } from '@mantine/core';
import Breadcrumbs from 'components/Breadcrumbs';
import PlusIcon from 'components/icons/PlusIcon';
import { _t, _tx } from 'lang';
import TeacherDashboardLayout from 'layout/teacher-dashboard-layout/TeacherDashboardLayout';
import { Link, useNavigate, useParams } from 'react-router-dom';
import TeacherHomeworkCard from 'pages/teacher-module/classroom/homework/card/TeacherHomeworkCard';
import HomeworkDisplay from 'components/HomeworkDisplay';
import useApi from 'api/use-api';
import { useEffect, useState } from 'react';
import ArrowRightIcon from 'components/icons/ArrowRightIcon';
import useClassrooms from 'data/classrooms/use-classrooms';
import Message from 'components/Message';
import IIcon from 'components/icons/IIcon';
import sprintf from 'lang/sprintf';

/**
 * Homework for a classroom.
 *
 * @see https://www.figma.com/file/SjggpqHVlWxBKa2cIG349A/Math-Exercises?node-id=628%3A5103&t=J3hpSwWIuCcusYbV-0
 */
export default function ClassroomHomeworkPage() {
  const { classroomId } = useParams();
  const { getAction } = useApi();
  const { getClassroom } = useClassrooms();
  const getClassroomHomeworkAction = getAction('GetClassroomHomework');
  const [leftContent, setLeftContent] = useState<JSX.Element[] | undefined>(undefined);
  const [rightContent, setRightContent] = useState<JSX.Element[] | undefined>(undefined);
  const navigate = useNavigate();
  const classroom = getClassroom({ classroomId: classroomId! });
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    getClassroomHomeworkAction({ parameters: { classroomId: classroomId! } })
      .success(({ homework }) => {
        const mappedHomework = homework
          .sort((first, second) => (first.homeworkNumber > second.homeworkNumber ? -1 : 1))
          .map((homework) => ({
            homework,
            component: (
              <TeacherHomeworkCard
                key={homework.homeworkId}
                homeworkId={homework.homeworkId}
                isActive={!homework.isPastDeadline}
                assigned={homework.assigned.at}
                deadline={homework.deadline}
                name={`${_t('Homework')} #${homework.homeworkNumber}`}
                exercises={homework.exerciseSet.exercises}
                exercisesCount={homework.exerciseSet.exercises.length}
                submitted={{
                  total: homework.stats.numberOfStudents,
                  actual: homework.stats.numberOfSubmits,
                }}
                successRate={homework.stats.successRate}
              />
            ),
          }));

        setLeftContent(
          mappedHomework.filter(({ homework }) => !homework.isPastDeadline).map(({ component }) => component)
        );

        setRightContent(mappedHomework.map(({ component }) => component));
      })
      .complete(() => setLoading(false));
  }, [classroomId]);

  if (loading) {
    return <TeacherDashboardLayout hasHeader hasSidebar />;
  }

  return (
    <TeacherDashboardLayout hasHeader hasSidebar>
      <div className="flex items-center justify-between">
        <Breadcrumbs>
          <Link to={`/teacher/classrooms/classroom/${classroomId}/detail`}>{classroom?.name}</Link>
          <ArrowRightIcon stroke="#738491" />
          <div className="font-normal text-text-dark-2">{_tx('Homework', 'Homework', 2)}</div>
        </Breadcrumbs>
      </div>
      <div className="mt-[16px] flex items-center justify-between">
        <div className="flex items-baseline gap-[12px]">
          <div className="text-[24px] font-semibold leading-[29px]">{_tx('Homework', 'Homework', 2)}</div>
          {leftContent && leftContent.length > 0 && (
            <div className="text-[16px] font-medium leading-[19px] text-text-shadow">
              {
                // Translators: Number of active homework in a classroom. E.g. 2 active (plural) or 1 active (singular).
                sprintf(_tx('%d active', '%d active', leftContent.length), leftContent.length)
              }
            </div>
          )}
        </div>
        <Button
          className="h-[42px] rounded-[7px] bg-green-primary text-[14px] font-semibold leading-[17px] hover:bg-green-dark active:bg-green-dark"
          onClick={() => navigate(`/teacher/classrooms/classroom/${classroomId}/exercises`)}
        >
          <div className="flex items-center gap-[10px]">
            <PlusIcon />
            <span>{_t('Assign a new homework')}</span>
          </div>
        </Button>
      </div>
      <div className="lg:pt-[32px]">
        <HomeworkDisplay
          leftTitle={_tx('Active homework', 'Active homework', 2)}
          // Translators: Heading for all homework in a classroom. Always in plural.
          rightTitle={_t('All homework')}
          leftNoContentMessage={
            <Message
              children={
                <div className="flex items-center gap-[11px]">
                  <div className="h-[18px] w-[18px]">
                    <IIcon />
                  </div>
                  <div>
                    {
                      // Translators: The text of a message which is displayed when there are no ongoing homework assignments. The text is always in plural form.
                      _t('Currently, there is no ongoing homework.')
                    }
                  </div>
                </div>
              }
              type="info"
            />
          }
          rightNoContentMessage={
            <Message
              children={
                <div className="flex items-center gap-[11px]">
                  <div className="h-[18px] w-[18px]">
                    <IIcon />
                  </div>
                  <div>{_t("You haven't assigned a homework yet.")}</div>
                </div>
              }
              type="info"
            />
          }
          leftContent={leftContent}
          rightContent={rightContent}
          leftPath={`/teacher/classrooms/classroom/${classroomId}/homework/active`}
          rightPath={`/teacher/classrooms/classroom/${classroomId}/homework/all`}
        />
      </div>
    </TeacherDashboardLayout>
  );
}
