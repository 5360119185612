interface Props {
  stroke: string;
  className?: string;
}
/**
 * Lock icon
 */
export default function LockIcon({ stroke, className = '' }: Props) {
  return (
    <svg
      className={className}
      width="14"
      height="18"
      viewBox="0 0 14 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M11.9933 16.9998H2.00354C1.45615 16.9998 1 16.54 1 15.9883V8.86184C1 8.31011 1.45615 7.85034 2.00354 7.85034H11.9933C12.5407 7.85034 12.9969 8.31011 12.9969 8.86184V15.9883C13.0425 16.54 12.5863 16.9998 11.9933 16.9998Z"
        stroke={stroke}
        strokeWidth="2"
        strokeMiterlimit="10"
      />
      <path
        d="M3.46338 7.16092V4.58621C3.46338 2.6092 5.05992 1 7.02138 1C8.98285 1 10.5794 2.6092 10.5794 4.58621V7.16092"
        stroke={stroke}
        strokeWidth="2"
        strokeMiterlimit="10"
      />
    </svg>
  );
}
