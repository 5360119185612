import Message from 'components/Message';
import IIcon from 'components/icons/IIcon';
import DisappearingNotification from 'pages/teacher-module/classroom/DisappearingNotification';
import InviteStudentModal from 'pages/teacher-module/classroom/students/modals/InviteStudentModal';
import ArrowRightIcon from 'components/icons/ArrowRightIcon';
import StudentDetailRow from 'pages/teacher-module/classroom/students/StudentDetailRow';
import { Button, Select } from '@mantine/core';
import Breadcrumbs from 'components/Breadcrumbs';
import StudentIcon from 'components/icons/StudentIcon';
import { _t, _tx } from 'lang';
import { Link, useLocation } from 'react-router-dom';
import { ClassroomStudent } from 'data/classrooms/classrooms-context';
import { useEffect, useState } from 'react';

interface Props {
  classroomId: string;
  classroomName: string;
  students: ClassroomStudent[];
}

/**
 * The content of the Classroom students page.
 */
export default function ClassroomStudentsContent({ classroomId, classroomName, students }: Props) {
  const [displayMessage, setDisplayMessage] = useState(false);
  const [openedInviteModal, setOpenedInviteModal] = useState(false);
  const [sortingValue, setSortingValue] = useState<string>('alpha');
  let { state } = useLocation();

  /**
   * Sorts the students by the sorting value.
   */
  function sortStudents(students: ClassroomStudent[]) {
    if (sortingValue === 'alpha') {
      students.sort(({ student: a }, { student: b }) => {
        const comparisonResult = a.lastName.localeCompare(b.lastName);

        if (comparisonResult === 0) {
          return a.firstName.localeCompare(b.firstName);
        }

        return comparisonResult;
      });
    } else if (sortingValue === 'success') {
      students.sort(({ stats: a }, { stats: b }) => {
        if (!a || !b) {
          return 0;
        }

        if (a.averageSuccessRate === undefined || b.averageSuccessRate === undefined) {
          return 0;
        }

        return b.averageSuccessRate - a.averageSuccessRate;
      });
    }

    return students;
  }

  students = sortStudents([...students]);

  useEffect(() => {
    if (state && state.displayMessage) {
      setDisplayMessage(true);
      window.history.replaceState({}, document.title);
    }
  }, [state]);

  return (
    <div>
      <div className="flex items-center justify-between">
        <Breadcrumbs>
          <Link to={`/teacher/classrooms/classroom/${classroomId}/detail`}>{classroomName}</Link>
          <ArrowRightIcon stroke="#738491" />
          <div className="font-normal text-text-dark-2">{_t('Students in the classroom')}</div>
        </Breadcrumbs>
      </div>
      <div className="mt-[16px] flex items-center justify-between border-b border-b-input-outline-shadow pb-[32px]">
        <div className="flex items-baseline gap-[12px]">
          <div className="text-[24px] font-semibold leading-[29px]">{_t('Students in the classroom')}</div>
          <div className="text-[16px] font-medium leading-[19px] text-text-shadow">
            {students.length} {_tx('student', 'students', students.length)}
          </div>
        </div>
        {displayMessage && (
          <DisappearingNotification text={_t('Student removed successfully.')} setDisplay={setDisplayMessage} />
        )}
        <Button
          className="h-[42px] w-[276px] rounded-[7px] bg-green-primary text-[14px] font-semibold leading-[17px] hover:bg-green-dark active:bg-green-dark"
          onClick={() => setOpenedInviteModal(true)}
        >
          <div className="flex items-center gap-[10px]">
            <StudentIcon stroke="#FFFFFF" />
            <span>{_t('Add students to the classroom')}</span>
          </div>
        </Button>
      </div>
      <div className="pt-[32px]">
        <div className="flex items-center justify-between text-[14px] font-medium leading-[17px] text-text-shadow">
          <div className="w-[244px] pl-[66px]">{_t('Student')}</div>
          <div className="w-[194px] text-center">{_t('Average success rate')}</div>
          <div className="w-[188px] lg:w-[288px]">
            <Select
              data={[
                { label: _t('Alphabetically'), value: 'alpha' },
                { label: _t('Average success rate'), value: 'success' },
              ]}
              value={sortingValue}
              onChange={(val) => {
                if (val) {
                  setSortingValue(val);
                  sortStudents(students);
                }
              }}
              radius={7}
              styles={{
                root: { width: '100%' },
                input: {
                  fontWeight: 500,
                  backgroundColor: '#FFF',
                },
                item: {
                  padding: '0px!important',
                  paddingLeft: '16px!important',
                  fontWeight: 500,
                  height: '42px!important',
                  display: 'flex',
                  alignItems: 'center',
                  fontSize: '14px',
                  lineHeight: '17px',
                },
                dropdown: {
                  padding: '8px!important',
                  margin: '0!important',
                },
                itemsWrapper: {
                  display: 'flex!important',
                  flexDirection: 'column',
                  gap: '8px!important',
                  padding: '0!important',
                },
              }}
            />
          </div>
        </div>
        <div className="flex flex-col gap-[8px] pt-[20px]">
          {students.length > 0 ? (
            students.map(({ student, stats }, index) => (
              <StudentDetailRow
                key={student.userId}
                index={index + 1}
                studentId={student.userId}
                fullName={`${student.firstName} ${student.lastName}`}
                avgSuccessRate={stats.averageSuccessRate}
                setDisplayMessage={setDisplayMessage}
              />
            ))
          ) : (
            <Message
              children={
                <div className="flex items-center gap-[11px]">
                  <div className="h-[18px] w-[18px]">
                    <IIcon />
                  </div>
                  <div>{_t('The classroom does not have any students yet.')}</div>
                </div>
              }
              type="info"
            />
          )}
        </div>
      </div>
      <InviteStudentModal opened={openedInviteModal} setOpened={setOpenedInviteModal} classroomId={classroomId!} />
    </div>
  );
}
