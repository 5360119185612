/**
 * Minus sign icon.
 */
export default function MinusIcon() {
  return (
    <svg width="12" height="2" viewBox="0 0 12 2" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M1 1L11 1" stroke="#738491" strokeWidth="2" strokeLinecap="round" />
    </svg>
  );
}
