import { _t } from 'lang';
import ImageCard from 'pages/presentation-module/who-we-are/ImageCard';

/**
 * Image cards with Mentors.
 */
export default function Mentors() {
  return (
    <>
      <ImageCard
        image="/team/tereza-hannemann.jpg" // cspell:disable-line
        name="Mgr. Tereza Hannemann, Ph.D." // cspell:disable-line
        position={_t('Mentor')}
        contact="https://www.mff.cuni.cz/cs/fakulta/organizacni-struktura/lide?hdl=7630"
        contactName={_t('University website')}
      />
      <ImageCard
        image="/team/adam-dingle.jpg"
        name="Adam Dingle, M.Sc."
        position={_t('Mentor')}
        contact="https://ksvi.mff.cuni.cz/~dingle/"
        contactName={_t('University website')}
        positionClassName="lg:mt-[30px]"
      />
    </>
  );
}
