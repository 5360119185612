import { _t } from 'lang';
import { useForm } from '@mantine/form';
import { JoinClassroomPayload } from 'api/actions/join-classroom/join-classroom-payload';
import useApi from 'api/use-api';
import { useState } from 'react';
import required from 'components/forms/validators/rules/rule-required';
import createValidator from 'components/forms/validators/create-validator';
import { CLASSROOM_INVITE_CODE_INVALID_ERROR } from 'api/errors/error-codes';
import TextInput from 'components/forms/inputs/TextInput';
import { Button } from '@mantine/core';
import useStudentDashboard from 'data/student-dashboard/use-student-dashboard';

/**
 * Renders the information that the student has no classroom assigned.
 */
export default function HasNoClassroomAssigned() {
  const [loading, setLoading] = useState(false);
  const [joined, setJoined] = useState(false);
  const { fetchStudentDashboard } = useStudentDashboard();
  const { getAction } = useApi();
  const joinClassroomAction = getAction('JoinClassroom');

  /**
   * The form data.
   */
  const form = useForm<JoinClassroomPayload>({
    initialValues: {
      inviteCode: '',
    },

    validate: {
      inviteCode: createValidator([required]),
    },
  });

  /**
   * Is executed when the form is submitted with valid data.
   */
  function submitForm(payload: JoinClassroomPayload) {
    if (!loading) {
      setLoading(true);

      joinClassroomAction({ payload })
        .success(() => {
          fetchStudentDashboard();
          setJoined(true);
        })
        .error(CLASSROOM_INVITE_CODE_INVALID_ERROR, () => {
          form.setFieldError('inviteCode', _t('The invite code is invalid.'));
          setLoading(false);
        });
    }
  }

  if (joined) {
    return <></>;
  }

  return (
    <div className="mt-[16px] rounded-[10px] bg-white pb-[40px] pl-[16px] pr-[16px] pt-[40px] lg:mt-[24px] lg:min-h-[calc(100vh-250px)] lg:pt-[80px]">
      <div className="ml-auto mr-auto max-w-[370px]">
        <div className="text-[20px] font-semibold leading-[24px]">{_t('You are not in any classroom yet')}</div>
        <div className="mt-[8px] text-[12px] leading-[18px] text-text-dark-2">
          {_t('You cannot access any homework until you join a classroom.')}{' '}
          {_t('Ask your teacher for an invite code which you will input below.')}{' '}
          {_t('After inputting the code, you will be added to the classroom.')}
        </div>
        <div className="pt-[32px]">
          <form onSubmit={form.onSubmit(submitForm)}>
            <TextInput
              className="text-[14px] font-medium leading-[17px] text-text-dark"
              withAsterisk
              label={_t('Invite code')}
              placeholder={_t('Invite code')}
              isValid={form.isValid('inviteCode')}
              {...form.getInputProps('inviteCode')}
            />

            <div className="pt-[32px]">
              <Button
                className="h-[56px] bg-green-primary text-[18px] leading-[22px] text-white
           hover:bg-green-dark active:bg-green-dark"
                fullWidth
                type="submit"
                radius={10}
                size="lg"
                disabled={loading}
              >
                {_t('Join a classroom')}
              </Button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}
