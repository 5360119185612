import { Tooltip } from '@mantine/core';
import NumericInput, { NumericInputProps } from 'components/assignment/inputs/NumericInput';
import { _t } from 'lang';
import { MouseEvent, useState } from 'react';

/**
 * The parameters of the component.
 */
interface FractionInputProps extends NumericInputProps {
  isPrint?: boolean;
  simplestForm?: boolean;
}

/**
 * Specialized input for fractions.
 */
export default function FractionInput({ isPrint = false, simplestForm, ...props }: FractionInputProps) {
  const [tooltipOpened, setTooltipOpened] = useState(false);

  /**
   * Handles the click on the tooltip.
   */
  function handleTooltipClick(e: MouseEvent) {
    e.preventDefault();
    setTooltipOpened((tooltipOpened) => !tooltipOpened);
  }

  return (
    <NumericInput
      {...props}
      label={
        <span>
          {_t('Write the result as a')}
          <Tooltip
            width={250}
            withArrow
            multiline
            opened={tooltipOpened}
            onMouseEnter={() => setTooltipOpened(true)}
            onMouseLeave={() => setTooltipOpened(false)}
            onClick={handleTooltipClick}
            label={_t('To write a fraction use the slash character (/). For example, 1/2.')}
          >
            <span className="p-[4px]">
              <span className={isPrint ? '' : 'underline decoration-dotted'}>{_t('fraction')}</span>
              {!isPrint && (
                <span className="ml-[2px] inline-flex h-[13px] w-[13px] items-center justify-center rounded-[50%] bg-pretest-blue text-[12px] font-bold text-[white]">
                  ?
                </span>
              )}
            </span>
          </Tooltip>
          {simplestForm && <span>{_t('in the simplest form')}</span>}
        </span>
      }
      inputMode="text" // This must be text to allow the slash character.
    />
  );
}
