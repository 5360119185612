import { Button, Tooltip } from '@mantine/core';
import { _t } from 'lang';
import sprintf from 'lang/sprintf';
import { useNavigate } from 'react-router-dom';
import HomeworkResultView from 'components/HomeworkResultView';
import HomeworkStatus from 'components/HomeworkStatus';

interface Props {
  classroomId: string;
  homeworkId: string;
  assignmentId?: string;
  homeworkNumber: number;
  successRate?: number;
  isActive: boolean;
  studentName: string;
}

/**
 * The row of the Student detail page.
 */
export default function StudentHomeworkDetailRow({
  classroomId,
  homeworkId,
  assignmentId,
  homeworkNumber,
  successRate,
  isActive,
  studentName,
}: Props) {
  const navigate = useNavigate();

  const hasSuccessRate = successRate !== undefined;

  const homeworkTitle = sprintf(_t('Homework #%d'), homeworkNumber);

  return (
    <div
      className={`flex h-[66px] items-center justify-between rounded-[10px] p-[12px] pl-[24px] ${
        hasSuccessRate ? 'bg-white' : 'bg-[#FCFCFD]'
      }`}
    >
      <div
        className="group w-[280px] cursor-pointer text-[18px] font-semibold leading-[22px]"
        onClick={() => navigate(`/teacher/classrooms/classroom/${classroomId}/homework/homework/${homeworkId}`)}
      >
        {/* Translators: %d is for the homework number */}
        <Tooltip
          styles={{ tooltip: { fontWeight: 500 } }}
          label={sprintf(_t('Go to homework #%d'), homeworkNumber)}
          withArrow
        >
          <div className="flex items-center gap-[12px]">
            <div className="group-hover:underline">{homeworkTitle}</div>
            <HomeworkStatus status={isActive ? 'ongoing' : 'finished'} />
          </div>
        </Tooltip>
      </div>
      <div className="w-[194px]">
        <HomeworkResultView successRate={successRate} active={isActive} />
      </div>
      <Button
        disabled={!hasSuccessRate}
        className="h-[42px] w-[176px] rounded-[7px] bg-green-primary-opacity-01 text-[12px] font-semibold leading-[15px] text-green-primary hover:bg-green-primary-opacity-02 active:bg-green-primary-opacity-02 lg:w-[276px]"
        onClick={() =>
          navigate(`/teacher/classrooms/classroom/${classroomId}/homework/homework/${homeworkId}/check`, {
            state: { assignmentId: assignmentId!, homeworkTitle, studentName },
          })
        }
        styles={{
          root: {
            '&:disabled': {
              backgroundColor: '#0AB8921A',
              color: '#0AB892',
              opacity: 0.4,
            },
          },
        }}
      >
        <div className="flex items-center justify-between gap-[28px] text-[12px] font-semibold leading-[15px]">
          <div>{_t('Check homework')}</div>
        </div>
      </Button>
    </div>
  );
}
