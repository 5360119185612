import { IExerciseQuestion, IExerciseQuestionId } from 'data/assignment/assignment';
import createValidator from 'components/forms/validators/create-validator';
import decimalResult from 'components/forms/validators/rules/rule-decimal-result';
import fractionResult from 'components/forms/validators/rules/rule-fraction-result';
import intResult from 'components/forms/validators/rules/rule-int-result';
import { Validator } from 'components/forms/validators/validator';

/**
 * Map from question id to validator specific to that question's result type.
 */
type ValidatorsMap = Record<IExerciseQuestionId, Validator>;

/**
 * Creates a single validator for a single question.
 */
function createQuestionValidator(question: IExerciseQuestion) {
  if (question.resultType === 'Int') {
    return createValidator([intResult]);
  }

  if (question.resultType === 'Decimal') {
    return createValidator([decimalResult(question.precision)]);
  }

  if (question.resultType === 'Fraction') {
    return createValidator([fractionResult]);
  }

  return createValidator([]);
}

/**
 * Creates the validators for the exercise form.
 */
export default function createValidators(questions: IExerciseQuestion[]): ValidatorsMap {
  return questions.reduce(
    (agg, question) => ({ ...agg, [question.questionId]: createQuestionValidator(question) }),
    {} as ValidatorsMap
  );
}
