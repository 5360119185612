/**
 * The icon which is displayed when the pretest is failed.
 */
export default function PretestFailedIcon() {
  return (
    <svg width="54" height="54" viewBox="0 0 54 54" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M40 46H13C12.4477 46 12 45.5523 12 45V9C12 8.44772 12.4477 8 13 8H39C39.5523 8 40 8.44772 40 9V22.5"
        stroke="#353E45"
        strokeWidth="3"
        strokeLinecap="round"
      />
      <path d="M18.5 16H31" stroke="#353E45" strokeWidth="3" strokeLinecap="round" />
      <path d="M18.5 25H25.5" stroke="#353E45" strokeWidth="3" strokeLinecap="round" />
      <path d="M35 39L45 29" stroke="#353E45" strokeWidth="3" strokeLinecap="round" />
      <path d="M45 39L35 29" stroke="#353E45" strokeWidth="3" strokeLinecap="round" />
    </svg>
  );
}
