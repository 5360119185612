import { _t } from 'lang';
import { ValidationError } from 'components/forms/validators/validation-error';

/**
 * Validates that the password is not shorter than 8 characters.
 */
export default function passwordFormat(value: string): ValidationError | null {
  if (value.length < 8) {
    return {
      message: _t('The password must have at least 8 characters.'),
    };
  }

  return null;
}
