import { createContext, useContext } from 'react';

/**
 * The context of a teacher dashboard layout.
 */
interface ITeacherDashboardLayoutContext {
  /**
   * Whether the mobile menu is visible.
   */
  isPhoneHeaderVisible: boolean;

  /**
   * Hides the mobile menu.
   */
  hidePhoneHeader: () => void;

  /**
   * Shows the mobile menu.
   */
  showPhoneHeader: () => void;
}

/**
 * The teacher dashboard layout context.
 */
export const TeacherDashboardLayoutContext = createContext<ITeacherDashboardLayoutContext>(undefined!);

/**
 * Uses the teacher dashboard layout context.
 */
export function useTeacherDashboardLayout() {
  return useContext(TeacherDashboardLayoutContext);
}
