/**
 * Four dots icon.
 */
export default function FourDotsIcon() {
  return (
    <svg width="104" height="104" viewBox="0 0 104 104" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx="52" cy="12" r="12" fill="#0AB892" />
      <circle cx="52" cy="92" r="12" fill="#0AB892" />
      <circle cx="92" cy="52" r="12" transform="rotate(90 92 52)" fill="#0AB892" />
      <circle cx="12" cy="52" r="12" transform="rotate(90 12 52)" fill="#0AB892" />
    </svg>
  );
}
