import { Tabs } from '@mantine/core';
import { _t, lang } from 'lang';
import HelpRow from 'pages/presentation-module/home/HelpRow';
import HelpTabLayout from 'pages/presentation-module/home/HelpTabLayout';
import { Link } from 'react-router-dom';
import { EXERCISES_PAGE_PATH } from 'routes/paths';

/**
 * The help section of the home page.
 */
export default function HelpContent() {
  return (
    <div className="pt-[40px]">
      <div id="how-it-works" className="h-0 w-0 translate-y-[-100px] lg:translate-y-[-140px]"></div>
      <div className="ml-auto mr-auto pl-[24px] pr-[24px] text-center text-[25px] font-semibold leading-[35px] text-text-dark md:text-[32px] md:leading-[42px] lg:max-w-[1170px]">
        <div>{_t('For whom did we create Curious Mind?')}</div>
      </div>
      <div className="pb-[80px] pt-[40px]">
        <Tabs
          defaultValue="teacher"
          styles={{
            tab: {
              marginRight: '2px',
              marginLeft: '2px',
              width: '300px',
              height: '53px',
              fontSize: '20px',
              lineHeight: '24px',
              fontWeight: 600,
              color: '#738491',
              '&[data-active]': {
                borderBottom: '2px solid #0AB892',
                color: '#353E45',
              },
              '@media (max-width: 767px)': {
                width: '100%',
              },
            },
          }}
        >
          <Tabs.List position="center">
            <Tabs.Tab value="teacher">{_t('Curious Mind for teachers')}</Tabs.Tab>
            <Tabs.Tab value="student">{_t('Curious Mind for students')}</Tabs.Tab>
          </Tabs.List>

          <Tabs.Panel value="teacher">
            <HelpTabLayout>
              <HelpRow
                imgSrc={`/img/screenshots/exercises-book-${lang}.png`}
                heading={_t('Practical website generating new exercises')}
                description={
                  <div>
                    {
                      _t('Curious Mind contains millions of mathematical exercises since its inception. We have achieved such a number of variants of exercises by parameterizing each exercise and letting the computer generate all possible combinations of parameters.') // prettier-ignore
                    }
                    <Link className="mb-[-8px] ml-[-8px] block p-[8px] text-green-primary" to={EXERCISES_PAGE_PATH}>
                      <span className="font-medium underline">{_t('Take a look at our exercises book')}</span>&nbsp;📙
                    </Link>
                  </div>
                }
              />
              <HelpRow
                imgSrc={`/img/screenshots/teacher-classrooms-${lang}.png`}
                heading={_t('All your classrooms in one place')}
                description={
                  <div>
                    {
                      _t('In the Curious Mind application, you have an overview of all your classrooms. Each classroom has its own invite code that you can share with your students so that they can join the classroom.') // prettier-ignore
                    }
                  </div>
                }
              />
              <HelpRow
                imgSrc={`/img/screenshots/teacher-dashboard-${lang}.png`}
                heading={_t('Complete overview of the classroom')}
                description={
                  <div>
                    {
                      _t('In the classroom user interface, you can view a list of students, assign a new exercise, or browse the exercises book. You can browse the results of exercises that you have already assigned to students in the sections of ongoing and completed homework.') // prettier-ignore
                    }
                  </div>
                }
              />
              <HelpRow
                imgSrc={`/img/screenshots/teacher-assign-homework-${lang}.png`}
                heading={_t('Simple assignment of homework')}
                description={
                  <div>
                    {
                      _t('First, select which exercises and how many times you want to assign to students. Each student will get different variants of the same exercise. In the last step, set the day and time of the deadline. By default, the exercise is assigned to the whole classroom, if necessary, you can assign the exercise only to selected students.') // prettier-ignore
                    }
                  </div>
                }
              />
              <HelpRow
                imgSrc={`/img/screenshots/teacher-check-homework-${lang}.png`}
                heading={_t('Automatic homework evaluation')}
                description={
                  <div>
                    {
                      _t('After students submit their answers, the web application will correct them immediately. We save time for teachers when correcting exercises and give students instant feedback on the correctness of the result. You can view the specific answers of all students in the detail of the exercise.') // prettier-ignore
                    }
                  </div>
                }
              />
            </HelpTabLayout>
          </Tabs.Panel>

          <Tabs.Panel value="student">
            <HelpTabLayout>
              <HelpRow
                imgSrc={`/img/screenshots/exercises-book-${lang}.png`}
                heading={_t('Practically endless collection of exercises')}
                description={
                  <div>
                    {
                      _t('Curious Mind contains millions of mathematical exercises since its inception. We have achieved such a number of variants of exercises by parameterizing each exercise and letting the computer generate all possible combinations of parameters.') // prettier-ignore
                    }
                    <Link className="mb-[-8px] ml-[-8px] block p-[8px] text-green-primary" to={EXERCISES_PAGE_PATH}>
                      <span className="font-medium underline">{_t('Take a look at our exercises book')}</span>&nbsp;📙
                    </Link>
                  </div>
                }
              />
              <HelpRow
                imgSrc={`/img/screenshots/student-solve-homework-${lang}.png`}
                heading={_t('Simple way to solve exercises online')}
                description={
                  <div>
                    {
                      _t('Each exercise has a given type of answer - select, integer, fraction, or real number with a precision of x decimal places. Just write your answers in the field below the exercise. You can solve exercises on a computer, but also on a smartphone or tablet.') // prettier-ignore
                    }
                  </div>
                }
              />
              <HelpRow
                imgSrc={`/img/screenshots/student-check-homework-${lang}.png`}
                heading={_t('Instant feedback')}
                description={
                  <div>
                    {
                      _t('The web application will send you feedback on your answers in a fraction of a second. If you have completed the exercise correctly, you will know immediately.') // prettier-ignore
                    }
                  </div>
                }
              />
              <HelpRow
                imgSrc={`/img/screenshots/student-dashboard-${lang}.png`}
                heading={_t('Overview of all results')}
                description={
                  <div>
                    {
                      _t('After logging in, you can view all the homework you have solved.') // prettier-ignore
                    }
                  </div>
                }
              />
            </HelpTabLayout>
          </Tabs.Panel>
        </Tabs>
      </div>
    </div>
  );
}
