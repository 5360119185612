import { Button } from '@mantine/core';
import { _t, lang } from 'lang';
import { useState } from 'react';

interface Props {
  exerciseId: number;
  title: string;
  type: string;
  description: string;
  previewSuffix?: string;
  onButtonClick?: () => void;
}

/**
 * The exercise card for students.
 */
export default function StudentExerciseCard({
  exerciseId,
  title,
  type,
  description,
  previewSuffix = 'png',
  onButtonClick,
}: Props) {
  const [hover, setHover] = useState<boolean>(false);
  const exerciseType = <div className="pt-[8px] text-[12px] font-semibold leading-[18px] text-text-shadow">{type}</div>;

  return (
    <div
      className="h-[240px] w-full rounded-[10px] bg-white lg:h-[295px] lg:min-w-[266px]"
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
    >
      {hover ? (
        <div className="flex h-full flex-col justify-between p-[16px] shadow-webinar-card-shadow">
          <div>
            <div className="text-[16px] font-medium leading-[22px] text-text-dark">{title}</div>
            {exerciseType}
            <div className="pt-[8px] text-[12px] leading-[18px] text-text-dark">{description}</div>
          </div>
          <div className="pt-[16px]">
            <Button
              className="h-[42px] w-full rounded-[5px] bg-green-primary-opacity-01 text-green-primary hover:bg-green-primary-opacity-02 focus:bg-green-primary-opacity-02"
              onClick={onButtonClick}
            >
              {_t('Solve the exercise')}
            </Button>
          </div>
        </div>
      ) : (
        <div className="h-full">
          <div className="flex h-[160px] items-center justify-center rounded-[10px_10px_0_0] bg-background-user lg:h-[215px]">
            <img
              className="max-h-full max-w-full p-[16px]"
              src={`/img/exercises/${exerciseId}-${lang}.${previewSuffix}`}
              alt={_t('exercise thumbnail')}
            />
          </div>
          <div className="flex h-[calc(100%-160px)] flex-col justify-end rounded-[0_0_10px_10px] p-[16px] shadow-[0px_4px_14px_rgba(22,_26,_29,_0.07)] lg:h-[calc(100%-215px)]">
            <div className="overflow-hidden text-ellipsis whitespace-nowrap text-[16px] font-medium leading-[22px] text-text-dark">
              {title}
            </div>
            {exerciseType}
          </div>
        </div>
      )}
    </div>
  );
}
