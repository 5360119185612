import { useNavigate } from 'react-router-dom';
import useCourseStep from 'pages/courses-module/course-step/context/use-course-step';
import ArrowRightIcon from 'components/icons/ArrowRightIcon';
import { Button } from '@mantine/core';
import { _t } from 'lang';

/**
 * The parameters of the component.
 */
interface Props {
  className?: string;
}

/**
 * Continue to the next step button.
 */
export default function ContinueToNextStepButton({ className = '' }: Props) {
  const navigate = useNavigate();
  const { nextStepLink } = useCourseStep();

  if (!nextStepLink) {
    return <></>;
  }

  return (
    <Button
      className={`h-[56px] bg-green-primary text-[16px] leading-[19px] hover:bg-green-dark active:bg-green-dark lg:flex-shrink-0 lg:flex-grow ${className}`}
      radius={10}
      onClick={() => navigate(nextStepLink)}
    >
      <div className="flex items-center gap-[11px]">
        {_t('Continue')}
        <ArrowRightIcon />
      </div>
    </Button>
  );
}
