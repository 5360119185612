import TeacherDashboardLayout from 'layout/teacher-dashboard-layout/TeacherDashboardLayout';
import { useParams } from 'react-router-dom';
import { useEffect } from 'react';
import useClassrooms from 'data/classrooms/use-classrooms';
import ClassroomStudentsContent from 'pages/teacher-module/classroom/students/ClassroomStudentsContent';

/**
 * Students in a classroom.
 *
 * @see https://www.figma.com/file/SjggpqHVlWxBKa2cIG349A/Math-Exercises?node-id=628%3A3651&t=J3hpSwWIuCcusYbV-0
 */
export default function ClassroomStudentsPage() {
  const { classroomId } = useParams();
  const { getClassroom } = useClassrooms();
  const classroom = getClassroom({ classroomId: classroomId! });
  const { getClassroomStudents, fetchClassroomStudents } = useClassrooms();
  const students = getClassroomStudents({ classroomId: classroomId! });

  useEffect(() => {
    fetchClassroomStudents({ classroomId: classroomId! });
  }, [classroomId]);

  return (
    <TeacherDashboardLayout hasHeader hasSidebar>
      {students === undefined ? (
        <div></div>
      ) : (
        <ClassroomStudentsContent classroomId={classroomId!} classroomName={classroom?.name!} students={students} />
      )}
    </TeacherDashboardLayout>
  );
}
