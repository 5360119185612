/**
 * Arrow left icon
 */
export default function ArrowLeftIcon() {
  return (
    <svg width="6" height="10" viewBox="0 0 6 10" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M5 1L1.70711 4.29289C1.31658 4.68342 1.31658 5.31658 1.70711 5.70711L5 9"
        stroke="#0AB892"
        strokeWidth="2"
        strokeLinecap="round"
      />
    </svg>
  );
}
