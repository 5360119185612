interface Props {
  stroke?: string;
  height?: string;
  width?: string;
}

/**
 * Paper icon for the teacher's sidebar navigation item.
 */
export default function PaperIcon({ stroke = '#535F6A', height = '22', width = '24' }: Props) {
  return (
    <>
      <svg
        width="16"
        height="18"
        viewBox="0 0 16 18"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className="hidden lg:block"
      >
        <path
          d="M1.14282 1.28564V0.285645C0.590537 0.285645 0.142822 0.73336 0.142822 1.28564L1.14282 1.28564ZM14.8571 1.28564H15.8571C15.8571 0.73336 15.4094 0.285645 14.8571 0.285645V1.28564ZM14.8571 17.9378C15.4094 17.9378 15.8571 17.4901 15.8571 16.9378C15.8571 16.3855 15.4094 15.9378 14.8571 15.9378V17.9378ZM1.14282 16.9378H0.142822C0.142822 17.4901 0.590537 17.9378 1.14282 17.9378L1.14282 16.9378ZM1.14282 2.28564H14.8571V0.285645H1.14282V2.28564ZM14.8571 15.9378H1.14282V17.9378H14.8571V15.9378ZM2.14282 16.9378V1.28564H0.142822V16.9378H2.14282ZM13.8571 1.28564V12H15.8571V1.28564H13.8571Z"
          fill={stroke}
        />
        <path
          d="M4.01111 5.54688H11.0923"
          stroke={stroke}
          strokeWidth="2"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M4.01111 8.93799H8.40327"
          stroke={stroke}
          strokeWidth="2"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
      <svg
        width={width}
        height={height}
        viewBox="0 0 22 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className="lg:hidden"
      >
        <path
          d="M1.85718 1.71484V0.714844C1.30489 0.714844 0.857178 1.16256 0.857178 1.71484L1.85718 1.71484ZM20.1429 1.71484H21.1429C21.1429 1.16256 20.6952 0.714844 20.1429 0.714844V1.71484ZM20.1429 23.5844C20.6952 23.5844 21.1429 23.1366 21.1429 22.5844C21.1429 22.0321 20.6952 21.5844 20.1429 21.5844V23.5844ZM1.85718 22.5844H0.857178C0.857178 23.1366 1.30489 23.5844 1.85718 23.5844L1.85718 22.5844ZM1.85718 2.71484H20.1429V0.714844H1.85718V2.71484ZM20.1429 21.5844H1.85718V23.5844H20.1429V21.5844ZM2.85718 22.5844V1.71484H0.857178V22.5844H2.85718ZM19.1429 1.71484V16.0007H21.1429V1.71484H19.1429Z"
          fill={stroke}
        />
        <path
          d="M5.68164 7.39648H15.1233"
          stroke={stroke}
          strokeWidth="2"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M5.68164 11.918H11.5379"
          stroke={stroke}
          strokeWidth="2"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </>
  );
}
