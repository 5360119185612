/**
 * The layout of the Help Tab.
 */
export default function HelpTabLayout({ children }: { children: React.ReactNode[] }) {
  return (
    <div className="ml-auto mr-auto flex flex-col items-center gap-[40px] pl-[24px] pr-[24px] pt-[40px] lg:max-w-[1170px] lg:items-start lg:px-0">
      {children}
    </div>
  );
}
