import { _t } from 'lang';
import { ValidationError } from 'components/forms/validators/validation-error';

/**
 * Validates integer input based on regex.
 */
export default function intResult(val: string): ValidationError | null {
  if (!val) {
    return null;
  }

  // minus or plus or nothing
  // digits - at least one
  const match = /^[-+]?[0-9]+$/.test(val.trim());
  if (!match) {
    return {
      message: _t('Please check the number format.'),
    };
  }

  return null;
}
