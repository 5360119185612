import { Button, NumberInput, Tooltip } from '@mantine/core';
import MinusIcon from 'components/icons/MinusIcon';
import PlusIcon from 'components/icons/PlusIcon';
import TickIcon from 'components/icons/TickIcon';
import { _t, lang } from 'lang';
import { useEffect, useState } from 'react';
import ExerciseDetailModal from 'pages/teacher-module/classroom/exercises/modals/ExerciseDetailModal';
import sprintf from 'lang/sprintf';
import Clickable from 'components/misc/Clickable';

interface Props {
  exerciseId: number;
  title: string;
  type: string;
  topics: string[];
  description: string;
  selected: boolean;
  initialNumberOfVariants: number;
  maximalNumberOfVariants: number;
  setSelected: (exerciseId: number, numberOfVariants: number) => void;
}

/**
 * Card holding the exercise information.
 */
export default function TeacherExerciseCard({
  exerciseId,
  title,
  type,
  description,
  selected,
  maximalNumberOfVariants,
  initialNumberOfVariants,
  setSelected,
}: Props) {
  const [hover, setHover] = useState<boolean>(false);
  const [focus, setFocus] = useState<boolean>(false);
  const [numberOfVariants, setNumberOfVariants] = useState<number>(initialNumberOfVariants);
  const [exerciseDetailModalOpened, setExerciseDetailModalOpened] = useState<boolean>(false);

  useEffect(() => {
    if (selected && !focus) {
      setSelected(exerciseId, numberOfVariants);
    }
  }, [numberOfVariants, focus]);

  return (
    <div
      className={`rounded-[10px] bg-white lg:h-[295px] exercise-id-${exerciseId} ${
        selected ? 'border-2 border-green-primary shadow-webinar-card-shadow' : ''
      }`}
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
    >
      {hover || focus || selected ? (
        <div className="flex h-full flex-col justify-between p-[16px]">
          <div>
            <div className="text-[16px] font-medium leading-[22px] text-text-dark">{title}</div>
            <div className="pt-[8px] text-[12px] leading-[18px] text-text-shadow">
              <span className="font-semibold">{type}</span>
            </div>
            <div className="pt-[8px] text-[12px] leading-[18px] text-text-dark">{description}</div>
            <Clickable
              className="m-[-4px] mt-[4px] inline-block p-[4px] text-[12px] font-semibold leading-[15px] text-green-primary underline"
              onClick={() => setExerciseDetailModalOpened(true)}
            >
              {_t('Exercise preview')}
            </Clickable>
          </div>
          <div className="mt-[20px] border-t border-t-input-outline-shadow">
            <div className="pt-[12px] text-[12px] leading-[18px] text-text-shadow">
              {_t('Select the number of exercises')}
            </div>
            <div className="flex items-center justify-between pt-[8px]">
              <div className="flex h-[32px] w-[112px] items-center justify-between rounded-[5px] border-b border-t border-b-input-outline-shadow border-t-input-outline-shadow">
                <Button
                  styles={{ root: { transform: 'none!important' } }}
                  className="flex h-[32px] w-[32px] items-center justify-center rounded-l-[5px] border border-r-0 border-input-outline-shadow bg-[#F3F6F8] hover:bg-[#E8EEF2]"
                  disabled={numberOfVariants === 0}
                  radius={0}
                  onClick={() => setNumberOfVariants((prev) => Math.max(prev - 1, 0))}
                >
                  <MinusIcon />
                </Button>
                <NumberInput
                  min={0}
                  step={1}
                  hideControls
                  value={numberOfVariants}
                  onFocus={() => setFocus(true)}
                  onBlur={() => setFocus(false)}
                  onChange={(val) => setNumberOfVariants(Math.min(maximalNumberOfVariants, Math.max(val ?? 0, 0)))}
                  max={maximalNumberOfVariants}
                  styles={{
                    input: {
                      height: '32px!important',
                      minHeight: '32px!important',
                      backgroundColor: 'white',
                      fontSize: '14px',
                      fontWeight: 600,
                      lineHeight: '17px',
                      color: '#353E45',
                      textAlign: 'center',
                      margin: 0,
                      paddingLeft: '15px!important',
                      borderRadius: 0,
                      cursor: 'pointer',
                      '&:focus': {
                        paddingLeft: '14px!important',
                        paddingRight: '11px!important',
                        cursor: 'text',
                      },
                    },
                    root: {
                      height: '32px',
                      width: '50px',
                    },
                    wrapper: {
                      height: '30px!important',
                      minHeight: '32px!important',
                      width: '50px',
                    },
                  }}
                />
                <Tooltip
                  withArrow
                  multiline
                  width={240}
                  disabled={numberOfVariants < maximalNumberOfVariants}
                  label={sprintf(_t('The maximal number of variants for this exercise is %d'), maximalNumberOfVariants)}
                >
                  <Button
                    styles={{
                      root: {
                        transform: 'none!important',
                        cursor: numberOfVariants === maximalNumberOfVariants ? 'not-allowed' : 'pointer',
                      },
                    }}
                    className="flex h-[32px] w-[32px] items-center justify-center rounded-r-[5px] border border-l-0 border-input-outline-shadow bg-[#F3F6F8] hover:bg-[#E8EEF2]"
                    radius={0}
                    onClick={() => setNumberOfVariants((prev) => Math.min(maximalNumberOfVariants, prev + 1))}
                  >
                    <PlusIcon stroke="#738491" />
                  </Button>
                </Tooltip>
              </div>
              {selected ? (
                <Button className="h-[32px] w-[97px] rounded-[5px] bg-green-primary">
                  <TickIcon />
                </Button>
              ) : (
                <Button
                  className="h-[32px] w-[97px] rounded-[5px] bg-green-primary-opacity-01 pl-0 pr-0 text-center text-green-primary hover:bg-green-primary-opacity-02 focus:bg-green-primary-opacity-02"
                  onClick={() => setSelected(exerciseId, numberOfVariants)}
                  disabled={numberOfVariants === 0}
                >
                  <div className="text-[12px] font-semibold leading-[15px]">
                    {sprintf(_t('Add (%d)'), numberOfVariants)}
                  </div>
                </Button>
              )}
            </div>
          </div>
        </div>
      ) : (
        <div>
          <div className="flex h-[215px] items-center justify-center rounded-[10px_10px_0_0] bg-background-user">
            <img
              className="max-h-full max-w-full p-[16px]"
              src={`/img/exercises/${exerciseId}-${lang}.png`}
              alt={_t('exercise thumbnail')}
            />
          </div>
          <div className="flex h-[80px] flex-col justify-end rounded-[0_0_10px_10px] p-[16px] shadow-[0px_4px_14px_rgba(22,_26,_29,_0.07)]">
            <div className="overflow-hidden text-ellipsis whitespace-nowrap text-[16px] font-medium leading-[22px] text-text-dark">
              {title}
            </div>
            <div className="pt-[8px] text-[12px] font-semibold leading-[18px] text-text-shadow">{type}</div>
          </div>
        </div>
      )}
      <ExerciseDetailModal
        opened={exerciseDetailModalOpened}
        setOpened={setExerciseDetailModalOpened}
        onAddExercise={() => setSelected(exerciseId, numberOfVariants)}
        exerciseId={exerciseId}
        selected={selected}
      />
    </div>
  );
}
