import { Checkbox, CheckboxStylesNames, CheckboxStylesParams, Styles, Tooltip } from '@mantine/core';
import useCourse from 'data/course/use-course';
import { _t } from 'lang';
import { useEffect, useState } from 'react';

/**
 * The parameters of the component.
 */
interface Props {
  value: boolean;
  courseId: string;
  stepId: number;
}

/**
 * The styles of the checkbox.
 */
const styles: Styles<CheckboxStylesNames, CheckboxStylesParams> = {
  input: {
    borderColor: '#738491',
    '&:checked': { borderColor: '#0AB892', backgroundColor: '#0AB892' },
    cursor: 'pointer',
  },
  icon: {
    color: 'white',
  },
};

/**
 * The course progress checkbox.
 */
export default function CourseProgressCheckbox({ value, courseId, stepId }: Props) {
  const [checked, setChecked] = useState(value);
  const { updateCourseProgress } = useCourse();
  const [loading, setLoading] = useState(false);

  /**
   * Changes the checkbox's state and makes call to the API.
   */
  function handleSetChecked(e: React.ChangeEvent<HTMLInputElement>) {
    if (!loading) {
      setLoading(true);

      const newState = e.currentTarget.checked;
      const progress = newState ? 1 : 0;

      setChecked(newState);

      updateCourseProgress({ courseId, steps: [{ stepId, progress }] })
        .error('*', () => setChecked(!newState))
        .complete(() => setLoading(false));
    }
  }

  // Updates the checkbox's state when the value changes.
  useEffect(() => {
    setChecked(value);
  }, [value]);

  const label = checked ? _t('Redo step') : _t('Mark as completed');

  return (
    <Tooltip withArrow color="dark" label={label}>
      <Checkbox
        size="xs"
        checked={checked}
        onChange={handleSetChecked}
        onClick={(e) => e.stopPropagation()}
        styles={styles}
      ></Checkbox>
    </Tooltip>
  );
}
