import { useState } from 'react';
import { TeacherDashboardLayoutContext } from 'layout/teacher-dashboard-layout/TeacherDashboardLayoutContext';

/**
 * Provides the basic page layout context.
 */
export default function TeacherDashboardLayoutProvider({ children }: { children: JSX.Element }) {
  const [isPhoneHeaderVisible, setMobileMenuVisibility] = useState(true);

  /**
   * Shows the mobile menu.
   */
  const showPhoneHeader = () => setMobileMenuVisibility(true);

  /**
   * Hides the mobile menu.
   */
  const hidePhoneHeader = () => setMobileMenuVisibility(false);

  return (
    <TeacherDashboardLayoutContext.Provider value={{ isPhoneHeaderVisible, showPhoneHeader, hidePhoneHeader }}>
      {children}
    </TeacherDashboardLayoutContext.Provider>
  );
}
