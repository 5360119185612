import { _t } from 'lang';
import ImageCard from 'pages/presentation-module/who-we-are/ImageCard';

/**
 * Image cards with Web Developers.
 */
export default function WebDevelopers() {
  return (
    <>
      <ImageCard
        image="/team/milan-wikarski.jpg" // cspell:disable-line
        name="Milan Wikarski" // cspell:disable-line
        position={`${_t('Tech Lead')}, ${_t('Software Architect')}`}
        contact="https://www.linkedin.com/in/milan-wikarski-0029b417a/"
        contactName="LinkedIn"
        webpage="https://wikarski.sk"
        webpageName="wikarski.sk" // cspell:disable-line
      />
      <ImageCard
        image="/team/miriam-wikarska.jpg" // cspell:disable-line
        name="Miriam Wikarská" // cspell:disable-line
        position={_t('Front-End Developer')}
        contact="https://www.linkedin.com/in/miriam-wikarsk%C3%A1-6411781b8/"
        contactName="LinkedIn"
      />
      <ImageCard
        image="/team/peter-rovder.jpg" // cspell:disable-line
        name="Peter Rovder" // cspell:disable-line
        position={_t('UI/UX Designer')}
        contact="https://www.linkedin.com/in/peterrovder/"
        contactName="LinkedIn"
      />
      <ImageCard
        image="/team/kristyna-lhotanova.jpg" // cspell:disable-line
        name="Kristýna Lhoťanová" // cspell:disable-line
        position={_t('Backend-End Developer')}
        contact="https://www.linkedin.com/in/krist%C3%BDna-lho%C5%A5anov%C3%A1-31a895245/"
        contactName="LinkedIn"
      />
      <ImageCard
        image="/team/eva-lhotanova.jpg" // cspell:disable-line
        name="Mgr. Eva Lhoťanová" // cspell:disable-line
        position={_t('Copywriter')}
        contact="https://www.linkedin.com/in/eva-lho%C5%A5anov%C3%A1-925694101/"
        contactName="LinkedIn"
      />
      <ImageCard
        image="/team/una-adlovic.jpg" // cspell:disable-line
        name="Una Adilović" // cspell:disable-line
        position={_t('Exercises Developer')}
        contact="https://www.linkedin.com/in/una-adilovic/"
        contactName="LinkedIn"
      />
      <ImageCard
        image="/team/michael-bohin.jpg" // cspell:disable-line
        name="Michael Bohin" // cspell:disable-line
        position={`${_t('Exercises Developer')}, ${_t('Author')}`}
        contact="https://www.linkedin.com/in/michael-bohin-9a13109b/"
        contactName="LinkedIn"
        email="bohin@ksvi.mff.cuni.cz"
      />
    </>
  );
}
