interface Props {
  stroke?: string;
}

/**
 * Filter icon.
 */
export default function FilterIcon({ stroke = '#0AB892' }: Props) {
  return (
    <svg width="18" height="8" viewBox="0 0 18 8" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M1 1H17" stroke={stroke} strokeWidth="2" strokeLinecap="round" />
      <path d="M5 7L13 7" stroke={stroke} strokeWidth="2" strokeLinecap="round" />
    </svg>
  );
}
