import { Menu, Skeleton } from '@mantine/core';
import useApi from 'api/use-api';
import Avatar from 'components/Avatar';
import ArrowDownIcon from 'components/icons/ArrowDownIcon';
import LockIcon from 'components/icons/LockIcon';
import LogoutIcon from 'components/icons/LogoutIcon';
import ZvedavaMyslIcon from 'components/icons/ZvedavaMyslIcon';
import { _t } from 'lang';
import UserDetail from 'layout/parts/header/UserDetail';
import { useState } from 'react';
import { Link } from 'react-router-dom';
import { HOME_PAGE_PATH, STUDENT_CHANGE_PASSWORD_PAGE_PATH } from 'routes/paths';

/**
 * The phone header of the student' pages.
 */
export default function StudentDesktopHeader() {
  const { firstName, lastName, email, logout, ready } = useApi();
  const [lockIconStroke, setLockIconStroke] = useState<string>('#738491');
  const [logoutIconStroke, setLogoutIconStroke] = useState<string>('#738491');

  return (
    <div className="z-30 hidden h-[90px] w-screen items-center justify-between bg-white pb-[37px] pl-[32px] pr-[40px] pt-[34px] shadow-3xl lg:fixed lg:flex">
      <div>
        <Link className="m-[-10px] block p-[10px]" to={HOME_PAGE_PATH}>
          <ZvedavaMyslIcon />
        </Link>
      </div>
      <Menu
        width={295}
        position="bottom-end"
        offset={40}
        styles={{
          item: {
            '&:hover': { backgroundColor: '#F3F6F8', color: '#353E45', borderRadius: '7px' },
          },
          dropdown: {
            boxShadow: '0px 14px 14px rgba(22, 26, 29, 0.1)',
            borderRadius: '7px',
            paddingLeft: '8px!important',
            paddingTop: '8px!important',
            paddingRight: '8px!important',
            paddingBottom: '8px!important',
          },
          divider: {
            borderTopColor: '#D9E0E6',
            marginTop: '8px!important',
            marginBottom: '8px!important',
          },
          itemIcon: {
            marginRight: '0',
          },
        }}
      >
        <Menu.Target>
          <div className="flex cursor-pointer items-center">
            {!ready ? <Skeleton height={42} circle /> : <Avatar label={`${firstName} ${lastName}`} size={42} />}
            <div className="pb-[7px] pl-[10px] pr-[10px] pt-[7px]">
              <ArrowDownIcon />
            </div>
          </div>
        </Menu.Target>
        <Menu.Dropdown className="border-0">
          <Menu.Item className="pb-[16px] pl-[16px] pr-[16px] pt-[16px]">
            {!ready ? (
              <div className="flex items-center gap-[16px]">
                <Skeleton height={42} circle />
                <div className="flex w-[80%] flex-col gap-[5px]">
                  <Skeleton height={8} radius="xl" width="40%" />
                  <Skeleton height={8} radius="xl" width="40%" />
                </div>
              </div>
            ) : (
              <div>
                <UserDetail name={`${firstName} ${lastName}`} email={email} />
              </div>
            )}
          </Menu.Item>
          <Menu.Item
            className="pb-[15px] pl-[22px] pr-[22px] pt-[14px] text-[16px] font-medium text-text-shadow"
            icon={<LockIcon className="mr-[18px]" stroke={lockIconStroke} />}
            component={Link}
            to={STUDENT_CHANGE_PASSWORD_PAGE_PATH}
            onMouseEnter={() => setLockIconStroke('#353E45')}
            onMouseLeave={() => setLockIconStroke('#738491')}
          >
            {_t('Change password')}
          </Menu.Item>
          <Menu.Item
            className="pb-[15px] pl-[22px] pr-[22px] pt-[14px] text-[16px] font-medium text-text-shadow"
            onClick={logout}
            icon={<LogoutIcon className="mr-[14px]" stroke={logoutIconStroke} />}
            onMouseEnter={() => setLogoutIconStroke('#353E45')}
            onMouseLeave={() => setLogoutIconStroke('#738491')}
          >
            {_t('Log out')}
          </Menu.Item>
        </Menu.Dropdown>
      </Menu>
    </div>
  );
}
