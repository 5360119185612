import VideoIcon from 'components/icons/VideoIcon';
import DocumentIcon from 'components/icons/DocumentIcon';
import ExerciseIcon from 'components/icons/ExerciseIcon';

interface Props {
  courseStepType: string;
  active: boolean;
}

/**
 * Based on the courseStepType returns corresponding icon.
 */
export default function CourseStepIcon({ courseStepType, active }: Props) {
  if (courseStepType === 'video' || courseStepType === 'stream') {
    return <VideoIcon active={active} />;
  }

  if (courseStepType === 'text') {
    return <DocumentIcon active={active} />;
  }

  if (courseStepType === 'exercise') {
    return <ExerciseIcon active={active} />;
  }

  return <></>;
}
