import TeacherDashboardLayout from 'layout/teacher-dashboard-layout/TeacherDashboardLayout';
import { useParams } from 'react-router-dom';
import { useEffect, useState } from 'react';
import useApi from 'api/use-api';
import { GetClassroomHomeworkDetailResponse } from 'api/actions/get-classroom-homework-detail/get-classroom-homework-detail-response';
import HomeworkDetailContent from 'pages/teacher-module/classroom/homework/detail/HomeworkDetailContent';
import useClassrooms from 'data/classrooms/use-classrooms';

/**
 * The homework detail.
 *
 * @see https://www.figma.com/file/SjggpqHVlWxBKa2cIG349A/Math-Exercises?node-id=575%3A1255&t=J3hpSwWIuCcusYbV-0
 */
export default function HomeworkDetailPage() {
  const { homeworkId, classroomId } = useParams();
  const { getAction } = useApi();
  const { getClassroom } = useClassrooms();
  const getClassroomHomeworkDetailAction = getAction('GetClassroomHomeworkDetail');
  const [homework, setHomework] = useState<GetClassroomHomeworkDetailResponse['homework']>();
  const [students, setStudents] = useState<GetClassroomHomeworkDetailResponse['students']>();
  const classroom = getClassroom({ classroomId: classroomId! });

  useEffect(() => {
    getClassroomHomeworkDetailAction({ parameters: { classroomId: classroomId!, homeworkId: homeworkId! } }).success(
      ({ homework, students }) => {
        setHomework(homework);
        setStudents(students);
      }
    );
  }, [homeworkId]);

  const ready = homework && students && classroom;

  if (!ready) {
    return <TeacherDashboardLayout hasHeader hasSidebar />;
  }

  return (
    <TeacherDashboardLayout hasHeader hasSidebar>
      <HomeworkDetailContent
        homeworkNumber={homework.homeworkNumber}
        assignedAt={homework.assigned.at}
        deadline={homework.deadline}
        isPastDeadline={homework.isPastDeadline}
        exerciseSet={homework.exerciseSet}
        stats={homework.stats}
        students={students}
        classroomName={classroom!.name}
        classroomId={classroomId!}
        homeworkId={homeworkId!}
      />
    </TeacherDashboardLayout>
  );
}
