import { createContext, useContext } from 'react';

/**
 * The context of a basic page layout.
 */
interface IBasicPageLayoutContext {
  /**
   * Whether the mobile menu is visible.
   */
  isPhoneHeaderVisible: boolean;

  /**
   * Hides the mobile menu.
   */
  hidePhoneHeader: () => void;

  /**
   * Shows the mobile menu.
   */
  showPhoneHeader: () => void;
}

/**
 * The basic page layout context.
 */
export const BasicPageLayoutContext = createContext<IBasicPageLayoutContext>(undefined!);

/**
 * Uses the basic page layout context.
 */
export function useBasicPageLayout() {
  return useContext(BasicPageLayoutContext);
}
