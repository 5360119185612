import { AxiosInstance } from 'axios';
import { SweetPath } from 'sweet-path';
import dispatchAction from 'api/dispatch-action';

import { JoinClassroomPayload } from 'api/actions/join-classroom/join-classroom-payload';
import { JoinClassroomPathParameters } from 'api/actions/join-classroom/join-classroom-path-parameters';

import { JoinClassroomResponse } from 'api/actions/join-classroom/join-classroom-response';

/**
 * The path of the JoinClassroom action.
 */
export const joinClassroomActionPath = new SweetPath<keyof JoinClassroomPathParameters>('/classroom/join', {
  path: '{param}',
});

/**
 * Creates a contextualized JoinClassroom actions.
 */
export default function createJoinClassroomAction(axios: AxiosInstance) {
  return function joinClassroomAction({ payload }: { payload: JoinClassroomPayload }) {
    const path = joinClassroomActionPath.original;

    return dispatchAction(() =>
      axios.post<JoinClassroomResponse, JoinClassroomResponse, JoinClassroomPayload>(path, payload)
    );
  };
}
