import BookIcon from 'components/icons/BookIcon';
import HomeIcon from 'components/icons/HomeIcon';
import PaperIcon from 'components/icons/PaperIcon';
import PenIcon from 'components/icons/PenIcon';
import useStudentDashboard from 'data/student-dashboard/use-student-dashboard';
import { _t, _tx, lang } from 'lang';
import { useStudentDashboardLayout } from 'layout/student-dashboard-layout/StudentDashboardLayoutContext';
import BottomBarMenuItem from 'layout/student-dashboard-layout/parts/sidebar/BottomBarMenuItem';
import { useParams } from 'react-router-dom';
import { STUDENT_DASHBOARD_PAGE_PATH, STUDENT_EXERCISES_PAGE_PATH } from 'routes/paths';

/**
 * Computes the tailwind width class for the bottom bar menu item.
 */
function computeWidth(menuItems: number): string {
  switch (menuItems) {
    case 1:
      return 'w-full';
    case 2:
      return 'w-[50%]';
    case 3:
      return 'w-[33.3%]';
    case 4:
      return 'w-[25%]';
    default:
      return ''; // This should never happen.
  }
}

/**
 * The mobile nav bar
 */
export default function MobileNavBar() {
  const { isPhoneHeaderVisible } = useStudentDashboardLayout();
  const { getActiveHomework, getClassrooms } = useStudentDashboard();

  const { sectionSlug, stepId } = useParams();
  const slug = 'priprava-na-prijimacky';

  const linkCourse = `/course/${slug}`;
  const linkStep = sectionSlug && stepId ? `/${sectionSlug}/step/${stepId}` : '';
  const linkFull = `${linkCourse}${linkStep}`;

  const isInClassroom = getClassrooms().length > 0;
  const activeHomeworkCount = getActiveHomework().length;

  let menuItems = 4;

  if (!isInClassroom) {
    menuItems -= 1; // Exclude homework
  }

  if (lang !== 'cs') {
    menuItems -= 1; // Exclude HS admissions
  }

  const itemWidth = computeWidth(menuItems);

  return (
    <div className={isPhoneHeaderVisible ? 'lg:hidden' : ''}>
      <div className="fixed bottom-0 left-0 z-[1000] h-[64px] w-full bg-white p-[6px] shadow-student-mobile-navigation-bar">
        <div className={'flex items-center justify-between'}>
          <div className={itemWidth}>
            <BottomBarMenuItem
              to={STUDENT_DASHBOARD_PAGE_PATH}
              icon={<HomeIcon stroke="#535F6A" />}
              activeIcon={<HomeIcon stroke="#353E45" />}
              label={_t('Home')}
            />
          </div>
          {isInClassroom && (
            <div className="w-[25%]">
              <BottomBarMenuItem
                to="/student/homework/active"
                icon={<PaperIcon stroke="#535F6A" />}
                activeIcon={<PaperIcon stroke="#353E45" />}
                label={_tx('Homework', 'Homework', 2)}
                supplementaryItemInfo={activeHomeworkCount > 0 ? activeHomeworkCount : undefined}
                basePath="/student/homework"
              />
            </div>
          )}
          <div className={itemWidth}>
            <BottomBarMenuItem
              to={STUDENT_EXERCISES_PAGE_PATH}
              icon={<BookIcon stroke="#535F6A" />}
              activeIcon={<BookIcon stroke="#353E45" />}
              label={_t('Exercises book')}
            />
          </div>
          {lang === 'cs' && (
            <div className={itemWidth}>
              <BottomBarMenuItem
                to={linkFull}
                icon={<PenIcon stroke="#535F6A" />}
                activeIcon={<PenIcon stroke="#353E45" />}
                // Translators: This is a label for a button in the bottom bar of the student dashboard. HS means high school. The translation should also abbreviate the word.
                label={_t('HS admissions')}
              />
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
