import SelectInputItem from 'components/assignment/inputs/SelectInputItem';
import CorrectIcon from 'components/icons/CorrectIcon';
import IncorrectIcon from 'components/icons/IncorrectIcon';
import AnswerStats from 'components/assignment/inputs/AnswerStats';
import { IExerciseQuestion } from 'data/assignment/assignment';

/**
 * The parameters for the SelectInput component.
 */
interface Props {
  question: IExerciseQuestion;
  assignmentIsSolved: boolean;
  options: Array<{ text: string; value: string }>;
  value: string;
  result?: string;
  onChange: Function;
  readOnly: boolean;
}

/**
 * Select input.
 */
export default function SelectInput({
  question,
  assignmentIsSolved,
  options,
  value,
  result,
  onChange,
  readOnly,
}: Props) {
  return (
    <div>
      {assignmentIsSolved && !question.isCorrect && <AnswerStats answer={value} result={result!} />}
      {options.map((option) => {
        let icon = <></>;
        const isSelected = value === option.value;
        const isResult = result === option.value;
        const isCorrect = isSelected && isResult;

        if (assignmentIsSolved && isSelected && !isCorrect) {
          icon = <IncorrectIcon />;
        }

        if (isResult) {
          icon = <CorrectIcon />;
        }

        return (
          <div
            className="grid grid-cols-[1fr,_30px] items-center gap-[11px] lg:grid-cols-[570px_30px]"
            key={option.value}
          >
            <SelectInputItem
              assignmentIsSolved={assignmentIsSolved}
              label={option.text}
              value={option.value}
              onChange={onChange}
              isSelected={isSelected}
              isResult={isResult}
              isCorrect={isCorrect}
              readOnly={readOnly}
            />
            <div className="mb-[6px]">{icon}</div>
          </div>
        );
      })}
    </div>
  );
}
