interface Props {
  symbol: string;
  label?: string;
}

/**
 * Emoji component.
 */
export default function Emoji({ symbol, label = '' }: Props) {
  return (
    <span className="emoji" role="img" aria-label={label} aria-hidden={!label}>
      {symbol}
    </span>
  );
}
