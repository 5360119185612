import RegisterForm from 'components/forms/RegisterForm';
import { _t } from 'lang';
import Emoji from 'components/Emoji';
import { LOGIN_PAGE_PATH, PRIVACY_POLICY_PAGE_PATH } from 'routes/paths';
import { Link } from 'react-router-dom';
import FormPageLayout from 'layout/form-page-layout/FormPageLayout';

/**
 * Parameters for the register page.
 */
export interface RegisterPageProps {
  registerForCourse?: boolean;
}

/**
 * The register page.
 *
 * @see https://www.notion.so/Register-b875164919094f21aafcce1c68eb9f9f
 */
export default function RegisterPage({ registerForCourse = false }: RegisterPageProps = {}) {
  const text = _t('(By registering, you agree to our) (Terms of Use) (and the) (Privacy Policy)');

  const [byRegisteringYouAgreeToOur, , , privacyPolicy] = text
    .split(/[()]/g)
    .map((x) => x.trim())
    .filter(Boolean);

  return (
    <FormPageLayout>
      <div className="block p-[24px] pt-[45px] lg:ml-[50%] lg:flex lg:min-h-screen lg:items-center lg:pl-[130px] lg:pt-[24px]">
        <div className="lg:w-[370px]">
          <p className="pb-[32px] text-[24px] font-semibold leading-[29px]">
            <span>
              {_t('Register')} <Emoji symbol="👋" label={_t('waving-hand')} />
            </span>
          </p>
          <RegisterForm registerForCourse={registerForCourse} />
          <p className="border-b border-b-input-outline-shadow pb-[24px] pt-[24px] text-center text-[12px] leading-[20px] text-text-registration">
            {byRegisteringYouAgreeToOur}{' '}
            <Link className="underline" to={PRIVACY_POLICY_PAGE_PATH}>
              {privacyPolicy}
            </Link>
            .
          </p>
          <p className="pt-[24px] text-center text-[14px] leading-[20px] text-text-footer">
            {_t('Already have an account?')}{' '}
            <Link className="font-semibold text-green-primary" to={LOGIN_PAGE_PATH}>
              {_t('Log in')}
            </Link>
          </p>
        </div>
      </div>
    </FormPageLayout>
  );
}
