import Emoji from 'components/Emoji';
import { _t, lang } from 'lang';
import HelpContent from 'pages/presentation-module/landing/HelpContent';
import CourseCtaButton from 'pages/presentation-module/landing/CourseCtaButton';

/**
 * Home page for the logged user.
 */
export default function LandingPageContent() {
  const cta = <CourseCtaButton title="Intenzivní příprava začína právě teď" />;

  return (
    <div>
      <div className="relative overflow-hidden pb-[35px] lg:bg-right lg:bg-no-repeat lg:pb-0">
        <div className="grid min-h-[calc(100vh-90px)] w-full grid-rows-[auto_auto_auto] gap-y-[30px] lg:grid-rows-[auto_1fr_80px] lg:bg-pencils-desktop lg:bg-[length:55%] lg:bg-right lg:bg-no-repeat">
          {/* ROW 1 */}
          <div></div>

          {/* ROW 2 */}
          <div className="lg:grid lg:grid-cols-[570px_1fr] lg:items-center lg:gap-x-[20px] lg:pl-[calc((50vw-609px)+24px)]">
            <div className="mx-auto w-full max-w-[618px] px-[24px] text-center lg:px-0 lg:text-left">
              <p className="text-[25px] font-semibold leading-[35px] md:text-[42px] md:leading-[54px]">
                <span className="md:whitespace-pre-line">
                  Opravdu efektivní příprava na přijímačky, díky které to zvládnete
                </span>{' '}
                <Emoji symbol={'📚'} label={_t('books')} />
              </p>
              <p className="pb-[32px] pt-[16px] font-normal text-text-shadow md:pt-[24px] md:text-[19px] md:leading-[30px]">
                <span className="md:whitespace-pre-line">
                  Do ostrých zkoušek zbývá pár dní. Ale s námi to ještě stíháte. Využijte přípravu na přijímačky z
                  matematiky za last minute ceny. Máme ještě pár volných míst v kurzu.
                </span>
              </p>
              <div className="hidden lg:block">{cta}</div>
            </div>
            <div className="relative px-[24px] lg:px-0">
              <img
                className="mx-auto block h-auto w-full max-w-[768px] rounded-[10px] shadow-[2px_5px_20px_4px_rgba(0,0,0,0.12)] lg:mx-0 lg:max-w-none lg:translate-x-[10px] lg:shadow-xl"
                alt={_t('Teacher dashboard')}
                src={`/img/screenshots/teacher-dashboard-${lang}.png`}
              />
              <div className="mt-[32px] block lg:hidden">{cta}</div>
            </div>
          </div>

          {/* ROW 3 */}
          <div className="px-[24px] lg:pl-[calc((50vw-609px)+24px)]">
            <span>Žádná panika. To stihneme!</span>{' '}
            <a href="#how-it-works" className="font-medium text-green-primary underline">
              Podívejte se jak na to.
            </a>
          </div>
        </div>
      </div>
      <HelpContent />
    </div>
  );
}
