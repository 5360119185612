import { Card } from '@mantine/core';
import MiddleDot from 'components/MiddleDot';
import { kebabCase } from 'lodash';

interface Props {
  image: string;
  name: string;
  position: string;
  positionClassName?: string;
  contact: string;
  contactName: string;
  webpage?: string;
  webpageName?: string;
  email?: string;
}

/**
 * Single image card.
 */
export default function ImageCard({
  image,
  name,
  position,
  positionClassName = '',
  contact,
  contactName,
  webpage,
  webpageName,
  email,
}: Props) {
  return (
    <Card className="w-full rounded-[10px] border-0 p-0 shadow-card-shadow lg:w-full">
      <Card.Section style={{ marginTop: 0, marginBottom: 0 }} className="m-0">
        <div className="hidden lg:block">
          <img className="h-auto w-full" src={image} height={270} width={270} alt={kebabCase(name)} />
        </div>
        <div className="lg:hidden">
          <img className="h-auto w-full" src={image} height={158} width={158} alt={kebabCase(name)} />
        </div>
        <div className="flex flex-col p-[12px] text-text-dark lg:pb-[16px] lg:pl-[16px] lg:pr-[16px]">
          <span className="text-[14px] font-semibold leading-[18px] lg:text-[20px] lg:leading-[30px]">{name}</span>
          <span
            className={`pt-[4px] text-[12px] font-medium leading-[15px] lg:text-[16px] lg:leading-[19px] ${positionClassName}`}
          >
            {position}
          </span>
          <div className="flex flex-wrap gap-[4px] pt-[8px] text-[10px] leading-[14px] text-text-shadow lg:gap-[8px] lg:pt-[12px] lg:text-[12px] lg:font-medium lg:leading-[15px]">
            <a href={contact} target="_blank" rel="noreferrer">
              {contactName}
            </a>
            {webpage && (
              <>
                <MiddleDot />
                <div className="flex gap-[4px] lg:gap-[8px]">
                  <a href={webpage} target="_blank" rel="noreferrer">
                    {webpageName}
                  </a>
                </div>
              </>
            )}
            {email && (
              <>
                <MiddleDot />
                <div className="flex gap-[4px] lg:gap-[8px]">
                  <a href={`mailto:${email}`} target="_blank" rel="noreferrer">
                    {email}
                  </a>
                </div>
              </>
            )}
          </div>
        </div>
      </Card.Section>
    </Card>
  );
}
