import StarIcon from 'components/icons/StarIcon';
import { _t } from 'lang';
import { useState } from 'react';

export type StarRatingType = 1 | 2 | 3 | 4;

interface Props {
  value?: StarRatingType;
  onChange: (value: StarRatingType) => void;
}

/**
 * Star rating for videos.
 */
export default function StarRating({ onChange, value }: Props) {
  const stars = [];

  // 0 = no hover
  // n = n-th star hover (counting from 1, not 0)
  const [hover, setHover] = useState(0);

  const starTexts = [
    _t('I did not like this'),
    _t('It was okay'),
    _t('It was good, I am satisfied'),
    _t('Wow! I really loved it!'),
  ];

  for (let i = 0; i < 4; i++) {
    stars.push(
      <div
        className="cursor-pointer pb-[2px] pr-[8px] pt-[2px]"
        key={i}
        onMouseEnter={() => setHover(i + 1)}
        onMouseLeave={() => setHover(0)}
        onClick={() => onChange((i + 1) as StarRatingType)} // this cast is safe
      >
        <StarIcon filled={hover > i || (value !== undefined && value > i)} fill="#fff" />
      </div>
    );
  }

  return (
    <div className="flex flex-col items-center pt-[8px]">
      <span className="min-h-[15px] text-[12px] font-medium leading-[15px] lg:min-h-[17px] lg:text-[14px] lg:leading-[17px]">
        {(hover !== 0 || value !== undefined) && starTexts[(hover !== 0 ? hover : value !== undefined ? value : 1) - 1]}
      </span>
      <div className="flex pt-[4px]">{stars}</div>
    </div>
  );
}
