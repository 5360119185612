import { AxiosInstance } from 'axios';
import { SweetPath } from 'sweet-path';
import dispatchAction from 'api/dispatch-action';

import { ResetPasswordCommitPayload } from 'api/actions/reset-password-commit/reset-password-commit-payload';
import { ResetPasswordCommitPathParameters } from 'api/actions/reset-password-commit/reset-password-commit-path-parameters';

import { ResetPasswordCommitResponse } from 'api/actions/reset-password-commit/reset-password-commit-response';

/**
 * The path of the ResetPasswordCommit action.
 */
export const resetPasswordCommitActionPath = new SweetPath<keyof ResetPasswordCommitPathParameters>(
  '/user/password/reset/commit',
  { path: '{param}' }
);

/**
 * Creates a contextualized ResetPasswordCommit actions.
 */
export default function createResetPasswordCommitAction(axios: AxiosInstance) {
  return function resetPasswordCommitAction({ payload }: { payload: ResetPasswordCommitPayload }) {
    const path = resetPasswordCommitActionPath.original;

    return dispatchAction(() =>
      axios.post<ResetPasswordCommitResponse, ResetPasswordCommitResponse, ResetPasswordCommitPayload>(path, payload)
    );
  };
}
