import { useState } from 'react';
import { StudentDashboardLayoutContext } from 'layout/student-dashboard-layout/StudentDashboardLayoutContext';

/**
 * Provides the basic page layout context.
 */
export default function StudentDashboardLayoutProvider({ children }: { children: JSX.Element }) {
  const [isPhoneHeaderVisible, setMobileMenuVisibility] = useState(true);

  /**
   * Shows the mobile menu.
   */
  const showPhoneHeader = () => setMobileMenuVisibility(true);

  /**
   * Hides the mobile menu.
   */
  const hidePhoneHeader = () => setMobileMenuVisibility(false);

  return (
    <StudentDashboardLayoutContext.Provider value={{ isPhoneHeaderVisible, showPhoneHeader, hidePhoneHeader }}>
      {children}
    </StudentDashboardLayoutContext.Provider>
  );
}
