import WebinarCard from 'components/webinars/WebinarCard';
import { _t } from 'lang';

/**
 * Polish webinars.
 */
export default function WebinarsPl() {
  // Days of week translations.
  const monday = _t('Monday'); // eslint-disable-line @typescript-eslint/no-unused-vars
  const tuesday = _t('Tuesday'); // eslint-disable-line @typescript-eslint/no-unused-vars
  const wednesday = _t('Wednesday'); // eslint-disable-line @typescript-eslint/no-unused-vars
  const thursday = _t('Thursday'); // eslint-disable-line @typescript-eslint/no-unused-vars
  const friday = _t('Friday'); // eslint-disable-line @typescript-eslint/no-unused-vars
  const saturday = _t('Saturday'); // eslint-disable-line @typescript-eslint/no-unused-vars
  const sunday = _t('Sunday'); // eslint-disable-line @typescript-eslint/no-unused-vars

  return (
    <>
      <WebinarCard
        isInPast={false}
        name="Curious Mind - Introduction for teachers"
        lecturers={[{ name: 'Michael', avatar: 'team/michael-bohin.jpg' }]}
        onDay={`${friday}, 30.6.`}
        startsAt="16:00"
        duration="40 minut"
        description="Webinarium jest w języku angielskim."
        webinarUrl="https://zoom.us/j/91270188358?pwd=M3BEVmFOUk5hcmVFRytVOHNHNzladz09"
        subscribeUrl="https://9599da45.sibforms.com/serve/MUIFAOPkwzHFp80YZZK6MHT7Ba7vl0cAyll2UQfHXeRdyAT7_j8blWvt6QBN0sOpZEmqOF6G15kvAvAUeN0wK67s1dN4syCa3rM4spnlv7ccv85XMcbf17scLoj7cgGpLb1lt0Axdc3VFRVeMsUcIp1gkB07EKK3G6VwC9ECLOiuHE5v2eSexRxE7YR5CiYJ8bbLPLPFomML0eeC"
      />
      <WebinarCard
        isInPast={false}
        name="Zeszyt ćwiczeń online - wprowadzenie dla nauczycieli"
        lecturers={[{ name: 'Marcin', avatar: 'team/marcin.jpg' }]}
        onDay={`${monday}, 3.7.`}
        startsAt="17:00"
        duration="40 minut"
        description=""
        webinarUrl="https://zoom.us/j/99059847125?pwd=b1c1YkNpanRzZXgxQllIcWpMS2ZiQT09"
        subscribeUrl="https://9599da45.sibforms.com/serve/MUIFANKw5DRdDrDy0KSLGBn6biPxoQcw35j3O-TYCYob_2umknEzINDVrEpeQKmvGxKhzEauYLP9wX9aw9Lxew6l0vzgDQFIRLLEwemSe3fCRmmhaPXkrAwk0NBSCDiB7rlwsO2c4-rmCCwrN5fHbT7x-ZkXw4y5n-MX1z9AGsFQ6kvp_xxEbZMmj5XbCEgZ1xjdtRb3rYjWY_Be"
      />
      <WebinarCard
        isInPast={false}
        name="Zeszyt ćwiczeń online - wprowadzenie dla nauczycieli"
        lecturers={[{ name: 'Marcin', avatar: 'team/marcin.jpg' }]}
        onDay={`${tuesday}, 4.7.`}
        startsAt="17:00"
        duration="40 minut"
        description=""
        webinarUrl="https://zoom.us/j/94833180662?pwd=VUJVVytzSEd0c2puc0t1VDFPVGRPZz09"
        subscribeUrl="https://9599da45.sibforms.com/serve/MUIFAGE3UhbqM8gi2GbC6hwI7LGiQd0gVKnghuz_mmuJOPjMrOeBFisjrXhReWRNskklT5tmD-fUu-Ydblhud4oPEqHvmjkMM_ay6M8bTsPPmXib0C5f34u_ZrnY1L-SUEU7cZwfEIQ7s9zg387jDOOYrVJ1zivejYNwg8LBMaGNeYmtayktso6Py_aeDLQyabXOi-cSChRy0FUs"
      />
      <WebinarCard
        isInPast={false}
        name="Zeszyt ćwiczeń online - wprowadzenie dla nauczycieli"
        lecturers={[{ name: 'Marcin', avatar: 'team/marcin.jpg' }]}
        onDay={`${wednesday}, 19.7.`}
        startsAt="17:00"
        duration="40 minut"
        description=""
        webinarUrl="https://zoom.us/j/94997349916?pwd=MldNYU5DWnEyZTZmQ1NNWkk0cFdTdz09"
        subscribeUrl="https://9599da45.sibforms.com/serve/MUIFAMM-LqdRbA8hrbFXuv-LIkXrZNt_WlF8Qr1gPkBn31w65-gdUCQdUpBeb34QRNhLLjcPcSzqZdxUPVc7UCUBvzQARbokMuj8-UW43KHW5hpDqosw-Huexdc69qAIqQQ0j1zqnofjaGDQNGflQRwgnnwkYlNtIBlZZsjc1ofRV9J8iHKttOOMGRPvF8RWznJ9IP_fe3GjhWBf"
      />
      <WebinarCard
        isInPast={false}
        name="Zeszyt ćwiczeń online - wprowadzenie dla nauczycieli"
        lecturers={[{ name: 'Marcin', avatar: 'team/marcin.jpg' }]}
        onDay={`${thursday}, 20.7.`}
        startsAt="17:00"
        duration="40 minut"
        description=""
        webinarUrl="https://zoom.us/j/91819339288?pwd=KzJhbzVMNlpCcTR5d2QrR0NwRUZMZz09"
        subscribeUrl="https://9599da45.sibforms.com/serve/MUIFANbg_YKnghxcbikp2x5eIDUVdPHi6iNwrmOAB6RjwJT42UWZsIQ2M-eWZkG6UHeYPYyS2RLJ5pb3IG6ZBVKBoJTrXsW0-8UMoidoA4_Y_qXSI7-VwdNs4CrT3xRI0A2G6l7a8Yncz8OucK4J4WJs0PrApPMqzL87ZMHeLZF2Mwgld7RtgIv74sZssLXGDgT1Nt43R2yMSe1M"
      />
    </>
  );
}
