/**
 * Cancel icon.
 */
export default function CancelIcon() {
  return (
    <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect width="40" height="40" rx="10" fill="#F3F6F8" />
      <rect
        width="23.2581"
        height="1.93817"
        rx="0.969087"
        transform="matrix(-0.699746 -0.714391 -0.699746 0.714391 28.6309 27.6152)"
        fill="#738491"
      />
      <rect
        width="23.2581"
        height="1.93817"
        rx="0.969087"
        transform="matrix(0.699746 -0.714391 0.699746 0.714391 11.3691 27.6152)"
        fill="#738491"
      />
    </svg>
  );
}
