import EuropeanUnionDisclaimer from 'layout/parts/footer/EuropeanUnionDisclaimer';
import CompanyName from 'layout/parts/footer/CompanyName';
import Copyright from 'layout/parts/footer/Copyright';
import FooterLinks from 'layout/parts/footer/FooterLinks';

interface Props {
  className?: string;
}

/**
 * Page footer.
 */
export default function PageFooter({ className = '' }: Props) {
  return (
    <div className="lg:bg-left-circle lg:bg-left-bottom lg:bg-no-repeat">
      <div className={`bg-bottom-circle bg-right-bottom bg-no-repeat lg:bg-right-circle ${className}`}>
        <div className="lg:border-t lg:border-t-input-outline-shadow">
          <div className="ml-auto mr-auto lg:grid lg:max-w-[1170px] lg:grid-cols-2 lg:grid-rows-1">
            <div className="border-t border-t-input-outline-shadow pb-[32px] pl-[24px] pt-[32px] lg:flex lg:border-r lg:border-t-0 lg:border-r-input-outline-shadow lg:pl-0">
              <FooterLinks />
            </div>
            <div className="lg:flex lg:justify-end">
              <div className=" border-t border-t-input-outline-shadow pb-[32px] pl-[24px] pr-[24px] pt-[32px]  lg:border-t-0">
                <EuropeanUnionDisclaimer />
              </div>
            </div>
          </div>
        </div>
        <div className="lg:border-t lg:border-t-input-outline-shadow">
          <div className="ml-auto mr-auto lg:max-w-[1170px]">
            <div className="flex flex-col border-t border-t-input-outline-shadow pb-[16px] pl-[24px] pr-[24px] pt-[16px] lg:grid lg:grid-cols-2 lg:grid-rows-1 lg:flex-row lg:border-t-0 lg:pl-0">
              <CompanyName />
              <Copyright />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
