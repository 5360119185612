import Clickable from 'components/misc/Clickable';
import { useState } from 'react';

interface Props {
  value: number;
  onClick: () => void;
  isSelected: boolean;
}

/**
 * One item in the time picker.
 */
export default function TimePickerItem({ value, onClick, isSelected }: Props) {
  const [hover, setHover] = useState(false);

  const hoverStyles = 'rounded-[4px] bg-[#F8F9FA] text-[#495057]';
  const selectedStyles = 'rounded-full bg-green-primary text-white';
  const defaultStyles = 'rounded-full bg-white text-[#495057]';

  return (
    <Clickable
      className={`flex h-[35px] w-[35px] items-center justify-center text-[14px] leading-[38px]  ${
        isSelected ? selectedStyles : hover ? hoverStyles : defaultStyles
      }`}
      onClick={onClick}
      onMouseEnter={() => {
        setHover(true);
      }}
      onMouseLeave={() => {
        setHover(false);
      }}
    >
      {value}
    </Clickable>
  );
}
