import { Button, Tooltip } from '@mantine/core';
import { _t } from 'lang';
import sprintf from 'lang/sprintf';
import HomeworkResultView from 'components/HomeworkResultView';
import { useNavigate } from 'react-router-dom';

interface Props {
  studentId: string;
  name: string;
  successRate?: number;
  active: boolean;
  assignmentId?: string;
  homeworkId: string;
  classroomId: string;
  homeworkTitle: string;
  isInClassroom: boolean;
}

/**
 * Homework Detail row.
 */
export default function HomeworkDetailRow({
  studentId,
  name,
  successRate,
  active,
  assignmentId,
  homeworkId,
  classroomId,
  homeworkTitle,
  isInClassroom,
}: Props) {
  const navigate = useNavigate();

  const hasSuccessRate = successRate !== undefined;
  return (
    <div
      className={`flex h-[66px] items-center justify-between rounded-[10px] p-[12px] pl-[24px] ${
        hasSuccessRate ? 'bg-white' : 'bg-[#FCFCFD]'
      }`}
    >
      <div
        className={`w-[220px] text-[16px] font-medium leading-[19px] ${
          hasSuccessRate ? 'text-text-dark' : 'text-text-shadow'
        }`}
      >
        {isInClassroom ? (
          <Tooltip
            // Translators: %s is for the student's name
            label={sprintf(_t('Go to student %s'), name)}
            withArrow
          >
            <span
              className="cursor-pointer hover:underline"
              onClick={() => navigate(`/teacher/classrooms/classroom/${classroomId}/students/student/${studentId}`)}
            >
              {name}
            </span>
          </Tooltip>
        ) : (
          <Tooltip label={_t('This student has been removed from the classroom')} withArrow>
            <span className="cursor-not-allowed line-through">{name}</span>
          </Tooltip>
        )}
      </div>
      <div className="w-[194px]">
        <HomeworkResultView successRate={successRate} active={active} />
      </div>
      <Button
        disabled={!hasSuccessRate}
        className="h-[42px] w-[176px] rounded-[7px] bg-green-primary-opacity-01 text-[12px] font-semibold leading-[15px] text-green-primary hover:bg-green-primary-opacity-02 active:bg-green-primary-opacity-02 lg:w-[276px]"
        onClick={() =>
          navigate(`/teacher/classrooms/classroom/${classroomId}/homework/homework/${homeworkId}/check`, {
            state: { assignmentId: assignmentId!, homeworkTitle, studentName: name },
          })
        }
        styles={{
          root: {
            '&:disabled': {
              backgroundColor: '#0AB8921A',
              color: '#0AB892',
              opacity: 0.4,
            },
          },
        }}
      >
        <div className="flex items-center justify-between gap-[28px] text-[12px] font-semibold leading-[15px]">
          <div>{_t('Check homework')}</div>
        </div>
      </Button>
    </div>
  );
}
