import useApi from 'api/use-api';
import axios from 'axios';
import { _t } from 'lang';
import Qs from 'qs';
import { useCallback, useMemo } from 'react';
import { createContext, useContext } from 'react';

/**
 * The file manager context interface.
 */
interface IFileManagerContext {
  uploadFile: ({ fileName, contents }: { fileName: string; contents: string | ArrayBuffer | null }) => Promise<string>;
  readFile: ({ fileId }: { fileId: string }) => Promise<Blob>;
  getFileMetadata: ({ fileId }: { fileId: string }) => Promise<any>;
  getFileThumbnail: ({ fileId }: { fileId: string }) => Promise<Blob>;
}

/**
 * Creates empty context.
 */
export const FileManagerContext = createContext<IFileManagerContext>(undefined!);

/**
 * Requires the API context.
 */
export const useFileManager = () => useContext(FileManagerContext);

/**
 * File Manager Provider.
 */
export default function FileManagerProvider({ children }: { children: React.ReactNode }) {
  const { jwt } = useApi();

  /**
   * The connector object = axios instance.
   */
  const connector = useMemo(() => {
    const connector = axios.create({
      timeout: 20000, // todo: consider using env variable
      baseURL: '/api/files', // todo: consider using env variable
    });

    connector.interceptors.request.use((cfg) => {
      if (!cfg.headers) {
        cfg.headers = {};
      }

      // Add JWT to the request.
      if (jwt && !cfg.headers.Authorization) {
        cfg.headers.Authorization = `Bearer ${jwt}`;
      }

      // Serialize deep objects.
      cfg.paramsSerializer = (params) => Qs.stringify(params, { arrayFormat: 'brackets', encode: false });

      return cfg;
    });

    return connector;
  }, [jwt]);

  /**
   * Uploads the file and returns its ID.
   */
  const uploadFile = useCallback(
    async ({ fileName, contents }: { fileName: string; contents: string | ArrayBuffer | null }): Promise<string> => {
      try {
        const { data } = await connector.post('/file/upload', { fileName, contents });

        return data.fileId;
      } catch (e: any) {
        if (e?.response?.status === 413) {
          throw new Error(_t('The file is too large. Maximum file size is 5MB.'));
        }

        throw e;
      }
    },
    [connector]
  );

  /**
   * Retrieves the contents of the file.
   */
  const readFile = useCallback(
    async ({ fileId }: { fileId: string }): Promise<Blob> => {
      const { data } = await connector.get(`/file/${fileId}`, { responseType: 'blob' });
      return data;
    },
    [connector]
  );

  /**
   * Retrieves the metadata of the file.
   */
  const getFileMetadata = useCallback(
    async ({ fileId }: { fileId: string }) => {
      const { data } = await connector.get(`/file/${fileId}/metadata`);
      return data;
    },
    [connector]
  );

  /**
   * Retrieves the thumbnail of the file.
   */
  const getFileThumbnail = useCallback(
    async ({ fileId }: { fileId: string }) => {
      const { data } = await connector.get(`/file/${fileId}/thumbnail`, { responseType: 'blob' });
      return data;
    },
    [connector]
  );

  return (
    <FileManagerContext.Provider value={{ uploadFile, readFile, getFileMetadata, getFileThumbnail }}>
      {children}
    </FileManagerContext.Provider>
  );
}
