import { AxiosInstance } from 'axios';
import { SweetPath } from 'sweet-path';
import dispatchAction from 'api/dispatch-action';

import { ResetPasswordRequestPayload } from 'api/actions/reset-password-request/reset-password-request-payload';
import { ResetPasswordRequestPathParameters } from 'api/actions/reset-password-request/reset-password-request-path-parameters';

import { ResetPasswordRequestResponse } from 'api/actions/reset-password-request/reset-password-request-response';

/**
 * The path of the ResetPasswordRequest action.
 */
export const resetPasswordRequestActionPath = new SweetPath<keyof ResetPasswordRequestPathParameters>(
  '/user/password/reset/request',
  { path: '{param}' }
);

/**
 * Creates a contextualized ResetPasswordRequest actions.
 */
export default function createResetPasswordRequestAction(axios: AxiosInstance) {
  return function resetPasswordRequestAction({ payload }: { payload: ResetPasswordRequestPayload }) {
    const path = resetPasswordRequestActionPath.original;

    return dispatchAction(() =>
      axios.post<ResetPasswordRequestResponse, ResetPasswordRequestResponse, ResetPasswordRequestPayload>(path, payload)
    );
  };
}
