import { useParams } from 'react-router-dom';
import HomeIcon from 'components/icons/HomeIcon';
import { STUDENT_DASHBOARD_PAGE_PATH, STUDENT_EXERCISES_PAGE_PATH } from 'routes/paths';
import useStudentDashboard from 'data/student-dashboard/use-student-dashboard';
import PaperIcon from 'components/icons/PaperIcon';
import BookIcon from 'components/icons/BookIcon';
import PenIcon from 'components/icons/PenIcon';
import CollapsedSidebarMenuItem from 'layout/student-dashboard-layout/parts/sidebar/CollapsedSidebarMenuItem';
import MobileNavBar from 'layout/student-dashboard-layout/parts/sidebar/MobileNavBar';

/**
 * Collapsed sidebar for the student dashboard layout.
 */
export default function StudentCollapsedSidebar() {
  const { getClassrooms } = useStudentDashboard();

  const { sectionSlug, stepId } = useParams();
  const slug = 'priprava-na-prijimacky';

  const linkCourse = `/student/course/${slug}`;
  const linkStep = sectionSlug && stepId ? `/${sectionSlug}/step/${stepId}` : '';
  const linkFull = `${linkCourse}${linkStep}`;

  const isInClassroom = getClassrooms().length > 0;

  return (
    <div>
      <MobileNavBar />
      <div className="fixed hidden items-center bg-white shadow-3xl lg:flex lg:min-h-screen lg:w-[88px] lg:flex-col lg:pl-[16px] lg:pr-[16px] lg:pt-[32px]">
        <div className="flex flex-col items-center gap-[8px]">
          <CollapsedSidebarMenuItem
            to={STUDENT_DASHBOARD_PAGE_PATH}
            icon={<HomeIcon stroke="#535F6A" />}
            activeIcon={<HomeIcon stroke="#353E45" />}
          />
          {isInClassroom && (
            <CollapsedSidebarMenuItem
              to="/student/homework/active"
              icon={<PaperIcon stroke="#535F6A" />}
              activeIcon={<PaperIcon stroke="#353E45" />}
            />
          )}
          <CollapsedSidebarMenuItem
            to={STUDENT_EXERCISES_PAGE_PATH}
            icon={<BookIcon stroke="#535F6A" />}
            activeIcon={<BookIcon stroke="#353E45" />}
          />
          <CollapsedSidebarMenuItem
            to={linkFull}
            icon={<PenIcon stroke="#535F6A" />}
            activeIcon={<PenIcon stroke="#353E45" />}
          />
        </div>
      </div>
    </div>
  );
}
