interface Props {
  value: number;
  active?: boolean;
  className?: string;
}

/**
 * Number in a red circle.
 */
export default function NumberInCircle({
  value,
  active = true,
  className = 'w-[16px] h-[16px] text-[10px] font-medium leading-[12px]',
}: Props) {
  const bgColor = active ? 'bg-red' : 'bg-text-shadow';

  return (
    <div className={`flex items-center justify-center rounded-full text-white ${bgColor} ${className}`}>{value}</div>
  );
}
