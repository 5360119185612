import AssignmentEvaluation from 'components/assignment/AssignmentEvaluation';
import { Button } from '@mantine/core';
import { _t } from 'lang';
import createInitialValues, { AnswersMap } from 'components/assignment/form/create-initial-values';
import createValidators from 'components/assignment/form/create-validators';
import { useForm } from '@mantine/form';
import MarkdownText from 'components/MarkdownText';
import AssignmentQuestion from 'components/assignment/AssignmentQuestion';
import useApi from 'api/use-api';
import parseAnswers from 'components/assignment/form/parse-answers';
import { IAssignment, IAssignmentEvaluation } from 'data/assignment/assignment';
import { SubmitAssignmentSolutionResponse } from 'api/actions/submit-assignment-solution/submit-assignment-solution-response';
import { showNotification } from '@mantine/notifications';
import { ReactNode, useState } from 'react';
import AssignmentAd from 'components/ads/AssignmentAd';

/**
 * The parameters of the component.
 */
interface Props {
  assignment: IAssignment;
  refresh: () => void;
  onSolved?: (evaluation: IAssignmentEvaluation | undefined) => void;
  afterEvaluationContent?: ReactNode | ((evaluation: IAssignmentEvaluation) => ReactNode);
  hideSubmitButton?: boolean;
  isPrint?: boolean;
}

/**
 * The content of the assignment.
 */
export default function AssignmentContent({
  assignment,
  refresh,
  onSolved,
  afterEvaluationContent = null,
  hideSubmitButton = false,
  isPrint = false,
}: Props) {
  // Assignment
  const [invalidQuestions, setInvalidQuestions] = useState<string[]>([]);
  const questions = assignment.exercises.flatMap((exercise) => exercise.questions) ?? [];
  const { exercises, evaluation, assignmentId } = assignment;
  const isSolved = !!evaluation;

  // Api
  const { getAction } = useApi();
  const submitAssignmentSolutionAction = getAction('SubmitAssignmentSolution');

  // Form
  const formData = useForm<AnswersMap>({
    initialValues: createInitialValues(questions),
    validate: createValidators(questions) as any,
  });

  /**
   * Collects the data and submits them.
   */
  function submitForm(answers: AnswersMap) {
    const solution = parseAnswers(questions, answers);

    submitAssignmentSolutionAction({
      parameters: { assignmentId },
      payload: { solution },
    }).success(({ isSolved, questions: { invalid }, evaluation }: SubmitAssignmentSolutionResponse) => {
      if (isSolved) {
        refresh();

        if (onSolved) {
          onSolved(evaluation);
        }
      } else {
        setInvalidQuestions(invalid);

        showNotification({
          title: _t('Something went wrong.'),
          message: _t('Some questions were not saved correctly, please check the input format.'),
          color: 'red',
        });
      }
    });
  }

  return (
    <div className={isPrint ? 'bg-white' : ''}>
      <div className="mb-[56px] ml-[-100px] mr-[-100px] empty:mb-0">
        <AssignmentAd />
      </div>

      {evaluation && (
        <AssignmentEvaluation
          assignment={assignment}
          refresh={refresh}
          afterEvaluationContent={afterEvaluationContent}
        />
      )}

      <form onSubmit={formData.onSubmit(submitForm)}>
        <div className="flex flex-col">
          {exercises.map((exercise, exerciseIndex) => (
            <div key={exerciseIndex} className={isPrint ? 'mb-8' : ''}>
              {exerciseIndex !== 0 && (
                <div
                  className={`${isPrint ? 'hidden' : 'bg-input-outline-shadow'} mb-[48px] mt-[48px] h-[1px] w-full`}
                />
              )}
              <div className="mb-[-48px] font-semibold text-text-dark">
                {exercise.description && (
                  <div className="mb-[48px] text-[24px] leading-[34px]">
                    <MarkdownText text={exercise.description} />
                  </div>
                )}
                {exercise.questions.map((question, questionIndex) => (
                  <AssignmentQuestion
                    key={question.questionId}
                    hasDescription={!!exercise.description}
                    questionIndex={questionIndex}
                    assignmentIsSolved={isSolved}
                    question={question}
                    isValid={!invalidQuestions.includes(question.questionId) && formData.isValid(question.questionId)}
                    readOnly={isSolved}
                    isPrint={isPrint}
                    {...formData.getInputProps(question.questionId)}
                  />
                ))}
              </div>
              <wbr />
            </div>
          ))}
        </div>

        {!isSolved && !hideSubmitButton && (
          <Button
            className={
              'mt-[48px] h-[56px] min-w-[240px] bg-green-primary pb-[16px] pl-[32px] pr-[32px] pt-[16px] text-[18px] font-semibold leading-[22px] text-white hover:bg-green-dark active:bg-green-dark'
            }
            type="submit"
            radius={10}
          >
            {_t('Evaluate')}
          </Button>
        )}
      </form>
    </div>
  );
}
