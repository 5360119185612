import SelectInputIcon from 'components/icons/SelectInputIcon';
import SelectInputSelectedIcon from 'components/icons/SelectInputSelectedIcon';
import MarkdownText from 'components/MarkdownText';
import { useState } from 'react';

/**
 * The parameters of the component.
 */
interface Props {
  assignmentIsSolved: boolean;
  value: string;
  label: string;
  onChange: any;
  isCorrect?: boolean;
  isSelected: boolean;
  isResult: boolean;
  readOnly?: boolean;
}

/**
 * Single item in the SelectInput component.
 */
export default function SelectInputItem({
  assignmentIsSolved,
  value,
  label,
  onChange,
  isCorrect,
  isSelected,
  isResult,
  readOnly,
}: Props) {
  const [hover, setHover] = useState(false);

  /**
   * Handles the click event.
   */
  function onClick() {
    if (!readOnly) {
      onChange(value);
    }
  }

  // Styles
  let borderColor = 'border-transparent';

  if (isSelected || (!readOnly && hover)) {
    borderColor = 'border-text-dark';
  }

  if (assignmentIsSolved && isSelected && !isCorrect) {
    borderColor = 'border-red';
  }

  const background = isResult ? 'bg-green-primary-opacity-01' : 'bg-bg-shadow';
  const letterBackground = isResult ? 'bg-green-primary-opacity-02' : 'bg-input-outline-shadow';
  const letterText = isResult ? 'text-green-primary' : 'text-text-shadow';

  const cursor = readOnly ? '' : 'cursor-pointer';

  return (
    <div
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
      className="mb-[8px] flex items-stretch rounded-[10px] lg:w-[570px]"
    >
      <div
        className={`flex items-center justify-center gap-[12px] rounded-[10px_0_0_10px] border-2 border-r-0 pl-[14px] pr-[17px] ${borderColor} ${cursor} ${letterBackground}`}
      >
        <div>{isSelected ? <SelectInputSelectedIcon /> : <SelectInputIcon />}</div>
        <span className={`w-[10px] text-[14px] font-bold ${letterText}`}>{value.toUpperCase()}</span>
      </div>
      <div
        className={`flex w-full items-center gap-[12px] rounded-[0_10px_10px_0] border-2 border-l-0 pb-[8px] pl-[12px] pr-[16px] pt-[8px] lg:pb-[16px] lg:pl-[16px] lg:pt-[16px] ${borderColor} ${background} ${cursor}`}
        onClick={onClick}
      >
        <div className="flex items-center gap-[10px] text-[14px] font-medium leading-[21px]">
          <MarkdownText text={label} />
        </div>
      </div>
    </div>
  );
}
