import { GetStudentDashboardResponse } from 'api/actions/get-student-dashboard/get-student-dashboard-response';
import { createContext } from 'react';

/**
 * The data about a single classroom.
 */
export type Classroom = GetStudentDashboardResponse['classrooms'][number];

/**
 * The data about a single homework.
 */
export type Homework = GetStudentDashboardResponse['homework'][number];

/**
 * The statistics for the student.
 */
export type Stats = GetStudentDashboardResponse['stats'];

/**
 * The context for the student dashboard.
 */
interface StudentDashboardContext {
  /**
   * Whether the data is ready to be displayed.
   */
  ready: boolean;

  /**
   *  Whether the data is being fetched.
   */
  loading: boolean;

  /**
   * Fetches the data for the student's dashboard.
   */
  fetchStudentDashboard: () => void;

  /**
   * Returns the classrooms in which the student is enrolled.
   */
  getClassrooms: () => Classroom[];

  /**
   * Returns the homework the student has assigned.
   */
  getHomework: () => Homework[];

  /**
   * Returns the active homework the student has assigned.
   */
  getActiveHomework: () => Homework[];

  /**
   * Returns the statistics for the student.
   */
  getStats: () => Stats;
}

export default createContext<StudentDashboardContext>(undefined!);
