import { unknownErrorNotification } from 'api/notifications';
import useCourseStep from 'pages/courses-module/course-step/context/use-course-step';
import CourseStepContentText from 'pages/courses-module/course-step/main/content/CourseStepContentText';
import CourseStepContentVideo from 'pages/courses-module/course-step/main/content/video/CourseStepContentVideo';
import CourseStepContentExercise from 'pages/courses-module/course-step/main/content/CourseStepContentExercise';
import CourseStepContentPretest from 'pages/courses-module/course-step/main/content/pretest/CourseStepContentPretest';
import CourseStepPaywall from 'pages/courses-module/course-step/main/content/CourseStepPaywall';
import { useEffect, useState } from 'react';

/**
 * Discriminator of the course step type. Can only be used after the course
 * step has been loaded.
 */
export default function CourseStepContent() {
  const { currStep } = useCourseStep();
  const [userIsPremium] = useState(false);

  useEffect(() => {
    if (currStep?.paywall) {
      // todo: fetch user premium status
    }
  }, [currStep?.paywall]);

  if (!currStep) {
    unknownErrorNotification();
    return <></>;
  }

  if (currStep.paywall && !userIsPremium) {
    return <CourseStepPaywall />;
  }

  if (currStep.type === 'text') {
    return <CourseStepContentText text={currStep.text} />;
  }

  if (currStep.type === 'video') {
    return <CourseStepContentVideo video={currStep.video} />;
  }

  if (currStep.type === 'stream') {
    return <CourseStepContentVideo video={currStep.stream.source} />;
  }

  if (currStep.type === 'exercise') {
    return <CourseStepContentExercise assignmentId={currStep.assignment} />;
  }

  if (currStep.type === 'pretest') {
    return (
      <CourseStepContentPretest
        assignmentId={currStep.pretest.assignment.assignmentId}
        isSolved={currStep.pretest.assignment.isSolved}
        steps={currStep.pretest.steps}
        nextStep={currStep.pretest.nextStep}
      />
    );
  }

  return <></>;
}
