import { Button /*, Menu */ } from '@mantine/core';
// import RedCrossIcon from 'components/icons/RedCrossIcon';
// import ThreeDotsIcon from 'components/icons/ThreeDotsIcon';
import ThumbsUpIcon from 'components/icons/ThumbsUpIcon';
import { _t } from 'lang';
import { useNavigate, useParams } from 'react-router-dom';
import HomeworkDateTimeDisplay from 'pages/teacher-module/classroom/homework/card/HomeworkDateTimeDisplay';
import HomeworkTitle from 'pages/teacher-module/classroom/homework/card/HomeworkTitle';
import HomeworkRemainingTime from 'components/HomeworkRemainingTime';
import { GetClassroomHomeworkResponse } from 'api/actions/get-classroom-homework/get-classroom-homework-response';
import SubmittedCountStat from 'pages/teacher-module/classroom/homework/card/stats/SubmittedCountStat';

interface Props {
  homeworkId: string;
  isActive: boolean;
  assigned: string;
  deadline: string;
  name: string;
  exercises: GetClassroomHomeworkResponse['homework'][number]['exerciseSet']['exercises'];
  exercisesCount: number;
  submitted: {
    total: number;
    actual: number;
  };
  successRate: number;
}

/**
 * Teacher homework card
 */
export default function TeacherHomeworkCard({
  homeworkId,
  isActive,
  assigned,
  deadline,
  name,
  exercises,
  exercisesCount,
  submitted,
  successRate,
}: Props) {
  const { classroomId } = useParams();
  const navigate = useNavigate();

  return (
    <div className="flex flex-col rounded-[10px] bg-white pb-[29px] pl-[24px] pr-[24px] pt-[24px]">
      <div className="flex items-center justify-between">
        <div>
          <HomeworkDateTimeDisplay dateTime={new Date(assigned)} />
          <HomeworkTitle
            title={name}
            isActive={isActive}
            titleClassNames="text-[18px] font-semibold leading-[22px]"
            classNames="pt-[10px]"
          />
        </div>
        <div className="flex items-center gap-[16px]">
          <Button
            className="h-[42px] rounded-[7px] bg-green-primary-opacity-01 text-[12px] font-semibold leading-[15px] text-green-primary hover:bg-green-primary-opacity-02 active:bg-green-primary-opacity-02"
            onClick={() => navigate(`/teacher/classrooms/classroom/${classroomId}/homework/homework/${homeworkId}`)}
          >
            {_t('Homework detail')}
          </Button>
          {/* <Menu
            // width={200} --> width is now determined by the content
            position="bottom-end"
            offset={8}
            styles={{
              dropdown: {
                padding: '8px!important',
                borderRadius: '7px',
                ':hover': {
                  backgroundColor: 'white',
                },
              },
              item: {
                padding: '0!important',
              },
            }}
          >
            <Menu.Target>
              <div className="flex cursor-pointer items-center justify-center rounded-[7px] bg-bg-secondary-button-hover lg:h-[42px] lg:w-[42px]">
                <ThreeDotsIcon />
              </div>
            </Menu.Target>
            <Menu.Dropdown>
              {isActive && (
                <Menu.Item>
                  <div
                    className="bg flex items-center gap-[10px] pt-[9px] pb-[9px] pl-[14px] pr-[8px] hover:bg-bg-red-menu-item"
                    onClick={() => {
                      alert(_t('Not implemented!')); // TODO what should happen here?
                    }}
                  >
                    <RedCrossIcon />
                    <span className="text-[15px] font-medium leading-[15px] text-red">{_t('Cancel homework')}</span>
                  </div>
                </Menu.Item>
              )}
            </Menu.Dropdown>
          </Menu> */}
        </div>
      </div>
      <div className="flex-grow justify-between pt-[8px]">
        <div className="max-w-[426px] overflow-hidden text-[14px] font-medium leading-[24px] text-text-dark-2 lg:max-h-[72px]">
          {/* TODO the linear gradient `homework-fade` */}
          <ul className="list-inside list-disc pl-[0.25em]">
            {exercises.map((exercise) => (
              <li key={exercise.exerciseId}>{exercise.exerciseTitle}</li>
            ))}
          </ul>
        </div>
        <div className="flex justify-end gap-[32px] pt-[8px] xl:mt-[-36px]">
          <div className="flex items-end gap-[12px] text-[12px] leading-[15px] text-text-shadow">
            {/* <div className="flex items-center gap-[8px]">
              <span>{_t('Number of exercises')}</span>
              <div className="flex h-[20px] w-[20px] items-center justify-center">
                <div className="flex h-[14px] w-[14px] items-center justify-center">
                  <PaperIcon stroke="#353E45" />
                </div>
              </div>
              <span className="font-bold text-text-dark">{exercisesCount}</span>
            </div> */}
          </div>

          <SubmittedCountStat actual={submitted.actual} total={submitted.total} />

          {isActive ? (
            <div className="flex translate-y-[8px] items-end">
              <HomeworkRemainingTime
                assigned={new Date(assigned)}
                deadline={new Date(deadline)}
                shouldTranslate={true}
              />
            </div>
          ) : (
            successRate && (
              <div className="flex items-end gap-[10px] text-[12px] leading-[15px] text-text-shadow">
                <div>{_t('Total success rate')}</div>
                <div className="flex items-end gap-[7px]">
                  <ThumbsUpIcon />
                  <span className="font-bold text-text-dark">
                    {successRate !== undefined ? `${Math.round(successRate * 100)} %` : '-'}
                  </span>
                </div>
              </div>
            )
          )}
        </div>
      </div>
    </div>
  );
}
