interface Props {
  stroke?: string;
}

/**
 * Door Icon
 */
export default function DoorIcon({ stroke = '#353E45' }: Props) {
  return (
    <svg width="13" height="18" viewBox="0 0 13 18" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect x="1" y="1" width="11" height="16" rx="1" stroke={stroke} strokeWidth="2" />
      <line x1="11" y1="9" x2="9" y2="9" stroke={stroke} strokeWidth="2" strokeLinecap="round" />
    </svg>
  );
}
