import { AxiosInstance } from 'axios';
import { SweetPath } from 'sweet-path';
import dispatchAction from 'api/dispatch-action';

import { GetClassroomHomeworkDetailPathParameters } from 'api/actions/get-classroom-homework-detail/get-classroom-homework-detail-path-parameters';

import { GetClassroomHomeworkDetailResponse } from 'api/actions/get-classroom-homework-detail/get-classroom-homework-detail-response';

/**
 * The path of the GetClassroomHomeworkDetail action.
 */
export const getClassroomHomeworkDetailActionPath = new SweetPath<keyof GetClassroomHomeworkDetailPathParameters>(
  '/classroom/{classroomId}/homework/{homeworkId}',
  { path: '{param}' }
);

/**
 * Creates a contextualized GetClassroomHomeworkDetail actions.
 */
export default function createGetClassroomHomeworkDetailAction(axios: AxiosInstance) {
  return function getClassroomHomeworkDetailAction({
    parameters,
  }: {
    parameters: GetClassroomHomeworkDetailPathParameters;
  }) {
    const path = getClassroomHomeworkDetailActionPath.insert(parameters);

    return dispatchAction(() =>
      axios.get<GetClassroomHomeworkDetailResponse, GetClassroomHomeworkDetailResponse>(path)
    );
  };
}
