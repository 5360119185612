import BookIcon from 'components/icons/BookIcon';
import HomeIcon from 'components/icons/HomeIcon';
import PaperIcon from 'components/icons/PaperIcon';
import { _t, _tx, lang } from 'lang';
import StudentNavigationItem from 'layout/student-dashboard-layout/parts/sidebar/StudentNavigationItem';
import { STUDENT_DASHBOARD_PAGE_PATH, STUDENT_EXERCISES_PAGE_PATH } from 'routes/paths';
import NumberInCircle from 'components/NumberInCircle';
import useStudentDashboard from 'data/student-dashboard/use-student-dashboard';
import PenIcon from 'components/icons/PenIcon';
import { useParams } from 'react-router-dom';
import useCourse from 'data/course/use-course';
import { Progress } from '@mantine/core';
import MobileNavBar from 'layout/student-dashboard-layout/parts/sidebar/MobileNavBar';

/**
 * StudentSidebar
 */
export default function StudentSidebar() {
  const { getActiveHomework, getClassrooms } = useStudentDashboard();
  const activeHomeworkCount = getActiveHomework().length;

  const { sectionSlug, stepId } = useParams();
  const slug = 'priprava-na-prijimacky';

  const { getCourse } = useCourse();
  const course = getCourse({ slug });

  const linkCourse = `/course/${slug}`;
  const linkStep = sectionSlug && stepId ? `/${sectionSlug}/step/${stepId}` : '';
  const linkFull = `${linkCourse}${linkStep}`;

  const isInClassroom = getClassrooms().length > 0;

  return (
    <div>
      <MobileNavBar />
      <div className="fixed hidden bg-white shadow-3xl lg:flex lg:min-h-screen lg:w-[358px] lg:flex-col lg:p-[32px]">
        <div className="flex flex-col gap-[8px]">
          <StudentNavigationItem
            name={_t('Home')}
            icon={<HomeIcon stroke="#535F6A" />}
            activeIcon={<HomeIcon stroke="#353E45" />}
            to={STUDENT_DASHBOARD_PAGE_PATH}
          />
          {isInClassroom && (
            <StudentNavigationItem
              name={_tx('Homework', 'Homework', 2)}
              icon={<PaperIcon stroke="#535F6A" />}
              activeIcon={<PaperIcon stroke="#353E45" />}
              to="/student/homework/active"
              basePath="/student/homework"
              supplementaryItemInfo={
                activeHomeworkCount && activeHomeworkCount > 0 ? (
                  <NumberInCircle
                    value={activeHomeworkCount}
                    className="h-[20px] w-[20px] text-[12px] font-semibold leading-[15px]"
                  />
                ) : undefined
              }
            />
          )}
          <StudentNavigationItem
            name={_t('Exercises book')}
            icon={<BookIcon stroke="#535F6A" />}
            activeIcon={<BookIcon stroke="#353E45" />}
            to={STUDENT_EXERCISES_PAGE_PATH}
          />
          {lang === 'cs' && (
            <StudentNavigationItem
              name={
                <div className="mt-[10px] flex flex-col items-stretch justify-start">
                  <span className="tracking-tight">{_t('High school admissions')}</span>
                  <div className="pt-[8px]">
                    <Progress
                      size="xs"
                      radius="xl"
                      value={Math.round((course?.progress ?? 0) * 100)}
                      styles={{
                        bar: { backgroundColor: '#0AB892' },
                        root: { backgroundColor: '#D9E0E6' },
                      }}
                    />
                  </div>
                </div>
              }
              icon={<PenIcon stroke="#535F6A" />}
              activeIcon={<PenIcon stroke="#353E45" />}
              to={linkFull}
            />
          )}
        </div>
      </div>
    </div>
  );
}
