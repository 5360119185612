interface Props {
  children: JSX.Element | JSX.Element[];
  className?: string;
}

/**
 * Breadcrumbs component
 */
export default function Breadcrumbs({ children, className = '' }: Props) {
  return (
    <div
      className={`text-main-primary flex items-center gap-[21px] text-[14px] font-medium leading-[17px] [&>*]:after:ml-[8px] [&>*]:after:content-[''] [&>:last-child]:after:content-[''] ${className}`}
    >
      {children}
    </div>
  );
}
