import { Exercise } from 'api/actions/get-exercises/get-exercises-response';
import useApi from 'api/use-api';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import ExerciseExample from 'pages/private/all-exercises/ExerciseExample';
import { _t } from 'lang';

/**
 * Page which displays one example per exercise.
 */
export default function AllExercisesPage() {
  const { lang } = useParams();

  const { getAction } = useApi();
  const getExercisesAction = getAction('GetExercises');

  const [loading, setLoading] = useState(true);
  const [exercises, setExercises] = useState<Exercise[]>([]);

  useEffect(() => {
    setLoading(true);

    getExercisesAction({ query: { language: lang! } })
      .success(({ exercises }) => setExercises(exercises.sort((a, b) => a.exerciseId - b.exerciseId)))
      .complete(() => setLoading(false));
  }, [lang]);

  if (loading) {
    return <div>{_t('Loading ...')}</div>;
  }

  return (
    <div className="pb-[30px]">
      <h1 className="mb-[30px] mt-[20px] text-center font-bold">All Exercises</h1>
      <div className="ml-auto mr-auto grid max-w-[1440px] grid-cols-[repeat(auto-fill,604px)] items-stretch justify-center gap-[20px]">
        {exercises.map(({ exerciseId, title, description }) => (
          <div className="rounded-[10px] border-[1px] border-[#eee] shadow-md" key={exerciseId}>
            <div className="p-[16px]">
              <h2 className="mb-[8px] font-semibold">
                #{exerciseId} - {title}
              </h2>
              <p>{description}</p>
            </div>
            <div className="border-t-[1px] border-t-[#eee] p-[16px]">
              <ExerciseExample exerciseId={exerciseId} language={lang!} />
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}
