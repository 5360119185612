/**
 * Left arrow icon
 */
export default function LeftArrowIcon() {
  return (
    <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M14.6665 21.3333L10.0403 16.707C9.64975 16.3165 9.64975 15.6833 10.0403 15.2928L14.6665 10.6666"
        stroke="#738491"
        strokeWidth="2"
        strokeLinecap="round"
      />
      <line
        x1="1"
        y1="-1"
        x2="15"
        y2="-1"
        transform="matrix(1 -8.74229e-08 -8.74229e-08 -1 10 15)"
        stroke="#738491"
        strokeWidth="2"
        strokeLinecap="round"
      />
    </svg>
  );
}
