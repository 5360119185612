import BasicPageLayout from 'layout/basic-page-layout/BasicPageLayout';
import useApi from 'api/use-api';
import LandingPageContent from 'pages/presentation-module/landing/LandingPageContent';

/**
 * The landing page.
 */
export default function LandingPage() {
  const { ready } = useApi();

  return (
    <BasicPageLayout hasHeader hasFooter>
      {ready ? <LandingPageContent /> : <></>}
    </BasicPageLayout>
  );
}
