import { Alert, AlertProps } from '@mantine/core';

/**
 * The parameters of the component.
 */
interface Props extends AlertProps {
  type: 'info';
}

/**
 * Displays messages to the user.
 */
export default function Message({ type, className, children, ...otherProps }: Props) {
  const classes = type === 'info' ? 'bg-info-grey rounded-[10px] text-[14px] leading-[20px] font-semibold' : '';

  const childrenColor = type === 'info' ? '#738491' : '';

  return (
    <Alert
      className={`flex h-[72px] items-center pl-[27px] ${classes} ${className}`}
      styles={{
        message: { fontSize: '14px', fontWeight: 500, lineHeight: '20px', color: childrenColor },
      }}
      {...otherProps}
    >
      {children}
    </Alert>
  );
}
