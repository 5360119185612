import { noop } from 'lodash';
import { CONNECTION_ERROR, JSON_WEB_TOKEN_INVALID_ERROR } from 'api/errors/error-codes';
import { connectionErrorNotification, unknownErrorNotification } from 'api/notifications';

type SuccessHandler<TResponse> = (response: TResponse) => void;
type ErrorHandler = (error: Error) => void;
type CompleteHandler = () => void;
type ErrorCode = number | '*';
type ErrorHandlerMap = Map<ErrorCode, ErrorHandler[]>;

/**
 * Action with better error handling.
 */
export class DispatchedAction<TResponse> {
  /**
   * The success handler.
   */
  onSuccess: SuccessHandler<TResponse>[];

  /**
   * The error handlers map. The key is the error code (or null for all errors).
   */
  onError: ErrorHandlerMap;

  /**
   * The complete handler.
   */
  onComplete: CompleteHandler;

  /**
   * Adds a success handler.
   */
  success(onSuccess: SuccessHandler<TResponse>) {
    this.onSuccess.push(onSuccess);
    return this;
  }

  /**
   * Adds an error handler.
   */
  error(code: ErrorCode, onError: ErrorHandler) {
    if (!this.onError.has(code)) {
      this.onError.set(code, []);
    }

    this.onError.get(code)!.push(onError);

    return this;
  }

  /**
   * Adds a complete handler.
   */
  complete(onComplete: CompleteHandler) {
    this.onComplete = onComplete;
    return this;
  }

  /**
   * Handles the error.
   */
  _handleError(code: ErrorCode | null, error: Error): number {
    if (code && this.onError.has(code)) {
      const handlers = this.onError.get(code)!;
      handlers.forEach((handler) => handler(error));

      return handlers.length;
    }

    return 0;
  }

  /**
   * Creates a new instance of DispatchedAction.
   */
  constructor(action: () => Promise<TResponse>) {
    this.onSuccess = [];
    this.onError = new Map([[CONNECTION_ERROR, [connectionErrorNotification]]]);
    this.onComplete = noop;

    action()
      .then((response) => this.onSuccess.forEach((handler) => handler(response)))
      .catch((error) => {
        let handled = 0;
        const code = error?.code ?? null;

        handled += this._handleError(code, error);
        handled += this._handleError('*', error);

        // Fallback - show unknown error notification. If the error is caused
        // by invalid JSON Web Token, do not show the notification. This will
        // be handled by the Axios connector in api-provider.tsx.
        if (handled === 0 && code !== JSON_WEB_TOKEN_INVALID_ERROR) {
          unknownErrorNotification();
        }
      })
      .finally(() => this.onComplete());
  }
}

/**
 * Dispatches an action.
 */
export default function dispatchAction<TResponse>(action: () => Promise<TResponse>) {
  return new DispatchedAction(action);
}
