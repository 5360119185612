import { DispatchedAction } from 'api/dispatch-action';
import useApi from 'api/use-api';
import CourseContext, { Course, CourseId, CourseIdOrSlug, CoursePlan, CourseSlug } from 'data/course/course-context';
import { useEffect, useState } from 'react';

/**
 * Provides the course data.
 */
export default function CourseProvider({ children }: { children: React.ReactNode }) {
  const [courses, setCourses] = useState<Array<Course>>([]);
  const [coursePlans, setCoursePlans] = useState<Map<CourseIdOrSlug, CoursePlan>>(new Map());

  const { getAction, ready } = useApi();
  const getCoursesAction = getAction('GetCourses');
  const getCoursePlanAction = getAction('GetCoursePlan');
  const updateCourseProgressAction = getAction('UpdateCourseProgress');
  const enrollInCourseAction = getAction('EnrollInCourse');

  /**
   * Fetches data about courses.
   */
  function fetchCourses(): void {
    getCoursesAction().success(({ courses }) => setCourses(courses));
  }

  /**
   * Fetches the plan of the specified course.
   */
  function fetchCoursePlan({ courseIdOrSlug }: { courseIdOrSlug: CourseIdOrSlug }): void {
    getCoursePlanAction({ parameters: { courseIdOrSlug } }).success((coursePlan) => {
      setCoursePlans((oldMap) => {
        const newMap = new Map(oldMap);

        newMap.set(coursePlan.courseId, coursePlan);
        newMap.set(coursePlan.slug, coursePlan);

        return newMap;
      });
    });
  }

  /**
   * Returns the only course with the specified id or slug.
   */
  function getCourse({ courseId, slug }: { courseId?: CourseId; slug?: CourseSlug }): Course | undefined {
    return courses.find((course) => course.courseId === courseId || course.slug === slug);
  }

  /**
   * Returns the plan for the specified course.
   */
  function getCoursePlan({ courseIdOrSlug }: { courseIdOrSlug: CourseIdOrSlug }): CoursePlan | undefined {
    return coursePlans.get(courseIdOrSlug);
  }

  /**
   * Returns the progress of the specified course step.
   */
  function getCourseStepProgress({
    courseIdOrSlug,
    stepId,
  }: {
    courseIdOrSlug: CourseIdOrSlug;
    stepId: number;
  }): number {
    const coursePlan = getCoursePlan({ courseIdOrSlug });

    let progress = 0;

    if (coursePlan) {
      for (const section of coursePlan.sections) {
        for (const step of section.steps) {
          if (step.stepId === stepId) {
            progress = step.progress;
          }
        }
      }
    }

    return progress;
  }

  /**
   * Updates the progress of a course.
   */
  function updateCourseProgress({
    courseId,
    steps,
  }: {
    courseId: CourseId;
    steps: { stepId: number; progress: number }[];
  }): DispatchedAction<void> {
    return updateCourseProgressAction({ parameters: { courseId }, payload: { steps } }).success(() => {
      // Fetch dependencies asynchronously.
      fetchCourses();
      fetchCoursePlan({ courseIdOrSlug: courseId });
    });
  }

  /**
   * Enrolls the user in the specified course.
   */
  function enrollInCourse({ courseId }: { courseId: CourseId }): DispatchedAction<void> {
    return enrollInCourseAction({ parameters: { courseId } }).success(() => {
      // Fetch dependencies asynchronously.
      fetchCourses();
    });
  }

  // Run this when session is ready.
  useEffect(() => {
    if (ready) {
      fetchCourses();
    }
  }, [ready]);

  return (
    <CourseContext.Provider
      value={{
        fetchCourses,
        fetchCoursePlan,
        getCourse,
        getCoursePlan,
        updateCourseProgress,
        enrollInCourse,
        getCourseStepProgress,
      }}
    >
      {children}
    </CourseContext.Provider>
  );
}
