import { isEmpty } from 'lodash';
import { Link, useParams } from 'react-router-dom';

interface Props {
  leftTitle: string | JSX.Element;
  rightTitle: string;
  leftContent: JSX.Element[] | undefined;
  rightContent: JSX.Element[] | undefined;
  leftNoContentMessage: string | JSX.Element;
  rightNoContentMessage: string | JSX.Element;
  contentGap?: string;
  leftContentGap?: string;
  rightContentGap?: string;
  leftPath: string;
  rightPath: string;
}

/**
 * Displays homework.
 */
export default function HomeworkDisplay({
  leftTitle,
  rightTitle,
  leftContent,
  rightContent,
  leftNoContentMessage,
  rightNoContentMessage,
  contentGap = 'gap-[16px]',
  leftContentGap = contentGap,
  rightContentGap = contentGap,
  leftPath,
  rightPath,
}: Props) {
  const { tab: activeTab } = useParams();
  const activeClasses = 'bg-white font-semibold text-text-dark';

  return (
    <div>
      <div className="mb-[20px] flex h-[42px] items-center rounded-[7px] border-2 border-input-outline-shadow text-[14px] font-medium leading-[17px] text-text-shadow">
        <Link
          className={`flex h-full w-[50%] cursor-pointer items-center justify-center rounded-l-[7px] pl-[12px] pr-[12px] ${
            activeTab === 'active' ? activeClasses : ''
          }`}
          to={leftPath}
        >
          {leftTitle}
        </Link>
        <Link
          className={`flex h-full w-[50%] cursor-pointer items-center justify-center rounded-r-[7px] border-l-2 border-l-input-outline-shadow pl-[12px] pr-[12px] text-center ${
            activeTab === 'all' ? activeClasses : ''
          }`}
          to={rightPath}
        >
          {rightTitle}
        </Link>
      </div>
      <div>
        {activeTab === 'active' ? (
          !isEmpty(leftContent) ? (
            <div className={`flex flex-col ${leftContentGap}`}>{leftContent}</div>
          ) : (
            leftNoContentMessage
          )
        ) : !isEmpty(rightContent) ? (
          <div className={`flex flex-col ${rightContentGap}`}>{rightContent}</div>
        ) : (
          rightNoContentMessage
        )}
      </div>
    </div>
  );
}
