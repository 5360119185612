import LoginForm from 'components/forms/LoginForm';
import { _t } from 'lang';
import Emoji from 'components/Emoji';
import { Link, useLocation } from 'react-router-dom';
import { REGISTER_PAGE_PATH } from 'routes/paths';
import FormPageLayout from 'layout/form-page-layout/FormPageLayout';
import Alert from 'components/Alert';
import CorrectIcon from 'components/icons/CorrectIcon';

/**
 * The login page.
 *
 * @see https://www.notion.so/Login-f4dbd3e4494742c6b171678edf188d40
 */
export default function LoginPage() {
  const location = useLocation();

  return (
    <FormPageLayout>
      <div className="block p-[24px] pt-0 lg:ml-[50%] lg:flex lg:min-h-screen lg:items-center lg:pl-[130px] lg:pt-[24px]">
        <div className="lg:w-[370px]">
          <Alert
            icon={<CorrectIcon />}
            type="success"
            className={
              location.state && location.state.displayMessage
                ? 'mb-[7px] mt-[24px] lg:absolute lg:top-[80px] lg:mt-0 lg:w-[370px]'
                : 'hidden'
            }
          >
            <span className="inline-block translate-y-[1px] text-[14px] font-medium leading-[20px]">
              {_t('You have successfully reset your password. You can log in.')}
            </span>
          </Alert>
          <p className="pb-[32px] pt-[37px] text-[24px] font-semibold leading-[29px]">
            <span>{_t('Login')}</span> <Emoji symbol="👋" label={_t('waving-hand')} />
          </p>
          <LoginForm />
          <p className="pt-[32px] text-center text-[14px] leading-[20px] text-text-footer">
            {_t('Do not have an account yet?')}{' '}
            <Link to={REGISTER_PAGE_PATH} className="font-semibold text-green-primary">
              {_t('Register')}
            </Link>
          </p>
        </div>
      </div>
    </FormPageLayout>
  );
}
