import { AxiosInstance } from 'axios';
import { SweetPath } from 'sweet-path';
import dispatchAction from 'api/dispatch-action';

import { SubmitHomeworkAttachmentPayload } from 'api/actions/submit-homework-attachment/submit-homework-attachment-payload';
import { SubmitHomeworkAttachmentPathParameters } from 'api/actions/submit-homework-attachment/submit-homework-attachment-path-parameters';

import { SubmitHomeworkAttachmentResponse } from 'api/actions/submit-homework-attachment/submit-homework-attachment-response';

/**
 * The path of the SubmitHomeworkAttachment action.
 */
export const submitHomeworkAttachmentActionPath = new SweetPath<keyof SubmitHomeworkAttachmentPathParameters>(
  '/student/homework/{homeworkId}/attachment',
  { path: '{param}' }
);

/**
 * Creates a contextualized SubmitHomeworkAttachment actions.
 */
export default function createSubmitHomeworkAttachmentAction(axios: AxiosInstance) {
  return function submitHomeworkAttachmentAction({
    parameters,
    payload,
  }: {
    parameters: SubmitHomeworkAttachmentPathParameters;
    payload: SubmitHomeworkAttachmentPayload;
  }) {
    const path = submitHomeworkAttachmentActionPath.insert(parameters);

    return dispatchAction(() =>
      axios.post<SubmitHomeworkAttachmentResponse, SubmitHomeworkAttachmentResponse, SubmitHomeworkAttachmentPayload>(
        path,
        payload
      )
    );
  };
}
