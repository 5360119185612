/**
 * The pack/unpack icon.
 */
export default function PackUnpackIcon() {
  return (
    <svg width="6" height="10" viewBox="0 0 6 10" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M1.00003 9L4.29292 5.70711C4.68345 5.31658 4.68345 4.68342 4.29292 4.29289L1.00003 1"
        stroke="#353E45"
        strokeWidth="2"
        strokeLinecap="round"
      />
    </svg>
  );
}
