import { AxiosInstance } from 'axios';
import { SweetPath } from 'sweet-path';
import dispatchAction from 'api/dispatch-action';

import { ResetPasswordValidatePayload } from 'api/actions/reset-password-validate/reset-password-validate-payload';
import { ResetPasswordValidatePathParameters } from 'api/actions/reset-password-validate/reset-password-validate-path-parameters';

import { ResetPasswordValidateResponse } from 'api/actions/reset-password-validate/reset-password-validate-response';

/**
 * The path of the ResetPasswordValidate action.
 */
export const resetPasswordValidateActionPath = new SweetPath<keyof ResetPasswordValidatePathParameters>(
  '/user/password/reset/validate',
  { path: '{param}' }
);

/**
 * Creates a contextualized ResetPasswordValidate actions.
 */
export default function createResetPasswordValidateAction(axios: AxiosInstance) {
  return function resetPasswordValidateAction({ payload }: { payload: ResetPasswordValidatePayload }) {
    const path = resetPasswordValidateActionPath.original;

    return dispatchAction(() =>
      axios.post<ResetPasswordValidateResponse, ResetPasswordValidateResponse, ResetPasswordValidatePayload>(
        path,
        payload
      )
    );
  };
}
