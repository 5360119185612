import { AxiosInstance } from 'axios';
import { SweetPath } from 'sweet-path';
import dispatchAction from 'api/dispatch-action';

import { UpdateUserProfilePayload } from 'api/actions/update-user-profile/update-user-profile-payload';
import { UpdateUserProfilePathParameters } from 'api/actions/update-user-profile/update-user-profile-path-parameters';

import { UpdateUserProfileResponse } from 'api/actions/update-user-profile/update-user-profile-response';

/**
 * The path of the UpdateUserProfile action.
 */
export const updateUserProfileActionPath = new SweetPath<keyof UpdateUserProfilePathParameters>('/user/profile', {
  path: '{param}',
});

/**
 * Creates a contextualized UpdateUserProfile actions.
 */
export default function createUpdateUserProfileAction(axios: AxiosInstance) {
  return function updateUserProfileAction({ payload }: { payload: UpdateUserProfilePayload }) {
    const path = updateUserProfileActionPath.original;

    return dispatchAction(() =>
      axios.put<UpdateUserProfileResponse, UpdateUserProfileResponse, UpdateUserProfilePayload>(path, payload)
    );
  };
}
