import { AxiosInstance } from 'axios';
import { SweetPath } from 'sweet-path';
import dispatchAction from 'api/dispatch-action';

import { RefreshClassroomInviteCodePathParameters } from 'api/actions/refresh-classroom-invite-code/refresh-classroom-invite-code-path-parameters';

import { RefreshClassroomInviteCodeResponse } from 'api/actions/refresh-classroom-invite-code/refresh-classroom-invite-code-response';

/**
 * The path of the RefreshClassroomInviteCode action.
 */
export const refreshClassroomInviteCodeActionPath = new SweetPath<keyof RefreshClassroomInviteCodePathParameters>(
  '/classroom/{classroomId}/invite-code/refresh',
  { path: '{param}' }
);

/**
 * Creates a contextualized RefreshClassroomInviteCode actions.
 */
export default function createRefreshClassroomInviteCodeAction(axios: AxiosInstance) {
  return function refreshClassroomInviteCodeAction({
    parameters,
  }: {
    parameters: RefreshClassroomInviteCodePathParameters;
  }) {
    const path = refreshClassroomInviteCodeActionPath.insert(parameters);

    return dispatchAction(() =>
      axios.put<RefreshClassroomInviteCodeResponse, RefreshClassroomInviteCodeResponse>(path)
    );
  };
}
