import CancelIcon from 'components/icons/CancelIcon';
import ZvedavaMyslIcon from 'components/icons/ZvedavaMyslIcon';
import Clickable from 'components/misc/Clickable';
import { Link, useNavigate } from 'react-router-dom';
import { HOME_PAGE_PATH } from 'routes/paths';

interface Props {
  children: JSX.Element;
  hideCloseButton?: boolean;
}

/**
 * Page with a form on the right side and a background image on the left side.
 */
export default function FormPageLayout({ children, hideCloseButton = false }: Props) {
  const navigate = useNavigate();

  return (
    <div>
      {/* Header - mobile */}
      <div className="flex h-[66px] items-center justify-between bg-white pl-[16px] pr-[12px] shadow-3xl lg:hidden">
        <Link to={HOME_PAGE_PATH}>
          <ZvedavaMyslIcon />
        </Link>
        {!hideCloseButton && (
          <Link to={HOME_PAGE_PATH}>
            <CancelIcon />
          </Link>
        )}
      </div>

      {/* Header - desktop */}
      {!hideCloseButton && (
        <div className="hidden lg:absolute lg:right-[32px] lg:top-[24px] lg:block">
          <Clickable onClick={() => navigate(-1)}>
            <CancelIcon />
          </Clickable>
        </div>
      )}

      {/* Contents */}
      <div className="lg:flex">
        <div className="hidden h-screen items-center justify-center bg-white shadow-3xl lg:fixed lg:left-0 lg:top-0 lg:flex lg:w-[50%] lg:bg-math-symbols lg:bg-center lg:bg-no-repeat">
          <ZvedavaMyslIcon className="h-[65px] w-auto translate-x-[-11px] translate-y-[3px]" />
        </div>
        {children}
      </div>
    </div>
  );
}
