import { Modal } from '@mantine/core';
import CrossIcon from 'components/icons/CrossIcon';
import InfoIcon from 'components/icons/InfoIcon';
import RefreshIcon from 'components/icons/RefreshIcon';
import Clickable from 'components/misc/Clickable';
import useClassrooms from 'data/classrooms/use-classrooms';
import { _t } from 'lang';
import { noop } from 'lodash';
import CopyShareLink from 'pages/teacher-module/classrooms/modal/CopyShareLink';
import { Dispatch, SetStateAction, useState } from 'react';

interface Props {
  opened: boolean;
  setOpened: Dispatch<SetStateAction<boolean>>;
  classroomId: string;
}

/**
 * Invite student to the classroom modal.
 *
 * @see https://www.figma.com/file/SjggpqHVlWxBKa2cIG349A/Math-Exercises?node-id=590-835&t=RiotZo8eDxHvOmBr-0
 */
export default function InviteStudentModal({ opened, setOpened, classroomId }: Props) {
  const { getClassroom, fetchNewClassroomInviteCode } = useClassrooms();
  const [fill, setFill] = useState('#738491');
  const classroom = getClassroom({ classroomId: classroomId! });
  let inviteCode = classroom?.inviteCode;

  /**
   * Generates a new invite code and saves it to the component's state.
   */
  function generateNewInviteCode() {
    fetchNewClassroomInviteCode({ classroomId: classroomId! });
  }

  return (
    <Modal
      opened={opened}
      onClose={noop} // this is a required prop
      centered
      size="auto"
      withCloseButton={false}
      radius={10}
      styles={{
        root: {
          padding: '0!important',
        },
        body: {
          padding: '0!important',
        },
        modal: {
          padding: '0!important',
        },
      }}
    >
      <div>
        <div className="flex flex-row-reverse justify-items-end rounded-[10px_10px_0_0] bg-bg-shadow bg-invite-student-modal bg-center bg-no-repeat lg:h-[242px] lg:w-[570px]">
          <div className="pr-[31px] pt-[31px]">
            <Clickable
              onClick={() => setOpened(false)}
              className="flex h-[42px] w-[42px] items-center justify-center rounded-[7px] p-[8px] hover:bg-bg-secondary-button-hover"
              onMouseEnter={() => {
                setFill('#353E45');
              }}
              onMouseLeave={() => {
                setFill('#738491');
              }}
            >
              <CrossIcon fill={fill} />
            </Clickable>
          </div>
        </div>
        <div className="ml-auto mr-auto lg:w-[442px] lg:pb-[56px] lg:pt-[32px]">
          <div className="text-[24px] font-semibold leading-[29px]">{_t('Add students to the classroom')}</div>
          <div className="pt-[8px] text-[14px] leading-[22px]">
            {_t('Share the invite code with the students who should join the classroom.')}{' '}
            {_t('The students with this code will register and will be added automatically to the classroom.')}
          </div>
          <CopyShareLink link={inviteCode || ''} classNames="lg:pt-[16px]" />
          <div className="lg:pt-[16px]">
            <Clickable
              onClick={generateNewInviteCode}
              className="inline-flex items-center gap-[6px] text-[14px] font-medium leading-[20px] text-green-primary underline"
            >
              {_t('Generate a new invite code')}
              <RefreshIcon />
            </Clickable>
          </div>
          <div className="flex items-center gap-[12px] pt-[12px] text-[12px] leading-[17px] text-text-shadow">
            <div className="shrink-0">
              <InfoIcon />
            </div>
            {_t('After generating a new invite code, the old one will no longer be functional.')}{' '}
            {_t('The students who have already joined the classroom will not be affected by the change.')}
          </div>
        </div>
      </div>
    </Modal>
  );
}
