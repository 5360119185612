/**
 * The icon with the letter i.
 */
export default function InfoIcon() {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx="12" cy="12" r="12" fill="#F3F6F8" />
      <path
        d="M9.3913 17.447C9.3913 17.288 9.43045 17.0368 9.50876 16.6933C9.55444 16.4962 9.62296 16.2354 9.71432 15.9111L10.9183 11.5229C10.9314 11.472 10.9412 11.4212 10.9477 11.3703C10.9608 11.3194 10.9673 11.2717 10.9673 11.2272C10.9673 10.9664 10.8824 10.8043 10.7128 10.7407C10.5431 10.6771 10.2331 10.6357 9.78285 10.6167V10.1015C10.1613 10.0761 10.7095 10.0252 11.4274 9.94889C12.1517 9.86622 12.664 9.79626 12.9642 9.73902L13.8256 9.57685L12.2692 15.1384C12.1387 15.6154 12.0506 15.9461 12.0049 16.1305C11.8874 16.6011 11.8287 16.9064 11.8287 17.0463C11.8287 17.1862 11.8613 17.2784 11.9266 17.323C11.9918 17.3611 12.0636 17.3802 12.1419 17.3802C12.3442 17.3802 12.5955 17.2117 12.8957 16.8746C13.1958 16.5375 13.4601 16.1846 13.6885 15.8157L14.129 16.0924C13.4765 17.0272 12.9772 17.6568 12.6314 17.9812C12.0636 18.5154 11.4567 18.7825 10.8107 18.7825C10.4452 18.7825 10.1157 18.6744 9.822 18.4582C9.53486 18.2356 9.3913 17.8985 9.3913 17.447ZM13.2676 5.21729C13.6396 5.21729 13.9561 5.34448 14.2171 5.59887C14.4782 5.85325 14.6087 6.1617 14.6087 6.5242C14.6087 6.8867 14.4782 7.19833 14.2171 7.45908C13.9561 7.71346 13.6396 7.84066 13.2676 7.84066C12.8957 7.84066 12.5759 7.71346 12.3083 7.45908C12.0473 7.19833 11.9168 6.8867 11.9168 6.5242C11.9168 6.1617 12.0473 5.85325 12.3083 5.59887C12.5759 5.34448 12.8957 5.21729 13.2676 5.21729Z"
        fill="#738491"
      />
    </svg>
  );
}
