import { AxiosInstance } from 'axios';
import { SweetPath } from 'sweet-path';
import dispatchAction from 'api/dispatch-action';

import { LoginPayload } from 'api/actions/login/login-payload';
import { LoginPathParameters } from 'api/actions/login/login-path-parameters';

import { LoginResponse } from 'api/actions/login/login-response';

/**
 * The path of the Login action.
 */
export const loginActionPath = new SweetPath<keyof LoginPathParameters>('/user/login', { path: '{param}' });

/**
 * Creates a contextualized Login actions.
 */
export default function createLoginAction(axios: AxiosInstance) {
  return function loginAction({ payload }: { payload: LoginPayload }) {
    const path = loginActionPath.original;

    return dispatchAction(() => axios.post<LoginResponse, LoginResponse, LoginPayload>(path, payload));
  };
}
