/**
 * Waiting Icon
 */
export default function WaitingIcon() {
  return (
    <img
      src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAwAAAAMCAYAAABWdVznAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAHESURBVHgBXVE9bxNBEJ2Z3fvwGRzbyDJIQVgKFQUlBRIFEjQgpYO/AE2CUiSiQbaUgEACKZifkA5RUFCGmgYJfgCIQAMFwY4w59u73RnmJELhKXal3Xlv3nuD8K+Gw3FrFsldEbgKCNdbfpIeHIAdDIBHo1F53If1sTYcLydWXgnIir4kApjOemnTf56mncwkvrSmE08PFeipBsSW9wSgD4iVcnhlkcn3DibENpQmsplPi6S/rACie9u7q8raA8JS5Thgfhw8918Ob1Uh7TqTRbFQmrhq3pyU7Z6NkW4ruxORChF2nj3Y2Kun7o426itfezTOIw9ta2JTNnybGOQcC8/rTxMVr2Ghrpw//YtsyKpQNawqqT04ApzrlBnTGV4E7O9PCIM5SapLUG2hyHtNp9A7Z3d0YxGwdLYYMGBGBjPBUFpG8wGFVzSYioBvbj18/jOrJu8Ou12hKV9khAuI7DyzT6j51VJSvAEXXVZJLY3VacPqb9u9Fh2FGSLlSJgL62aQ5k/u3/lGTzc3/0ja2AbGHyrNo0BQY0xamprhIDEyuCVv3/7f9HFt7Ywvqet62qk6DGT5BNZ8cSf444v1dVf3/AW6fcvByL+MJQAAAABJRU5ErkJggg=="
      alt="waiting"
    />
  );
}
