import Avatar from 'components/Avatar';
import { Avatar as MantineAvatar, Tooltip } from '@mantine/core';

interface Props {
  persons: Array<{ name: string; avatar?: string }>;
  max?: number;
  overlap?: number;
  avatarSize?: number;
  className?: string;
}

/**
 * User View component.
 */
export default function PersonView({ persons, max = 3, overlap = 23, avatarSize = 60, className = '' }: Props) {
  const gridStyle = {
    gridTemplateColumns: `repeat(${Math.min(max + 1, persons.length) + 1}, ${avatarSize - overlap}px)`,
    gridTemplateRows: `${avatarSize}px`,
  };

  return (
    <div className={`grid items-center ${className}`} style={gridStyle}>
      {persons.slice(0, max).map(({ name, avatar }, i) => {
        return (
          <Tooltip withArrow key={i} label={name}>
            <div style={{ width: avatarSize, height: avatarSize }}>
              {avatar ? (
                <MantineAvatar radius={avatarSize} src={avatar} size={avatarSize} />
              ) : (
                <Avatar label={name} size={avatarSize} />
              )}
            </div>
          </Tooltip>
        );
      })}
    </div>
  );
}
