/**
 * Computes the start time of the video in seconds.
 */
export default function computeStartTime(duration: number, progress: number): number {
  if (progress <= 0.05 || progress >= 1) {
    return 0;
  }

  return Math.floor(Math.max(0, duration * progress));
}
