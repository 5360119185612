import { _t } from 'lang';

/**
 * EU disclaimer
 */
export default function EuropeanUnionDisclaimer() {
  return (
    <div className="text-[12px] font-medium leading-[20px] text-text-shadow">
      <div className="flex flex-wrap gap-[32px]">
        <img src="/img/eu-logo.svg" alt={_t('European union logo')} />
        <img src="/img/matfyz-black.png" className="h-[41px] opacity-75" alt={_t('MatFyz logo')} />
      </div>
      <div className="max-w-[327px] pt-[32px] lg:max-w-[470px]">{_t('EuropeanUnionDisclaimer.Text')}</div>
    </div>
  );
}
