import ClassroomStatsCard from 'components/ClassroomStatsCard';
import { Link } from 'react-router-dom';
import NumberInCircle from 'components/NumberInCircle';
import AssignedAssignmentsIcon from 'components/icons/AssignedAssignmentsIcon';
import NonSubmittedAssignmentsIcon from 'components/icons/NonSubmittedAssignmentsIcon';
import ThumbsUpLargeIcon from 'components/icons/ThumbsUpLargeIcon';
import { Carousel } from '@mantine/carousel';
import { _t, _tx } from 'lang';
import Message from 'components/Message';
import IIcon from 'components/icons/IIcon';
import { isEmpty } from 'lodash';
import sprintf from 'lang/sprintf';

interface Props {
  activeHomework?: JSX.Element[];
  finishedHomework?: JSX.Element[];
  successRate?: number;
  numberOfNotSubmitted?: number;
}

/**
 * The content of the student dashboard page.
 */
export default function StudentDashboardContent({
  activeHomework,
  finishedHomework,
  successRate,
  numberOfNotSubmitted,
}: Props) {
  const allHomeworkTitle = _t('Number of your homework');
  const allHomeworkCount = (finishedHomework?.length ?? 0) + (activeHomework?.length ?? 0);
  const allHomeworkText = sprintf(_tx('%d homework', '%d homework', allHomeworkCount), allHomeworkCount);

  const successRateTitle = _tx('Success rate of your homework', 'Success rate of your homework', 2);
  const successRateText = successRate !== undefined ? `${Math.round(successRate * 100)} %` : '-';

  const numberOfNotSubmittedTitle = _tx('Number of non-submitted homework', 'Number of non-submitted homework', 2);
  const numberOfNotSubmittedText = numberOfNotSubmitted
    ? sprintf(_tx('%d homework', '%d homework', numberOfNotSubmitted), numberOfNotSubmitted)
    : '-';

  return (
    <div>
      <div className="pt-[16px] lg:pt-[24px]">
        <div className="relative left-1/2 right-1/2 mx-[-50vw] w-screen lg:hidden">
          <Carousel
            withControls={false}
            align="start"
            withIndicators={true}
            slideGap="xs"
            includeGapInSize={true}
            slideSize={327}
            styles={{
              indicator: {
                height: '8px!important',
                width: '8px!important',
                backgroundColor: '#D9E0E6!important',
                '&[data-active]': {
                  backgroundColor: '#0AB892!important',
                },
              },
              indicators: {
                position: 'relative',
                top: '12px',
              },
            }}
          >
            <Carousel.Slide>
              <div className="px-[24px]">
                <ClassroomStatsCard
                  className="min-h-[103px] w-[calc(100vw-48px)]"
                  icon={<AssignedAssignmentsIcon />}
                  title={allHomeworkTitle}
                  value={allHomeworkText}
                />
              </div>
            </Carousel.Slide>
            <Carousel.Slide>
              <div className="px-[24px]">
                <ClassroomStatsCard
                  className="min-h-[103px] w-[calc(100vw-48px)]"
                  icon={<ThumbsUpLargeIcon />}
                  title={successRateTitle}
                  value={successRateText}
                />
              </div>
            </Carousel.Slide>
            <Carousel.Slide>
              <div className="px-[24px]">
                <ClassroomStatsCard
                  className="min-h-[103px] w-[calc(100vw-48px)]"
                  icon={<NonSubmittedAssignmentsIcon />}
                  title={numberOfNotSubmittedTitle}
                  value={numberOfNotSubmittedText}
                />
              </div>
            </Carousel.Slide>
          </Carousel>
        </div>
        <div className="hidden gap-[16px] lg:flex">
          <ClassroomStatsCard
            className="min-h-[86px] w-[33%]"
            icon={<AssignedAssignmentsIcon />}
            title={allHomeworkTitle}
            value={allHomeworkText}
          />
          <ClassroomStatsCard
            className="min-h-[86px] w-[33%]"
            icon={<ThumbsUpLargeIcon />}
            title={successRateTitle}
            value={successRateText}
          />
          <ClassroomStatsCard
            className="min-h-[86px] w-[33%]"
            icon={<NonSubmittedAssignmentsIcon />}
            title={numberOfNotSubmittedTitle}
            value={numberOfNotSubmittedText}
          />
        </div>
      </div>
      <div className="pt-[42px] lg:pt-[56px]">
        <div className="flex items-center gap-[8px] text-[20px] font-semibold leading-[24px]">
          <div>{_tx('New homework', 'New homework', 2)}</div>
          {activeHomework && activeHomework.length > 0 && (
            <NumberInCircle
              value={activeHomework.length}
              className="h-[20px] w-[20px] text-[12px] font-semibold leading-[15px]"
            />
          )}
        </div>
        <div className="flex flex-col gap-[16px] pt-[16px] lg:pt-[20px]">
          {!isEmpty(activeHomework) ? (
            activeHomework
          ) : (
            <Message
              type="info"
              children={
                <div className="flex items-center gap-[11px]">
                  <div className="h-[18px] w-[18px]">
                    <IIcon />
                  </div>
                  <div>{_t('You have no homework. You can rest. 😊')}</div>
                </div>
              }
            />
          )}
        </div>
      </div>
      <div className="pt-[32px] lg:pt-[56px]">
        <div className="flex items-baseline justify-between">
          <div className="text-[20px] font-semibold leading-[24px]">
            {_tx('Results of my homework', 'Results of my homework', 2)}
          </div>
          <Link
            to="/student/homework/all"
            className="hidden text-[14px] font-medium leading-[17px] text-text-shadow lg:block"
          >
            {_t('Display all')}
          </Link>
        </div>
        <div className="flex flex-col gap-[8px] pt-[16px] lg:pt-[20px]">
          {!isEmpty(finishedHomework) ? (
            finishedHomework
          ) : (
            <Message
              type="info"
              children={
                <div className="flex items-center gap-[11px]">
                  <div className="h-[18px] w-[18px]">
                    <IIcon />
                  </div>
                  <div>{_t("You haven't submitted any homework yet.")}</div>
                </div>
              }
            />
          )}
        </div>
      </div>
    </div>
  );
}
