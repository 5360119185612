import { _t } from 'lang';
import sprintf from 'lang/sprintf';

/**
 * Returns the name of the grade.
 */
export default function getGradeName(grade: number) {
  switch (grade) {
    case 1:
      return _t('1st grade');
    case 2:
      return _t('2nd grade');
    case 3:
      return _t('3rd grade');
    default:
      // Translators: %d is the grade number. Only applies to 4th grade and above.
      return sprintf(_t('%dth grade'), grade);
  }
}
