import { Button } from '@mantine/core';
import { useForm } from '@mantine/form';
import { ResetPasswordRequestPayload } from 'api/actions/reset-password-request/reset-password-request-payload';
import useApi from 'api/use-api';
import createValidator from 'components/forms/validators/create-validator';
import emailFormat from 'components/forms/validators/rules/rule-email-format';
import required from 'components/forms/validators/rules/rule-required';
import { _t } from 'lang';
import TextInput from 'components/forms/inputs/TextInput';
import { EMAIL_DOES_NOT_EXIST_ERROR } from 'api/errors/error-codes';
import { useState } from 'react';

/**
 * The type of the reset password form data.
 */
type FormData = ResetPasswordRequestPayload;

interface Props {
  onSuccess: Function;
}

/**
 * The reset password form.
 */
export default function ResetPasswordForm({ onSuccess }: Props) {
  const [loading, setLoading] = useState(false);
  const [hadSuccess, setHadSuccess] = useState(false);
  const { getAction } = useApi();
  const resetPasswordRequest = getAction('ResetPasswordRequest');

  /**
   * The form data.
   */
  const form = useForm<FormData>({
    initialValues: {
      email: '',
    },

    validate: {
      email: createValidator([required, emailFormat]),
    },
  });

  /**
   * Is executed when the form is submitted with valid data.
   */
  function submitForm({ email }: FormData) {
    if (!loading) {
      setLoading(true);

      resetPasswordRequest({ payload: { email } })
        .error(EMAIL_DOES_NOT_EXIST_ERROR, () =>
          form.setFieldError('email', _t('No account with this email address exists.'))
        )
        .success(() => {
          setHadSuccess(true);
          onSuccess();
        })
        .complete(() => setLoading(false));
    }
  }

  return (
    <form onSubmit={form.onSubmit(submitForm)}>
      <TextInput
        className="text-[14px] font-medium leading-[17px] text-text-dark"
        withAsterisk
        label={_t('Email')}
        placeholder={_t('Your email')}
        isValid={form.isValid('email')}
        {...form.getInputProps('email')}
      />

      <div className="pt-[24px]">
        <Button
          disabled={loading || hadSuccess}
          className="h-[56px] bg-green-primary text-[18px] font-semibold leading-[22px] hover:bg-green-dark active:bg-green-dark"
          fullWidth
          type="submit"
          radius={10}
        >
          {_t('Send')}
        </Button>
      </div>
    </form>
  );
}
