import StudentDashboardLayout from 'layout/student-dashboard-layout/StudentDashboardLayout';
import StudentChangePasswordContent from 'pages/student-module/profile/StudentChangePasswordContent';

/**
 * The page where the student can change their password.
 */
export default function StudentChangePasswordPage() {
  return (
    <StudentDashboardLayout hasHeader hasSidebar>
      <StudentChangePasswordContent />
    </StudentDashboardLayout>
  );
}
