import { lang } from 'lang';

/**
 * The parameters of the FaqImage component.
 */
export interface FaqImageProps {
  src: string;
  alt: string;
}

/**
 * An image used in the FAQ component.
 */
export default function FaqImage({ src, alt }: FaqImageProps) {
  const fullSrc = `/img/faq/faq-${src}-${lang}.png`;

  return (
    <img
      alt={alt}
      src={fullSrc}
      className="mx-auto my-[24px] w-full rounded-[5px] border border-solid border-input-outline-shadow"
    />
  );
}
