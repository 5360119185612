import ClassroomDoorIcon from 'components/icons/ClassroomDoorIcon';
import { _t } from 'lang';
import { Link } from 'react-router-dom';
import { HOME_PAGE_PATH } from 'routes/paths';

/**
 * Displayed when the screen is too small to display the teacher dashboard.
 */
export default function SmallScreenMessage() {
  return (
    <div className="flex min-h-screen w-full flex-col items-center justify-center bg-white px-[24px] py-[40px] lg:hidden">
      <div className="ml-auto mr-auto max-w-[515px] text-center">
        <div className="text-[24px] font-medium leading-[29px] text-text-dark-2">
          <span>{_t('Your screen is too small')}&nbsp;🔍</span>
        </div>
        <div className="mt-[12px] text-[16px] font-medium leading-[26px] text-text-shadow">
          {
            _t('We are sorry, but the teacher dashboard is currently not available on mobile devices. We are working very hard to make it available as soon as possible. In the meantime, please increase the size of your browser window or use a desktop computer.') // prettier-ignore
          }
        </div>
        <div className="mt-[12px] text-[16px] font-medium leading-[26px] text-text-shadow">
          {
            _t("Don't worry, the student dashboard is available on mobile devices so your pupils can still use Curious Mind on their phones.") // prettier-ignore
          }
        </div>
        <div className="mt-[12px] text-center text-green-primary underline">
          <Link to={HOME_PAGE_PATH} className="-m-2 p-2 text-[16px] font-semibold leading-[26px]">
            {_t('Go back to the homepage')}
          </Link>
        </div>
        <div className="mt-[44px] flex h-[235px] items-center justify-center">
          <div className="w-[188px]">
            <ClassroomDoorIcon />
          </div>
        </div>
      </div>
    </div>
  );
}
