/**
 * The icon of the Select Input components when the Select is selected.
 */
export default function SelectInputSelectedIcon() {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx="8" cy="8" r="7" fill="#F3F6F8" stroke="#0AB892" strokeWidth="2" />
      <circle cx="8" cy="8" r="4" fill="#0AB892" />
    </svg>
  );
}
