import { MantineThemeOverride } from '@mantine/core';

export const LG_SCREEN_RESOLUTION = 1240;

const theme: MantineThemeOverride = {
  fontFamily: 'Inter, sans-serif',
  black: '#353E45',
  white: '#FCFDFD',
  primaryColor: 'black',
  colors: {
    black: ['#353E45'],
    white: ['#FCFDFD'],
    green: ['#0AB892', '#0AB892', '#0AB892', '#0AB892', '#0AB892', '#0AB892', '#0AB892'],
  },
  components: {
    TextInput: {
      styles: {
        wrapper: {
          marginBottom: '0!important',
        },
        root: {
          position: 'relative',
        },
        label: {
          fontSize: '14px',
          lineHeight: '17px',
          fontWeight: 500,
          color: '#353E45',
        },
        input: {
          backgroundColor: '#F3F6F8',
          fontSize: '16px',
          fontWeight: 'normal',
          lineHeight: '20px',
          color: '#353E45',
          height: '56px',
          marginTop: '10px',
          paddingLeft: '13px',
          borderColor: '#D9E0E6',
          '&:focus': {
            borderWidth: '2px',
            borderColor: '#0AB892!important',
            paddingLeft: '12px', // due to focus' border width of 2px whereas the border on idle input is 1px
          },
          '&::placeholder': {
            fontSize: '16px',
            lineHeight: '19px',
            fontWeight: 400,
            color: '#738491',
          },
        },
        invalid: {
          borderColor: '#E82C2C!important',
          '&:focus': {
            borderColor: '#E82C2C!important',
          },
          color: '#353E45',
        },
        error: {
          borderColor: '#E82C2C!important',
          '&:focus': {
            borderColor: '#E82C2C!important',
          },
          '&:focus-within': {
            borderColor: '#E82C2C!important',
          },
          position: 'absolute',
          top: 0,
          right: 0,
          textAlign: 'right',
          color: '#E82C2C',
          fontSize: '14px',
          lineHeight: '17px',
        },
      },
    },
    PasswordInput: {
      styles: {
        wrapper: {
          marginBottom: '0!important',
        },
        root: {
          position: 'relative',
        },
        label: {
          fontSize: '14px',
          lineHeight: '17px',
          fontWeight: 500,
          color: '#353E45',
        },
        input: {
          backgroundColor: '#F3F6F8',
          fontSize: '16px',
          fontWeight: 'normal',
          lineHeight: '20px',
          color: '#353E45',
          height: '56px',
          marginTop: '10px',
          paddingLeft: '13px',
          borderColor: '#D9E0E6',
          '&:focus': {
            borderWidth: '2px',
            borderColor: '#0AB892!important',
            paddingLeft: '12px', // due to focus' border width of 2px whereas the border on idle input is 1px
          },
          '&:focus-within': {
            borderColor: '#0AB892!important',
            borderWidth: '2px',
            paddingLeft: '12px', // due to focus' border width of 2px whereas the border on idle input is 1px
          },
        },
        innerInput: {
          position: 'static',
          paddingLeft: '0 !important',
          backgroundColor: '#F3F6F8',
          fontSize: '16px',
          fontWeight: 'normal',
          lineHeight: '20px',
          color: '#353E45',
          height: '100%',
          '&::placeholder': {
            fontSize: '16px',
            lineHeight: '19px',
            fontWeight: 400,
            color: '#738491',
          },
        },
        rightSection: {
          width: '50px',
        },
        invalid: {
          '&:focus-within': {
            borderWidth: '2px',
            borderColor: '#E82C2C!important',
          },
          '&:focus': {
            border: 'none',
          },
          color: '#353E45',
        },
        error: {
          borderColor: '#E82C2C!important',
          position: 'absolute',
          top: 0,
          right: 0,
          textAlign: 'right',
          color: '#E82C2C',
          fontSize: '14px',
          lineHeight: '17px',
        },
      },
    },
    NumberInput: {
      styles: {
        label: {
          fontSize: '14px',
          lineHeight: '17px',
          fontWeight: 500,
          color: '#353E45',
        },
        input: {
          backgroundColor: '#F3F6F8',
          fontSize: '16px',
          fontWeight: 500,
          lineHeight: '19px',
          color: '#353E45',
          height: '56px',
          marginTop: '10px',
          paddingLeft: '51px!important',
          borderColor: '#D9E0E6',
          '&:focus': {
            borderWidth: '2px',
            borderColor: '#0AB892!important',
            paddingLeft: '50px!important',
          },
          '&::placeholder': {
            fontSize: '16px',
            lineHeight: '19px',
            fontWeight: 400,
            color: '#738491',
          },
        },
        invalid: {
          borderColor: '#E82C2C!important',
          '&:focus': {
            borderColor: '#E82C2C!important',
          },
          color: '#353E45',
        },
        error: {
          borderColor: '#E82C2C!important',
          '&:focus': {
            borderColor: '#E82C2C!important',
          },
          '&:focus-within': {
            borderColor: '#E82C2C!important',
          },
        },
      },
    },
    Select: {
      styles: {
        root: {
          height: '42px',
        },
        input: {
          height: '42px',
          backgroundColor: '#F3F6F8',
          fontSize: '14px',
          lineHeight: '17px!important',
          fontWeight: 600,
          borderColor: '#D9E0E6',
          '::placeholder': {
            color: '#738491',
            fontWeight: 500,
            fontSize: '14px',
            lineHeight: '17px',
          },
        },
        item: {
          color: '#353E45!important',
          fontSize: '14px',
          lineHeight: '17px',
          fontWeight: 600,
        },
      },
    },
  },
  globalStyles: (theme) => ({
    [`@media (min-width: ${LG_SCREEN_RESOLUTION}px)`]: {
      'iframe[src*="youtube"]': {
        borderRadius: '10px',
      },
    },
    '@media print': {
      body: {
        backgroundColor: '#fff !important',
      },
    },
  }),
};

export default theme;
