import { _t, lang } from 'lang';
import HelpRow from 'pages/presentation-module/landing/HelpRow';
import HelpTabLayout from 'pages/presentation-module/landing/HelpTabLayout';
import YoutubeTeachers from 'pages/presentation-module/who-we-are/sections/YoutubeTeachers';
import CourseCtaButton from 'pages/presentation-module/landing/CourseCtaButton';

/**
 * The help section of the home page.
 */
export default function HelpContent() {
  return (
    <div className="pt-[40px]">
      <div id="how-it-works" className="h-0 w-0 translate-y-[-100px] lg:translate-y-[-140px]"></div>
      <div className="md:leading-[54px]lg:max-w-[1170px] ml-auto mr-auto pl-[24px] pr-[24px] text-center text-[25px] font-semibold leading-[35px] md:text-[36px]">
        <div>{_t('Žádná panika. To stihneme')}</div>
      </div>
      <div className="pb-[80px] pt-[40px]">
        <HelpTabLayout>
          <HelpRow
            imgSrc={`/img/screenshots/exercises-book-${lang}.png`}
            heading="Každý týden živé vysílání"
            description={<div>Podrobně probereme příklady, postupy i principy.</div>}
          />
          <HelpRow
            imgSrc={`/img/screenshots/teacher-classrooms-${lang}.png`}
            heading="Příprava na další vysílání"
            description={
              <div>Dopředu budete znát příklady, které budeme probírat. Můžete si je vyzkoušet nanečisto.</div>
            }
          />
          <HelpRow
            imgSrc={`/img/screenshots/teacher-dashboard-${lang}.png`}
            heading="Bez domácích úkolů to nepůjde"
            description={
              <div>DÚ jsou základ, aby se dobře vypracovávali dostanete naši parametrickou online cvičebnici.</div>
            }
          />
          <HelpRow
            imgSrc={`/img/screenshots/teacher-assign-homework-${lang}.png`}
            heading="Probereme vzorové příklady"
            description={<div>Není čas ztrácet čas, proto probereme ostré texty z minulých let.</div>}
          />
        </HelpTabLayout>
      </div>
      <div className="mx-auto w-full max-w-[1170px] gap-6 px-[24px] text-center lg:grid lg:grid-cols-[3fr_2fr] lg:px-0 lg:text-left">
        <div>
          <p className="text-[25px] font-semibold leading-[35px] md:text-[36px] md:leading-[54px]">
            <span className="md:whitespace-pre-line">Společně nakopneme matematiku</span>
          </p>
          <p className="pb-[32px] pt-[16px] font-normal text-text-shadow md:pt-[24px] md:text-[19px] md:leading-[30px]">
            <span className="md:whitespace-pre-line">
              Kromě našich živých vysílání, máte v rámci kurzu přípravy na přijímací zkoušky z matematiky přístup k naší
              parametrické online cvičebnici. V té najdete od každého příkladu tisíce variant. Takže pokud nejsou nějaké
              příklady vaší silnou stránkou, nevadí. Můžete trénovat a trénovat. Přidáte se do našeho last minute kurzu?
              Stále máme volná místa.
            </span>
          </p>
          <CourseCtaButton title="Chci začít s přípravou" />
        </div>
        <div>
          <img src="/img/pencils-notes-phone.svg" alt="Pencils and notes" className="lg:hidden" />
          <img src="/img/pencils-notes-desktop.svg" alt="Pencils and notes" className="hidden lg:block" />
        </div>
      </div>
      <div className="ml-auto mr-auto max-w-[1218px] pb-[64px] pl-[24px] pr-[24px] pt-[24px] lg:pb-[80px] lg:pt-[80px]">
        <p className="text-center text-[25px] font-semibold leading-[35px] md:text-[36px] md:leading-[54px]">
          <span className="md:whitespace-pre-line">Naši lektoři vás v tom nenechají</span>
        </p>
        <div className="min-h-[820px]">
          <div className="grid grid-cols-[repeat(auto-fit,157px)] justify-center gap-x-[12px] gap-y-[24px] pt-[32px] md:grid-cols-[repeat(auto-fit,270px)] lg:justify-start lg:gap-x-[30px] lg:gap-y-[32px]">
            <YoutubeTeachers />
          </div>
        </div>
      </div>
    </div>
  );
}
