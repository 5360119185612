interface Props {
  stroke?: string;
}

/**
 * Book icon for the teacher's sidebar navigation item.
 */
export default function BookIcon({ stroke = '#535F6A' }: Props) {
  return (
    <div>
      <svg
        width="16"
        height="21"
        viewBox="0 0 16 21"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className="hidden lg:block"
      >
        <path
          d="M14.8571 1.28564H1.14282V16.9378H14.8571V1.28564Z"
          stroke={stroke}
          strokeWidth="2"
          strokeMiterlimit="10"
          strokeLinejoin="round"
        />
        <path
          d="M4.01123 5.54639H11.0925"
          stroke={stroke}
          strokeWidth="2"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M4.01123 8.93799H8.40339"
          stroke={stroke}
          strokeWidth="2"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M4 18L4 20"
          stroke={stroke}
          strokeWidth="2"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
      <svg
        width="22"
        height="28"
        viewBox="0 0 22 28"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className="lg:hidden"
      >
        <path
          d="M20.1429 1.71484H1.85718V22.5844H20.1429V1.71484Z"
          stroke={stroke}
          strokeWidth="2"
          strokeMiterlimit="10"
          strokeLinejoin="round"
        />
        <path
          d="M5.68164 7.39648H15.1233"
          stroke={stroke}
          strokeWidth="2"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M5.68164 11.918H11.5379"
          stroke={stroke}
          strokeWidth="2"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M5.66675 24L5.66675 26.6667"
          stroke={stroke}
          strokeWidth="2"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </div>
  );
}
