import { Button } from '@mantine/core';
import ArrowRightIcon from 'components/icons/ArrowRightIcon';
import PretestFailedIcon from 'components/icons/PretestFailedIcon';
import PretestPassedIcon from 'components/icons/PretestPassedIcon';
import { IAssignmentEvaluation } from 'data/assignment/assignment';
import { _t, _tx } from 'lang';
import sprintf from 'lang/sprintf';
import useCourseStep from 'pages/courses-module/course-step/context/use-course-step';
import { useNavigate } from 'react-router-dom';

/**
 * The parameters of the component.
 */
interface Props {
  evaluation: IAssignmentEvaluation;
  steps: number[];
  nextStep?: { stepId: number; sectionSlug: string };
}

/**
 * The evaluation of a pretest.
 */
export default function PretestEvaluation({ evaluation: { passed }, steps, nextStep }: Props) {
  const navigate = useNavigate();
  const { course, nextStepLink } = useCourseStep();

  const bgColor = passed ? 'bg-light-green' : 'bg-light-red';

  const stepText = sprintf(
    _tx('Feel free to skip the next step.', 'Feel free to skip the next %d steps.', steps.length),
    steps.length
  );

  const text = passed
    ? `${_t('Congratulations! You have answered all the questions correctly.')} ${stepText}👏`
    : _t('Some of the answers are incorrect. The following videos cover the examples from this quiz. Can you find the way to solve them? 🤔'); // prettier-ignore

  /**
   * Handles the click on the button.
   */
  function buttonClick() {
    if (passed && nextStep) {
      navigate(`/course/${course.slug}/${nextStep.sectionSlug}/step/${nextStep.stepId}`);
    } else if (nextStepLink) {
      navigate(nextStepLink);
    } else {
      // This could only happen if the pretest is the last step of the course.
    }
  }

  return (
    <div
      className={`mt-[16px] grid w-full grid-cols-[1fr,63px] items-center gap-[16px] rounded-[10px] p-[16px] md:grid-cols-[54px,1fr,83px] ${bgColor}`}
    >
      <div className="hidden md:block">{passed ? <PretestPassedIcon /> : <PretestFailedIcon />}</div>
      <div className="text-[16px] font-semibold leading-[20px] text-text-dark md:whitespace-pre-line">{text}</div>
      <Button
        onClick={buttonClick}
        className="flex h-[40px] w-full items-center justify-center rounded-[10px] bg-green-primary hover:bg-green-dark md:h-[56px]"
      >
        <ArrowRightIcon />
      </Button>
    </div>
  );
}
