/**
 * The icon which is displayed when the pretest is passed.
 */
export default function PretestPassedIcon() {
  return (
    <svg width="54" height="54" viewBox="0 0 54 54" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M16 24.8039H18.4253C19.794 24.8039 21.0676 24.1041 21.8015 22.9489L27.9629 13.2508C28.8534 11.8491 30.3988 11 32.0595 11V11C33.5606 11 34.7016 12.3491 34.4526 13.8293L33.178 21.4054C32.9728 22.6254 33.9132 23.7373 35.1503 23.7373H42.2592C44.1787 23.7373 45.6045 25.515 45.1876 27.3888L42.2368 40.6515C41.9315 42.0238 40.7142 43 39.3084 43H23.6524C22.6012 43 21.5923 42.5862 20.8438 41.8481L19.4895 40.5126C18.741 39.7746 17.7321 39.3608 16.6809 39.3608H16"
        stroke="#353E45"
        strokeWidth="3"
        strokeLinecap="round"
      />
      <path
        d="M9 42H14C15.1046 42 16 41.1046 16 40V25C16 23.8954 15.1046 23 14 23H9"
        stroke="#353E45"
        strokeWidth="3"
        strokeLinecap="round"
      />
      <path d="M44 30L38 30" stroke="#353E45" strokeWidth="3" strokeLinecap="round" />
      <path d="M42 36L36 36" stroke="#353E45" strokeWidth="3" strokeLinecap="round" />
    </svg>
  );
}
