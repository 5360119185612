import { validate } from 'email-validator';
import { _t } from 'lang';
import { ValidationError } from 'components/forms/validators/validation-error';

/**
 * Validates the email format.
 */
export default function emailFormat(value: string): ValidationError | null {
  if (!validate(value)) {
    return {
      message: _t('The email address is invalid.'),
    };
  }

  return null;
}
