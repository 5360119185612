import { _t } from 'lang';
import { ValidationError } from 'components/forms/validators/validation-error';

/**
 * Validates that the input is not empty.
 */
export default function required(value: string): ValidationError | null {
  if (value === '') {
    return {
      message: _t('This field is required.'),
    };
  }

  return null;
}
