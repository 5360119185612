import { _t } from 'lang';
import sprintf from 'lang/sprintf';

/**
 * The parameters for the HomeworkStatus component.
 */
export interface HomeworkStatusProps {
  status: 'ongoing' | 'finished';
  classes?: string;
}

/**
 * The map of the status to the corresponding class names.
 */
const classesMap = {
  ongoing: 'bg-bg-homework-status border-[#FFD9AD] text-text-homework-status',
  finished: 'bg-bg-shadow border-input-outline-shadow text-text-shadow',
};

/**
 * The map of the status to the corresponding text.
 */
const textMap = {
  // Translators: The text for the homework status. The %s is a placeholder for the class name. A homework is ongoing if it is not finished yet and it has not been cancelled.
  ongoing: sprintf(_t('Ongoing <span class="%s">homework</span>'), 'hidden'),
  // Translators: The text for the homework status. The %s is a placeholder for the class name. A homework is finished if it is not ongoing and it has not been cancelled.
  finished: sprintf(_t('Finished <span class="%s">homework</span>'), 'hidden'),
};

/**
 * The status badge for the homework.
 */
export default function HomeworkStatus({ status, classes }: HomeworkStatusProps) {
  const classNames = `${classesMap[status]} ${classes}`;
  const text = textMap[status];

  return (
    <div
      // leading -> 12 px; different from Figma due to better align
      className={`flex h-[20px] w-[78px] items-center justify-center rounded-[10px] border text-[12px] font-medium leading-[12px] ${classNames}`}
      dangerouslySetInnerHTML={{ __html: text }}
    />
  );
}
