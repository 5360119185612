import { _t } from 'lang';

interface Props {
  progress: number;
  color?: string;
}

/**
 * Displays the progress - numerical value and unit (%).
 */
export default function ProgressText({ progress, color }: Props) {
  return (
    <span className="text-[10px] font-normal leading-[12px]" style={{ color }}>
      {_t('Completed')} <span className="font-semibold">{Math.round(progress * 100)}%</span>
    </span>
  );
}
