import { Loader, LoadingOverlay } from '@mantine/core';
import { IconFileUpload } from '@tabler/icons-react';
import { GetClassroomHomeworkDetailResponse } from 'api/actions/get-classroom-homework-detail/get-classroom-homework-detail-response';
import useApi from 'api/use-api';
import useFileDownload from 'api/use-file-download';
import Breadcrumbs from 'components/Breadcrumbs';
import Assignment from 'components/assignment/Assignment';
import HomeworkComments from 'components/homework/HomeworkComments';
import ArrowRightIcon from 'components/icons/ArrowRightIcon';
import CrossIcon from 'components/icons/CrossIcon';
import Clickable from 'components/misc/Clickable';
import useClassrooms from 'data/classrooms/use-classrooms';
import { _t, _tx } from 'lang';
import TeacherDashboardLayout from 'layout/teacher-dashboard-layout/TeacherDashboardLayout';
import { useEffect, useMemo, useState } from 'react';
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom';

/**
 * Allows the teacher to check the homework of a student.
 */
export default function HomeworkCheckPage() {
  const navigate = useNavigate();
  const { download } = useFileDownload();
  const { classroomId, homeworkId } = useParams();
  const { getAction } = useApi();
  const [homework, setHomework] = useState<GetClassroomHomeworkDetailResponse | null>(null);
  const [loading, setLoading] = useState<boolean>(true);
  let { state } = useLocation();
  const { getClassroom } = useClassrooms();
  const [fill, setFill] = useState('#738491');

  const classroom = getClassroom({ classroomId: classroomId! });

  /**
   * Fetches the homework.
   */
  const fetchHomework = () => {
    if (classroomId && homeworkId) {
      setLoading(true);

      const getHomework = getAction('GetClassroomHomeworkDetail');

      getHomework({ parameters: { classroomId, homeworkId } })
        .success(setHomework)
        .complete(() => setLoading(false));
    }
  };

  useEffect(() => {
    fetchHomework();
  }, []);

  const { assignment, student } = useMemo(() => {
    if (!state.assignmentId || !homework) {
      return {};
    }

    const studentHomework = homework.students.find(
      (student) => student.assignment.generated?.assignmentId === state.assignmentId
    );

    if (!studentHomework) {
      return {};
    }

    const { assignment, student } = studentHomework;
    return { assignment, student };
  }, [state, homework]);

  const attachments = useMemo(() => assignment?.attachments || [], [assignment]);

  return (
    <TeacherDashboardLayout hasHeader hasSidebar>
      <div className="mt-[-13px] flex items-center justify-between">
        <Breadcrumbs>
          <Link to={`/teacher/classrooms/classroom/${classroomId}/detail`}>{classroom?.name}</Link>
          <ArrowRightIcon stroke="#738491" />
          <Link to={`/teacher/classrooms/classroom/${classroomId}/homework/active`}>
            {_tx('Homework', 'Homework', 2)}
          </Link>
          <ArrowRightIcon stroke="#738491" />
          <Link to={`/teacher/classrooms/classroom/${classroomId}/homework/homework/${homeworkId}`}>
            {state?.homeworkTitle}
          </Link>
          <ArrowRightIcon stroke="#738491" />
          <div className="font-normal text-text-dark-2">
            {_t('Solution check:')} {state?.studentName}
          </div>
        </Breadcrumbs>
        <Clickable
          onClick={() => navigate(-1)}
          className="flex h-[42px] w-[42px] items-center justify-center rounded-[7px] p-[8px] hover:bg-bg-shadow"
          onMouseEnter={() => {
            setFill('#353E45');
          }}
          onMouseLeave={() => {
            setFill('#738491');
          }}
        >
          <CrossIcon fill={fill} />
        </Clickable>
      </div>
      <div className="relative my-[16px] flex flex-col gap-y-[12px] rounded-[10px] bg-white p-[24px]">
        <LoadingOverlay visible={loading} loaderProps={{ size: 32, color: 'green' }} />
        <div className="mb-2 flex w-full items-center justify-between">
          <div className="flex items-center gap-x-[12px]">
            <IconFileUpload size={24} />
            <div className="mt-[1px] text-[18px] font-semibold leading-[19px] text-text-dark">
              {_t('Homework attachments')}
            </div>
          </div>
        </div>
        {attachments.map(({ fileId, fileName }) => (
          <div key={fileId} className="flex items-baseline gap-2 pl-1 text-[14px] font-medium leading-[18px]">
            <span className=" text-text-dark-2">{fileName}</span>
            <span onClick={() => download(fileId)} className="cursor-pointer text-green-primary">
              {_t('(Download)')}
            </span>
          </div>
        ))}
      </div>
      {assignment && homeworkId ? (
        <HomeworkComments
          mode="teacher"
          classroomId={classroomId}
          studentId={student.userId}
          loading={loading}
          assignment={assignment}
          homeworkId={homeworkId}
          onCommentAdded={fetchHomework}
        />
      ) : (
        <></>
      )}
      <div className="rounded-[10px] bg-white lg:mt-[11px] ">
        <div className="mx-auto max-w-[618px] p-[24px] lg:pb-[48px] lg:pt-[48px]">
          {state?.assignmentId && (
            <Assignment
              loader={<Loader className="mx-auto" variant="oval" color="green" />}
              assignmentId={state.assignmentId}
            />
          )}
        </div>
      </div>
    </TeacherDashboardLayout>
  );
}
