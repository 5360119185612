import { ExerciseSet, Student } from 'api/actions/assign-classroom-homework/assign-classroom-homework-payload';
import { CreateClassroomResponse } from 'api/actions/create-classroom/create-classroom-response';
import { DispatchedAction } from 'api/dispatch-action';
import useApi from 'api/use-api';
import ClassroomsContext, {
  Classroom,
  ClassroomGrade,
  ClassroomId,
  ClassroomName,
  ClassroomStudent,
} from 'data/classrooms/classrooms-context';
import { useEffect, useState } from 'react';

/**
 * Provides the classrooms data
 */
export default function ClassroomsProvider({ children }: { children: React.ReactNode }) {
  const [loading, setLoading] = useState(true);
  const [classrooms, setClassrooms] = useState<Array<Classroom>>([]);
  const [classroomStudents, setClassroomStudents] = useState<Map<string, Array<ClassroomStudent>>>(new Map());

  const { getAction, ready, role } = useApi();
  const getClassroomsAction = getAction('GetClassrooms');
  const createClassroomAction = getAction('CreateClassroom');
  const getClassroomStudentsAction = getAction('GetClassroomStudents');
  const refreshClassroomInviteCodeAction = getAction('RefreshClassroomInviteCode');
  const assignClassroomHomeworkAction = getAction('AssignClassroomHomework');

  /**
   * Fetches the data about classrooms.
   */
  function fetchClassrooms(): void {
    setLoading(true);

    getClassroomsAction()
      .success(({ classrooms }) => setClassrooms(classrooms))
      .complete(() => setLoading(false));
  }

  /**
   * Fetches the data about students in a classroom.
   */
  function fetchClassroomStudents({ classroomId }: { classroomId: ClassroomId }): void {
    getClassroomStudentsAction({ parameters: { classroomId } }).success(({ students }) => {
      setClassroomStudents((prev) => {
        const newMap = new Map(prev);
        newMap.set(classroomId, students);
        return newMap;
      });
    });
  }

  /**
   * Fetches new invite code for a classroom.
   */
  function fetchNewClassroomInviteCode({ classroomId }: { classroomId: ClassroomId }): void {
    refreshClassroomInviteCodeAction({ parameters: { classroomId } }).success(({ inviteCode }) => {
      fetchClassrooms();
    });
  }

  /**
   * Returns all classrooms.
   */
  function getClassrooms(): Classroom[] {
    return classrooms;
  }

  /**
   * Returns the only classroom with the specified id.
   */
  function getClassroom({ classroomId }: { classroomId: ClassroomId }): Classroom | undefined {
    return classrooms.find((classroom) => classroom.classroomId === classroomId);
  }

  /**
   * Returns the classroom's invite code
   */
  function getClassroomInviteCode({ classroomId }: { classroomId: ClassroomId }): string | undefined {
    return classrooms.find((classroom) => classroom.classroomId === classroomId)?.inviteCode;
  }

  /**
   * Creates a new classroom and updates the classrooms state.
   */
  function createClassroom({
    name,
    grade,
  }: {
    name: ClassroomName;
    grade: ClassroomGrade;
  }): DispatchedAction<CreateClassroomResponse> {
    return createClassroomAction({ payload: { grade, name } }).success(() => {
      fetchClassrooms();
    });
  }

  /**
   * Retrieves the students in a classroom.
   */
  function getClassroomStudents({ classroomId }: { classroomId: ClassroomId }): ClassroomStudent[] | undefined {
    return classroomStudents.get(classroomId);
  }

  /**
   * Assigns a homework to a classroom.
   */
  function assignHomework({
    classroomId,
    deadline,
    exerciseSet,
    students,
  }: {
    classroomId: ClassroomId;
    deadline: string;
    exerciseSet: ExerciseSet;
    students?: Student[];
  }) {
    return assignClassroomHomeworkAction({
      parameters: { classroomId },
      payload: { deadline, exerciseSet, students },
    }).success(() => {
      fetchClassrooms();
    });
  }

  // Run this when session is ready.
  useEffect(() => {
    if (ready && role === 'teacher') {
      fetchClassrooms();
    }
  }, [ready, role]);

  // Reset data when user logs out.
  useEffect(() => {
    if (!ready) {
      setClassrooms([]);
      setClassroomStudents(new Map());
    }
  }, [ready]);

  // Enable classrooms only for teachers.
  if (role !== 'teacher') {
    return <>{children}</>;
  }

  return (
    <ClassroomsContext.Provider
      value={{
        loading,
        fetchClassrooms,
        fetchClassroomStudents,
        fetchNewClassroomInviteCode,
        getClassrooms,
        getClassroom,
        getClassroomInviteCode,
        createClassroom,
        getClassroomStudents,
        assignHomework,
      }}
    >
      {children}
    </ClassroomsContext.Provider>
  );
}
