import { AxiosInstance } from 'axios';
import { SweetPath } from 'sweet-path';
import dispatchAction from 'api/dispatch-action';

import { GetExerciseTypesPathParameters } from 'api/actions/get-exercise-types/get-exercise-types-path-parameters';
import { GetExerciseTypesQueryParameters } from 'api/actions/get-exercise-types/get-exercise-types-query-parameters';
import { GetExerciseTypesResponse } from 'api/actions/get-exercise-types/get-exercise-types-response';

/**
 * The path of the GetExerciseTypes action.
 */
export const getExerciseTypesActionPath = new SweetPath<keyof GetExerciseTypesPathParameters>('/exercise/type', {
  path: '{param}',
});

/**
 * Creates a contextualized GetExerciseTypes actions.
 */
export default function createGetExerciseTypesAction(axios: AxiosInstance) {
  return function getExerciseTypesAction({ query }: { query: GetExerciseTypesQueryParameters }) {
    const path = getExerciseTypesActionPath.original;

    return dispatchAction(() => axios.get<GetExerciseTypesResponse, GetExerciseTypesResponse>(path, { params: query }));
  };
}
