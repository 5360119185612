import { Link, LinkProps } from 'react-router-dom';

interface Props extends LinkProps {
  hideMenu: () => void;
  className?: string;
  linkClass?: string;
}

/**
 * Wrapper for the Link component that hides the phone menu when clicked.
 */
export default function PhoneMenuLink({ hideMenu, className, linkClass = '', ...rest }: Props) {
  return (
    <div onClick={hideMenu} className={`cursor-pointer ${className}`}>
      <Link {...rest} className={`active:text-green-primary ${linkClass}`} />
    </div>
  );
}
