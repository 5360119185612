interface Props {
  stroke?: string;
}

/**
 * Right arrow icon.
 */
export default function ArrowRightIcon({ stroke = 'white' }: Props) {
  return (
    <svg width="8" height="14" viewBox="0 0 8 14" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M1.3335 12.3333L5.95972 7.70703C6.35025 7.3165 6.35025 6.68334 5.95972 6.29281L1.3335 1.66658"
        stroke={stroke}
        strokeWidth="2"
        strokeLinecap="round"
      />
    </svg>
  );
}
