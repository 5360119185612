import { _t } from 'lang';
import ImageCard from 'pages/presentation-module/who-we-are/ImageCard';

/**
 * Image cards with Youtube Teachers.
 */
export default function YoutubeTeachers() {
  return (
    <>
      <ImageCard
        image="/team/anna-yaghobova.jpg" // cspell:disable-line
        name="Mgr. Anna Yaghobová" // cspell:disable-line
        position={_t('Lecturer')}
        contact="https://www.ms.mff.cuni.cz/~yaghoboa/"
        contactName={_t('University webpage')}
      />
      <ImageCard
        image="/team/jan-sedlak.jpg" // cspell:disable-line
        name="Mgr. Jan Sedlák" // cspell:disable-line
        position={_t('Lecturer')}
        contact=""
        contactName=""
      />
      <ImageCard
        image="/team/kamil-cinkraut.jpg" // cspell:disable-line
        name="Mgr. Kamil Cinkraut" // cspell:disable-line
        position={_t('Lecturer')}
        contact=""
        contactName=""
      />
      <ImageCard
        image="/team/eliska-hajkova.jpg" // cspell:disable-line
        name="Bc. Eliška Hájková" // cspell:disable-line
        position={_t('Lecturer')}
        contact=""
        contactName=""
      />
      <ImageCard
        image="/team/willy-svoboda.jpg" // cspell:disable-line
        name="Bc. Willy Svoboda" // cspell:disable-line
        position={_t('Lecturer')}
        contact="https://www.instagram.com/p/Cj8dbeVoFb_/"
        contactName="Instagram"
      />
      <ImageCard
        image="/team/michael-bohin.jpg" // cspell:disable-line
        name="Michael Bohin" // cspell:disable-line
        position={_t('Lecturer')}
        contact="https://www.linkedin.com/in/michael-bohin-9a13109b/"
        contactName="LinkedIn"
        email="bohin@ksvi.mff.cuni.cz"
      />
      <ImageCard
        image="/team/vendula-stillerova.jpg" // cspell:disable-line
        name="Vendula Stillerová" // cspell:disable-line
        position={_t('Lecturer')}
        contact=""
        contactName=""
      />
    </>
  );
}
