import { NavLink, useMatch } from 'react-router-dom';

interface Props {
  to: string;
  heading: string;
}

/**
 * Section link.
 */
export default function SectionLink({ to, heading }: Props) {
  const match = useMatch(to);

  const activeClasses = match ? 'border-green-primary bg-white' : 'border-bg-shadow bg-bg-shadow';

  return (
    <NavLink
      to={to}
      className={`flex h-[36px] shrink grow-0 items-center justify-center rounded-[10px] border-[2px] pl-[18px] pr-[18px] md:h-[42px] md:pl-[30px] md:pr-[30px] ${activeClasses}`}
    >
      {({ isActive }) => {
        const activeClassesHeading = isActive ? 'text-text-dark' : 'text-text-shadow';

        return (
          <span className={`text-[13px] font-semibold md:text-[16px] md:leading-[42px] ${activeClassesHeading}`}>
            {heading}
          </span>
        );
      }}
    </NavLink>
  );
}
