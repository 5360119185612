import { AxiosInstance } from 'axios';
import { SweetPath } from 'sweet-path';
import dispatchAction from 'api/dispatch-action';

import { GetClassroomsPathParameters } from 'api/actions/get-classrooms/get-classrooms-path-parameters';

import { GetClassroomsResponse } from 'api/actions/get-classrooms/get-classrooms-response';

/**
 * The path of the GetClassrooms action.
 */
export const getClassroomsActionPath = new SweetPath<keyof GetClassroomsPathParameters>('/classroom', {
  path: '{param}',
});

/**
 * Creates a contextualized GetClassrooms actions.
 */
export default function createGetClassroomsAction(axios: AxiosInstance) {
  return function getClassroomsAction() {
    const path = getClassroomsActionPath.original;

    return dispatchAction(() => axios.get<GetClassroomsResponse, GetClassroomsResponse>(path));
  };
}
