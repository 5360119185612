import { Button } from '@mantine/core';
import { useNavigate } from 'react-router-dom';

/**
 * The parameters of the component.
 */
interface Props {
  courseSlug: string;
  sectionSlug?: string;
  stepId?: number;
  icon: JSX.Element;
  className: string;
  prefix?: string;
}

/**
 * Upon click displays the next step in the course.
 */
export default function NavigateCourseButton({ courseSlug, sectionSlug, stepId, icon, className, prefix = '' }: Props) {
  const navigate = useNavigate();

  return (
    <Button
      className={`h-full w-[32px] rounded-none border-0 bg-text-dark p-0 text-white ${className}`}
      onClick={() => navigate(`${prefix}/course/${courseSlug}/${sectionSlug}/step/${stepId}`)}
      type="button"
    >
      {icon}
    </Button>
  );
}
