import { useStudentDashboardLayout } from 'layout/student-dashboard-layout/StudentDashboardLayoutContext';
import StudentPhoneHeader from 'layout/student-dashboard-layout/parts/header/StudentPhoneHeader';
import StudentDesktopHeader from 'layout/student-dashboard-layout/parts/header/StudentDesktopHeader';

/**
 * The header of the students' dashboard page.
 */
export default function StudentHeader() {
  const { isPhoneHeaderVisible } = useStudentDashboardLayout();

  return (
    <div>
      <div className={isPhoneHeaderVisible ? 'lg:hidden' : 'hidden'}>
        <StudentPhoneHeader />
      </div>
      <div className="hidden lg:block">
        <StudentDesktopHeader />
      </div>
    </div>
  );
}
