import StartExercises from 'components/StartExercises';
import { _t } from 'lang';
import BasicPageLayout from 'layout/basic-page-layout/BasicPageLayout';

/**
 * Contact page.
 */
export default function ContactPage() {
  return (
    <BasicPageLayout hasFooter hasHeader>
      <div>
        <div className="flex min-h-[220px] items-center bg-contact-phone bg-cover bg-no-repeat text-white md:bg-contact-desktop">
          <div className="ml-auto mr-auto flex max-w-[818px] flex-col items-center pl-[24px] pr-[24px] text-center">
            <span className="text-[32px] font-semibold leading-[42px] md:text-[44px] md:leading-[54px]">
              {_t('Contact')}
            </span>
          </div>
        </div>

        <div className="pb-[64px] pl-[24px] pr-[24px] pt-[24px] md:pb-[80px] md:pt-[80px]">
          <div className="justify-center gap-[30px] md:flex">
            <div className="ml-auto mr-auto flex max-w-[370px] items-center p-[48px] pl-[24px] md:ml-0 md:mr-0 md:w-[370px] md:pl-[48px]">
              <div>
                <h1 className="text-[24px] font-semibold leading-[42px]">Zvědavá mysl s.r.o.</h1>
                <div className="mb-[50px] mt-[16px] border-l-[2px] border-input-outline-shadow pl-[24px]">
                  <p className="text-[16px] font-medium leading-[26px]">
                    Zvědavá mysl s.r.o.
                    <br />
                    Na Folimance 2155/15
                    <br />
                    120 00 Praha 2, Vinohrady
                  </p>
                  <p className="mt-[16px] text-[16px] font-medium leading-[26px]">IČO: 14285461</p>
                </div>
                <p className="text-[20px] font-semibold leading-[30px]">Michael Bohin</p>
                <p className="mt-[4px] text-[16px] font-medium leading-[19px]">{_t('Project author')}</p>
                <p className="mt-[16px] text-[16px] font-medium leading-[19px] text-green-primary underline underline-offset-[3px]">
                  <a href="mailto:michael@zvedavamysl.cz">michael@zvedavamysl.cz</a>
                </p>
              </div>
            </div>
            <div className="ml-auto mr-auto mt-[24px] max-w-[370px] md:ml-0 md:mr-0 md:mt-0 md:w-[370px]">
              <img
                className="block h-auto rounded-[10px] object-cover shadow-[0px_14px_14px_rgba(22,26,29,0.1)] md:h-full"
                src="/team/michael-bohin.jpg"
                alt="Michael Bohin"
              />
            </div>
          </div>
        </div>

        <div className="ml-auto mr-auto max-w-[1218px] px-[24px] pb-[64px] pt-[64px] lg:pb-[80px] lg:pt-[80px]">
          <StartExercises />
        </div>
      </div>
    </BasicPageLayout>
  );
}
