import { PasswordInput as MantinePasswordInput, PasswordInputProps } from '@mantine/core';
import EyeIcon from 'components/icons/EyeIcon';
import EyeIconOff from 'components/icons/EyeIconOff';

/**
 * Password input
 */
export default function PasswordInput({ ...otherProps }: PasswordInputProps) {
  return (
    <MantinePasswordInput
      radius={10}
      placeholder="********"
      rightSectionWidth={50}
      visibilityToggleIcon={({ reveal }) => (reveal ? <EyeIconOff /> : <EyeIcon />)}
      {...otherProps}
    />
  );
}
