import { lang } from 'lang';

/**
 * A dot which displays the number of planned webinars.
 */
export default function PlannedWebinarsDot() {
  const plannedWebinars = lang === 'cs' ? 0 : 5;

  if (!plannedWebinars) {
    return <></>;
  }

  return (
    <div className="ml-[4px] inline-flex h-[17px] w-[17px] items-center justify-center rounded-[10px] bg-green-primary text-[12px] font-bold leading-[12px] text-white no-underline">
      <span className="mt-[1px]">{plannedWebinars}</span>
    </div>
  );
}
