import CorrectIcon from 'components/icons/CorrectIcon';
import IncorrectIcon from 'components/icons/IncorrectIcon';
import { IExerciseQuestion } from 'data/assignment/assignment';
import { _t } from 'lang';

/**
 * The parameters of the component.
 */
interface Props {
  question: IExerciseQuestion;
}

/**
 * Formats number.
 */
function formatNumber(num: string) {
  return num.replace('.', ',');
}

/**
 * Displays result properly.
 */
function displayResult(question: IExerciseQuestion) {
  if (question.resultType === 'Fraction' && question.result) {
    return (
      <span>
        {formatNumber(question.result.numerator)}/{formatNumber(question.result.denominator)}
      </span>
    );
  }

  // This cast is safe because the result type is Int or Decimal.
  return <span>{formatNumber(question.result as string)}</span>;
}

/**
 * Displays the result of the question.
 */
export default function ResultDisplay({ question }: Props) {
  if (question.resultType === 'Select' || question.resultType === 'MultiSelect') {
    return <></>;
  }

  const color = question.isCorrect ? 'text-green-primary' : 'text-red';
  const classes = `flex justify-end items-center gap-[11px] pt-[12px] text-[16px] font-medium leading-[19px] ${color}`;

  if (question.isCorrect) {
    return (
      <div className={classes}>
        {_t('Correct answer')} <CorrectIcon />
      </div>
    );
  }

  return (
    <div className={classes}>
      <span>
        {_t('Correct answer is')} {displayResult(question)}
      </span>
      <IncorrectIcon />
    </div>
  );
}
