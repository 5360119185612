import HomeworkStatus from 'components/HomeworkStatus';

interface Props {
  title: string;
  isActive: boolean;
  titleClassNames?: string;
  classNames?: string;
}

/**
 * Homework title.
 */
export default function HomeworkTitle({
  title,
  isActive,
  titleClassNames = 'text-[18px] font-semibold leading-[22px]',
  classNames = '',
}: Props) {
  const status = isActive ? 'ongoing' : 'finished';

  return (
    <div className={`flex items-center gap-[12px] ${classNames}`}>
      <div className={titleClassNames}>{title}</div>
      <div>
        <HomeworkStatus status={status} />
      </div>
    </div>
  );
}
