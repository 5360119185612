import { AxiosInstance } from 'axios';
import { SweetPath } from 'sweet-path';
import dispatchAction from 'api/dispatch-action';

import { GetClassroomDetailPathParameters } from 'api/actions/get-classroom-detail/get-classroom-detail-path-parameters';

import { GetClassroomDetailResponse } from 'api/actions/get-classroom-detail/get-classroom-detail-response';

/**
 * The path of the GetClassroomDetail action.
 */
export const getClassroomDetailActionPath = new SweetPath<keyof GetClassroomDetailPathParameters>(
  '/classroom/{classroomId}',
  { path: '{param}' }
);

/**
 * Creates a contextualized GetClassroomDetail actions.
 */
export default function createGetClassroomDetailAction(axios: AxiosInstance) {
  return function getClassroomDetailAction({ parameters }: { parameters: GetClassroomDetailPathParameters }) {
    const path = getClassroomDetailActionPath.insert(parameters);

    return dispatchAction(() => axios.get<GetClassroomDetailResponse, GetClassroomDetailResponse>(path));
  };
}
