import { AxiosInstance } from 'axios';
import { SweetPath } from 'sweet-path';
import dispatchAction from 'api/dispatch-action';

import { KickClassroomStudentPathParameters } from 'api/actions/kick-classroom-student/kick-classroom-student-path-parameters';

import { KickClassroomStudentResponse } from 'api/actions/kick-classroom-student/kick-classroom-student-response';

/**
 * The path of the KickClassroomStudent action.
 */
export const kickClassroomStudentActionPath = new SweetPath<keyof KickClassroomStudentPathParameters>(
  '/classroom/{classroomId}/students/{studentId}/kick',
  { path: '{param}' }
);

/**
 * Creates a contextualized KickClassroomStudent actions.
 */
export default function createKickClassroomStudentAction(axios: AxiosInstance) {
  return function kickClassroomStudentAction({ parameters }: { parameters: KickClassroomStudentPathParameters }) {
    const path = kickClassroomStudentActionPath.insert(parameters);

    return dispatchAction(() => axios.post<KickClassroomStudentResponse, KickClassroomStudentResponse>(path));
  };
}
