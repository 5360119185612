import { Button, Menu } from '@mantine/core';
import RedCrossIcon from 'components/icons/RedCrossIcon';
import { _t } from 'lang';
import { Dispatch, SetStateAction, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import RemoveStudentModal from 'pages/teacher-module/classroom/students/modals/RemoveStudentModal';
import ThreeDotsIcon from 'components/icons/ThreeDotsIcon';

interface Props {
  studentId: string;
  index: number;
  fullName: string;
  avgSuccessRate?: number;
  setDisplayMessage: Dispatch<SetStateAction<boolean>>;
}

/**
 * One row with student details in the Classroom/Students page.
 */
export default function StudentDetailRow({ studentId, index, fullName, avgSuccessRate, setDisplayMessage }: Props) {
  const { classroomId } = useParams();
  const navigate = useNavigate();
  const [openRemoveModal, setOpenRemoveModal] = useState(false);

  return (
    <div className="flex items-center justify-between rounded-[10px] bg-white pb-[12px] pl-[24px] pr-[12px] pt-[12px] text-[16px] font-medium leading-[19px] lg:h-[66px]">
      <div className="flex w-[220px] items-center">
        <div className="w-[42px] pr-[27px] text-text-shadow">{index.toString().padStart(2, '0')}</div>
        <div>{fullName}</div>
      </div>
      <div className="w-[194px]">
        <div className="flex justify-center font-semibold">
          {avgSuccessRate ? `${Math.round(avgSuccessRate * 100)} %` : '-'}
        </div>
      </div>
      <div className="flex w-[176px] items-center gap-[16px] lg:w-[276px]">
        <Button
          className="h-[42px] w-[234px] rounded-[7px] bg-green-primary-opacity-01 text-[12px] font-semibold leading-[15px] text-green-primary hover:bg-green-primary-opacity-02  focus:bg-green-primary-opacity-02"
          onClick={() => navigate(`/teacher/classrooms/classroom/${classroomId}/students/student/${studentId}`)}
        >
          {_t('Learn more about the student')}
        </Button>
        <Menu
          // width={200} --> width is now determined by the content
          position="bottom-end"
          offset={8}
          styles={{
            dropdown: {
              padding: '8px!important',
              borderRadius: '7px',
              ':hover': {
                backgroundColor: 'white',
              },
            },
            item: {
              padding: '0!important',
            },
          }}
        >
          <Menu.Target>
            <div className="flex cursor-pointer items-center justify-center rounded-[7px] bg-bg-secondary-button-hover lg:h-[42px] lg:w-[42px]">
              <ThreeDotsIcon />
            </div>
          </Menu.Target>
          <Menu.Dropdown>
            <Menu.Item>
              <div
                className="bg flex items-center gap-[10px] pb-[9px] pl-[14px] pr-[8px] pt-[9px] hover:bg-bg-red-menu-item"
                onClick={() => {
                  setOpenRemoveModal(true);
                }}
              >
                <RedCrossIcon />
                <span className="text-[15px] font-medium leading-[15px] text-red">
                  {_t('Remove student from the classroom')}
                </span>
              </div>
            </Menu.Item>
          </Menu.Dropdown>
        </Menu>
      </div>
      <RemoveStudentModal
        studentId={studentId}
        opened={openRemoveModal}
        setOpened={setOpenRemoveModal}
        setDisplayMessage={setDisplayMessage}
      />
    </div>
  );
}
