interface Props {
  stroke?: string;
}

/**
 * Clock icon
 */
export default function ClockIcon({ stroke = '#353E45' }: Props) {
  return (
    <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx="7" cy="7" r="6" stroke={stroke} strokeWidth="2" />
      <path d="M7 4.5V7.5H9.5" stroke={stroke} strokeWidth="2" strokeLinecap="round" />
    </svg>
  );
}
