import { Accordion } from '@mantine/core';
import CourseProgressBar from 'components/CourseProgressBar';
import DurationDisplay from 'components/DurationDisplay';
import CourseStepIcon from 'components/icons/CourseStepIcon';
import ProgressText from 'components/ProgressText';
import { Link } from 'react-router-dom';
import CourseProgressCheckbox from 'pages/courses-module/course-step/sidebar/CourseProgressCheckbox';
import { useEffect, useRef, useState } from 'react';
import { _t } from 'lang';
import { LG_SCREEN_RESOLUTION } from 'theme';
import PretestLabel from 'components/PretestLabel';

interface CourseStep {
  stepId: number;
  title: string;
  type: string;
  duration: number;
  progress: number;
  completed: boolean;
  date?: string;
}

interface Props {
  activeStepId: number;
  step: CourseStep;
  courseId: string;
  courseSlug: string;
  sectionSlug: string;
  prefix?: string;
}

/**
 * Returns the step type in the translation function.
 */
function getStepType({ type }: CourseStep) {
  if (type === 'text') {
    return _t('Text');
  } else if (type === 'video') {
    return _t('Video');
  } else if (type === 'stream') {
    return _t('Live-stream');
  } else if (type === 'exercise') {
    return _t('Exercise');
  } else {
    return '';
  }
}

/**
 * The one item in the CourseStepSidebarPanel
 */
export default function CourseStepSidebarPanelItem({
  step,
  activeStepId,
  courseId,
  courseSlug,
  sectionSlug,
  prefix = '',
}: Props) {
  const ref = useRef<HTMLDivElement>(null);
  const [hover, setHover] = useState(false);
  const isActive = activeStepId === step.stepId;
  const activeOrHover = isActive || hover;

  // green-dark; text-dark; text-shadow
  const progressTextColor = step.completed ? '#089174' : activeOrHover ? '#353E45' : '#738491';
  // green-primary; text-dark; text-shadow
  const progressBarColor = step.completed ? '#0AB892' : activeOrHover ? '#353E45' : '#738491';
  const progressBarRootColor = activeOrHover ? '#9BA7B0' : '#D9E0E6';

  useEffect(() => {
    setTimeout(() => {
      if (isActive) {
        const isMobile = window.innerWidth < LG_SCREEN_RESOLUTION;

        if (isMobile) {
          window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
        } else {
          ref.current?.scrollIntoView({ behavior: 'smooth', block: 'start' });
        }
      }
    }, 0); // Next tick.
  }, [isActive]);

  return (
    <div ref={ref} onMouseEnter={() => setHover(true)} onMouseLeave={() => setHover(false)}>
      <Accordion.Panel>
        <Link
          to={`${prefix}/course/${courseSlug}/${sectionSlug}/step/${step.stepId}`}
          className={`block pb-[16px] pl-[32px] pr-[32px] pt-[16px] ${activeOrHover && 'bg-input-outline-shadow'}`}
        >
          <div className="flex h-[25px] gap-[12px]">
            <CourseProgressCheckbox value={step.completed} courseId={courseId} stepId={step.stepId} />
            <span className="overflow-hidden text-ellipsis whitespace-nowrap text-[14px] font-medium leading-[17px]">
              {step.title}
            </span>
          </div>
          <div className={`pl-[28px] text-[12px] font-normal text-text-shadow ${activeOrHover && 'text-text-dark'}`}>
            <div className="flex w-full items-stretch justify-between">
              <div className="flex items-center gap-[4px]">
                {step.type === 'pretest' ? (
                  <PretestLabel />
                ) : (
                  <>
                    <CourseStepIcon courseStepType={step.type} active={activeOrHover} />
                    <span>{getStepType(step)}</span>
                    <span>&#183;</span>
                    {step.type === 'stream' ? <>{step.date}</> : <DurationDisplay duration={step.duration} />}
                  </>
                )}
              </div>
              <div className="flex items-center gap-[4px]">
                <ProgressText progress={step.progress} color={progressTextColor} />
              </div>
            </div>
            <CourseProgressBar progress={step.progress} rootColor={progressBarRootColor} color={progressBarColor} />
          </div>
        </Link>
      </Accordion.Panel>
    </div>
  );
}
