/**
 * Formats the given string using the given parameters.
 */
export default function sprintf(str: string, ...params: (string | number)[]) {
  let i = 0;
  return str.replace(/%[sd]/g, () => {
    const replacement = i < params.length ? String(params[i]) : '';

    i++;

    return replacement;
  });
}
