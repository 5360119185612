/**
 * Question Mark Icon
 */
export default function QuestionMarkIcon() {
  return (
    <svg width="9" height="13" viewBox="0 0 9 13" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M3.17116 8.57955V8.43182C3.17495 7.74621 3.23935 7.20076 3.36435 6.79545C3.49313 6.39015 3.67495 6.06439 3.9098 5.81818C4.14465 5.56818 4.43063 5.33901 4.76776 5.13068C4.99882 4.98674 5.20526 4.82955 5.38707 4.65909C5.57268 4.48485 5.71851 4.29167 5.82457 4.07955C5.93063 3.86364 5.98366 3.62311 5.98366 3.35795C5.98366 3.05871 5.91359 2.79924 5.77344 2.57955C5.63329 2.35985 5.44389 2.18939 5.20526 2.06818C4.97041 1.94697 4.70715 1.88636 4.41548 1.88636C4.14654 1.88636 3.89086 1.94508 3.64844 2.0625C3.4098 2.17614 3.21094 2.35038 3.05185 2.58523C2.89654 2.81629 2.80942 3.10985 2.79048 3.46591H0.767756C0.786695 2.74621 0.960938 2.14394 1.29048 1.65909C1.62382 1.17424 2.06321 0.810606 2.60866 0.568181C3.15791 0.325757 3.76397 0.204545 4.42685 0.204545C5.15033 0.204545 5.7867 0.331439 6.33594 0.585227C6.88897 0.839015 7.31889 1.20076 7.62571 1.67045C7.93632 2.13636 8.09162 2.68939 8.09162 3.32955C8.09162 3.76136 8.02154 4.14773 7.88139 4.48864C7.74503 4.82955 7.54995 5.13258 7.29616 5.39773C7.04238 5.66288 6.74124 5.89962 6.39276 6.10795C6.08594 6.29735 5.83404 6.49432 5.63707 6.69886C5.44389 6.90341 5.29995 7.14394 5.20526 7.42045C5.11435 7.69318 5.067 8.0303 5.06321 8.43182V8.57955H3.17116ZM4.1598 12.125C3.81889 12.125 3.52533 12.0038 3.27912 11.7614C3.03291 11.5189 2.9098 11.2235 2.9098 10.875C2.9098 10.5341 3.03291 10.2424 3.27912 10C3.52533 9.75758 3.81889 9.63636 4.1598 9.63636C4.49692 9.63636 4.78859 9.75758 5.0348 10C5.2848 10.2424 5.4098 10.5341 5.4098 10.875C5.4098 11.1061 5.35109 11.3163 5.23366 11.5057C5.12003 11.6951 4.96851 11.8466 4.77912 11.9602C4.59351 12.0701 4.38707 12.125 4.1598 12.125Z"
        fill="#535F6A"
      />
    </svg>
  );
}
