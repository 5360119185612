/**
 * Large thumbs up icon
 */
export default function ThumbsUpLargeIcon() {
  return (
    <svg width="40" height="36" viewBox="0 0 40 36" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M9 15.8039H11.4253C12.794 15.8039 14.0676 15.1041 14.8015 13.9489L20.9629 4.25081C21.8534 2.84909 23.3988 2 25.0595 2V2C26.5606 2 27.7016 3.34906 27.4526 4.82932L26.178 12.4054C25.9728 13.6254 26.9132 14.7373 28.1503 14.7373H35.2592C37.1787 14.7373 38.6045 16.515 38.1876 18.3888L35.2368 31.6515C34.9315 33.0238 33.7142 34 32.3084 34H16.6524C15.6012 34 14.5923 33.5862 13.8438 32.8481L12.4895 31.5126C11.741 30.7746 10.7321 30.3608 9.68094 30.3608H9"
        stroke="#353E45"
        strokeWidth="3"
        strokeLinecap="round"
      />
      <path
        d="M2 33H7C8.10457 33 9 32.1046 9 31V16C9 14.8954 8.10457 14 7 14H2"
        stroke="#353E45"
        strokeWidth="3"
        strokeLinecap="round"
      />
      <path d="M37 21L31 21" stroke="#353E45" strokeWidth="3" strokeLinecap="round" />
      <path d="M35 27L29 27" stroke="#353E45" strokeWidth="3" strokeLinecap="round" />
    </svg>
  );
}
