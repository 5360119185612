interface Props {
  stroke: string;
  className?: string;
}
/**
 * Logout icon.
 */
export default function LogoutIcon({ stroke, className }: Props) {
  return (
    <svg
      className={className}
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8 1H3C1.89543 1 1 1.89543 1 3V15C1 16.1046 1.89543 17 3 17H8"
        stroke={stroke}
        strokeWidth="2"
        strokeLinecap="round"
      />
      <path d="M5 9L16 9" stroke={stroke} strokeWidth="2" strokeLinecap="round" />
      <path
        d="M13 5L17.0001 9.00012L13 13.0002"
        stroke={stroke}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
