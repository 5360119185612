import { IExerciseQuestion, IExerciseQuestionAnswer } from 'data/assignment/assignment';
import MarkdownText from 'components/MarkdownText';
import NumericInput from 'components/assignment/inputs/NumericInput';
import AssignmentQuestionResult from 'components/assignment/AssignmentQuestionResult';
import useShouldValidate from 'components/forms/inputs/use-should-validate';
import SelectInput from 'components/assignment/inputs/SelectInput';
import MultiselectInput from 'components/assignment/inputs/MultiselectInput';
import { _t, _tx } from 'lang';
import sprintf from 'lang/sprintf';
import FractionInput from 'components/assignment/inputs/FractionInput';

/**
 * The parameters of the component.
 */
interface Props {
  // Layout props
  questionIndex: number;
  hasDescription: boolean;
  isPrint: boolean;
  // Data props
  assignmentIsSolved: boolean;
  question: IExerciseQuestion;
  isValid: boolean;
  // Input props
  onBlur?: any;
  onChange: any;
  onFocus?: any;
  readOnly: boolean;
  value: IExerciseQuestionAnswer | undefined;
}

/**
 * A single assignment question.
 */
export default function AssignmentQuestion({
  questionIndex,
  hasDescription,
  assignmentIsSolved,
  question,
  isValid,
  ...props
}: Props) {
  const { onBlur, shouldValidate } = useShouldValidate(props);

  const error = assignmentIsSolved
    ? !question.isCorrect
    : shouldValidate && !isValid
    ? _t('Please check the answer format.')
    : null;

  const inputProps = {
    ...props,
    question,
    assignmentIsSolved,
    onBlur,
    error,
  };

  const textSize = hasDescription ? 'text-[20px] leading-[28px]' : 'text-[24px] leading-[34px]';

  return (
    <div className="assignment-question">
      {questionIndex !== 0 && !hasDescription && (
        <div className="mb-[48px] mt-[48px] h-[1px] w-full bg-input-outline-shadow" />
      )}

      <div className="mb-[48px]">
        <div className={`text-text-dark ${textSize}`}>
          <MarkdownText text={`${hasDescription ? `${questionIndex + 1}\\. ` : ''}${question.question}`} />
        </div>
        <div className="pt-[24px]">
          {question.resultType === 'Int' ? (
            <NumericInput
              {...inputProps}
              value={inputProps.value as string} // This cast is safe because the result type is Int.
              label={_t('Write the result as an integer')}
            />
          ) : question.resultType === 'Decimal' ? (
            <NumericInput
              {...inputProps}
              value={inputProps.value as string} // This cast is safe because the result type is Decimal.
              label={sprintf(
                // Translators: Text above the input field for the decimal answer. The %d is a placeholder for the number of decimal places.
                _tx('Write the result as a decimal number with %d decimal place', 'Write the result as a decimal number with %d decimal places', question.precision), // prettier-ignore
                question.precision
              )}
            />
          ) : question.resultType === 'Fraction' ? (
            <FractionInput
              {...inputProps}
              value={inputProps.value as string} // This cast is safe because the result type is Fraction.
              simplestForm={question.simplestForm}
            />
          ) : question.resultType === 'Select' ? (
            <SelectInput
              {...inputProps}
              value={inputProps.value as string} // This cast is safe because the result type is Select.
              options={question.options}
              result={question.result}
            />
          ) : question.resultType === 'MultiSelect' ? (
            <MultiselectInput
              {...inputProps}
              value={inputProps.value as string[]} // This cast is safe because the result type is Select.
              options={question.options}
              result={question.result}
            />
          ) : (
            <></>
          )}
        </div>
        {assignmentIsSolved && <AssignmentQuestionResult question={question} />}
      </div>
    </div>
  );
}
