import { Menu } from '@mantine/core';
import StudentIcon from 'components/icons/StudentIcon';
import ThreeDotsIcon from 'components/icons/ThreeDotsIcon';
import { _t, _tx } from 'lang';
import { Link, useNavigate } from 'react-router-dom';
import { GetClassroomsResponse } from 'api/actions/get-classrooms/get-classrooms-response';
import InviteStudentModal from 'pages/teacher-module/classroom/students/modals/InviteStudentModal';
import { useState } from 'react';
import sprintf from 'lang/sprintf';
import NumberInCircle from 'components/NumberInCircle';
import PlusIcon from 'components/icons/PlusIcon';
import ClassroomMenuItem from 'pages/teacher-module/classrooms/content/ClassroomMenuItem';
// import PenIcon from 'components/icons/PenIcon';
import PaperIcon from 'components/icons/PaperIcon';
import AddPersonIcon from 'components/icons/AddPersonIcon';
import TruncatedText from 'pages/teacher-module/classrooms/content/TruncatedText';

interface Props {
  name: string;
  classroomId: string;
  numberOfStudents: number;
  activeHomework: GetClassroomsResponse['classrooms'][number]['activeHomework'];
}

/**
 * One row in the list of teacher's classrooms.
 */
export default function ClassroomRow({ name, classroomId, numberOfStudents, activeHomework }: Props) {
  const navigate = useNavigate();
  const [openInviteStudentModal, setOpenInviteStudentsModal] = useState(false);

  return (
    <div className="flex w-full items-center justify-between rounded-[7px] bg-white lg:h-[66px] lg:pb-[12px] lg:pl-[24px] lg:pr-[14px] lg:pt-[12px]">
      <div className="flex items-center lg:gap-[120px]">
        <div className="flex items-center lg:gap-[60px] lg:pb-[10px] lg:pt-[10px]">
          <TruncatedText text={name} classNames="w-[120px] text-[18px] font-semibold leading-[22px]" />
          <div className="flex items-center lg:w-[105px] lg:gap-[8px]">
            <StudentIcon />
            <div className="text-[14px] font-medium leading-[17px] text-text-shadow">
              {sprintf(_tx('%d student', '%d students', numberOfStudents), numberOfStudents)}
            </div>
          </div>
        </div>
        {activeHomework.length > 0 && (
          <div className="flex items-center gap-[4px]">
            <NumberInCircle value={activeHomework.length} active={true} />
            <div className="text-[10px] font-medium leading-[12px] text-text-shadow">
              {/* Translator: Number of active homework in a classroom */}
              {_tx('active homework', 'active homework', activeHomework.length)}
            </div>
          </div>
        )}
      </div>
      <div className="flex items-center lg:gap-[16px]">
        <Link
          className="flex h-[42px] w-[234px] items-center justify-center rounded-[7px] bg-green-primary-opacity-01 text-[12px] font-semibold leading-[15px] text-green-primary hover:bg-green-primary-opacity-02 active:bg-green-primary-opacity-02"
          to={`/teacher/classrooms/classroom/${classroomId}/detail`}
          state={{ switchingClassroom: true }}
        >
          {_t('Enter the classroom')}
        </Link>
        <div>
          <Menu
            // width={200} --> width is now determined by the content
            position="bottom-end"
            offset={8}
            styles={{
              dropdown: {
                padding: '8px!important',
                borderRadius: '7px',
                ':hover': {
                  backgroundColor: 'white',
                },
              },
              item: {
                padding: '0!important',
              },
            }}
          >
            <Menu.Target>
              <div className="flex cursor-pointer items-center justify-center rounded-[7px] bg-bg-secondary-button-hover lg:h-[42px] lg:w-[42px]">
                <ThreeDotsIcon />
              </div>
            </Menu.Target>
            <Menu.Dropdown>
              <div className="flex flex-col gap-[8px]">
                <ClassroomMenuItem
                  icon={<PlusIcon stroke="#535F6A" strokeWidth="1" />}
                  activeIcon={<PlusIcon stroke="#353E45" strokeWidth="1" />}
                  label={_t('Assign homework')}
                  onClick={() => {
                    navigate(`/teacher/classrooms/classroom/${classroomId}/exercises`);
                  }}
                />
                {/* <ClassroomMenuItem
                  icon={<PenIcon stroke="#535F6A" lgHeight="11" lgWidth="15" lgStrokeWidth="1" />}
                  activeIcon={<PenIcon stroke="#353E45" lgHeight="11" lgWidth="15" lgStrokeWidth="1" />}
                  label={_t('Change classroom name')}
                  onClick={() => {
                    alert(_t('Not implemented!'));
                  }}
                /> */}
                <ClassroomMenuItem
                  icon={<AddPersonIcon stroke="#535F6A" width="16" height="13" strokeWidth="1" />}
                  activeIcon={<AddPersonIcon stroke="#353E45" width="16" height="13" strokeWidth="1" />}
                  label={_t('Invite students')}
                  onClick={() => {
                    setOpenInviteStudentsModal(true);
                  }}
                />
                {activeHomework.map((activeHw) => (
                  // TODO the icon should be with strokeWidth = 1
                  <ClassroomMenuItem
                    key={activeHw.homeworkId}
                    icon={<PaperIcon stroke="#535F6A" />}
                    activeIcon={<PaperIcon stroke="#353E45" />}
                    // Translator: %d is the number of the homework.
                    label={sprintf(_t('Check homework #%d'), activeHw.homeworkNumber)}
                    onClick={() => {
                      navigate(`/teacher/classrooms/classroom/${classroomId}/homework/homework/${activeHw.homeworkId}`);
                    }}
                  />
                ))}
              </div>
            </Menu.Dropdown>
          </Menu>
        </div>
      </div>
      <InviteStudentModal
        opened={openInviteStudentModal}
        setOpened={setOpenInviteStudentsModal}
        classroomId={classroomId}
      />
    </div>
  );
}
