/**
 * Incorrect icon.
 */
export default function IncorrectIcon() {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx="12" cy="12" r="9" fill="#E82C2C" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.08867 9.80919C7.70319 9.41369 7.70319 8.77245 8.08867 8.37695C8.47415 7.98145 9.09914 7.98145 9.48463 8.37695L11.9981 10.9558L14.5117 8.37695C14.8971 7.98145 15.5221 7.98145 15.9076 8.37695C16.2931 8.77245 16.2931 9.41369 15.9076 9.80919L13.3941 12.388L15.4291 14.476C15.8146 14.8715 15.8146 15.5127 15.4291 15.9082C15.0436 16.3037 14.4186 16.3037 14.0332 15.9082L11.9981 13.8203L9.96312 15.9082C9.57763 16.3037 8.95264 16.3037 8.56716 15.9082C8.18168 15.5127 8.18168 14.8715 8.56716 14.476L10.6022 12.388L8.08867 9.80919Z"
        fill="#F3F6F8"
      />
    </svg>
  );
}
