import { AxiosInstance } from 'axios';
import { SweetPath } from 'sweet-path';
import dispatchAction from 'api/dispatch-action';

import { GetCoursePlanPathParameters } from 'api/actions/get-course-plan/get-course-plan-path-parameters';

import { GetCoursePlanResponse } from 'api/actions/get-course-plan/get-course-plan-response';

/**
 * The path of the GetCoursePlan action.
 */
export const getCoursePlanActionPath = new SweetPath<keyof GetCoursePlanPathParameters>(
  '/course/{courseIdOrSlug}/plan',
  { path: '{param}' }
);

/**
 * Creates a contextualized GetCoursePlan actions.
 */
export default function createGetCoursePlanAction(axios: AxiosInstance) {
  return function getCoursePlanAction({ parameters }: { parameters: GetCoursePlanPathParameters }) {
    const path = getCoursePlanActionPath.insert(parameters);

    return dispatchAction(() => axios.get<GetCoursePlanResponse, GetCoursePlanResponse>(path));
  };
}
