import { Button, Modal, ScrollArea, Switch, Tooltip } from '@mantine/core';
import { GetClassroomStudentsResponse } from 'api/actions/get-classroom-students/get-classroom-students-response';
import useApi from 'api/use-api';
import ClockIcon from 'components/icons/ClockIcon';
import CrossIcon from 'components/icons/CrossIcon';
import StudentIcon from 'components/icons/StudentIcon';
import TickIcon from 'components/icons/TickIcon';
import Clickable from 'components/misc/Clickable';
import { _t, lang } from 'lang';
import { isEmpty, noop } from 'lodash';
import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { DatePicker } from '@mantine/dates';
import CalendarIcon from 'components/icons/CalendarIcon';
import dayjs from 'dayjs';
import ExclamationMarkIcon from 'components/icons/ExclamationMarkIcon';
import CopyInviteCode from 'layout/teacher-dashboard-layout/parts/sidebar/CopyInviteCode';
import useClassrooms from 'data/classrooms/use-classrooms';
import IIcon from 'components/icons/IIcon';
import TimePicker from 'pages/teacher-module/classroom/exercises/modals/TimePicker';
import { useForm } from '@mantine/form';
import StudentSelect from 'pages/teacher-module/classroom/exercises/modals/StudentSelect';

interface Props {
  selectedExercises: { variants: number; exerciseId: number }[];
  opened: boolean;
  setOpened: Dispatch<SetStateAction<boolean>>;
}

interface FormData {
  date?: Date;
  time: { hours?: number; minutes?: number };
  students: string[];
  assignToIndividuals: boolean;
}

/**
 * The assign exercises modal.
 *
 * @see https://www.figma.com/file/SjggpqHVlWxBKa2cIG349A/Math-Exercises?node-id=629-7117&t=Galbe5mORUZiCo4v-0
 */
export default function AssignExercisesModal({ selectedExercises, opened, setOpened }: Props) {
  const { classroomId } = useParams();
  const navigate = useNavigate();
  const { assignHomework } = useClassrooms();
  const { getAction } = useApi();
  const getClassroomStudentsAction = getAction('GetClassroomStudents');
  const [students, setStudents] = useState<GetClassroomStudentsResponse['students']>([]);
  const [fill, setFill] = useState('#738491');
  const [loading, setLoading] = useState(false);
  const { getClassroom } = useClassrooms();

  const classroom = getClassroom({ classroomId: classroomId! });

  /**
   * The form data.
   */
  const form = useForm<FormData>({
    initialValues: {
      date: undefined,
      time: {
        hours: undefined,
        minutes: undefined,
      },
      students: [],
      assignToIndividuals: false,
    },

    validate: {
      date: (val: any) => {
        if (!val) {
          return _t('Please select a date');
        }

        return null;
      },
      time: (val) => {
        if (!val || val.hours === undefined || val.minutes === undefined) {
          return _t('Please select a time');
        }

        return null;
      },
      students: (val, { assignToIndividuals }) => {
        if (assignToIndividuals && isEmpty(val)) {
          return _t('Please select at least one student');
        }

        return null;
      },
    },
  });

  /**
   * Makes the call to the API and assigns the homework to the classroom.
   */
  function assignHomeworkToClassroom({ date, time, students, assignToIndividuals }: FormData) {
    setLoading(true);

    const deadline = new Date(date!.getFullYear(), date!.getMonth(), date!.getDate(), time.hours, time.minutes, 0);

    const exercises = selectedExercises.map((exercise) => ({
      count: exercise.variants,
      exerciseId: exercise.exerciseId,
    }));

    let mappedStudents = undefined;
    if (assignToIndividuals) {
      mappedStudents = students.map((student) => ({ userId: student }));
    }

    assignHomework({
      classroomId: classroomId!,
      deadline: deadline.toISOString(),
      exerciseSet: {
        language: lang,
        exercises,
      },
      students: mappedStudents,
    })
      .success(({ homeworkId }) => {
        setOpened(false);

        navigate(`/teacher/classrooms/classroom/${classroomId}/detail`, {
          state: { displaySuccessNotification: true, homeworkId },
        });
      })
      .complete(() => {
        setLoading(false);
      });
  }

  useEffect(() => {
    getClassroomStudentsAction({ parameters: { classroomId: classroomId! } }).success(({ students }) => {
      const s = students.sort(({ student: first }, { student: second }) =>
        first.lastName.localeCompare(second.lastName)
      );
      setStudents(s);
    });
  }, [classroomId]);

  return (
    <Modal
      opened={opened}
      onClose={noop} // this is a required prop
      centered
      size="auto"
      withCloseButton={false}
      radius={10}
      styles={{
        root: {
          padding: '0!important',
        },
        body: {
          padding: '0!important',
        },
        modal: {
          padding: '0!important',
        },
      }}
    >
      <div className="h-[815px] w-[570px] rounded-[10px] bg-white">
        <div className="flex flex-col">
          <div className="flex h-[90px] items-center justify-between rounded-[10px_10px_0_0] border-b border-b-input-outline-shadow bg-[#FCFCFD] pb-[33px] pl-[39px] pr-[35px] pt-[33px]">
            <div className="flex items-center gap-[9px] text-[20px] font-semibold leading-[24px] text-text-dark">
              {_t('Assign homework')}
              <Tooltip
                width={250}
                withArrow
                multiline
                label={_t('Each student will be assigned a different variant of each exercise.')} // prettier-ignore
              >
                <div className="flex translate-y-[1px] cursor-help items-center justify-center p-[4px]">
                  <span className="ml-[2px] inline-flex h-[19px] w-[19px] items-center justify-center rounded-[50%] bg-pretest-blue text-[14px] font-bold text-[white]">
                    ?
                  </span>
                </div>
              </Tooltip>
            </div>
            <Clickable
              onClick={() => setOpened(false)}
              className="flex h-[42px] w-[42px] items-center justify-center rounded-[7px] p-[8px] hover:bg-bg-shadow"
              onMouseEnter={() => {
                setFill('#353E45');
              }}
              onMouseLeave={() => {
                setFill('#738491');
              }}
            >
              <CrossIcon fill={fill} />
            </Clickable>
          </div>
          {students && !isEmpty(students) ? (
            <div>
              <form onSubmit={form.onSubmit(assignHomeworkToClassroom)}>
                <ScrollArea.Autosize maxHeight={619}>
                  <div className="min-h-[619px] pb-[33px] pl-[35px] pr-[32px] pt-[33px]">
                    <div className="flex items-center gap-[11px]">
                      <ClockIcon />
                      <div className="text-[18px] font-semibold leading-[22px]">{_t('Homework deadline')}</div>
                    </div>
                    <div className="pl-[27px] pt-[4px]">
                      <div className="text-[14px] leading-[17px] text-text-dark-2">
                        {
                          _t('Input the exact date and time the assignment should be finished. Students will not be able to submit their homework after the deadline.') // prettier-ignore
                        }
                      </div>
                      <div className="flex flex-col gap-[16px] pt-[20px]">
                        <DatePicker
                          placeholder={_t('Pick the date')}
                          icon={<CalendarIcon />}
                          {...form.getInputProps('date')}
                          clearable={false}
                          // TODO the locale does not work properly
                          locale={dayjs.locale(lang)}
                          minDate={new Date()}
                          radius={7}
                          styles={{
                            root: {
                              height: '42px!important',
                            },
                            input: {
                              height: '42px!important',
                              fontWeight: 600,
                              backgroundColor: '#F3F6F8',
                              borderColor: '#D9E0E6',
                              '::placeholder': {
                                fontWeight: 400,
                                color: '#738491',
                              },
                            },
                            day: {
                              '&[data-selected]': {
                                color: '#FFF!important',
                                backgroundColor: '#0AB892!important',
                                borderRadius: '100%',
                              },
                              '&[data-weekend]': {
                                color: '#353E45!important',
                                '&[data-selected]': {
                                  color: '#FFF!important',
                                },
                              },
                              '&[data-outside]': {
                                color: '#ced4da!important',
                              },
                            },
                          }}
                        />
                        <TimePicker {...form.getInputProps('time')} />
                      </div>
                      <div className="border-b border-b-input-outline-shadow pt-[32px]"></div>
                    </div>
                    <div className="flex items-center justify-between">
                      <div className="flex items-center gap-[11px] pt-[33px]">
                        <StudentIcon stroke="#353E45" />
                        <div className="text-[18px] font-semibold leading-[22px]">
                          {_t('Assign the homework to individuals only')}
                        </div>
                      </div>
                      <div className="h-[32px]">
                        <Switch
                          checked={form.getInputProps('assignToIndividuals').value}
                          onChange={form.getInputProps('assignToIndividuals').onChange}
                          size="lg"
                          styles={{
                            trackLabel: { cursor: 'pointer!important' },
                            track: {
                              backgroundColor: form.getInputProps('assignToIndividuals').value ? '#0AB892' : '#F3F6F8',
                              height: '32px',
                              cursor: 'pointer!important',
                            },
                          }}
                        />
                      </div>
                    </div>
                    <div className="pl-[25px] pt-[4px]">
                      <div className="w-[400px] text-[14px] leading-[17px] text-text-dark-2">
                        {_t('The homework can be assigned either to the whole class or to some students only.')}
                      </div>
                      {form.getInputProps('assignToIndividuals').value && (
                        <div className="mt-[20px] rounded-[5px] bg-bg-shadow p-[10px]">
                          {students.length > 0 ? (
                            <div className="flex flex-col gap-[4px]">
                              {students.map(({ student }) => (
                                <StudentSelect
                                  key={student.userId}
                                  firstName={student.firstName}
                                  lastName={student.lastName}
                                  studentId={student.userId}
                                  checked={form.getInputProps('students').value.includes(student.userId)}
                                  checkedStudents={form.getInputProps('students').value}
                                  {...form.getInputProps('students')}
                                />
                              ))}
                            </div>
                          ) : (
                            <div>{_t('There are no students in the classroom!')}</div>
                          )}
                        </div>
                      )}
                    </div>
                  </div>
                </ScrollArea.Autosize>
                <div className="flex h-[106px] items-center justify-end border-t border-t-input-outline-shadow pb-[32px] pl-[46px] pr-[24px] pt-[32px]">
                  <Button
                    className="h-[42px] w-[198px] rounded-[7px] bg-green-primary hover:bg-green-dark focus:bg-green-dark"
                    type="submit"
                    disabled={!form.isValid() || loading}
                  >
                    <div className="flex items-center gap-[9px] text-[14px] font-semibold leading-[17px] text-white">
                      <TickIcon />
                      {_t('Assign')}
                    </div>
                  </Button>
                </div>
              </form>
            </div>
          ) : (
            <div className="flex flex-col">
              <div className="min-h-[619px] pb-[33px] pl-[35px] pr-[32px] pt-[33px]">
                <div className="flex items-center gap-[11px]">
                  <ExclamationMarkIcon />
                  <div className="text-[18px] font-semibold leading-[22px] text-red">
                    {_t('There are no students in the classroom')}
                  </div>
                </div>
                <div className="pb-[32px] pl-[29px] pt-[4px]">
                  <div className="text-[12px] leading-[18px] text-red">
                    {_t('Share the classroom invite code with students who should join this classroom.')}
                  </div>
                  <div className="pt-[16px]">
                    <CopyInviteCode inviteCode={classroom ? classroom.inviteCode : ''} />
                  </div>
                  <div className="flex items-center gap-[15px] pt-[16px]">
                    <div>
                      <IIcon />
                    </div>
                    <div className="text-[12px] leading-[18px] text-text-dark-2">
                      {_t('The student will input this code during or after the registration.')}{' '}
                      {_t('The student will then be added to the classroom.')}
                    </div>
                  </div>
                </div>
              </div>
              <div className="flex h-[106px] items-center justify-end border-t border-t-input-outline-shadow pb-[32px] pl-[46px] pr-[24px] pt-[32px]">
                <Button
                  className="h-[42px] w-[198px] rounded-[7px] bg-green-primary hover:bg-green-dark focus:bg-green-dark"
                  onClick={() => {
                    setOpened(false);
                  }}
                >
                  <div className="flex items-center gap-[9px] text-[14px] font-semibold leading-[17px] text-white ">
                    <CrossIcon fill="#FFF" width="14" height="14" />
                    {_t('Cancel')}
                  </div>
                </Button>
              </div>
            </div>
          )}
        </div>
      </div>
    </Modal>
  );
}
