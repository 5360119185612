/**
 * White star.
 */
export default function WhiteStarIcon() {
  return (
    <svg width="14" height="13" viewBox="0 0 14 13" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M6.54669 0.328601C6.68114 -0.109535 7.31886 -0.109533 7.45331 0.328603L8.6985 4.38646C8.75954 4.58537 8.94988 4.71992 9.16318 4.71493L13.5146 4.61318C13.9844 4.6022 14.1815 5.1923 13.7947 5.45209L10.2129 7.85824C10.0373 7.97619 9.96463 8.19389 10.0354 8.38972L11.4795 12.3847C11.6355 12.816 11.1195 13.1807 10.7461 12.9032L7.28718 10.3324C7.11763 10.2064 6.88237 10.2064 6.71282 10.3324L3.25394 12.9032C2.88047 13.1807 2.36455 12.816 2.52047 12.3847L3.96458 8.38972C4.03537 8.19389 3.96266 7.97619 3.78709 7.85824L0.205276 5.45209C-0.181461 5.1923 0.0156079 4.6022 0.485437 4.61318L4.83682 4.71493C5.05012 4.71992 5.24046 4.58537 5.3015 4.38646L6.54669 0.328601Z"
        fill="white"
      />
    </svg>
  );
}
