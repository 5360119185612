import { Button, Modal, ScrollArea, Tooltip } from '@mantine/core';
import useApi from 'api/use-api';
import Assignment from 'components/assignment/Assignment';
import CrossIcon from 'components/icons/CrossIcon';
import { IconPrinter } from '@tabler/icons-react';
import TickIcon from 'components/icons/TickIcon';
import Clickable from 'components/misc/Clickable';
import { _t, lang } from 'lang';
import { isEmpty, noop } from 'lodash';
import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { EXPORT_EXERCISES_PAGE_PATH } from 'routes/paths';

interface Props {
  selectedExercises: { variants: number; exerciseId: number }[];
  opened: boolean;
  setOpened: Dispatch<SetStateAction<boolean>>;
  openAssignExercisesModal: Dispatch<SetStateAction<boolean>>;
}

/**
 * This modal displays the detail of the selected assignments.
 */
export default function SelectedAssignmentsModal({
  selectedExercises,
  opened,
  setOpened,
  openAssignExercisesModal,
}: Props) {
  const { getAction } = useApi();
  const createAssignmentAction = getAction('CreateAssignment');
  const [assignmentId, setAssignmentId] = useState<string>();
  const [fill, setFill] = useState('#738491');

  useEffect(() => {
    if (!isEmpty(selectedExercises)) {
      const exercises = selectedExercises.map((exercise) => ({
        exerciseId: exercise.exerciseId,
        count: exercise.variants,
      }));

      createAssignmentAction({
        payload: {
          exerciseSet: {
            language: lang,
            exercises,
          },
        },
      }).success(({ assignmentId }) => {
        setAssignmentId(assignmentId);
      });
    }
  }, [selectedExercises]);

  return (
    <Modal
      opened={opened}
      onClose={noop} // this is a required prop
      centered
      size="auto"
      withCloseButton={false}
      radius={10}
      styles={{
        root: {
          padding: '0!important',
        },
        body: {
          padding: '0!important',
        },
        modal: {
          padding: '0!important',
        },
      }}
    >
      <div className="flex h-[815px] w-[970px] flex-col rounded-[10px] bg-white">
        <div className="flex h-[90px] items-center justify-between rounded-[10px_10px_0_0] border-b border-b-input-outline-shadow bg-[#FCFCFD] pb-[33px] pl-[39px] pr-[35px] pt-[33px]">
          <div className="flex items-center gap-[4px] text-[20px] font-semibold leading-[24px] text-text-dark">
            {_t('Homework preview')}
            <Tooltip
              width={250}
              withArrow
              multiline
              // Translators: Tooltip text which explains that this is only an example of a homework.
              label={_t('This is only an example of a homework. Each student will be assigned a different variant of each exercise.')} // prettier-ignore
            >
              <div className="flex translate-y-[1px] cursor-help items-center justify-center p-[4px]">
                <span className="ml-[2px] inline-flex h-[19px] w-[19px] items-center justify-center rounded-[50%] bg-pretest-blue text-[14px] font-bold text-[white]">
                  ?
                </span>
              </div>
            </Tooltip>
          </div>
          <Clickable
            onClick={() => setOpened(false)}
            className="flex h-[42px] w-[42px] items-center justify-center rounded-[7px] p-[8px] hover:bg-bg-shadow"
            onMouseEnter={() => {
              setFill('#353E45');
            }}
            onMouseLeave={() => {
              setFill('#738491');
            }}
          >
            <CrossIcon fill={fill} />
          </Clickable>
        </div>
        <ScrollArea>
          <div className="min-h-[619px]">
            <div className="ml-auto mr-auto w-[570px] pb-[48px] pt-[48px]">
              {assignmentId && <Assignment assignmentId={assignmentId} />}
            </div>
          </div>
        </ScrollArea>
        <div className="flex h-[106px] items-stretch justify-between border-t border-t-input-outline-shadow pb-[32px] pl-[46px] pr-[24px] pt-[32px]">
          <div className="flex flex-row gap-[16px]">
            <Button
              className="h-[42px] w-[198px] rounded-[7px] border border-text-shadow bg-white text-text-dark"
              component={Link}
              to={EXPORT_EXERCISES_PAGE_PATH.replace(':assignmentId', assignmentId ?? '')}
              target="_blank"
            >
              <div className="flex items-center gap-[13px] text-[14px] font-semibold leading-[17px]">
                <IconPrinter size={20} />
                <div>{_t('Print')}</div>
              </div>
            </Button>
          </div>
          <div className="flex flex-row gap-[16px]">
            <Button
              className="h-[42px] w-[198px] rounded-[7px] bg-green-primary-opacity-01 text-green-primary hover:bg-green-primary-opacity-02 focus:bg-green-primary-opacity-02"
              onClick={() => {
                setOpened(false);
              }}
            >
              {_t('Add more exercises')}
            </Button>
            <Button
              className="h-[42px] w-[198px] rounded-[7px] bg-green-primary hover:bg-green-dark focus:bg-green-dark"
              onClick={() => {
                setOpened(false);
                openAssignExercisesModal(true);
              }}
            >
              <div className="flex items-center gap-[9px] text-[14px] font-semibold leading-[17px] text-white ">
                <TickIcon />
                {_t('Assign')}
              </div>
            </Button>
          </div>
        </div>
      </div>
    </Modal>
  );
}
