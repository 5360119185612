import { UserRole } from 'api/api-context';
import useApi from 'api/use-api';
import { _t } from 'lang';
import { Link } from 'react-router-dom';
import { CLASSROOMS_PAGE_PATH, REGISTER_PAGE_PATH, STUDENT_DASHBOARD_PAGE_PATH } from 'routes/paths';

/**
 * Returns the link target for the CTA button.
 */
function getLinkTarget(role: UserRole) {
  switch (role) {
    case 'teacher':
      return CLASSROOMS_PAGE_PATH;
    case 'student':
      return STUDENT_DASHBOARD_PAGE_PATH;
    case 'guest':
      return REGISTER_PAGE_PATH;
    default:
      return ''; // This should never happen.
  }
}

/**
 * Main CTA Button.
 */
export default function MainCtaButton() {
  const { role } = useApi();
  const target = getLinkTarget(role);

  return (
    <Link
      className="ml-auto mr-auto flex h-[56px] w-full max-w-[327px] items-center justify-center rounded-[10px] bg-green-primary text-[18px] font-semibold leading-[22px] text-white hover:bg-green-dark active:bg-green-dark lg:ml-0 lg:mr-0 lg:w-[254px]"
      to={target}
    >
      {_t('Start with Curious Mind')}
    </Link>
  );
}
