import { Button } from '@mantine/core';
import PlusIcon from 'components/icons/PlusIcon';
import { _t, _tx } from 'lang';
import TeacherDashboardLayout from 'layout/teacher-dashboard-layout/TeacherDashboardLayout';
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom';
import ClassroomStatsCard from 'components/ClassroomStatsCard';
import AssignedAssignmentsIcon from 'components/icons/AssignedAssignmentsIcon';
import NonSubmittedAssignmentsIcon from 'components/icons/NonSubmittedAssignmentsIcon';
import ThumbsUpLargeIcon from 'components/icons/ThumbsUpLargeIcon';
import useApi from 'api/use-api';
import { useEffect, useState } from 'react';
import TeacherHomeworkCard from 'pages/teacher-module/classroom/homework/card/TeacherHomeworkCard';
import { isEmpty } from 'lodash';
import ClassroomLoader from 'pages/teacher-module/classroom/ClassroomLoader';
import Message from 'components/Message';
import IIcon from 'components/icons/IIcon';
import sprintf from 'lang/sprintf';
import DisappearingNotification from 'pages/teacher-module/classroom/DisappearingNotification';

/**
 * Teacher dashboard page.
 *
 * @see https://www.figma.com/file/SjggpqHVlWxBKa2cIG349A/Math-Exercises?node-id=520%3A429&t=J3hpSwWIuCcusYbV-0
 */
export default function TeacherDashboardPage() {
  // API and params
  const { classroomId } = useParams();
  const { getAction } = useApi();
  const getClassroomDetailAction = getAction('GetClassroomDetail');
  const navigate = useNavigate();

  // state
  const [loading, setLoading] = useState<boolean>(true);
  let { state } = useLocation();

  // data
  const [activeHomework, setActiveHomework] = useState<JSX.Element[] | undefined>(undefined);
  const [pastHomework, setPastHomework] = useState<JSX.Element[] | undefined>(undefined);
  const [allHomeworkCount, setAllHomeworkCount] = useState<number>(0);
  const [avgSuccessRate, setAvgSuccessRate] = useState<number | undefined>(0);
  const [rateOfNotSubmitted, setRateOfNotSubmitted] = useState<number | undefined>(0);
  const [classroomName, setClassroomName] = useState<string>();
  const [displayNotification, setDisplayNotification] = useState<boolean>(false);
  const [activeHomeworkId, setActiveHomeworkId] = useState<string>();

  useEffect(() => {
    if (!isEmpty(state)) {
      if (state.displaySuccessNotification) {
        setActiveHomeworkId(state.homeworkId);
        setDisplayNotification(true);
      }

      window.history.replaceState({}, document.title);
    }

    getClassroomDetailAction({ parameters: { classroomId: classroomId! } })
      .success((response) => {
        setAllHomeworkCount(response.homework.length);
        setAvgSuccessRate(response.stats.averageSuccessRate);
        setRateOfNotSubmitted(response.stats.rateOfNotSubmitted);
        setClassroomName(response.classroom.name);

        const mappedHomework = response.homework
          .sort((first, second) => (first.homeworkNumber > second.homeworkNumber ? -1 : 1))
          .map((hw) => ({
            homework: hw,
            component: (
              <TeacherHomeworkCard
                key={hw.homeworkId}
                homeworkId={hw.homeworkId}
                isActive={!hw.isPastDeadline}
                assigned={hw.assigned.at}
                deadline={hw.deadline}
                name={`${_t('Homework')} #${hw.homeworkNumber}`}
                exercises={hw.exerciseSet.exercises}
                exercisesCount={hw.exerciseSet.exercises.length}
                submitted={{
                  total: hw.stats.numberOfStudents,
                  actual: hw.stats.numberOfSubmits,
                }}
                successRate={hw.stats.successRate}
              />
            ),
          }));

        setPastHomework(
          mappedHomework.filter(({ homework }) => homework.isPastDeadline).map(({ component }) => component)
        );
        setActiveHomework(
          mappedHomework.filter(({ homework }) => !homework.isPastDeadline).map(({ component }) => component)
        );
      })
      .complete(() => setLoading(false));
  }, [classroomId, state]);

  const switchingClassroom = !!(state?.switchingClassroom ?? false);

  if (switchingClassroom && loading) {
    return <ClassroomLoader />;
  }

  if (loading) {
    return <TeacherDashboardLayout hasHeader hasSidebar />;
  }

  return (
    <TeacherDashboardLayout hasHeader hasSidebar>
      <div className="flex items-center justify-between">
        <div className="text-[24px] font-semibold leading-[29px]">{classroomName}</div>
        {displayNotification && (
          <DisappearingNotification
            text={_t('Homework assigned successfully.')}
            link={
              <Link
                to={`/teacher/classrooms/classroom/${classroomId}/homework/homework/${activeHomeworkId}`}
                className="text-[14px] font-semibold leading-[20px] underline"
              >
                {_t('Take a look')}
              </Link>
            }
            setDisplay={setDisplayNotification}
          />
        )}
        <Button
          className="h-[42px] rounded-[7px] bg-green-primary text-[14px] font-semibold leading-[17px] hover:bg-green-dark active:bg-green-dark"
          onClick={() => navigate(`/teacher/classrooms/classroom/${classroomId}/exercises`)}
        >
          <div className="flex items-center gap-[10px]">
            <PlusIcon />
            <span>{_t('Assign a new homework')}</span>
          </div>
        </Button>
      </div>
      <div className="flex items-center lg:gap-[16px] lg:pt-[24px]">
        <ClassroomStatsCard
          icon={<AssignedAssignmentsIcon />}
          title={_tx('Assigned homework', 'Assigned homework', 2)}
          // Translators: The number of homework assignments either in a classroom or for a student. Eg. "1 homework" (singular) or "2 homework" (plural).
          value={sprintf(_tx('%d homework', '%d homework', allHomeworkCount), allHomeworkCount)}
          className="lg:w-[33.3%]"
        />
        <ClassroomStatsCard
          icon={<ThumbsUpLargeIcon />}
          // Translators: The success rate of all homework assignments in a classroom for all students.
          title={_t('Classroom success rate')}
          value={avgSuccessRate ? `${Math.round(avgSuccessRate * 100)} %` : '-'}
          className="lg:w-[33.3%]"
        />
        <ClassroomStatsCard
          icon={<NonSubmittedAssignmentsIcon />}
          // Translators: Homework which has not been submitted (singular) or the number of homework assignments which have not been submitted (plural).
          title={_tx('Non-submitted homework', 'Non-submitted homework', 2)}
          value={rateOfNotSubmitted ? `${Math.round(rateOfNotSubmitted * 100)} %` : '-'}
          className="lg:w-[33.3%]"
        />
      </div>
      <div className="lg:pt-[56px]">
        <div className="text-[20px] font-semibold leading-[24px]">
          {
            // Translators: %d ongoing homework assignments. E.g. "2 ongoing homework assignments" (plural) or "1 ongoing homework assignment" (singular).
            _tx('Ongoing homework', 'Ongoing homework', 2)
          }
        </div>
        <div className="flex flex-col gap-[16px] pt-[20px]">
          {!isEmpty(activeHomework) ? (
            activeHomework
          ) : (
            <Message
              children={
                <div className="flex items-center gap-[11px]">
                  <div className="h-[18px] w-[18px]">
                    <IIcon />
                  </div>
                  <div>
                    {
                      // Translators: The text of a message which is displayed when there are no ongoing homework assignments. The text is always in plural form.
                      _t('Currently, there is no ongoing homework.')
                    }
                  </div>
                </div>
              }
              type="info"
            />
          )}
        </div>
      </div>
      <div className="pt-[56px]">
        <div className="flex items-center justify-between">
          <div className="text-[20px] font-semibold leading-[24px]">
            {
              // Translators: %d finished homework. E.g. "2 finished homework" (plural) or "1 finished homework" (singular).
              _tx('Finished homework', 'Finished homework', 2)
            }
          </div>
          <div
            className="cursor-pointer text-[14px] font-medium leading-[17px] text-text-shadow"
            onClick={() => navigate(`/teacher/classrooms/classroom/${classroomId}/homework/all`)}
          >
            {_t('Show all')}
          </div>
        </div>
        <div className="flex flex-col gap-[16px] pt-[20px]">
          {!isEmpty(pastHomework) ? (
            pastHomework
          ) : (
            <Message
              children={
                <div className="flex items-center gap-[11px]">
                  <div className="h-[18px] w-[18px]">
                    <IIcon />
                  </div>
                  <div>{_t("You haven't assigned a homework yet.")}</div>
                </div>
              }
              type="info"
            />
          )}
        </div>
      </div>
    </TeacherDashboardLayout>
  );
}
