import CourseStepSidebar from 'pages/courses-module/course-step/sidebar/CourseStepSidebar';
import CourseStepMain from 'pages/courses-module/course-step/main/CourseStepMain';
import useCourseStep from 'pages/courses-module/course-step/context/use-course-step';
import ToggleSidebar from 'pages/courses-module/course-step/ToggleSidebar';

/**
 * The layout of CourseStep page.
 */
export default function CourseStepLayout() {
  const { isSidebarVisible, setIsSidebarVisible } = useCourseStep();

  const mainStyle = isSidebarVisible ? 'lg:w-[calc(100vw-470px)]' : 'lg:w-screen';
  const sidebarStyle = isSidebarVisible ? 'lg:w-[470px] right-0 lg:fixed' : 'hidden';

  return (
    <div className="flex flex-col bg-white lg:h-[calc(100vh-90px)] lg:flex-row lg:justify-between">
      {/* Main */}
      <div className={`w-screen lg:h-full ${mainStyle}`}>
        <CourseStepMain />
      </div>

      {/* Hide / Show sidebar */}
      <ToggleSidebar
        isSidebarVisible={isSidebarVisible}
        onClick={() => setIsSidebarVisible((isVisible) => !isVisible)}
      />

      {/* Sidebar */}
      <div
        className={`h-auto min-h-[calc(100vh-211px-66px)] overflow-x-auto bg-white lg:h-[calc(100vh-90px)] lg:shadow-[0px_4px_14px_rgba(22,26,29,0.07)] ${sidebarStyle}`}
      >
        <CourseStepSidebar />
      </div>
    </div>
  );
}
