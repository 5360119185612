import { Stack } from '@mantine/core';
import { BUY_PREMIUM_TOKEN } from 'env';
import { _t } from 'lang';
import FormPageLayout from 'layout/form-page-layout/FormPageLayout';
import { useEffect } from 'react';
import { Link, useSearchParams } from 'react-router-dom';

/**
 * Page to display thank you message after buying a course.
 */
export default function ThankYouPage() {
  const [searchParams] = useSearchParams();
  const token = searchParams.get('token');

  useEffect(() => {
    // todo: send request to backend to verify the token and make the user premium.
  }, [token]);

  if (token !== BUY_PREMIUM_TOKEN) {
    // todo: error page
    return <></>;
  }

  return (
    <FormPageLayout hideCloseButton>
      <div className="block p-[24px] pt-0 lg:ml-[50%] lg:flex lg:min-h-screen lg:items-center lg:pl-[130px] lg:pt-[24px]">
        <div className="lg:w-[370px]">
          <Stack spacing={16}>
            <h1>Thank you for your purchase!</h1>
            <p>We are happy to have you as a customer. We hope you will enjoy the course and learn a lot from it.</p>
            <p>In case you encounter any issues, do not hesitate to contact us at</p>
            <p>
              <a href="mailto:michael@zvedavamysl.cz">michael@zvedavamysl.cz</a>
              <br />
              +420 777 777 777
            </p>
            <Link
              className="ml-auto mr-auto flex h-[56px] w-full max-w-[327px] items-center justify-center rounded-[10px] bg-green-primary text-[18px] font-semibold leading-[22px] text-white hover:bg-green-dark active:bg-green-dark lg:ml-0 lg:mr-0 lg:w-[254px]"
              to="/course/zive-doucovani"
            >
              {_t('Go to course page')}
            </Link>
          </Stack>
        </div>
      </div>
    </FormPageLayout>
  );
}
