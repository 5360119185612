/**
 * Eye icon
 */
export default function EyePrettyIcon() {
  return (
    <svg width="18" height="10" viewBox="0 0 18 10" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M16.6569 5C16.049 5.63493 15.0052 6.61919 13.6876 7.45438C12.3039 8.33151 10.6883 9 9 9C7.31174 9 5.6961 8.33151 4.31238 7.45438C2.99483 6.61919 1.95102 5.63493 1.34308 5C1.95102 4.36507 2.99483 3.38081 4.31238 2.54562C5.6961 1.66849 7.31174 1 9 1C10.6883 1 12.3039 1.66849 13.6876 2.54562C15.0052 3.38081 16.049 4.36507 16.6569 5Z"
        stroke="#353E45"
        strokeWidth="2"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9 7C10.6569 7 12 5.65685 12 4C12 2.34315 10.6569 1 9 1C7.34315 1 6 2.34315 6 4C6 5.65685 7.34315 7 9 7ZM11 5C11.5523 5 12 4.55228 12 4C12 3.44772 11.5523 3 11 3C10.4477 3 10 3.44772 10 4C10 4.55228 10.4477 5 11 5Z"
        fill="#353E45"
      />
    </svg>
  );
}
