import Alert from 'components/Alert';
import Emoji from 'components/Emoji';
import ChangePasswordForm from 'components/forms/ChangePasswordForm';
import CorrectIcon from 'components/icons/CorrectIcon';
import { _t } from 'lang';
import { useState } from 'react';

/**
 * The contents of the StudentChangePasswordPage.
 */
export default function StudentChangePasswordContent() {
  const [displayMessage, setDisplayMessage] = useState<boolean>(false);

  return (
    <div className="flex pb-[64px] pl-[24px] pr-[24px] pt-[24px] lg:pb-0 lg:pt-[50px]">
      <div className="ml-auto mr-auto w-full lg:w-[370px]">
        <Alert icon={<CorrectIcon />} type="success" className={displayMessage ? 'visible' : 'invisible'}>
          <span className="text-[14px] font-medium leading-[20px]">
            {_t('You have successfully changed your password!')}
          </span>
        </Alert>
        <p className="pb-[32px] pt-[40px] text-[24px] font-semibold leading-[29px] text-text-dark">
          {_t('Change password')} <Emoji symbol="👀" label={_t('eyes')} />
        </p>
        <ChangePasswordForm onSuccess={() => setDisplayMessage(true)} inputBackground="#FFF" />
      </div>
    </div>
  );
}
