// cspell:disable

/**
 * --- DO NOT MODIFY THIS FILE ---------------------------------------------------------------------
 *
 * This file is automatically generated using generator/generate-environment.ts. If you wish to
 * change the environment variables, modify the file config/environment.yaml and then run:
 *
 *     npm run generate:environment
 *
 * -------------------------------------------------------------------------------------------------
 */

import getenv from 'env/getenv';

/**
 * Whether the feature is on.
 */
function isFeatureOn(feature: string): boolean {
  return feature === 'on';
}

/**
 * The base url of the REST API.
 */
export const REST_API_URL =
  process.env.REACT_APP_REST_API_URL ||
  getenv('REST_API_URL', {
    development: '/api',
    test: '/api',
    production: '/api',
  });

/**
 * The timeout for the REST API.
 */
export const REST_API_TIMEOUT = Number(
  process.env.REACT_APP_REST_API_TIMEOUT ||
    getenv('REST_API_TIMEOUT', {
      development: '5000',
      test: '5000',
      production: '8000',
    })
);

/**
 * The host of the web app.
 */
export const WEB_APP_HOST =
  process.env.REACT_APP_WEB_APP_HOST ||
  getenv('WEB_APP_HOST', {
    development: 'localhost:3000',
    test: 'localhost:3000',
    production: 'https://zvedavamysl.cz',
  });

/**
 * The interval in seconds to check the progress of a video.
 */
export const VIDEO_PROGRESS_INTERVAL_SECONDS = Number(
  process.env.REACT_APP_VIDEO_PROGRESS_INTERVAL_SECONDS ||
    getenv('VIDEO_PROGRESS_INTERVAL_SECONDS', {
      development: '5',
      test: '5',
      production: '5',
    })
);

/**
 * The website id of the umami analytics.
 */
export const UMAMI_WEBSITE_ID =
  process.env.REACT_APP_UMAMI_WEBSITE_ID ||
  getenv('UMAMI_WEBSITE_ID', {
    development: '',
    test: '',
    production: '5298f74a-bd5c-4b73-8309-cd241f8b26f4',
  });

/**
 * The script url of the umami analytics.
 */
export const UMAMI_SCRIPT_URL =
  process.env.REACT_APP_UMAMI_SCRIPT_URL ||
  getenv('UMAMI_SCRIPT_URL', {
    development: '',
    test: '',
    production: 'https://umami.zvedavamysl.cz/script.js',
  });

/**
 * The script url of the AdSense.
 */
export const ADSENSE_SCRIPT_URL =
  process.env.REACT_APP_ADSENSE_SCRIPT_URL ||
  getenv('ADSENSE_SCRIPT_URL', {
    development: '',
    test: '',
    production: 'https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-2712222548495564',
  });

/**
 * Whether the assignment ad is allowed.
 */
export const FEATURE_TOGGLE_DISPLAY_ASSIGNMENT_AD = isFeatureOn(
  process.env.REACT_APP_FEATURE_TOGGLE_DISPLAY_ASSIGNMENT_AD ||
    getenv('FEATURE_TOGGLE_DISPLAY_ASSIGNMENT_AD', {
      development: 'on',
      test: 'off',
      production:
        'on',
    })
);

/**
 * Whether the webinars page should be displayed.
 */
export const FEATURE_TOGGLE_WEBINARS_PAGE = isFeatureOn(
  process.env.REACT_APP_FEATURE_TOGGLE_WEBINARS_PAGE ||
    getenv('FEATURE_TOGGLE_WEBINARS_PAGE', {
      development: 'on',
      test: 'on',
      production: 'on',
    })
);

/**
 * Whether the teacher module can be accessed.
 */
export const FEATURE_TOGGLE_TEACHER_MODULE = isFeatureOn(
  process.env.REACT_APP_FEATURE_TOGGLE_TEACHER_MODULE ||
    getenv('FEATURE_TOGGLE_TEACHER_MODULE', {
      development: 'on',
      test: 'on',
      production: 'on',
    })
);

/**
 * Whether the private pages can be accessed.
 */
export const FEATURE_TOGGLE_PRIVATE_PAGES = isFeatureOn(
  process.env.REACT_APP_FEATURE_TOGGLE_PRIVATE_PAGES ||
    getenv('FEATURE_TOGGLE_PRIVATE_PAGES', {
      development: 'on',
      test: 'on',
      production: '',
    })
);

/**
 * The token to buy the premium.
 */
export const BUY_PREMIUM_TOKEN =
  process.env.REACT_APP_BUY_PREMIUM_TOKEN ||
  getenv('BUY_PREMIUM_TOKEN', {
    development: 'dpZosAsM9hddCFF7',
    test: 'dpZosAsM9hddCFF7',
    production: '',
  });

/**
 * The url to buy the premium.
 */
export const BUY_PREMIUM_URL =
  process.env.REACT_APP_BUY_PREMIUM_URL ||
  getenv('BUY_PREMIUM_URL', {
    development: 'https://buy.stripe.com/test_6oEfZnfjV7LKavm4gg',
    test: 'https://buy.stripe.com/test_6oEfZnfjV7LKavm4gg',
    production: '',
  });
