import { GetClassroomHomeworkDetailResponse } from 'api/actions/get-classroom-homework-detail/get-classroom-homework-detail-response';
import HomeworkDateTimeDisplay from 'pages/teacher-module/classroom/homework/card/HomeworkDateTimeDisplay';
import HomeworkTitle from 'pages/teacher-module/classroom/homework/card/HomeworkTitle';
import ClassroomStatsCard from 'components/ClassroomStatsCard';
import ThumbsUpLargeIcon from 'components/icons/ThumbsUpLargeIcon';
import HomeworkRemainingTime from 'components/HomeworkRemainingTime';
import { Select } from '@mantine/core';
import HomeworkDetailRow from 'pages/teacher-module/classroom/homework/detail/HomeworkDetailRow';
import Breadcrumbs from 'components/Breadcrumbs';
import ArrowRightIcon from 'components/icons/ArrowRightIcon';
import { _t, _tx, dateFnsLocale } from 'lang';
import { isEmpty } from 'lodash';
import { Link } from 'react-router-dom';
import StudentLargeIcon from 'components/icons/StudentLargeIcon';
import { useState } from 'react';
import { format } from 'date-fns';

interface Props {
  classroomId: string;
  homeworkId: string;
  homeworkNumber: GetClassroomHomeworkDetailResponse['homework']['homeworkNumber'];
  assignedAt: GetClassroomHomeworkDetailResponse['homework']['assigned']['at'];
  deadline: GetClassroomHomeworkDetailResponse['homework']['deadline'];
  isPastDeadline: GetClassroomHomeworkDetailResponse['homework']['isPastDeadline'];
  exerciseSet: GetClassroomHomeworkDetailResponse['homework']['exerciseSet'];
  stats: GetClassroomHomeworkDetailResponse['homework']['stats'];
  students: GetClassroomHomeworkDetailResponse['students'];
  classroomName: string;
}

/**
 * The content of the Homework detail page.
 */
export default function HomeworkDetailContent({
  classroomId,
  homeworkId,
  homeworkNumber,
  assignedAt,
  deadline,
  isPastDeadline,
  exerciseSet,
  stats,
  students,
  classroomName,
}: Props) {
  const homeworkTitle = `${_t('Homework')} #${homeworkNumber}`;
  const [sortingValue, setSortingValue] = useState<string>('alpha');

  // Translators: This is a date format string used in a homework card to display the date when the homework was assigned. See https://date-fns.org/v2.29.3/docs/format for more information.
  const dateFormat = _t('dd. LLLL, yyyy | HH:mm');

  /**
   * Sorts the students by the sorting value.
   */
  function sortStudents(students: GetClassroomHomeworkDetailResponse['students']) {
    if (sortingValue === 'alpha') {
      students.sort(({ student: a }, { student: b }) => {
        const comparisonResult = a.lastName.localeCompare(b.lastName);

        if (comparisonResult === 0) {
          return a.firstName.localeCompare(b.firstName);
        }

        return comparisonResult;
      });
    } else if (sortingValue === 'success') {
      students.sort(({ assignment: { submitted: a } }, { assignment: { submitted: b } }) => {
        if (!a || !b) {
          return 0;
        }

        if (a.evaluation.successRate === undefined || b.evaluation.successRate === undefined) {
          return 0;
        }

        return b.evaluation.successRate - a.evaluation.successRate;
      });
    }

    return students;
  }

  students = sortStudents([...students]);

  return (
    <div>
      <Breadcrumbs>
        <Link to={`/teacher/classrooms/classroom/${classroomId}/detail`}>{classroomName}</Link>
        <ArrowRightIcon stroke="#738491" />
        <Link to={`/teacher/classrooms/classroom/${classroomId}/homework/active`}>
          {_tx('Homework', 'Homework', 2)}
        </Link>
        <ArrowRightIcon stroke="#738491" />
        <div className="font-normal text-text-dark-2">{homeworkTitle}</div>
      </Breadcrumbs>
      <div className="flex items-start justify-between pt-[23px]">
        <div>
          <HomeworkDateTimeDisplay dateTime={new Date(assignedAt)} />
          <HomeworkTitle
            title={homeworkTitle}
            isActive={!isPastDeadline}
            titleClassNames="text-[24px] leading-[29px] font-semibold"
            classNames="pt-[10px]"
          />
          <div className="max-w-[429px] pt-[16px] text-[14px] font-medium leading-[24px] text-text-dark-2">
            <ul className="ml-[8px] list-inside list-disc">
              {exerciseSet.exercises.map((ex) => (
                <li key={ex.exerciseId}>{ex.exerciseTitle}</li>
              ))}
            </ul>
          </div>
        </div>
        <div className="flex gap-[16px]">
          <ClassroomStatsCard
            icon={<ThumbsUpLargeIcon />}
            title={_t('Average success rate')}
            value={stats.numberOfSubmits > 0 ? `${Math.round(stats.successRate * 100)} %` : '-'}
            className="w-[250px] xl:w-[280px]"
          />
          <ClassroomStatsCard
            icon={<StudentLargeIcon />}
            title={_t('Submitted')}
            value={
              <div>
                {stats.numberOfSubmits}{' '}
                <span className="font-normal">
                  {_t('of')} {stats.numberOfStudents}
                </span>
              </div>
            }
            className="w-[250px] xl:w-[280px]"
          />
        </div>
      </div>
      {isPastDeadline && (
        <div className="flex flex-row items-center justify-end gap-[12px]">
          <div className="text-[14px] leading-[17px] text-text-shadow">
            {_t("Date and time of the homework's deadline:")}{' '}
          </div>
          <div className="text-[16px] font-semibold leading-[19px] ">
            {format(new Date(deadline), dateFormat, { locale: dateFnsLocale })}
          </div>
        </div>
      )}
      <div className={`flex items-end justify-between gap-[30px] ${isPastDeadline ? 'pt-[17px]' : 'pt-[24px]'}`}>
        <div className={`h-[1px] border-t border-t-input-outline-shadow ${isPastDeadline ? 'w-full' : 'w-[100%]'}`} />
        {!isPastDeadline && (
          <div className="flex-shrink-0 lg:w-[320px]">
            <HomeworkRemainingTime
              assigned={new Date(assignedAt)}
              deadline={new Date(deadline)}
              barHeight="4px"
              remainsTextClassNames="text-[14px] leading-[17px]"
              remainingTimeClassNames="text-[16px] leading-[19px]"
            />
          </div>
        )}
      </div>
      <div className="pt-[31px]">
        {!isEmpty(students) ? (
          <div>
            <div className="flex items-end justify-between text-[14px] leading-[17px] text-text-shadow">
              <div className="w-[232px] pl-[24px] font-medium">{_t('Student')}</div>
              <div className="flex w-[194px] font-bold">
                <div className="ml-auto mr-auto">{_t('Success rate')}</div>
              </div>
              <div className="w-[188px] lg:w-[288px]">
                <Select
                  data={[
                    { label: _t('Alphabetically'), value: 'alpha' },
                    { label: _t('Success rate'), value: 'success' },
                  ]}
                  value={sortingValue}
                  onChange={(val) => {
                    if (val) {
                      setSortingValue(val);
                      sortStudents(students);
                    }
                  }}
                  radius={7}
                  styles={{
                    root: { width: '100%' },
                    input: {
                      fontWeight: 500,
                      backgroundColor: '#FFF',
                      border: '1px solid #D9E0E6',
                    },
                    item: {
                      padding: '0px!important',
                      paddingLeft: '16px!important',
                      fontWeight: 500,
                      height: '42px!important',
                      display: 'flex',
                      alignItems: 'center',
                      fontSize: '14px',
                      lineHeight: '17px',
                    },
                    dropdown: {
                      padding: '8px!important',
                      margin: '0!important',
                    },
                    itemsWrapper: {
                      display: 'flex!important',
                      flexDirection: 'column',
                      gap: '8px!important',
                      padding: '0!important',
                    },
                  }}
                />
              </div>
            </div>
            <div className="flex flex-col gap-[8px] pt-[20px]">
              {students.map(({ student, assignment }) => (
                <HomeworkDetailRow
                  key={student.userId}
                  studentId={student.userId}
                  name={`${student.firstName} ${student.lastName}`}
                  successRate={assignment.submitted?.evaluation?.successRate}
                  active={!isPastDeadline}
                  assignmentId={assignment.generated?.assignmentId}
                  homeworkId={homeworkId}
                  classroomId={classroomId}
                  homeworkTitle={homeworkTitle}
                  isInClassroom={student.isStudentInClassroom}
                />
              ))}
            </div>
          </div>
        ) : (
          <div>{_t('No students!')}</div>
        )}
      </div>
    </div>
  );
}
