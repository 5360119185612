import { MantineNumberSize, Progress } from '@mantine/core';

interface Props {
  progress: number;
  color: string;
  size?: MantineNumberSize;
  rootColor?: string;
}

/**
 * Course progress bar
 */
export default function CourseProgressBar({ progress, color, size = 'xs', rootColor = '#D9E0E6' }: Props) {
  return (
    <div className="w-full">
      <Progress
        size={size}
        value={Math.round(progress * 100)}
        styles={{
          bar: { backgroundColor: color },
          root: { height: '2px', backgroundColor: rootColor },
        }}
        className="mt-[8px]"
      />
    </div>
  );
}
