import useApi from 'api/use-api';
import Avatar from 'components/Avatar';
import ArrowDownIcon from 'components/icons/ArrowDownIcon';
import ZvedavaMyslIcon from 'components/icons/ZvedavaMyslIcon';
import Clickable from 'components/misc/Clickable';
import { useState } from 'react';
import { Link } from 'react-router-dom';
import { HOME_PAGE_PATH } from 'routes/paths';
import StudentPhoneMenu from 'layout/student-dashboard-layout/parts/header/StudentPhoneMenu';
import { Skeleton } from '@mantine/core';

/**
 * The phone header of the student' pages.
 */
export default function StudentPhoneHeader() {
  const [displayMenu, setDisplayMenu] = useState(false);
  const { firstName, lastName, ready } = useApi();

  const shadow = displayMenu ? '' : 'shadow-3xl';

  return (
    <div>
      <div
        className={`fixed left-0 top-0 z-30 flex h-[66px] w-full items-center justify-between bg-white pb-[18px] pl-[16px] pr-[20px] pt-[19px] ${shadow}`}
      >
        <Link to={HOME_PAGE_PATH}>
          <ZvedavaMyslIcon />
        </Link>
        <Clickable
          className="mr-[-15px] flex items-center gap-[11px] p-[15px]"
          onClick={() => setDisplayMenu((display) => !display)}
        >
          {ready ? <Avatar label={`${firstName} ${lastName}`} size={42} /> : <Skeleton circle height={42} />}
          <ArrowDownIcon />
          {displayMenu && <StudentPhoneMenu setDisplayMenu={setDisplayMenu} />}
        </Clickable>
      </div>
    </div>
  );
}
