import { Route, Routes } from 'react-router-dom';
import { FEATURE_TOGGLE_PRIVATE_PAGES, FEATURE_TOGGLE_WEBINARS_PAGE } from 'env';
import {
  CHANGE_PASSWORD_PAGE_PATH,
  CLASSROOMS_PAGE_PATH,
  CLASSROOM_HOMEWORK_PAGE_PATH,
  CLASSROOM_EXERCISES_PAGE_PATH,
  EXPORT_EXERCISES_PAGE_PATH,
  TEACHER_DASHBOARD_PAGE_PATH,
  CLASSROOM_STUDENTS_PAGE_PATH,
  CONTACT_PAGE_PATH,
  COURSE_PAGE_PATH,
  COURSE_STEP_PAGE_PATH,
  HOME_PAGE_PATH,
  LOGIN_PAGE_PATH,
  PRIVACY_POLICY_PAGE_PATH,
  REGISTER_PAGE_PATH,
  RESET_PASSWORD_PAGE_PATH,
  SET_PASSWORD_PAGE_PATH,
  SLOVAKIA_COMPARISON_PAGE_PATH,
  WEBINARS_PAGE_PATH,
  WHO_WE_ARE_PAGE_PATH,
  WHO_WE_ARE_PAGE_SECTION_PATH,
  CLASSROOM_HOMEWORK_DETAIL_PAGE_PATH,
  STUDENT_DETAIL_PAGE_PATH,
  STUDENT_DASHBOARD_PAGE_PATH,
  STUDENT_HOMEWORK_PAGE_PATH,
  STUDENT_EXERCISES_PAGE_PATH,
  STUDENT_SOLVE_HOMEWORK_PAGE_PATH,
  STUDENT_COURSE_STEP_PAGE_PATH,
  STUDENT_COURSE_PAGE_PATH,
  STUDENT_CHANGE_PASSWORD_PAGE_PATH,
  TEACHER_CHANGE_PASSWORD_PAGE_PATH,
  PRIVATE_ALL_EXERCISES_PAGE_PATH,
  CHECK_HOMEWORK_PAGE_PATH,
  EXERCISES_PAGE_PATH,
  EXERCISE_DETAIL_PAGE_PATH,
  STUDENT_EXERCISE_DETAIL_PAGE_PATH,
  FAQ_PAGE_PATH,
  THANK_YOU_PAGE_PATH,
  LANDING_PAGE_PATH,
  REGISTER_COURSE_PAGE_PATH,
} from 'routes/paths';
import ChangePasswordPage from 'pages/user-module/change-password/ChangePasswordPage';
import ContactPage from 'pages/presentation-module/contact/ContactPage';
import CourseStepPage from 'pages/courses-module/course-step/CourseStepPage';
import HomePage from 'pages/presentation-module/home/HomePage';
import LoginPage from 'pages/user-module/login/LoginPage';
import PrivacyPolicyPage from 'pages/presentation-module/privacy-policy/PrivacyPolicyPage';
import RegisterPage from 'pages/user-module/register/RegisterPage';
import RequireRole from 'routes/decorators/RequireRole';
import ResetPasswordPage from 'pages/user-module/reset-password/ResetPasswordPage';
import SetPasswordPage from 'pages/user-module/set-password/SetPasswordPage';
import SlovakiaComparisonPage from 'pages/presentation-module/slovakia-comparison/SlovakiaComparisonPage';
import WaitForSession from 'routes/decorators/WaitForSession';
import WebinarsPage from 'pages/presentation-module/webinars/WebinarsPage';
import WhoWeArePage from 'pages/presentation-module/who-we-are/WhoWeArePage';
import ClassroomsPage from 'pages/teacher-module/classrooms/ClassroomsPage';
import TeacherDashboardPage from 'pages/teacher-module/classroom/TeacherDashboardPage';
import ClassroomHomeworkPage from 'pages/teacher-module/classroom/homework/ClassroomHomeworkPage';
import ClassroomStudentsPage from 'pages/teacher-module/classroom/students/ClassroomStudentsPage';
import ClassroomExercisesPage from 'pages/teacher-module/classroom/exercises/ClassroomExercisesPage';
import HomeworkDetailPage from 'pages/teacher-module/classroom/homework/detail/HomeworkDetailPage';
import StudentDetailPage from 'pages/teacher-module/classroom/students/detail/StudentDetailPage';
import StudentDashboardPage from 'pages/student-module/dashboard/StudentDashboardPage';
import StudentHomeworkPage from 'pages/student-module/homework/StudentHomeworkPage';
import StudentExercisesPage from 'pages/student-module/exercises/StudentExercisesPage';
import SolveHomeworkPage from 'pages/student-module/homework/solve-homework/SolveHomeworkPage';
import StudentCourseStepPage from 'pages/student-module/course-step/StudentCourseStepPage';
import StudentChangePasswordPage from 'pages/student-module/profile/StudentChangePasswordPage';
import TeacherChangePasswordPage from 'pages/teacher-module/profile/TeacherChangePasswordPage';
import AllExercisesPage from 'pages/private/all-exercises/AllExercisesPage';
import HomeworkCheckPage from 'pages/teacher-module/classroom/homework/check/HomeworkCheckPage';
import ExercisesPage from 'pages/user-module/exercises/ExercisesPage';
import FaqPage from 'pages/presentation-module/faq/FaqPage';
import ExportExercisesPage from 'pages/teacher-module/classroom/exercises/ExportExercisesPage';
import ThankYouPage from 'pages/presentation-module/thank-you/ThankYouPage';
import LandingPage from 'pages/presentation-module/landing/LandingPage';

/**
 * All routes in the app must be registered here.
 */
export default function AppRoutes() {
  return (
    <Routes>
      {/* Presentation module */}
      <Route path={WHO_WE_ARE_PAGE_PATH} element={<WhoWeArePage />} />
      <Route path={WHO_WE_ARE_PAGE_SECTION_PATH} element={<WhoWeArePage />} />
      <Route path={CONTACT_PAGE_PATH} element={<ContactPage />} />
      <Route path={HOME_PAGE_PATH} element={<HomePage />} />
      <Route path={LANDING_PAGE_PATH} element={<LandingPage />} />
      <Route path={PRIVACY_POLICY_PAGE_PATH} element={<PrivacyPolicyPage />} />
      {FEATURE_TOGGLE_WEBINARS_PAGE && <Route path={WEBINARS_PAGE_PATH} element={<WebinarsPage />} />}
      <Route path={SLOVAKIA_COMPARISON_PAGE_PATH} element={<SlovakiaComparisonPage />} />
      <Route path={FAQ_PAGE_PATH} element={<FaqPage />} />
      <Route path={THANK_YOU_PAGE_PATH} element={<ThankYouPage />} />

      {/* User module */}
      <Route path={LOGIN_PAGE_PATH} element={<LoginPage />} />
      <Route
        path={CHANGE_PASSWORD_PAGE_PATH}
        element={
          <WaitForSession>
            <RequireRole redirect={HOME_PAGE_PATH} roles={['teacher', 'student']}>
              <ChangePasswordPage />
            </RequireRole>
          </WaitForSession>
        }
      />
      <Route path={REGISTER_PAGE_PATH} element={<RegisterPage registerForCourse={false} />} />
      <Route path={REGISTER_COURSE_PAGE_PATH} element={<RegisterPage registerForCourse={true} />} />
      <Route path={RESET_PASSWORD_PAGE_PATH} element={<ResetPasswordPage />} />
      <Route path={SET_PASSWORD_PAGE_PATH} element={<SetPasswordPage />} />

      {[EXERCISES_PAGE_PATH, EXERCISE_DETAIL_PAGE_PATH].map((path) => (
        <Route key={path} path={path} element={<ExercisesPage />} />
      ))}

      {/* Courses module */}
      {[COURSE_STEP_PAGE_PATH, COURSE_PAGE_PATH].map((path) => (
        <Route
          key={path}
          path={path}
          element={
            <WaitForSession>
              <CourseStepPage />
            </WaitForSession>
          }
        />
      ))}

      {/* Teacher module */}
      <Route
        path={TEACHER_CHANGE_PASSWORD_PAGE_PATH}
        element={
          <WaitForSession>
            <RequireRole redirect={HOME_PAGE_PATH} roles={['teacher']}>
              <TeacherChangePasswordPage />
            </RequireRole>
          </WaitForSession>
        }
      />
      <Route
        path={CLASSROOMS_PAGE_PATH}
        element={
          <WaitForSession>
            <RequireRole redirect={HOME_PAGE_PATH} roles={['teacher']}>
              <ClassroomsPage />
            </RequireRole>
          </WaitForSession>
        }
      />
      <Route
        path={TEACHER_DASHBOARD_PAGE_PATH}
        element={
          <WaitForSession>
            <RequireRole redirect={HOME_PAGE_PATH} roles={['teacher']}>
              <TeacherDashboardPage />
            </RequireRole>
          </WaitForSession>
        }
      />

      <Route
        path={CLASSROOM_HOMEWORK_PAGE_PATH}
        element={
          <WaitForSession>
            <RequireRole redirect={HOME_PAGE_PATH} roles={['teacher']}>
              <ClassroomHomeworkPage />
            </RequireRole>
          </WaitForSession>
        }
      />

      <Route
        path={CLASSROOM_HOMEWORK_DETAIL_PAGE_PATH}
        element={
          <WaitForSession>
            <RequireRole redirect={HOME_PAGE_PATH} roles={['teacher']}>
              <HomeworkDetailPage />
            </RequireRole>
          </WaitForSession>
        }
      />
      <Route
        path={CHECK_HOMEWORK_PAGE_PATH}
        element={
          <WaitForSession>
            <RequireRole redirect={HOME_PAGE_PATH} roles={['teacher']}>
              <HomeworkCheckPage />
            </RequireRole>
          </WaitForSession>
        }
      />

      <Route
        path={CLASSROOM_STUDENTS_PAGE_PATH}
        element={
          <WaitForSession>
            <RequireRole redirect={HOME_PAGE_PATH} roles={['teacher']}>
              <ClassroomStudentsPage />
            </RequireRole>
          </WaitForSession>
        }
      />
      <Route
        path={STUDENT_DETAIL_PAGE_PATH}
        element={
          <WaitForSession>
            <RequireRole redirect={HOME_PAGE_PATH} roles={['teacher']}>
              <StudentDetailPage />
            </RequireRole>
          </WaitForSession>
        }
      />

      <Route
        path={CLASSROOM_EXERCISES_PAGE_PATH}
        element={
          <WaitForSession>
            <RequireRole redirect={HOME_PAGE_PATH} roles={['teacher']}>
              <ClassroomExercisesPage />
            </RequireRole>
          </WaitForSession>
        }
      />

      <Route
        path={EXPORT_EXERCISES_PAGE_PATH}
        element={
          <WaitForSession>
            <RequireRole redirect={HOME_PAGE_PATH} roles={['teacher']}>
              <ExportExercisesPage />
            </RequireRole>
          </WaitForSession>
        }
      />

      {/* Student module */}
      <Route
        path={STUDENT_CHANGE_PASSWORD_PAGE_PATH}
        element={
          <WaitForSession>
            <RequireRole redirect={HOME_PAGE_PATH} roles={['student']}>
              <StudentChangePasswordPage />
            </RequireRole>
          </WaitForSession>
        }
      />
      <Route
        path={STUDENT_DASHBOARD_PAGE_PATH}
        element={
          <WaitForSession>
            <RequireRole redirect={HOME_PAGE_PATH} roles={['student']}>
              <StudentDashboardPage />
            </RequireRole>
          </WaitForSession>
        }
      />
      <Route
        path={STUDENT_HOMEWORK_PAGE_PATH}
        element={
          <WaitForSession>
            <RequireRole redirect={HOME_PAGE_PATH} roles={['student']}>
              <StudentHomeworkPage />
            </RequireRole>
          </WaitForSession>
        }
      />
      <Route
        path={STUDENT_SOLVE_HOMEWORK_PAGE_PATH}
        element={
          <WaitForSession>
            <RequireRole redirect={HOME_PAGE_PATH} roles={['student']}>
              <SolveHomeworkPage />
            </RequireRole>
          </WaitForSession>
        }
      />
      {[STUDENT_EXERCISES_PAGE_PATH, STUDENT_EXERCISE_DETAIL_PAGE_PATH].map((path) => (
        <Route
          key={path}
          path={path}
          element={
            <WaitForSession>
              <RequireRole redirect={HOME_PAGE_PATH} roles={['student']}>
                <StudentExercisesPage />
              </RequireRole>
            </WaitForSession>
          }
        />
      ))}
      {[STUDENT_COURSE_STEP_PAGE_PATH, STUDENT_COURSE_PAGE_PATH].map((path) => (
        <Route
          key={path}
          path={path}
          element={
            <WaitForSession>
              <StudentCourseStepPage />
            </WaitForSession>
          }
        />
      ))}

      {/* Private */}
      {FEATURE_TOGGLE_PRIVATE_PAGES && (
        <>
          <Route path={PRIVATE_ALL_EXERCISES_PAGE_PATH} element={<AllExercisesPage />} />
        </>
      )}
    </Routes>
  );
}
