import SelectInputItem from 'components/assignment/inputs/SelectInputItem';
import CorrectIcon from 'components/icons/CorrectIcon';
import IncorrectIcon from 'components/icons/IncorrectIcon';
import AnswerStats from 'components/assignment/inputs/AnswerStats';
import { IExerciseQuestion } from 'data/assignment/assignment';

interface MultiselectInputProps {
  question: IExerciseQuestion;
  assignmentIsSolved: boolean;
  options: Array<{ text: string; value: string }>;
  value: string[];
  result?: string[];
  onChange: Function;
  readOnly: boolean;
  isPrint?: boolean;
}

/**
 * Multiselect input.
 */
export default function MultiselectInput({
  question,
  assignmentIsSolved,
  options,
  value,
  result,
  onChange,
  readOnly,
  isPrint = false,
}: MultiselectInputProps) {
  if (!value) {
    value = [];
  }

  /**
   * Updates the value field in the formData.
   */
  function handleChange(newValue: string) {
    if (!readOnly) {
      const isSelected = value.includes(newValue);

      if (isSelected) {
        onChange(value.filter((item) => item !== newValue));
      } else {
        onChange([...value, newValue]);
      }
    }
  }

  return (
    <div>
      {assignmentIsSolved && !question.isCorrect && (
        <AnswerStats answer={[...value].sort().join(', ')} result={[...result!].sort().join(', ')} />
      )}
      {options.map((option, index) => {
        let icon = <></>;
        const isSelected = value.includes(option.value);
        const isResult = result ? result.includes(option.value) : false;
        const isCorrect = isSelected && isResult;

        if (assignmentIsSolved && isSelected && !isCorrect) {
          icon = <IncorrectIcon />;
        }

        if (isResult) {
          icon = <CorrectIcon />;
        }

        return (
          <div
            className={`${
              isPrint ? 'grid-cols-[100%]' : 'grid-cols-[311px_30px] lg:grid-cols-[570px_30px]'
            } grid items-center gap-[11px]`}
            key={option.value}
          >
            <SelectInputItem
              assignmentIsSolved={assignmentIsSolved}
              label={option.text}
              value={option.value}
              onChange={handleChange}
              isSelected={isSelected}
              isResult={isResult}
              isCorrect={isCorrect}
              readOnly={readOnly}
            />
            {!isPrint && <div className="mb-[6px]">{icon}</div>}
          </div>
        );
      })}
    </div>
  );
}
