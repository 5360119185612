import RedCrossIcon from 'components/icons/RedCrossIcon';
import WaitingIcon from 'components/icons/WaitingIcon';
import { _t } from 'lang';

interface Props {
  successRate?: number;
  active: boolean;
  hasSuccessText?: boolean;
}

/**
 * Renders homework's success rate or texts 'Waiting' or 'Did not submit' based on homework status.
 */
export default function HomeworkResultView({ successRate, active, hasSuccessText }: Props) {
  if (successRate !== undefined) {
    return (
      <div className="flex items-center justify-between text-[16px] font-semibold leading-[19px] lg:justify-center lg:gap-[16px]">
        {hasSuccessText && <div className="text-[12px] leading-[15px] text-text-shadow">{_t('Your success rate')}</div>}
        {Math.round(successRate * 100)} %
      </div>
    );
  }

  if (active) {
    return (
      <div className="flex h-[32px] items-center justify-center gap-[4px] rounded-[20px] border border-input-outline-shadow bg-bg-shadow text-[12px] font-semibold leading-[15px] text-text-shadow">
        <WaitingIcon />
        {
          // Translators: This is a text that appears when a student has not submitted the homework and the homework is still ongoing (not past deadline).
          _t('Did not submit yet')
        }
      </div>
    );
  }

  return (
    <div className="flex h-[32px] items-center justify-center gap-[4px] rounded-[20px] border border-[#FFE1E1] bg-bg-red-menu-item text-[12px] font-semibold leading-[15px] text-red">
      <RedCrossIcon />
      {
        // Translators: This is a text that appears when a student has not submitted the homework and the homework is past deadline.
        _t('Did not submit')
      }
    </div>
  );
}
