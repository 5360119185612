import createLoginAction from 'api/actions/login/login-action';
import createRegisterAction from 'api/actions/register/register-action';
import createRefreshSessionAction from 'api/actions/refresh-session/refresh-session-action';
import createCreateGuestSessionAction from 'api/actions/create-guest-session/create-guest-session-action';
import createChangePasswordAction from 'api/actions/change-password/change-password-action';
import createResetPasswordRequestAction from 'api/actions/reset-password-request/reset-password-request-action';
import createResetPasswordValidateAction from 'api/actions/reset-password-validate/reset-password-validate-action';
import createResetPasswordCommitAction from 'api/actions/reset-password-commit/reset-password-commit-action';
import createGetUserProfileAction from 'api/actions/get-user-profile/get-user-profile-action';
import createUpdateUserProfileAction from 'api/actions/update-user-profile/update-user-profile-action';
import createGetCoursesAction from 'api/actions/get-courses/get-courses-action';
import createGetCoursePlanAction from 'api/actions/get-course-plan/get-course-plan-action';
import createGetCourseStepAction from 'api/actions/get-course-step/get-course-step-action';
import createEnrollInCourseAction from 'api/actions/enroll-in-course/enroll-in-course-action';
import createUpdateCourseProgressAction from 'api/actions/update-course-progress/update-course-progress-action';
import createGiveCourseFeedbackAction from 'api/actions/give-course-feedback/give-course-feedback-action';
import createGetExercisesAction from 'api/actions/get-exercises/get-exercises-action';
import createGetExerciseTopicsAction from 'api/actions/get-exercise-topics/get-exercise-topics-action';
import createGetExerciseTypesAction from 'api/actions/get-exercise-types/get-exercise-types-action';
import createCreateAssignmentAction from 'api/actions/create-assignment/create-assignment-action';
import createGetAssignmentAction from 'api/actions/get-assignment/get-assignment-action';
import createSubmitAssignmentSolutionAction from 'api/actions/submit-assignment-solution/submit-assignment-solution-action';
import createRepeatAssignmentAction from 'api/actions/repeat-assignment/repeat-assignment-action';
import createGetClassroomsAction from 'api/actions/get-classrooms/get-classrooms-action';
import createCreateClassroomAction from 'api/actions/create-classroom/create-classroom-action';
import createGetClassroomDetailAction from 'api/actions/get-classroom-detail/get-classroom-detail-action';
import createRefreshClassroomInviteCodeAction from 'api/actions/refresh-classroom-invite-code/refresh-classroom-invite-code-action';
import createValidateClassroomInviteCodeAction from 'api/actions/validate-classroom-invite-code/validate-classroom-invite-code-action';
import createJoinClassroomAction from 'api/actions/join-classroom/join-classroom-action';
import createGetClassroomStudentsAction from 'api/actions/get-classroom-students/get-classroom-students-action';
import createGetClassroomStudentDetailAction from 'api/actions/get-classroom-student-detail/get-classroom-student-detail-action';
import createKickClassroomStudentAction from 'api/actions/kick-classroom-student/kick-classroom-student-action';
import createGetClassroomHomeworkAction from 'api/actions/get-classroom-homework/get-classroom-homework-action';
import createAssignClassroomHomeworkAction from 'api/actions/assign-classroom-homework/assign-classroom-homework-action';
import createGetClassroomHomeworkDetailAction from 'api/actions/get-classroom-homework-detail/get-classroom-homework-detail-action';
import createSubmitClassroomHomeworkCommentAction from 'api/actions/submit-classroom-homework-comment/submit-classroom-homework-comment-action';
import createGetStudentDashboardAction from 'api/actions/get-student-dashboard/get-student-dashboard-action';
import createSubmitHomeworkAttachmentAction from 'api/actions/submit-homework-attachment/submit-homework-attachment-action';
import createSubmitStudentHomeworkCommentAction from 'api/actions/submit-student-homework-comment/submit-student-homework-comment-action';
import createServerTimeAction from 'api/actions/server-time/server-time-action';
import createHealthCheckAction from 'api/actions/health-check/health-check-action';
import { AxiosInstance } from 'axios';

/**
 * All action names.
 */
export type ActionName =
  | 'Login'
  | 'Register'
  | 'RefreshSession'
  | 'CreateGuestSession'
  | 'ChangePassword'
  | 'ResetPasswordRequest'
  | 'ResetPasswordValidate'
  | 'ResetPasswordCommit'
  | 'GetUserProfile'
  | 'UpdateUserProfile'
  | 'GetCourses'
  | 'GetCoursePlan'
  | 'GetCourseStep'
  | 'EnrollInCourse'
  | 'UpdateCourseProgress'
  | 'GiveCourseFeedback'
  | 'GetExercises'
  | 'GetExerciseTopics'
  | 'GetExerciseTypes'
  | 'CreateAssignment'
  | 'GetAssignment'
  | 'SubmitAssignmentSolution'
  | 'RepeatAssignment'
  | 'GetClassrooms'
  | 'CreateClassroom'
  | 'GetClassroomDetail'
  | 'RefreshClassroomInviteCode'
  | 'ValidateClassroomInviteCode'
  | 'JoinClassroom'
  | 'GetClassroomStudents'
  | 'GetClassroomStudentDetail'
  | 'KickClassroomStudent'
  | 'GetClassroomHomework'
  | 'AssignClassroomHomework'
  | 'GetClassroomHomeworkDetail'
  | 'SubmitClassroomHomeworkComment'
  | 'GetStudentDashboard'
  | 'SubmitHomeworkAttachment'
  | 'SubmitStudentHomeworkComment'
  | 'ServerTime'
  | 'HealthCheck';

/**
 * Overloads for the getAction function.
 */
export type GetActionMap = ((action: 'Login') => ReturnType<typeof createLoginAction>) &
  ((action: 'Register') => ReturnType<typeof createRegisterAction>) &
  ((action: 'RefreshSession') => ReturnType<typeof createRefreshSessionAction>) &
  ((action: 'CreateGuestSession') => ReturnType<typeof createCreateGuestSessionAction>) &
  ((action: 'ChangePassword') => ReturnType<typeof createChangePasswordAction>) &
  ((action: 'ResetPasswordRequest') => ReturnType<typeof createResetPasswordRequestAction>) &
  ((action: 'ResetPasswordValidate') => ReturnType<typeof createResetPasswordValidateAction>) &
  ((action: 'ResetPasswordCommit') => ReturnType<typeof createResetPasswordCommitAction>) &
  ((action: 'GetUserProfile') => ReturnType<typeof createGetUserProfileAction>) &
  ((action: 'UpdateUserProfile') => ReturnType<typeof createUpdateUserProfileAction>) &
  ((action: 'GetCourses') => ReturnType<typeof createGetCoursesAction>) &
  ((action: 'GetCoursePlan') => ReturnType<typeof createGetCoursePlanAction>) &
  ((action: 'GetCourseStep') => ReturnType<typeof createGetCourseStepAction>) &
  ((action: 'EnrollInCourse') => ReturnType<typeof createEnrollInCourseAction>) &
  ((action: 'UpdateCourseProgress') => ReturnType<typeof createUpdateCourseProgressAction>) &
  ((action: 'GiveCourseFeedback') => ReturnType<typeof createGiveCourseFeedbackAction>) &
  ((action: 'GetExercises') => ReturnType<typeof createGetExercisesAction>) &
  ((action: 'GetExerciseTopics') => ReturnType<typeof createGetExerciseTopicsAction>) &
  ((action: 'GetExerciseTypes') => ReturnType<typeof createGetExerciseTypesAction>) &
  ((action: 'CreateAssignment') => ReturnType<typeof createCreateAssignmentAction>) &
  ((action: 'GetAssignment') => ReturnType<typeof createGetAssignmentAction>) &
  ((action: 'SubmitAssignmentSolution') => ReturnType<typeof createSubmitAssignmentSolutionAction>) &
  ((action: 'RepeatAssignment') => ReturnType<typeof createRepeatAssignmentAction>) &
  ((action: 'GetClassrooms') => ReturnType<typeof createGetClassroomsAction>) &
  ((action: 'CreateClassroom') => ReturnType<typeof createCreateClassroomAction>) &
  ((action: 'GetClassroomDetail') => ReturnType<typeof createGetClassroomDetailAction>) &
  ((action: 'RefreshClassroomInviteCode') => ReturnType<typeof createRefreshClassroomInviteCodeAction>) &
  ((action: 'ValidateClassroomInviteCode') => ReturnType<typeof createValidateClassroomInviteCodeAction>) &
  ((action: 'JoinClassroom') => ReturnType<typeof createJoinClassroomAction>) &
  ((action: 'GetClassroomStudents') => ReturnType<typeof createGetClassroomStudentsAction>) &
  ((action: 'GetClassroomStudentDetail') => ReturnType<typeof createGetClassroomStudentDetailAction>) &
  ((action: 'KickClassroomStudent') => ReturnType<typeof createKickClassroomStudentAction>) &
  ((action: 'GetClassroomHomework') => ReturnType<typeof createGetClassroomHomeworkAction>) &
  ((action: 'AssignClassroomHomework') => ReturnType<typeof createAssignClassroomHomeworkAction>) &
  ((action: 'GetClassroomHomeworkDetail') => ReturnType<typeof createGetClassroomHomeworkDetailAction>) &
  ((action: 'SubmitClassroomHomeworkComment') => ReturnType<typeof createSubmitClassroomHomeworkCommentAction>) &
  ((action: 'GetStudentDashboard') => ReturnType<typeof createGetStudentDashboardAction>) &
  ((action: 'SubmitHomeworkAttachment') => ReturnType<typeof createSubmitHomeworkAttachmentAction>) &
  ((action: 'SubmitStudentHomeworkComment') => ReturnType<typeof createSubmitStudentHomeworkCommentAction>) &
  ((action: 'ServerTime') => ReturnType<typeof createServerTimeAction>) &
  ((action: 'HealthCheck') => ReturnType<typeof createHealthCheckAction>);

/**
 * The map from action name to action creator.
 */
export const actions: Map<ActionName, (axios: AxiosInstance) => Promise<any>> = new Map([
  ['Login', createLoginAction],
  ['Register', createRegisterAction],
  ['RefreshSession', createRefreshSessionAction],
  ['CreateGuestSession', createCreateGuestSessionAction],
  ['ChangePassword', createChangePasswordAction],
  ['ResetPasswordRequest', createResetPasswordRequestAction],
  ['ResetPasswordValidate', createResetPasswordValidateAction],
  ['ResetPasswordCommit', createResetPasswordCommitAction],
  ['GetUserProfile', createGetUserProfileAction],
  ['UpdateUserProfile', createUpdateUserProfileAction],
  ['GetCourses', createGetCoursesAction],
  ['GetCoursePlan', createGetCoursePlanAction],
  ['GetCourseStep', createGetCourseStepAction],
  ['EnrollInCourse', createEnrollInCourseAction],
  ['UpdateCourseProgress', createUpdateCourseProgressAction],
  ['GiveCourseFeedback', createGiveCourseFeedbackAction],
  ['GetExercises', createGetExercisesAction],
  ['GetExerciseTopics', createGetExerciseTopicsAction],
  ['GetExerciseTypes', createGetExerciseTypesAction],
  ['CreateAssignment', createCreateAssignmentAction],
  ['GetAssignment', createGetAssignmentAction],
  ['SubmitAssignmentSolution', createSubmitAssignmentSolutionAction],
  ['RepeatAssignment', createRepeatAssignmentAction],
  ['GetClassrooms', createGetClassroomsAction],
  ['CreateClassroom', createCreateClassroomAction],
  ['GetClassroomDetail', createGetClassroomDetailAction],
  ['RefreshClassroomInviteCode', createRefreshClassroomInviteCodeAction],
  ['ValidateClassroomInviteCode', createValidateClassroomInviteCodeAction],
  ['JoinClassroom', createJoinClassroomAction],
  ['GetClassroomStudents', createGetClassroomStudentsAction],
  ['GetClassroomStudentDetail', createGetClassroomStudentDetailAction],
  ['KickClassroomStudent', createKickClassroomStudentAction],
  ['GetClassroomHomework', createGetClassroomHomeworkAction],
  ['AssignClassroomHomework', createAssignClassroomHomeworkAction],
  ['GetClassroomHomeworkDetail', createGetClassroomHomeworkDetailAction],
  ['SubmitClassroomHomeworkComment', createSubmitClassroomHomeworkCommentAction],
  ['GetStudentDashboard', createGetStudentDashboardAction],
  ['SubmitHomeworkAttachment', createSubmitHomeworkAttachmentAction],
  ['SubmitStudentHomeworkComment', createSubmitStudentHomeworkCommentAction],
  ['ServerTime', createServerTimeAction],
  ['HealthCheck', createHealthCheckAction],
] as any);
