import RemoveFilterIcon from 'components/icons/RemoveFilterIcon';
import Clickable from 'components/misc/Clickable';
import getGradeName from 'utils/get-grade-name';

interface Props {
  filter: string;
  removeFilter: () => void;
}

/**
 * Button that displays the name of the selected filter and allows to remove the filter.
 */
export default function FilterButton({ filter, removeFilter }: Props) {
  let label = filter;

  // TODO: Remove this when filters include labels as well as values.
  if (label.match(/^1?\d+$/)) {
    label = getGradeName(parseInt(label));
  }

  return (
    <div className="flex h-[32px] items-center gap-[16px] rounded-[20px] border border-text-shadow bg-white pl-[20px] pr-[8px]">
      <div className="text-[14px] font-medium leading-[17px] text-text-dark-2">
        {label.replace(/^./, (ch) => ch.toLocaleUpperCase())}
      </div>
      <Clickable onClick={removeFilter}>
        <RemoveFilterIcon />
      </Clickable>
    </div>
  );
}
