import { createContext, useContext } from 'react';

/**
 * The context of a student dashboard layout.
 */
interface IStudentDashboardLayoutContext {
  /**
   * Whether the mobile menu is visible.
   */
  isPhoneHeaderVisible: boolean;

  /**
   * Hides the mobile menu.
   */
  hidePhoneHeader: () => void;

  /**
   * Shows the mobile menu.
   */
  showPhoneHeader: () => void;
}

/**
 * The student dashboard layout context.
 */
export const StudentDashboardLayoutContext = createContext<IStudentDashboardLayoutContext>(undefined!);

/**
 * Uses the student dashboard layout context.
 */
export function useStudentDashboardLayout() {
  return useContext(StudentDashboardLayoutContext);
}
