import axios from 'axios';
import ApiContext, { ISession } from 'api/api-context';
import { useEffect, useState } from 'react';
import { ActionName, actions, GetActionMap } from 'api/actions/actions';
import ApiError from 'api/errors/api-error';
import { CONNECTION_ERROR, JSON_WEB_TOKEN_INVALID_ERROR, UNKNOWN_ERROR } from 'api/errors/error-codes';
import { LOGIN_PAGE_PATH } from 'routes/paths';
import { useNavigate } from 'react-router-dom';
import { DispatchedAction } from 'api/dispatch-action';
import { showNotification } from '@mantine/notifications';
import { _t } from 'lang';
import useLocalStorage from 'api/use-local-storage';
import { REST_API_TIMEOUT, REST_API_URL } from 'env';

/**
 * The session provider is used to provide the session context.
 */
export default function ApiProvider({ children }: { children: React.ReactNode }) {
  const [ready, setReady] = useState(false);

  const [{ jwt, role, firstName, lastName, email }, setSession] = useLocalStorage<ISession>('zvedavamysl.session', {
    jwt: '',
    role: 'guest',
    firstName: '',
    lastName: '',
    email: '',
  });

  const navigate = useNavigate();

  /**
   * The connector object = axios instance.
   */
  const connector = axios.create({
    timeout: REST_API_TIMEOUT,
    baseURL: REST_API_URL,
  });

  /**
   * Adds JWT to the request.
   */
  connector.interceptors.request.use((cfg) => {
    if (jwt) {
      if (!cfg.headers) {
        cfg.headers = {};
      }

      cfg.headers.Authorization = `Bearer ${jwt}`;
    }

    return cfg;
  });

  /**
   * Processes the success and fail response.
   */
  connector.interceptors.response.use(
    ({ data }) => data.data,
    (error) => {
      if (error?.code === 'ECONNABORTED') {
        throw new ApiError(CONNECTION_ERROR);
      }

      const code = error?.response?.data?.error?.code ?? UNKNOWN_ERROR;

      if (code === JSON_WEB_TOKEN_INVALID_ERROR) {
        setSession({ jwt: null, role: 'guest', firstName: '', lastName: '', email: '' });
        setReady(false);
      }

      throw new ApiError(code);
    }
  );

  /**
   * Contextualize (create) all actions only once.
   */
  const contextualizedActions = new Map(
    Array.from(actions.entries()).map(([name, createAction]) => [name, createAction(connector)])
  );

  /**
   * Creates a contextualized action.
   */
  const getAction: GetActionMap = (action: ActionName) => contextualizedActions.get(action) as any;

  /**
   * Decorates an action by storing the JWT in the response.
   */
  function storeJwtDecorator<TParams, TResponse extends ISession>(
    action: (params: TParams) => DispatchedAction<TResponse>
  ) {
    return (params: TParams) =>
      action(params).success((response) => {
        const { jwt, role, firstName, lastName, email } = response;

        setSession({ jwt, role, firstName, lastName, email });
        setReady(true);

        return response;
      });
  }

  const login = storeJwtDecorator(getAction('Login'));
  const register = storeJwtDecorator(getAction('Register'));
  const refreshSession = storeJwtDecorator(getAction('RefreshSession'));
  const createGuestSession = storeJwtDecorator(getAction('CreateGuestSession'));

  /**
   * Logs out user.
   */
  const logout = () => {
    setSession({ jwt: null, role: 'guest', firstName: '', lastName: '', email: '' });
    setReady(false);
    navigate(LOGIN_PAGE_PATH);
  };

  // Initiate the session.
  useEffect(() => {
    if (!ready) {
      refreshSession({ payload: {} }).error('*', () => {
        createGuestSession({ payload: {} }).error('*', () => {
          // At this point both methods of initiating a session failed.
          showNotification({
            title: _t('Something went wrong.'),
            message: _t("Sorry, we couldn't initiate your session. Please, try reloading the website."),
            color: 'red',
          });
        });
      });
    }
  }, [ready]);

  return (
    <ApiContext.Provider value={{ jwt, ready, role, firstName, lastName, email, login, register, getAction, logout }}>
      {children}
    </ApiContext.Provider>
  );
}
