import { Button } from '@mantine/core';
import ArrowRightIcon from 'components/icons/ArrowRightIcon';
import FilterIcon from 'components/icons/FilterIcon';
import { _t, lang } from 'lang';
import TeacherDashboardLayout from 'layout/teacher-dashboard-layout/TeacherDashboardLayout';
import { useEffect, useState } from 'react';
import ClassExercisesContent from 'pages/teacher-module/classroom/exercises/ClassroomExercisesContent';
import EyePrettyIcon from 'components/icons/EyePrettyIcon';
import FiltersModal from 'components/modals/filter/FiltersModal';
import Clickable from 'components/misc/Clickable';
import FilterButton from 'components/buttons/FilterButton';
import AssignExercisesModal from 'pages/teacher-module/classroom/exercises/modals/AssignExercisesModal';
import NumberInCircle from 'components/NumberInCircle';
import useApi from 'api/use-api';
import { GetExercisesResponse } from 'api/actions/get-exercises/get-exercises-response';
import { intersection, isEmpty, sortBy } from 'lodash';
import SelectedAssignmentsModal from 'pages/teacher-module/classroom/exercises/modals/SelectedAssignmentsModal';

/**
 * Exercises for a classroom.
 *
 * @see https://www.figma.com/file/SjggpqHVlWxBKa2cIG349A/Math-Exercises?node-id=629%3A6928&t=J3hpSwWIuCcusYbV-0
 */
export default function ClassroomExercisesPage() {
  const { getAction } = useApi();
  const getExercisesAction = getAction('GetExercises');

  // exercises
  const [allExercises, setAllExercises] = useState<GetExercisesResponse['exercises']>();

  // filters
  const [selectedFilters, setSelectedFilters] = useState<string[]>([]);

  // modals
  const [displayFiltersModal, setDisplayFiltersModal] = useState<boolean>(false);
  const [displayAssignExercisesModal, setDisplayAssignExercisesModal] = useState<boolean>(false);
  const [displaySelectedAssignmentsModal, setDisplaySelectedAssignmentsModal] = useState<boolean>(false);

  // resulting assignments
  const [selectedExercises, setSelectedExercises] = useState<{ variants: number; exerciseId: number }[]>([]);

  // Apply filters.
  let filteredExercises = allExercises || [];
  if (!isEmpty(selectedFilters) && allExercises) {
    filteredExercises = allExercises.filter((exercise) => {
      const { topics } = exercise;
      const grades = exercise.grades.map((grade) => String(grade));

      return intersection(topics, selectedFilters).length > 0 || intersection(grades, selectedFilters).length > 0;
    });
  }

  // Fetch exercises.
  useEffect(() => {
    getExercisesAction({ query: { language: lang } }).success(({ exercises }) =>
      setAllExercises(sortBy(exercises, 'exerciseId'))
    );
  }, []);

  return (
    <TeacherDashboardLayout hasHeader hasSidebar>
      <div className="flex items-center justify-between border-b border-b-input-outline-shadow pb-[32px]">
        <div className="flex items-center gap-[16px]">
          <div className="text-[24px] font-semibold leading-[29px]">{_t('Select exercises')}</div>
          {selectedExercises.length > 0 && (
            <div className="flex h-[42px] items-center gap-[8px] rounded-[20px] border border-input-outline-shadow bg-white pl-[16px] pr-[16px]">
              <div className="text-[14px] font-medium leading-[17px] text-text-shadow">{_t('Selected exercises')}</div>{' '}
              <NumberInCircle
                value={selectedExercises.map((val) => val.variants).reduce((a, b) => a + b, 0)}
                className="h-[20px] w-[20px] text-[12px] font-semibold leading-[15px]"
              />
            </div>
          )}
        </div>
        <div className="flex items-center gap-[16px]">
          {selectedExercises.length > 0 && (
            <Button
              className="h-[42px] rounded-[7px] bg-white text-text-dark"
              onClick={() => setDisplaySelectedAssignmentsModal(true)}
            >
              <div className="flex items-center gap-[13px] text-[14px] font-semibold leading-[17px]">
                <EyePrettyIcon />
                <div>{_t('Homework preview')}</div>
              </div>
            </Button>
          )}
          <Button
            disabled={selectedExercises.length === 0}
            className="h-[42px] min-w-[246px] bg-green-primary hover:bg-green-dark focus:bg-green-dark"
            radius={7}
            onClick={() => setDisplayAssignExercisesModal(true)}
          >
            <div className="flex items-center gap-[13px] text-[14px] font-semibold leading-[17px]">
              {_t('Assign homework')}
              <div className="flex h-[24px] w-[24px] items-center justify-center">
                <ArrowRightIcon />
              </div>
            </div>
          </Button>
        </div>
      </div>
      <div className="pt-[32px]">
        <div className="flex flex-wrap items-stretch gap-[8px]">
          <div className="flex items-stretch">
            <Button
              className="h-[42px] rounded-[7px] border border-input-outline-shadow bg-white text-green-primary"
              onClick={() => setDisplayFiltersModal(true)}
            >
              <div className="flex items-center gap-[8px] text-[14px] font-semibold leading-[17px]">
                <FilterIcon />
                {_t('Filter')}
              </div>
            </Button>
          </div>
          {selectedFilters.map((filter) => (
            <div className="flex items-center" key={filter}>
              <FilterButton
                filter={filter}
                removeFilter={() => setSelectedFilters((prev) => prev.filter((p) => p !== filter))}
              />
            </div>
          ))}
          <div className="flex items-center">
            {selectedFilters.length > 0 && (
              <Clickable
                className="text-[14px] font-medium leading-[17px] text-text-dark underline"
                onClick={() => setSelectedFilters([])}
              >
                {_t('delete all filters')}
              </Clickable>
            )}
          </div>
        </div>
      </div>
      <div className="pt-[22px]">
        <ClassExercisesContent
          exercises={filteredExercises}
          selectedExercises={selectedExercises}
          setSelectedExercises={setSelectedExercises}
        />
      </div>
      <FiltersModal
        opened={displayFiltersModal}
        setOpened={setDisplayFiltersModal}
        selectedFilters={selectedFilters}
        setSelectedFilters={setSelectedFilters}
        allExercises={allExercises || []}
      />
      <AssignExercisesModal
        opened={displayAssignExercisesModal}
        setOpened={setDisplayAssignExercisesModal}
        selectedExercises={selectedExercises}
      />
      <SelectedAssignmentsModal
        opened={displaySelectedAssignmentsModal}
        setOpened={setDisplaySelectedAssignmentsModal}
        selectedExercises={selectedExercises}
        openAssignExercisesModal={() => setDisplayAssignExercisesModal(true)}
      />
    </TeacherDashboardLayout>
  );
}
