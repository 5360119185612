import { format } from 'date-fns';
import { _t, dateFnsLocale } from 'lang';

/**
 * The parameters of the HomeworkDateTimeDisplay component.
 */
interface HomeworkDateTimeDisplayProps {
  dateTime: Date;
}

/**
 * Displays date and time in localized format, separated by a middle dot.
 */
export default function HomeworkDateTimeDisplay({ dateTime }: HomeworkDateTimeDisplayProps) {
  // Translators: This is a date format string used in a homework card to display the date when the homework was assigned. See https://date-fns.org/v2.29.3/docs/format for more information.
  const dateFormat = _t('dd. LLLL, yyyy');
  const formattedDate = format(dateTime, dateFormat, { locale: dateFnsLocale });

  // Translators: This is a time format string used in a homework card to display the time when the homework was assigned. See https://date-fns.org/v2.29.3/docs/format for more information.
  const timeFormat = _t('HH:mm');
  const formattedTime = format(dateTime, timeFormat, { locale: dateFnsLocale });

  return (
    <div className="flex items-center gap-[4px] text-[12px] font-medium leading-[15px] text-text-shadow">
      <div>{formattedDate}</div>
      <span className="translate-y-[-1px] text-[22px]">&#183;</span>
      <div>{formattedTime}</div>
    </div>
  );
}
