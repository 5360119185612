interface Props {
  stroke?: string;
  width?: string;
  height?: string;
}

/**
 * Arrow down icon.
 */
export default function ArrowDownIcon({ stroke = '#808D93', width = '16', height = '9' }: Props) {
  return (
    <svg width={width} height={height} viewBox="0 0 16 9" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M1.33325 1.66675L7.29281 7.62631C7.68334 8.01683 8.3165 8.01683 8.70703 7.62631L14.6666 1.66675"
        stroke={stroke}
        strokeWidth="2"
        strokeLinecap="round"
      />
    </svg>
  );
}
