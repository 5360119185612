import FaqAccordion from 'components/faq/FaqAccordion';
import FAQIcon from 'components/icons/FAQIcon';
import { _t } from 'lang';
import BasicPageLayout from 'layout/basic-page-layout/BasicPageLayout';

/**
 * The FAQ page.
 */
export default function FaqPage() {
  return (
    <BasicPageLayout hasHeader hasFooter>
      <div>
        <div className="bg-bg-shadow pb-[80px] pt-[60px]">
          <div className="mx-auto px-[24px] lg:max-w-[1170px] lg:px-0">
            <div className="flex flex-row items-center justify-center gap-[16px] pb-[60px] text-center text-[32px] font-semibold leading-[42px]">
              <div className="hidden md:block">
                <FAQIcon />
              </div>
              <div>{_t('Frequently asked questions and help')}</div>
            </div>
            <div className="mx-auto max-w-[770px]">
              <FaqAccordion />
            </div>
          </div>
        </div>
      </div>
    </BasicPageLayout>
  );
}
