import useApi from 'api/use-api';
import CorrectDarkIcon from 'components/icons/CorrectDarkIcon';
import { _t } from 'lang';
import { Link } from 'react-router-dom';
import { CLASSROOMS_PAGE_PATH, STUDENT_DASHBOARD_PAGE_PATH } from 'routes/paths';

/**
 * The info message on the homepage. Directs the user to their section.
 */
export default function HomePageInfoMessage() {
  const { role, ready } = useApi();

  if (!ready || role === 'guest') {
    return <div></div>;
  }

  const teacherAddress = _t("You're logged in as a teacher.");
  const teacherText = _t("If you want to assign homework to your students, go to the teacher's section.");
  const teacherCta = _t("Go to the teacher's section");

  const studentAddress = _t("You're logged in as a student.");
  const studentText = _t("If you want to solve your assigned homework, go to the student's section.");
  const studentCta = _t("Go to the student's section");

  const address = role === 'student' ? studentAddress : teacherAddress;
  const text = role === 'student' ? studentText : teacherText;
  const cta = role === 'student' ? studentCta : teacherCta;
  const link = role === 'student' ? STUDENT_DASHBOARD_PAGE_PATH : CLASSROOMS_PAGE_PATH;

  return (
    <div className="min-h-[90px] w-full bg-green-primary-opacity-01 py-[12px]">
      <div className="mx-auto flex h-full w-full max-w-[1218px] items-center gap-x-[16px] px-[24px] lg:gap-x-[8px]">
        <div className="h-[24px] w-[24px]">
          <CorrectDarkIcon />
        </div>
        <div className="lg:flex lg:flex-grow lg:justify-between">
          <div className="text-[14px] leading-[20px]">
            <span className="font-semibold">{address}</span> <span className="font-normal">{text}</span>
          </div>
          <Link className="text-[14px] font-semibold leading-[20px] text-green-dark underline" to={link}>
            {cta}
          </Link>
        </div>
      </div>
    </div>
  );
}
