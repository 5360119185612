import { Button } from '@mantine/core';
import Emoji from 'components/Emoji';
import { _t } from 'lang';
import { IAssignment, IAssignmentEvaluation } from 'data/assignment/assignment';
import { ReactNode, useState } from 'react';
import useApi from 'api/use-api';
import { isFunction } from 'lodash';

/**
 * The parameters of the component.
 */
interface Props {
  assignment: IAssignment;
  refresh: () => void;
  afterEvaluationContent?: ReactNode | ((evaluation: IAssignmentEvaluation) => ReactNode);
}

/**
 * The results table.
 */
export default function AssignmentEvaluation({ assignment, refresh, afterEvaluationContent = null }: Props) {
  const { repeatable } = assignment;
  const { correct, incorrect, successRate, total } = assignment.evaluation!;

  const [buttonDisabled, setButtonDisabled] = useState(false);

  const { getAction } = useApi();
  const repeatAssignmentAction = getAction('RepeatAssignment');

  /**
   * Repeats the assignment.
   */
  function repeatAssignment() {
    setButtonDisabled(true);

    repeatAssignmentAction({ parameters: { assignmentId: assignment.assignmentId } })
      .success(refresh)
      .complete(() => setButtonDisabled(false));
  }

  return (
    <div className="mb-[48px]">
      {/* Header */}
      <div className="text-[24px] font-semibold leading-[34px] text-text-dark">
        {_t('Results')} <Emoji symbol="🏆" label={_t('trophy')} />
      </div>

      {/* Table */}
      <div className="mt-[16px] border-b border-t border-b-input-outline-shadow border-t-input-outline-shadow text-[16px] leading-[19px]">
        <div className="flex justify-between  pb-[12px] pt-[12px]">
          <div>{_t('Correct')}</div>
          <div>{correct}</div>
        </div>
        <div className="flex justify-between border-b border-t border-b-input-outline-shadow border-t-input-outline-shadow pb-[12px] pt-[12px]">
          <div>{_t('Incorrect')}</div>
          <div>{incorrect}</div>
        </div>
        <div className="flex justify-between pb-[12px] pt-[12px]">
          <div className="font-semibold">{_t('Success rate')}</div>
          <div className="font-semibold">{Math.round(successRate * 100)} %</div>
        </div>
      </div>

      {/* Action buttons */}
      <div className="flex flex-col justify-between gap-[16px] border-b border-b-input-outline-shadow pb-[48px] empty:hidden lg:flex-row">
        {repeatable && (
          <Button
            disabled={buttonDisabled}
            className="lg:px- mt-[32px] h-[56px] border-green-primary px-0 text-[16px] leading-[19px] text-green-primary hover:bg-bg-secondary-button-hover hover:text-green-dark active:bg-bg-secondary-button-hover lg:flex-shrink lg:flex-grow-0 lg:px-[18px]"
            radius={10}
            onClick={repeatAssignment}
          >
            {total > 1 ? _t('Try again with different exercises') : _t('Try again with different exercise')}
          </Button>
        )}
        {isFunction(afterEvaluationContent) ? afterEvaluationContent(assignment.evaluation!) : afterEvaluationContent}
      </div>
    </div>
  );
}
