import ArrowLeftIcon from 'components/icons/ArrowLeftIcon';
import { _t } from 'lang';
import { Link } from 'react-router-dom';

interface Props {
  path: string;
  className?: string;
  text?: string;
}

/**
 * Go back button.
 */
export default function GoBackButton({ path, className = '', text = _t('Go back') }: Props) {
  return (
    <Link to={path} className={`flex cursor-pointer items-center gap-[10px] pt-[21px] ${className}`}>
      <ArrowLeftIcon />
      <span className="text-[16px] font-semibold leading-[20px] text-green-primary">{text}</span>
    </Link>
  );
}
