import { VIDEO_PROGRESS_INTERVAL_SECONDS } from 'env';

/**
 * Computes the progress based on the played time.
 */
export default function computeProgress(duration: number, playedSeconds: number): number {
  if (playedSeconds < VIDEO_PROGRESS_INTERVAL_SECONDS) {
    return 0;
  }

  const secondsLeft = duration - playedSeconds;

  if (secondsLeft < VIDEO_PROGRESS_INTERVAL_SECONDS) {
    return 1;
  }

  return Math.min(1, playedSeconds / duration);
}
