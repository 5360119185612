interface Props {
  width?: number;
  height?: number;
  active: boolean;
}

/**
 * Exercise icon.
 */
export default function ExerciseIcon({ width = 12, height = 12, active }: Props) {
  return (
    <svg width={width} height={height} viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect y="10" width="2" height="12" transform="rotate(-90 0 10)" fill={active ? '#353E45' : '#738491'} />
      <rect y="4" width="2" height="12" transform="rotate(-90 0 4)" fill={active ? '#353E45' : '#738491'} />
    </svg>
  );
}
