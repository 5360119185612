interface Props {
  stroke?: string;
}

/**
 * Student Icon
 */
export default function StudentIcon({ stroke = '#738491' }: Props) {
  return (
    <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx="7.00014" cy="4.14272" r="2.57143" stroke={stroke} strokeWidth="2" />
      <path
        d="M12.7137 12.7143C12.7137 9.55837 10.1554 7 6.99944 7C3.84353 7 1.28516 9.55837 1.28516 12.7143"
        stroke={stroke}
        strokeWidth="2"
        strokeLinecap="round"
      />
      <line x1="7.28516" y1="11" x2="8.85658" y2="11" stroke={stroke} strokeWidth="2" strokeLinecap="round" />
    </svg>
  );
}
