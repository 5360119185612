import { AxiosInstance } from 'axios';
import { SweetPath } from 'sweet-path';
import dispatchAction from 'api/dispatch-action';

import { RepeatAssignmentPathParameters } from 'api/actions/repeat-assignment/repeat-assignment-path-parameters';

import { RepeatAssignmentResponse } from 'api/actions/repeat-assignment/repeat-assignment-response';

/**
 * The path of the RepeatAssignment action.
 */
export const repeatAssignmentActionPath = new SweetPath<keyof RepeatAssignmentPathParameters>(
  '/assignment/{assignmentId}/repeat',
  { path: '{param}' }
);

/**
 * Creates a contextualized RepeatAssignment actions.
 */
export default function createRepeatAssignmentAction(axios: AxiosInstance) {
  return function repeatAssignmentAction({ parameters }: { parameters: RepeatAssignmentPathParameters }) {
    const path = repeatAssignmentActionPath.insert(parameters);

    return dispatchAction(() => axios.post<RepeatAssignmentResponse, RepeatAssignmentResponse>(path));
  };
}
