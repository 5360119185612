import Emoji from 'components/Emoji';
import { _t } from 'lang';
import { Link, useParams } from 'react-router-dom';
import HeaderProgressBar from 'layout/parts/header/HeaderProgressBar';
import useCourse from 'data/course/use-course';

/**
 * The parameters of the component.
 */
interface Props {
  className?: string;
}

/**
 * Continue Course link. Navigates the user to the first course at the last unfinished step.
 */
export default function ContinueCourseLink({ className = '' }: Props) {
  const { sectionSlug, stepId } = useParams();
  const slug = 'priprava-na-prijimacky';

  const { getCourse } = useCourse();
  const course = getCourse({ slug });

  const linkCourse = `/course/${slug}`;
  const linkStep = sectionSlug && stepId ? `/${sectionSlug}/step/${stepId}` : '';
  const linkFull = `${linkCourse}${linkStep}`;

  return (
    <Link className={`pl-[16px] pr-[16px] text-[16px] font-medium leading-[19px] ${className}`} to={linkFull}>
      <span className="whitespace-nowrap">
        {_t('High school admissions')} <Emoji symbol={'✍🏼'} label={_t('writing-hand')} />
      </span>
      <HeaderProgressBar progress={Math.round((course?.progress ?? 0) * 100)} />
    </Link>
  );
}
