interface Props {
  filled?: boolean;
  hover?: boolean;
  fill?: string;
}

/**
 * The feedback star icon
 */
export default function StarIcon({ filled, hover, fill = '#F7CE3D' }: Props) {
  return (
    <svg
      width="28"
      height="27"
      viewBox="0 0 28 27"
      fill={filled || hover ? fill : 'none'}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M19.8788 18.0283L20.8486 17.7843L19.8788 18.0283L21.8272 25.7733L15.0634 21.5269C14.4132 21.1187 13.5868 21.1187 12.9366 21.5269L13.4683 22.3738L12.9366 21.5269L6.17276 25.7733L8.12118 18.0283C8.30847 17.2838 8.05308 16.4978 7.46395 16.0055L1.33525 10.885L9.30327 10.3447C10.0692 10.2928 10.7378 9.80699 11.0239 9.09458L14 1.6835L16.9761 9.09458C17.2622 9.80698 17.9308 10.2928 18.6967 10.3447L26.6647 10.885L20.5361 16.0055C19.9469 16.4978 19.6915 17.2838 19.8788 18.0283Z"
        stroke={fill}
        strokeWidth="2"
      />
    </svg>
  );
}
