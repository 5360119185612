import useApi from 'api/use-api';
import { FEATURE_TOGGLE_DISPLAY_ASSIGNMENT_AD } from 'env';
import { useEffect } from 'react';

/**
 * The implementation of the assignment ad.
 */
function AssignmentAdImpl() {
  // Initialize the ad.
  useEffect(() => {
    ((window as any).adsbygoogle = (window as any).adsbygoogle || []).push({});
  }, []);

  return (
    <ins
      className="adsbygoogle"
      style={{ display: 'inline-block', width: '870px', height: '280px' }}
      data-ad-client="ca-pub-2712222548495564"
      data-ad-slot="9567941592"
    ></ins>
  );
}

/**
 * The Assignment ad.
 *
 * @see https://www.google.com/adsense/new/u/0/pub-2712222548495564/myads/units/display/1370600410
 */
export default function AssignmentAd() {
  const { email } = useApi();

  if (email !== 'milan@wikarski.sk') {
    return <></>;
  }

  return FEATURE_TOGGLE_DISPLAY_ASSIGNMENT_AD ? <AssignmentAdImpl /> : <></>;
}
