import StartExercises from 'components/StartExercises';
import { _t, lang } from 'lang';
import BasicPageLayout from 'layout/basic-page-layout/BasicPageLayout';
import WebinarsCs from 'pages/presentation-module/webinars/WebinarsCs';
import WebinarsPl from 'pages/presentation-module/webinars/WebinarsPl';

/**
 * The contents of the webinars page.
 */
function WebinarsPageContent() {
  switch (lang) {
    case 'cs':
      return <WebinarsCs />;
    case 'pl':
      return <WebinarsPl />;
    default:
      return <></>;
  }
}

/**
 * The webinars page.
 */
export default function WebinarsPage() {
  return (
    <BasicPageLayout hasFooter hasHeader>
      <div>
        <div className="flex min-h-[220px] items-center bg-contact-phone bg-cover bg-no-repeat text-white md:bg-contact-desktop">
          <div className="ml-auto mr-auto flex max-w-[718px] flex-col items-center pl-[24px] pr-[24px] text-center">
            <span className="text-[32px] font-semibold leading-[42px] md:text-[44px] md:leading-[54px]">
              {_t('Webinars')}
            </span>
          </div>
        </div>

        <div className="bg-bg-shadow">
          <div className="ml-auto mr-auto grid max-w-[1218px] grid-cols-[100%] justify-center gap-[24px] pb-[56px] pl-[24px] pr-[24px] pt-[56px] lg:grid-cols-2 lg:gap-[30px] lg:pb-[80px] lg:pt-[80px]">
            <WebinarsPageContent />
          </div>
        </div>

        <div className="ml-auto mr-auto max-w-[1218px] px-[24px] pb-[64px] pt-[64px] lg:pb-[80px] lg:pt-[80px]">
          <StartExercises />
        </div>
      </div>
    </BasicPageLayout>
  );
}
