// See https://dev.zvedavamysl.cz/docs/errors.yaml
export const CLIENT_ERROR = 4000;
export const JSON_WEB_TOKEN_INVALID_ERROR = 4001;
export const TOO_MANY_REQUESTS_ERROR = 4002;
export const ENDPOINT_DEPRECATED_ERROR = 4003;
export const OBJECT_DOES_NOT_EXIST_ERROR = 4004;
export const INVALID_PASSWORD_ERROR = 4005;
export const OBJECT_ALREADY_EXISTS_ERROR = 4006;
export const ACTION_NOT_PERMITTED_ERROR = 4007;
export const ACCESS_TOKEN_INVALID_ERROR = 4008;
export const COURSE_DOES_NOT_EXIST_ERROR = 4009;
export const COURSE_STEP_DOES_NOT_EXIST_ERROR = 4010;
export const EXERCISE_SET_DOES_NOT_EXIST_ERROR = 4011;
export const ASSIGNMENT_DOES_NOT_EXIST_ERROR = 4012;
export const INVALID_QUESTION_ANSWER_ERROR = 4013;
export const COURSE_SECTION_DOES_NOT_EXIST = 4014;
export const USER_DOES_NOT_EXIST_ERROR = 4015;
export const EMAIL_DOES_NOT_EXIST_ERROR = 4016;
export const EMAIL_ALREADY_USED_ERROR = 4017;
export const CLASSROOM_INVITE_CODE_INVALID_ERROR = 4023;

// Custom errors defined by web app.
export const UNKNOWN_ERROR = 5000;
export const CONNECTION_ERROR = 6000;
