/**
 * Refresh icon.
 */
export default function RefreshIcon() {
  return (
    <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M6 12C4.325 12 2.90625 11.4187 1.74375 10.2562C0.58125 9.09375 0 7.675 0 6C0 4.325 0.58125 2.90625 1.74375 1.74375C2.90625 0.58125 4.325 0 6 0C6.8625 0 7.6875 0.17825 8.475 0.53475C9.2625 0.89075 9.9375 1.4 10.5 2.0625V0H12V5.25H6.75V3.75H9.9C9.5 3.05 8.95325 2.5 8.25975 2.1C7.56575 1.7 6.8125 1.5 6 1.5C4.75 1.5 3.6875 1.9375 2.8125 2.8125C1.9375 3.6875 1.5 4.75 1.5 6C1.5 7.25 1.9375 8.3125 2.8125 9.1875C3.6875 10.0625 4.75 10.5 6 10.5C6.9625 10.5 7.83125 10.225 8.60625 9.675C9.38125 9.125 9.925 8.4 10.2375 7.5H11.8125C11.4625 8.825 10.75 9.90625 9.675 10.7438C8.6 11.5813 7.375 12 6 12Z"
        fill="#0AB892"
      />
    </svg>
  );
}
