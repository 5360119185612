/**
 * Equal sign icon.
 */
export default function EqualSignIcon() {
  return (
    <svg width="8" height="5" viewBox="0 0 8 5" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M0.772727 1.38636V0.159091H7.77273V1.38636H0.772727ZM0.772727 4.75V3.52273H7.77273V4.75H0.772727Z"
        fill="#353E45"
      />
    </svg>
  );
}
