interface Props {
  stroke?: string;
  width?: string;
  height?: string;
  strokeWidth?: string;
}

/**
 * Add Person Icon
 */
export default function AddPersonIcon({ stroke = '#353E45', width = '20', height = '16', strokeWidth = '2' }: Props) {
  return (
    <svg width={width} height={height} viewBox="0 0 20 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx="8.00056" cy="4.57136" r="3.28571" stroke={stroke} strokeWidth={strokeWidth} />
      <path
        d="M14.8569 14.8571C14.8569 11.07 11.7868 8 7.99972 8C4.21263 8 1.14258 11.07 1.14258 14.8571"
        stroke={stroke}
        strokeWidth={strokeWidth}
        strokeLinecap="round"
      />
      <line x1="8.14258" y1="13" x2="10.4283" y2="13" stroke={stroke} strokeWidth={strokeWidth} strokeLinecap="round" />
      <path d="M15 3H19" stroke={stroke} strokeWidth={strokeWidth} strokeLinecap="round" />
      <path d="M17 5L17 1" stroke={stroke} strokeWidth={strokeWidth} strokeLinecap="round" />
    </svg>
  );
}
