import { Button } from '@mantine/core';
import { useForm } from '@mantine/form';
import createValidator from 'components/forms/validators/create-validator';
import required from 'components/forms/validators/rules/rule-required';
import emailFormat from 'components/forms/validators/rules/rule-email-format';
import { _t } from 'lang';
import { LoginPayload } from 'api/actions/login/login-payload';
import { Link, useNavigate } from 'react-router-dom';
import {
  CLASSROOMS_PAGE_PATH,
  HOME_PAGE_PATH,
  RESET_PASSWORD_PAGE_PATH,
  STUDENT_DASHBOARD_PAGE_PATH,
} from 'routes/paths';
import useApi from 'api/use-api';
import TextInput from 'components/forms/inputs/TextInput';
import PasswordInput from 'components/forms/inputs/PasswordInput';
import { EMAIL_DOES_NOT_EXIST_ERROR, INVALID_PASSWORD_ERROR } from 'api/errors/error-codes';
import { useState } from 'react';

/**
 * The login form.
 */
export default function LoginForm() {
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const { login } = useApi();

  /**
   * The form data.
   */
  const form = useForm<LoginPayload>({
    initialValues: {
      email: '',
      password: '',
    },

    validate: {
      email: createValidator([required, emailFormat]),
      password: createValidator([required]),
    },
  });

  /**
   * Is executed when the form is submitted with valid data.
   */
  function submitForm(payload: LoginPayload) {
    if (!loading) {
      setLoading(true);

      login({ payload })
        .success(({ role }) => {
          if (role === 'teacher') {
            return navigate(CLASSROOMS_PAGE_PATH);
          } else if (role === 'student') {
            return navigate(STUDENT_DASHBOARD_PAGE_PATH);
          } else {
            return navigate(HOME_PAGE_PATH);
          }
        })
        .error(INVALID_PASSWORD_ERROR, () => form.setFieldError('password', _t('The password is invalid.')))
        .error(EMAIL_DOES_NOT_EXIST_ERROR, () =>
          form.setFieldError('email', _t('No account with this email address exists.'))
        )
        .complete(() => setLoading(false));
    }
  }

  return (
    <form onSubmit={form.onSubmit(submitForm)}>
      <TextInput
        className="text-[14px] font-medium leading-[17px] text-text-dark"
        withAsterisk
        label={_t('Email')}
        placeholder={_t('Your email')}
        isValid={form.isValid('email')}
        {...form.getInputProps('email')}
      />

      <PasswordInput
        className="mt-[24px] text-[14px] font-medium leading-[17px] text-text-dark"
        withAsterisk
        placeholder={_t('Your password')}
        label={_t('Password')}
        {...form.getInputProps('password')}
      />

      <div className="mt-2.5 flex justify-end">
        <Link className="text-[14px] font-medium leading-[20px] text-green-primary" to={RESET_PASSWORD_PAGE_PATH}>
          {_t('Forgot your password?')}
        </Link>
      </div>

      <div className="pt-[24px]">
        <Button
          className="h-[56px] bg-green-primary text-[18px] leading-[22px] text-white
           hover:bg-green-dark active:bg-green-dark"
          fullWidth
          type="submit"
          radius={10}
          size="lg"
          disabled={loading}
        >
          {_t('Login')}
        </Button>
      </div>
    </form>
  );
}
