import { _tx } from 'lang';

/**
 * The parameters of the component.
 */
interface Props {
  duration: number;
}

/**
 * Displays duration with time unit - default minutes.
 */
export default function DurationDisplay({ duration: seconds }: Props) {
  const minutes = Math.max(1, Math.round(seconds / 60));
  const hours = Math.floor(minutes / 60);
  const minutesLeft = minutes % 60;
  const parts = [];

  if (hours > 0) {
    parts.push(`${hours} ${_tx('hour', 'hours', hours)}`);
  }

  if (minutesLeft > 0) {
    parts.push(`${minutesLeft} ${_tx('minute', 'minutes', minutesLeft)}`);
  }

  const text = parts.join(' ');

  return <span>{text}</span>;
}
