/**
 * Correct icon.
 */
export default function CorrectIcon() {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx="12" cy="12" r="9" fill="#0EBE8A" />
      <path
        d="M8 12.5L9.79289 14.2929C10.1834 14.6834 10.8166 14.6834 11.2071 14.2929L16.5 9"
        stroke="white"
        strokeWidth="2"
        strokeLinecap="round"
      />
    </svg>
  );
}
