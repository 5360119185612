import { Accordion, AccordionStylesNames, AccordionStylesParams, Styles } from '@mantine/core';
import { unknownErrorNotification } from 'api/notifications';
import useCourse from 'data/course/use-course';
import CourseStepSidebarControl from 'pages/courses-module/course-step/sidebar/CourseStepSidebarControl';
import useCourseStep from 'pages/courses-module/course-step/context/use-course-step';
import { useEffect, useState } from 'react';
import CourseStepSidebarPanelItem from 'pages/courses-module/course-step/sidebar/CourseStepSidebarPanelItem';

/**
 * The styles for the accordion.
 */
const styles: Styles<AccordionStylesNames, AccordionStylesParams> = {
  item: {
    backgroundColor: '#F3F6F8',
    padding: 0,
    border: 'none',
  },
  control: {
    padding: 0,
    paddingRight: '32px',
    '&:hover': {
      backgroundColor: '#F3F6F8',
    },
  },
  label: {
    overflow: 'visible',
  },
  chevron: {
    marginBottom: '24px',
  },
  content: {
    backgroundColor: '#fff',
    padding: 0,
    '&:hover': {
      backgroundColor: '#D9E0E6',
      color: '#353E45!important',
    },
  },
};

interface Props {
  prefix?: string;
}

/**
 * The Course Step Sidebar.
 */
export default function CourseStepSidebar({ prefix = '' }: Props) {
  const {
    stepId: activeStepId,
    sectionSlug: activeSectionSlug,
    course: { courseId },
  } = useCourseStep();

  const [openedSections, setOpenedSections] = useState<string[]>([activeSectionSlug]);

  // Always open active section.
  useEffect(() => {
    if (!openedSections.includes(activeSectionSlug)) {
      setOpenedSections((current) => [...current, activeSectionSlug]);
    }
  }, [activeSectionSlug]);

  const { getCoursePlan } = useCourse();
  const coursePlan = getCoursePlan({ courseIdOrSlug: courseId });

  if (!coursePlan) {
    unknownErrorNotification();
    return <></>;
  }

  return (
    <Accordion multiple onChange={setOpenedSections} value={openedSections} styles={styles}>
      {coursePlan.sections.map((section) => (
        <Accordion.Item value={section.slug} className="mb-[8px]" key={section.slug}>
          <CourseStepSidebarControl {...section} />
          {openedSections.includes(section.slug) ? (
            section.steps.map((step) => (
              <CourseStepSidebarPanelItem
                key={step.stepId}
                step={step}
                activeStepId={activeStepId}
                courseId={courseId}
                courseSlug={coursePlan.slug}
                sectionSlug={section.slug}
                prefix={prefix}
              />
            ))
          ) : (
            <></>
          )}
        </Accordion.Item>
      ))}
    </Accordion>
  );
}
