import FaqAccordion from 'components/faq/FaqAccordion';
import FAQIcon from 'components/icons/FAQIcon';
import { _t } from 'lang';

/**
 * FAQ for the home page.
 */
export default function HomePageFaq() {
  return (
    <div id="faq" className="bg-bg-shadow pb-[50px] pt-[50px] md:pb-[80px] md:pt-[80px]">
      <div className="mx-auto px-[24px] lg:max-w-[1170px] lg:px-0">
        <div className="flex flex-row items-center justify-center gap-[16px] pb-[40px] text-center text-[32px] font-semibold leading-[42px]">
          <div className="hidden md:block">
            <FAQIcon />
          </div>
          <div>{_t('Frequently asked questions and help')}</div>
        </div>
        <div className="mx-auto max-w-[770px]">
          <FaqAccordion />
        </div>
      </div>
    </div>
  );
}
