import { Button } from '@mantine/core';
import { useForm } from '@mantine/form';
import { RegisterPayload } from 'api/actions/register/register-payload';
import useApi from 'api/use-api';
import createValidator from 'components/forms/validators/create-validator';
import emailFormat from 'components/forms/validators/rules/rule-email-format';
import required from 'components/forms/validators/rules/rule-required';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { CLASSROOMS_PAGE_PATH, STUDENT_DASHBOARD_PAGE_PATH } from 'routes/paths';
import TextInput from 'components/forms/inputs/TextInput';
import PasswordInput from 'components/forms/inputs/PasswordInput';
import { CLASSROOM_INVITE_CODE_INVALID_ERROR, EMAIL_ALREADY_USED_ERROR } from 'api/errors/error-codes';
import { _t } from 'lang';
import passwordFormat from 'components/forms/validators/rules/rule-password-format';
import { BUY_PREMIUM_URL, FEATURE_TOGGLE_TEACHER_MODULE } from 'env';
import UserRoleSelect from 'components/forms/inputs/UserRoleSelect';

/**
 * Parameters for the register form.
 */
export interface RegisterFormProps {
  registerForCourse?: boolean;
}

/**
 * The type of the register form data.
 */
type FormData = RegisterPayload & { passwordRepeat: string; inviteCode: string };

/**
 * The register form.
 */
export default function RegisterForm({ registerForCourse = false }: RegisterFormProps = {}) {
  const navigate = useNavigate();
  const { register, getAction } = useApi();
  const validateClassroomInviteCodeAction = getAction('ValidateClassroomInviteCode');
  const joinClassroomAction = getAction('JoinClassroom');
  const [loading, setLoading] = useState(false);

  /**
   * The form data.
   */
  const form = useForm<FormData>({
    initialValues: {
      firstName: '',
      lastName: '',
      email: '',
      password: '',
      passwordRepeat: '',
      role: 'student',
      inviteCode: '',
    },

    validate: {
      firstName: createValidator([required]),
      lastName: createValidator([required]),
      email: createValidator([required, emailFormat]),
      password: createValidator([required, passwordFormat]),
      passwordRepeat: (passwordRepeat, { password }) => {
        if (passwordRepeat === '') {
          return _t('This field is required.');
        }

        if (passwordRepeat !== password) {
          return _t('Passwords do not match.');
        }

        return null;
      },
    },
  });

  /**
   * Is executed when the form is submitted with valid data.
   */
  function submitForm({ email, firstName, lastName, password, role, inviteCode }: FormData) {
    if (!loading) {
      setLoading(true);

      register({ payload: { email, firstName, lastName, password, role } })
        .success(() => {
          if (registerForCourse) {
            window.open(`${BUY_PREMIUM_URL}?prefilled_email=${encodeURIComponent(email)}`, '_self');
          } else if (role === 'student') {
            if (inviteCode) {
              validateClassroomInviteCodeAction({ payload: { inviteCode } })
                .success(() => {
                  joinClassroomAction({ payload: { inviteCode } }).success(() => {
                    navigate(STUDENT_DASHBOARD_PAGE_PATH);
                  });
                })
                .error(CLASSROOM_INVITE_CODE_INVALID_ERROR, () => {
                  navigate(STUDENT_DASHBOARD_PAGE_PATH, { state: { showInvalidInviteCodeMessage: true } });
                });
            } else {
              navigate(STUDENT_DASHBOARD_PAGE_PATH);
            }
          } else {
            navigate(CLASSROOMS_PAGE_PATH);
          }
        })
        .error(EMAIL_ALREADY_USED_ERROR, () => form.setFieldError('email', _t('This email is already in use.')))
        .complete(() => setLoading(false));
    }
  }

  return (
    <form onSubmit={form.onSubmit(submitForm)}>
      {FEATURE_TOGGLE_TEACHER_MODULE && !registerForCourse && (
        <div className="pb-[32px]">
          <UserRoleSelect
            value={form.getInputProps('role').value}
            onChange={(value) => form.getInputProps('role').onChange(value)}
          />
        </div>
      )}

      <TextInput
        className="text-[14px] font-medium leading-[17px] text-text-dark"
        withAsterisk
        label={_t('First name')}
        placeholder={_t('Your first name')}
        isValid={form.isValid('firstName')}
        {...form.getInputProps('firstName')}
      />

      <TextInput
        className="mt-[24px] text-[14px] font-medium leading-[17px] text-text-dark"
        withAsterisk
        label={_t('Last name')}
        placeholder={_t('Your last name')}
        isValid={form.isValid('lastName')}
        {...form.getInputProps('lastName')}
      />

      <TextInput
        className="mt-[24px] text-[14px] font-medium leading-[17px] text-text-dark"
        withAsterisk
        label={_t('Email')}
        placeholder={_t('Your email')}
        isValid={form.isValid('email')}
        {...form.getInputProps('email')}
      />

      <PasswordInput
        className="mt-[24px] text-[14px] font-medium leading-[17px] text-text-dark"
        withAsterisk
        label={_t('Password')}
        placeholder={_t('Your password')}
        {...form.getInputProps('password')}
      />

      <PasswordInput
        className="mt-[24px] text-[14px] font-medium leading-[17px] text-text-dark"
        withAsterisk
        label={_t('Password (repeat)')}
        placeholder={_t('Repeat your password')}
        {...form.getInputProps('passwordRepeat')}
      />

      {FEATURE_TOGGLE_TEACHER_MODULE && !registerForCourse && form.getInputProps('role').value === 'student' && (
        <TextInput
          className="mt-[24px] text-[14px] font-medium leading-[17px] text-text-dark"
          label={_t('Classroom invite code (optional)')}
          placeholder={_t('Your classroom invite code')}
          isValid={form.isValid('inviteCode')}
          {...form.getInputProps('inviteCode')}
        />
      )}

      <div className="pt-[40px]">
        <Button
          disabled={loading}
          className="h-[56px] bg-green-primary text-[18px] font-semibold leading-[22px] hover:bg-green-dark active:bg-green-dark"
          fullWidth
          type="submit"
          radius={10}
          size="lg"
        >
          {_t('Register')}
        </Button>
      </div>
    </form>
  );
}
