/**
 * Exclamation Mark Icon
 */
export default function ExclamationMarkIcon() {
  return (
    <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx="9" cy="9" r="9" transform="rotate(-180 9 9)" fill="#E82C2C" />
      <rect x="10" y="11" width="2" height="7" rx="1" transform="rotate(-180 10 11)" fill="white" />
      <rect x="10" y="14" width="2" height="2" rx="1" transform="rotate(-180 10 14)" fill="white" />
    </svg>
  );
}
