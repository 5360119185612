import StudentDashboardLayoutProvider from 'layout/student-dashboard-layout/StudentDashboardLayoutProvider';
import StudentHeader from 'layout/student-dashboard-layout/parts/header/StudentHeader';
import StudentSidebar from 'layout/student-dashboard-layout/parts/sidebar/StudentSidebar';
import StudentFooter from 'layout/student-dashboard-layout/parts/footer/StudentFooter';
import StudentCollapsedSidebar from 'layout/student-dashboard-layout/parts/sidebar/StudentCollapsedSidebar';

/**
 * The properties of the students' dashboard layout.
 */
interface Props {
  hasHeader?: boolean;
  hasFooter?: boolean;
  hasSidebar?: boolean;
  hasCollapsedSidebar?: boolean;
  className?: string;
  children?: JSX.Element;
}

/**
 * The layout for the students' dashboard page.
 */
export default function StudentDashboardLayout({
  className,
  children,
  hasHeader = true,
  hasFooter = false,
  hasSidebar = false,
  hasCollapsedSidebar = false,
}: Props) {
  const contentPadding = hasHeader ? 'pt-[66px] lg:pt-[90px]' : '';
  const sidebarPadding = hasSidebar ? (hasCollapsedSidebar ? 'lg:pl-[88px]' : 'lg:pl-[358px]') : '';

  return (
    <StudentDashboardLayoutProvider>
      <div className="bg-bg-teacher">
        {hasHeader && <StudentHeader />}
        <div className={`min-h-screen overflow-y-scroll md:overflow-hidden ${contentPadding}`}>
          {hasSidebar ? hasCollapsedSidebar ? <StudentCollapsedSidebar /> : <StudentSidebar /> : <StudentSidebar />}
          <div className={`${sidebarPadding} ${className}`}>{children}</div>
        </div>
        {hasFooter && <StudentFooter />}
      </div>
    </StudentDashboardLayoutProvider>
  );
}
