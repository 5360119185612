import ChevronLeftIcon from 'components/icons/ChevronLeftIcon';
import NavigateCourseButton from 'components/buttons/NavigateCourseButton';
import useCourseStep from 'pages/courses-module/course-step/context/use-course-step';
import { useState } from 'react';
import { _t } from 'lang';

/**
 * The parameters of the component.
 */
interface Props {
  className?: string;
  prefix?: string;
}

/**
 * Upon click displays the previous step in the course.
 */
export default function PreviousStepButton({ className, prefix = '' }: Props) {
  const { course, prevStep } = useCourseStep();
  const [hover, setHover] = useState<boolean>(false);

  const stepId = prevStep?.stepId;
  const sectionSlug = prevStep?.section.slug;
  const visibility = stepId && sectionSlug ? 'visible' : 'invisible';

  return (
    <div
      className={`h-[64px] lg:flex lg:max-w-[180px] lg:items-center ${className} ${visibility} ${
        hover && 'lg:rounded-br-[5px] lg:rounded-tr-[5px] lg:bg-bg-course-button-hover'
      }`}
    >
      <div onMouseEnter={() => setHover(true)} onMouseLeave={() => setHover(false)} className="h-full">
        <NavigateCourseButton
          courseSlug={course.slug}
          sectionSlug={sectionSlug}
          stepId={stepId}
          className="rounded-br-[5px] rounded-tr-[5px]"
          icon={<ChevronLeftIcon />}
          prefix={prefix}
        />
      </div>
      {hover && (
        <div className="hidden lg:block lg:h-full lg:w-full lg:overflow-hidden lg:text-ellipsis lg:whitespace-nowrap lg:pb-[10px] lg:pl-[12px] lg:pr-[20px] lg:pt-[15px] lg:text-white">
          <p className="lg:text-[10px] lg:font-normal lg:leading-[12px]">{_t('Previous')}:</p>
          <span className="lg:text-[12px] lg:font-medium lg:leading-[15px]">{prevStep?.title}</span>
        </div>
      )}
    </div>
  );
}
