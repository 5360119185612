import { AxiosInstance } from 'axios';
import { SweetPath } from 'sweet-path';
import dispatchAction from 'api/dispatch-action';

import { GetCourseStepPathParameters } from 'api/actions/get-course-step/get-course-step-path-parameters';

import { GetCourseStepResponse } from 'api/actions/get-course-step/get-course-step-response';

/**
 * The path of the GetCourseStep action.
 */
export const getCourseStepActionPath = new SweetPath<keyof GetCourseStepPathParameters>(
  '/course/{courseId}/step/{stepId}',
  { path: '{param}' }
);

/**
 * Creates a contextualized GetCourseStep actions.
 */
export default function createGetCourseStepAction(axios: AxiosInstance) {
  return function getCourseStepAction({ parameters }: { parameters: GetCourseStepPathParameters }) {
    const path = getCourseStepActionPath.insert(parameters);

    return dispatchAction(() => axios.get<GetCourseStepResponse, GetCourseStepResponse>(path));
  };
}
