import { AxiosInstance } from 'axios';
import { SweetPath } from 'sweet-path';
import dispatchAction from 'api/dispatch-action';

import { GetUserProfilePathParameters } from 'api/actions/get-user-profile/get-user-profile-path-parameters';

import { GetUserProfileResponse } from 'api/actions/get-user-profile/get-user-profile-response';

/**
 * The path of the GetUserProfile action.
 */
export const getUserProfileActionPath = new SweetPath<keyof GetUserProfilePathParameters>('/user/profile', {
  path: '{param}',
});

/**
 * Creates a contextualized GetUserProfile actions.
 */
export default function createGetUserProfileAction(axios: AxiosInstance) {
  return function getUserProfileAction() {
    const path = getUserProfileActionPath.original;

    return dispatchAction(() => axios.get<GetUserProfileResponse, GetUserProfileResponse>(path));
  };
}
