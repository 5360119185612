import { Button } from '@mantine/core';
import { useForm } from '@mantine/form';
import { ChangePasswordPayload } from 'api/actions/change-password/change-password-payload';
import { _t } from 'lang';
import createValidator from 'components/forms/validators/create-validator';
import required from 'components/forms/validators/rules/rule-required';
import { useState } from 'react';
import useApi from 'api/use-api';
import PasswordInput from 'components/forms/inputs/PasswordInput';
import { INVALID_PASSWORD_ERROR } from 'api/errors/error-codes';
import passwordFormat from 'components/forms/validators/rules/rule-password-format';

/**
 * The type of the change password form data.
 */
type FormData = ChangePasswordPayload & { newPasswordRepeat: string };

interface Props {
  onSuccess: Function;
  inputBackground?: string;
}

/**
 * The change password form.
 */
export default function ChangePasswordForm({ onSuccess, inputBackground = '' }: Props) {
  const { getAction } = useApi();
  const [loading, setLoading] = useState(false);

  /**
   * The form data.
   */
  const form = useForm<FormData>({
    initialValues: {
      oldPassword: '',
      newPassword: '',
      newPasswordRepeat: '',
    },

    validate: {
      oldPassword: createValidator([required]),
      newPassword: createValidator([required, passwordFormat]),
      newPasswordRepeat: (passwordRepeat, { newPassword }) => {
        if (passwordRepeat === '') {
          return _t('This field is required.');
        }

        if (passwordRepeat !== newPassword) {
          return _t('Passwords do not match.');
        }

        return null;
      },
    },
  });

  /**
   * Is executed when the form is submitted with valid data.
   */
  function submitForm({ oldPassword, newPassword }: FormData) {
    const changePasswordAction = getAction('ChangePassword');

    if (!loading) {
      setLoading(true);

      changePasswordAction({ payload: { newPassword, oldPassword } })
        .success(() => onSuccess())
        .error(INVALID_PASSWORD_ERROR, () => form.setFieldError('oldPassword', _t('The password is invalid.')))
        .complete(() => setLoading(false));
    }
  }

  return (
    <form onSubmit={form.onSubmit(submitForm)}>
      <PasswordInput
        className="text-[14px] font-medium leading-[17px] text-text-dark"
        withAsterisk
        placeholder={_t('Your old password')}
        label={_t('Old password')}
        styles={{ input: { backgroundColor: inputBackground }, innerInput: { backgroundColor: inputBackground } }}
        {...form.getInputProps('oldPassword')}
      />

      <PasswordInput
        className="mt-[24px] text-[14px] font-medium leading-[17px] text-text-dark"
        withAsterisk
        placeholder={_t('Your new password')}
        label={_t('New password')}
        styles={{ input: { backgroundColor: inputBackground }, innerInput: { backgroundColor: inputBackground } }}
        {...form.getInputProps('newPassword')}
      />

      <PasswordInput
        className="mt-[24px] text-[14px] font-medium leading-[17px] text-text-dark"
        withAsterisk
        placeholder={_t('Repeat your new password')}
        label={_t('New password (repeat)')}
        styles={{ input: { backgroundColor: inputBackground }, innerInput: { backgroundColor: inputBackground } }}
        {...form.getInputProps('newPasswordRepeat')}
      />

      <div className="pt-[40px]">
        <Button
          disabled={loading}
          className="h-[56px] bg-green-primary pl-[40px] pr-[40px] text-[18px]
           leading-[22px] text-white hover:bg-green-dark active:bg-green-dark"
          type="submit"
          radius={10}
        >
          {_t('Change password')}
        </Button>
      </div>
    </form>
  );
}
