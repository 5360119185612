import { AxiosInstance } from 'axios';
import { SweetPath } from 'sweet-path';
import dispatchAction from 'api/dispatch-action';

import { GetClassroomStudentsPathParameters } from 'api/actions/get-classroom-students/get-classroom-students-path-parameters';

import { GetClassroomStudentsResponse } from 'api/actions/get-classroom-students/get-classroom-students-response';

/**
 * The path of the GetClassroomStudents action.
 */
export const getClassroomStudentsActionPath = new SweetPath<keyof GetClassroomStudentsPathParameters>(
  '/classroom/{classroomId}/student',
  { path: '{param}' }
);

/**
 * Creates a contextualized GetClassroomStudents actions.
 */
export default function createGetClassroomStudentsAction(axios: AxiosInstance) {
  return function getClassroomStudentsAction({ parameters }: { parameters: GetClassroomStudentsPathParameters }) {
    const path = getClassroomStudentsActionPath.insert(parameters);

    return dispatchAction(() => axios.get<GetClassroomStudentsResponse, GetClassroomStudentsResponse>(path));
  };
}
