import ClassroomDoorIcon from 'components/icons/ClassroomDoorIcon';
import { _t } from 'lang';

/**
 * Content displayed when there are no classrooms.
 */
export default function NoClassrooms() {
  return (
    <div className="ml-auto mr-auto text-center lg:max-w-[461px] lg:pt-[80px]">
      <div className="text-[24px] font-medium leading-[29px] text-text-dark-2">
        <span>{_t('You have not created a classroom yet')}</span>
      </div>
      <div className="text-[16px] font-medium leading-[22px] text-text-shadow lg:mt-[8px]">
        <span>{_t('Create a classroom, invite pupils and assign them their first exercises on Zvědavá Mysl.')}</span>
      </div>
      <div className="flex items-center justify-center lg:mt-[44px] lg:h-[235px]">
        <div className="lg:w-[188px]">
          <ClassroomDoorIcon />
        </div>
      </div>
    </div>
  );
}
