import BasicPageLayout from 'layout/basic-page-layout/BasicPageLayout';
import useApi from 'api/use-api';
import HomePageContent from 'pages/presentation-module/home/HomePageContent';

/**
 * The homepage.
 *
 * @see https://www.notion.so/Home-1b1ea37bffa94b959d406a4ac17dada6
 */
export default function HomePage() {
  const { ready } = useApi();

  return (
    <BasicPageLayout hasHeader hasFooter>
      {ready ? <HomePageContent /> : <></>}
    </BasicPageLayout>
  );
}
