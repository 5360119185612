import { Accordion } from '@mantine/core';

/**
 * The parameters of the FaqItem component.
 */
export interface FaqItemProps {
  value: string;
  question: string;
  children: JSX.Element | JSX.Element[];
}

/**
 * A single item in the FAQ accordion.
 */
export default function FaqItem({ value, question, children }: FaqItemProps) {
  return (
    <Accordion.Item value={value} className="shadow-sm">
      <Accordion.Control className="font-medium text-text-dark-2 hover:text-text-dark data-[active=true]:font-semibold data-[active=true]:text-text-dark">
        <div className="flex min-h-[72px] items-center justify-start py-[10px] text-[18px] leading-[22px]">
          {question}
        </div>
      </Accordion.Control>
      <Accordion.Panel>
        <div>{children}</div>
      </Accordion.Panel>
    </Accordion.Item>
  );
}
