import { AxiosInstance } from 'axios';
import { SweetPath } from 'sweet-path';
import dispatchAction from 'api/dispatch-action';

import { GetStudentDashboardPathParameters } from 'api/actions/get-student-dashboard/get-student-dashboard-path-parameters';

import { GetStudentDashboardResponse } from 'api/actions/get-student-dashboard/get-student-dashboard-response';

/**
 * The path of the GetStudentDashboard action.
 */
export const getStudentDashboardActionPath = new SweetPath<keyof GetStudentDashboardPathParameters>(
  '/student/dashboard',
  { path: '{param}' }
);

/**
 * Creates a contextualized GetStudentDashboard actions.
 */
export default function createGetStudentDashboardAction(axios: AxiosInstance) {
  return function getStudentDashboardAction() {
    const path = getStudentDashboardActionPath.original;

    return dispatchAction(() => axios.get<GetStudentDashboardResponse, GetStudentDashboardResponse>(path));
  };
}
