/**
 * The play icon
 */
export default function PlayIcon() {
  return (
    <svg width="13" height="16" viewBox="0 0 13 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M0 1.72318C0 0.955357 0.829482 0.47399 1.49614 0.854937L12.4806 7.13176C13.1524 7.51565 13.1524 8.48435 12.4806 8.86824L1.49614 15.1451C0.829482 15.526 0 15.0446 0 14.2768V1.72318Z"
        fill="#0AB892"
      />
    </svg>
  );
}
