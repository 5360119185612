import { UserRole } from 'api/api-context';
import useApi from 'api/use-api';
import { Navigate } from 'react-router-dom';

interface Props {
  children: JSX.Element;
  roles: UserRole[];
  redirect: string;
}

/**
 * Displays `children` if the user has one of the specified roles. Redirects to
 * the specified path otherwise.
 */
export default function RequireRole({ children, roles, redirect }: Props) {
  const { role } = useApi();

  if (!roles.includes(role)) {
    return <Navigate to={redirect} />;
  }

  return children;
}
