/**
 * Parameters of the FaqText component.
 */
export interface FaqTextProps {
  text: string;
}

/**
 * A text used in the FAQ component.
 */
export default function FaqText({ text }: FaqTextProps) {
  return <p className="text-[16px] font-normal leading-[24px] text-text-dark">{text}</p>;
}
