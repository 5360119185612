import { AxiosInstance } from 'axios';
import { SweetPath } from 'sweet-path';
import dispatchAction from 'api/dispatch-action';

import { GetClassroomStudentDetailPathParameters } from 'api/actions/get-classroom-student-detail/get-classroom-student-detail-path-parameters';

import { GetClassroomStudentDetailResponse } from 'api/actions/get-classroom-student-detail/get-classroom-student-detail-response';

/**
 * The path of the GetClassroomStudentDetail action.
 */
export const getClassroomStudentDetailActionPath = new SweetPath<keyof GetClassroomStudentDetailPathParameters>(
  '/classroom/{classroomId}/students/{studentId}',
  { path: '{param}' }
);

/**
 * Creates a contextualized GetClassroomStudentDetail actions.
 */
export default function createGetClassroomStudentDetailAction(axios: AxiosInstance) {
  return function getClassroomStudentDetailAction({
    parameters,
  }: {
    parameters: GetClassroomStudentDetailPathParameters;
  }) {
    const path = getClassroomStudentDetailActionPath.insert(parameters);

    return dispatchAction(() => axios.get<GetClassroomStudentDetailResponse, GetClassroomStudentDetailResponse>(path));
  };
}
