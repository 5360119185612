/**
 * Error which was returned by API.
 */
export default class ApiError extends Error {
  /**
   * The error code.
   */
  code: number;

  /**
   * Creates a new instance of ApiError.
   */
  constructor(code: number) {
    super('');
    this.code = code;
  }
}
