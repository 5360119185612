import { Popover } from '@mantine/core';
import ClockIcon from 'components/icons/ClockIcon';
import { _t } from 'lang';
import { Dispatch, SetStateAction, useState } from 'react';
import TimePickerItem from 'pages/teacher-module/classroom/exercises/modals/TimePickerItem';

interface Props {
  value: { hours?: number; minutes?: number };
  onChange: Dispatch<SetStateAction<{ hours?: number; minutes?: number }>>;
  error?: string | JSX.Element;
}

/**
 * The time picker component.
 */
export default function TimePicker({ value, onChange, error }: Props) {
  const [opened, setOpened] = useState(false);
  const [hours, setHours] = useState<number | undefined>(value.hours);
  const [minutes, setMinutes] = useState<number | undefined>(value.minutes);

  const hasValue = value.hours !== undefined || value.minutes !== undefined;

  /**
   * Updates the value in the parent component.
   */
  function updateValue({ hours, minutes }: { hours?: number; minutes?: number }) {
    onChange({ hours, minutes });
  }

  /**
   * Displays the number with leading zero if needed.
   */
  function displayPretty(val: number | undefined) {
    if (val === undefined) {
      return '__';
    }

    return val < 10 ? `0${val}` : val;
  }

  return (
    <div>
      <Popover
        position="bottom"
        width={476}
        opened={opened}
        onChange={setOpened}
        styles={{ dropdown: { padding: 0, margin: 0, border: 'none' } }}
      >
        <Popover.Target>
          <div className="cursor-pointer">
            <div
              className={`flex h-[42px] items-center gap-[11px] rounded-[7px] bg-bg-shadow ${
                opened ? 'border-[1px] pl-[11px]' : 'border border-input-outline-shadow pl-[11px]'
              } ${error ? 'border border-[#fa5252]' : 'border border-input-outline-shadow'}`}
              onClick={() => setOpened((o) => !o)}
            >
              <div>{hasValue ? <ClockIcon stroke="#353E45" /> : <ClockIcon stroke="#738491" />}</div>
              {hasValue ? (
                <div className="text-[14px] font-semibold leading-[17px]">{`
                ${displayPretty(value.hours)}:${displayPretty(value.minutes)}`}</div>
              ) : (
                <div
                  className={`text-[14px] leading-[17px] text-text-shadow ${
                    error ? 'text-[14px] font-semibold leading-[34px] text-[#fa5252]' : ''
                  }`}
                >
                  {_t('Select a time')}
                </div>
              )}
            </div>
            {/* cspell:disable-next-line */}
            <div className="mantine-Text-root mantine-InputWrapper-error mantine-DatePicker-error mantine-9bfeh0 mt-[5px]">
              {error}
            </div>
          </div>
        </Popover.Target>
        <Popover.Dropdown>
          <div className="h-[234px] rounded-[7px] border border-input-outline-shadow bg-white p-[16px] shadow-3xl">
            <div className="text-[20px] font-semibold leading-[24px] tracking-[0.38px]">{_t('Hour')}</div>
            <div className="grid grid-cols-12 grid-rows-2 pt-[11px] text-center">
              <TimePickerItem
                value={0}
                onClick={() => {
                  setHours(0);
                  updateValue({ hours: 0, minutes });
                }}
                isSelected={hours === 0}
              />
              <TimePickerItem
                value={1}
                onClick={() => {
                  setHours(1);
                  updateValue({ hours: 1, minutes });
                }}
                isSelected={hours === 1}
              />
              <TimePickerItem
                value={2}
                onClick={() => {
                  setHours(2);
                  updateValue({ hours: 2, minutes });
                }}
                isSelected={hours === 2}
              />
              <TimePickerItem
                value={3}
                onClick={() => {
                  setHours(3);
                  updateValue({ hours: 3, minutes });
                }}
                isSelected={hours === 3}
              />
              <TimePickerItem
                value={4}
                onClick={() => {
                  setHours(4);
                  updateValue({ hours: 4, minutes });
                }}
                isSelected={hours === 4}
              />
              <TimePickerItem
                value={5}
                onClick={() => {
                  setHours(5);
                  updateValue({ hours: 5, minutes });
                }}
                isSelected={hours === 5}
              />
              <TimePickerItem
                value={6}
                onClick={() => {
                  setHours(6);
                  updateValue({ hours: 6, minutes });
                }}
                isSelected={hours === 6}
              />
              <TimePickerItem
                value={7}
                onClick={() => {
                  setHours(7);
                  updateValue({ hours: 7, minutes });
                }}
                isSelected={hours === 7}
              />
              <TimePickerItem
                value={8}
                onClick={() => {
                  setHours(8);
                  updateValue({ hours: 8, minutes });
                }}
                isSelected={hours === 8}
              />
              <TimePickerItem
                value={9}
                onClick={() => {
                  setHours(9);
                  updateValue({ hours: 9, minutes });
                }}
                isSelected={hours === 9}
              />
              <TimePickerItem
                value={10}
                onClick={() => {
                  setHours(10);
                  updateValue({ hours: 10, minutes });
                }}
                isSelected={hours === 10}
              />
              <TimePickerItem
                value={11}
                onClick={() => {
                  setHours(11);
                  updateValue({ hours: 11, minutes });
                }}
                isSelected={hours === 11}
              />
              <TimePickerItem
                value={12}
                onClick={() => {
                  setHours(12);
                  updateValue({ hours: 12, minutes });
                }}
                isSelected={hours === 12}
              />
              <TimePickerItem
                value={13}
                onClick={() => {
                  setHours(13);
                  updateValue({ hours: 13, minutes });
                }}
                isSelected={hours === 13}
              />
              <TimePickerItem
                value={14}
                onClick={() => {
                  setHours(14);
                  updateValue({ hours: 14, minutes });
                }}
                isSelected={hours === 14}
              />
              <TimePickerItem
                value={15}
                onClick={() => {
                  setHours(15);
                  updateValue({ hours: 15, minutes });
                }}
                isSelected={hours === 15}
              />
              <TimePickerItem
                value={16}
                onClick={() => {
                  setHours(16);
                  updateValue({ hours: 16, minutes });
                }}
                isSelected={hours === 16}
              />
              <TimePickerItem
                value={17}
                onClick={() => {
                  setHours(17);
                  updateValue({ hours: 17, minutes });
                }}
                isSelected={hours === 17}
              />
              <TimePickerItem
                value={18}
                onClick={() => {
                  setHours(18);
                  updateValue({ hours: 18, minutes });
                }}
                isSelected={hours === 18}
              />
              <TimePickerItem
                value={19}
                onClick={() => {
                  setHours(19);
                  updateValue({ hours: 19, minutes });
                }}
                isSelected={hours === 19}
              />
              <TimePickerItem
                value={20}
                onClick={() => {
                  setHours(20);
                  updateValue({ hours: 20, minutes });
                }}
                isSelected={hours === 20}
              />
              <TimePickerItem
                value={21}
                onClick={() => {
                  setHours(21);
                  updateValue({ hours: 21, minutes });
                }}
                isSelected={hours === 21}
              />
              <TimePickerItem
                value={22}
                onClick={() => {
                  setHours(22);
                  updateValue({ hours: 22, minutes });
                }}
                isSelected={hours === 22}
              />
              <TimePickerItem
                value={23}
                onClick={() => {
                  setHours(23);
                  updateValue({ hours: 23, minutes });
                }}
                isSelected={hours === 23}
              />
            </div>
            <div className="pt-[30px] text-[20px] font-semibold leading-[24px] tracking-[0.38px]">{_t('Minute')}</div>
            <div className="grid grid-cols-12 grid-rows-1 gap-[12px] pt-[11px] text-center">
              <TimePickerItem
                value={0}
                onClick={() => {
                  setMinutes(0);
                  updateValue({ hours, minutes: 0 });
                }}
                isSelected={minutes === 0}
              />
              <TimePickerItem
                value={5}
                onClick={() => {
                  setMinutes(5);
                  updateValue({ hours, minutes: 5 });
                }}
                isSelected={minutes === 5}
              />
              <TimePickerItem
                value={10}
                onClick={() => {
                  setMinutes(10);
                  updateValue({ hours, minutes: 10 });
                }}
                isSelected={minutes === 10}
              />
              <TimePickerItem
                value={15}
                onClick={() => {
                  setMinutes(15);
                  updateValue({ hours, minutes: 15 });
                }}
                isSelected={minutes === 15}
              />
              <TimePickerItem
                value={20}
                onClick={() => {
                  setMinutes(20);
                  updateValue({ hours, minutes: 20 });
                }}
                isSelected={minutes === 20}
              />
              <TimePickerItem
                value={25}
                onClick={() => {
                  setMinutes(25);
                  updateValue({ hours, minutes: 25 });
                }}
                isSelected={minutes === 25}
              />
              <TimePickerItem
                value={30}
                onClick={() => {
                  setMinutes(30);
                  updateValue({ hours, minutes: 30 });
                }}
                isSelected={minutes === 30}
              />
              <TimePickerItem
                value={35}
                onClick={() => {
                  setMinutes(35);
                  updateValue({ hours, minutes: 35 });
                }}
                isSelected={minutes === 35}
              />
              <TimePickerItem
                value={40}
                onClick={() => {
                  setMinutes(40);
                  updateValue({ hours, minutes: 40 });
                }}
                isSelected={minutes === 40}
              />
              <TimePickerItem
                value={45}
                onClick={() => {
                  setMinutes(45);
                  updateValue({ hours, minutes: 45 });
                }}
                isSelected={minutes === 45}
              />
              <TimePickerItem
                value={50}
                onClick={() => {
                  setMinutes(50);
                  updateValue({ hours, minutes: 50 });
                }}
                isSelected={minutes === 50}
              />
              <TimePickerItem
                value={55}
                onClick={() => {
                  setMinutes(55);
                  updateValue({ hours, minutes: 55 });
                }}
                isSelected={minutes === 55}
              />
            </div>
          </div>
        </Popover.Dropdown>
      </Popover>
    </div>
  );
}
