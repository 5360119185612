import Assignment from 'components/assignment/Assignment';
import ArrowLeftIcon from 'components/icons/ArrowLeftIcon';
import CrossIcon from 'components/icons/CrossIcon';
import Clickable from 'components/misc/Clickable';
import { _t, _tx } from 'lang';
import { useNavigate, useParams } from 'react-router-dom';
import useStudentDashboard from 'data/student-dashboard/use-student-dashboard';
import { useState } from 'react';
import sprintf from 'lang/sprintf';
import { IconFileUpload } from '@tabler/icons-react';
import { Button, FileButton, LoadingOverlay } from '@mantine/core';
import { useFileManager } from 'api/file-manager';
import { unknownErrorNotification } from 'api/notifications';
import toBase64 from 'utils/to-base64';
import useApi from 'api/use-api';
import useFileDownload from 'api/use-file-download';
import HomeworkComments from 'components/homework/HomeworkComments';

/**
 * The content of the SolveHomework page.
 */
export default function SolveHomeworkContent() {
  const navigate = useNavigate();
  const { uploadFile } = useFileManager();
  const { getAction } = useApi();
  const { download } = useFileDownload();
  const { homeworkId } = useParams();
  const [uploadingFile, setUploadingFile] = useState<boolean>(false);
  const { getHomework, fetchStudentDashboard, loading } = useStudentDashboard();
  const homework = getHomework().find(({ homework }) => homework.homeworkId === homeworkId);
  const assignmentId = homework?.assignment?.generated?.assignmentId;
  const [fill, setFill] = useState('#738491');

  /**
   * Handles the file upload.
   */
  const onFileUpload = async (file: File | null) => {
    if (!file || !homeworkId) {
      return; // Operation canceled.
    }

    setUploadingFile(true);

    const submitHomeworkAttachment = getAction('SubmitHomeworkAttachment');

    try {
      const contents = await toBase64(file);
      const fileId = await uploadFile({ fileName: file.name, contents });

      submitHomeworkAttachment({ parameters: { homeworkId }, payload: { fileId, fileName: file.name } }).complete(
        fetchStudentDashboard
      );
    } catch (error) {
      unknownErrorNotification();
    } finally {
      setUploadingFile(false);
    }
  };

  return (
    <div className=" lg:pb-[64px] lg:pl-[64px] lg:pr-[64px] lg:pt-[32px]">
      <div className="flex h-[58px] items-center justify-between pl-[25px] pr-[24px] lg:h-[40px]">
        <div className="flex items-center gap-[16px]">
          <div className="flex items-center gap-[9px]">
            <ArrowLeftIcon />
            <span
              onClick={() => navigate(-1)}
              className="cursor-pointer text-[14px] font-medium leading-[17px] text-green-primary"
            >
              {_tx('My homework', 'My homework', 2)}
            </span>
          </div>
          <div className="h-[28px] border-r border-r-input-outline-shadow" />
          <div className="text-[14px] leading-[17px] text-text-dark-2">
            {sprintf(_t('Homework #%s'), homework?.homework.homeworkNumber || 0)}
          </div>
        </div>
        <Clickable
          onClick={() => navigate(-1)}
          className="flex h-[42px] w-[42px] items-center justify-center rounded-[7px] p-[8px] hover:bg-bg-shadow"
          onMouseEnter={() => {
            setFill('#353E45');
          }}
          onMouseLeave={() => {
            setFill('#738491');
          }}
        >
          <CrossIcon fill={fill} />
        </Clickable>
      </div>
      <div className="relative my-[16px] flex flex-col gap-y-[12px] rounded-[10px] bg-white p-[24px]">
        <LoadingOverlay visible={loading || uploadingFile} loaderProps={{ size: 32, color: 'green' }} />
        <div className="mb-2 flex w-full items-center justify-between">
          <div className="flex items-center gap-x-[12px]">
            <IconFileUpload size={24} />
            <div className="mt-[1px] text-[18px] font-semibold leading-[19px] text-text-dark">
              {_t('Homework attachments')}
            </div>
          </div>
          <div>
            <FileButton onChange={onFileUpload}>
              {(props) => (
                <Button
                  {...props}
                  className="h-[42px] rounded-[7px] bg-green-primary hover:bg-green-dark focus:bg-green-dark sm:w-[198px]"
                >
                  {_t('Upload')}
                </Button>
              )}
            </FileButton>
          </div>
        </div>
        {homework?.assignment.attachments?.map(({ fileId, fileName }) => (
          <div key={fileId} className="flex items-baseline gap-2 pl-1 text-[14px] font-medium leading-[18px]">
            <span className=" text-text-dark-2">{fileName}</span>
            <span onClick={() => download(fileId)} className="cursor-pointer text-green-primary">
              {_t('(Download)')}
            </span>
          </div>
        ))}
      </div>
      {homework?.assignment && homeworkId && (
        <HomeworkComments
          mode="student"
          loading={loading}
          assignment={homework.assignment}
          homeworkId={homeworkId}
          onCommentAdded={fetchStudentDashboard}
        />
      )}
      <div className="rounded-[10px] bg-white lg:mt-[16px]">
        <div className="mx-auto min-h-screen max-w-[570px] p-[24px] pb-[100px] pt-[48px]">
          {assignmentId && <Assignment assignmentId={assignmentId} onSolved={fetchStudentDashboard} />}
        </div>
      </div>
    </div>
  );
}
