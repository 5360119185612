import { Button, Loader } from '@mantine/core';
import FilterIcon from 'components/icons/FilterIcon';
import { _t, lang } from 'lang';
import { useEffect, useState } from 'react';
import { intersection, isEmpty, sortBy } from 'lodash';
import { GetExercisesResponse } from 'api/actions/get-exercises/get-exercises-response';
import useApi from 'api/use-api';
import FiltersModal from 'components/modals/filter/FiltersModal';
import StudentExerciseCard from 'components/StudentExerciseCard';
import FilterButton from 'components/buttons/FilterButton';
import Clickable from 'components/misc/Clickable';
import { useNavigate, useParams } from 'react-router-dom';
import ArrowLeftIcon from 'components/icons/ArrowLeftIcon';
import Assignment from 'components/assignment/Assignment';

/**
 * The content of the StudentExercises page.
 */
export default function StudentExercisesContent() {
  const navigate = useNavigate();

  const { getAction } = useApi();
  const getExercisesAction = getAction('GetExercises');
  const createAssignmentAction = getAction('CreateAssignment');

  const { exerciseId } = useParams();
  const [assignmentId, setAssignmentId] = useState<string | undefined>(undefined);

  // exercises
  const [allExercises, setAllExercises] = useState<GetExercisesResponse['exercises']>();
  const exercise = allExercises?.find((exercise) => exercise.exerciseId === Number(exerciseId));

  // filters
  const [selectedFilters, setSelectedFilters] = useState<string[]>([]);

  // modals
  const [displayFiltersModal, setDisplayFiltersModal] = useState<boolean>(false);

  // Apply filters.
  let filteredExercises = allExercises || [];
  if (!isEmpty(selectedFilters) && allExercises) {
    filteredExercises = allExercises.filter((exercise) => {
      const { topics } = exercise;
      const grades = exercise.grades.map((grade) => String(grade));

      return intersection(topics, selectedFilters).length > 0 || intersection(grades, selectedFilters).length > 0;
    });
  }

  // Fetch exercises.
  useEffect(() => {
    getExercisesAction({ query: { language: lang } }).success(({ exercises }) =>
      setAllExercises(sortBy(exercises, 'exerciseId'))
    );
  }, []);

  // Generate assignment.
  useEffect(() => {
    if (exerciseId) {
      createAssignmentAction({
        payload: { exerciseSet: { language: lang, exercises: [{ exerciseId: Number(exerciseId), count: 1 }] } },
      }).success(({ assignmentId }) => setAssignmentId(assignmentId));
    }
  }, [exerciseId]);

  // Unset assignment id when navigating away.
  useEffect(() => {
    if (!exerciseId) {
      setAssignmentId(undefined);
    }
  }, [exerciseId]);

  const hideIfDetail = exerciseId ? 'hidden' : '';
  const hideIfList = exerciseId ? '' : 'hidden';
  const loader = <Loader className="mx-auto" variant="oval" color="green" />;

  return (
    <div className="p-[24px] pb-[84px] lg:pb-[64px] lg:pl-[64px] lg:pr-[64px] lg:pt-[39px]">
      <div className="text-[24px] font-semibold leading-[29px]">
        {exerciseId ? (
          <div className="flex items-center gap-[16px]">
            <div className="flex items-center gap-[9px]">
              <ArrowLeftIcon />
              <span
                onClick={() => navigate(-1)}
                className="cursor-pointer text-[14px] font-medium leading-[17px] text-green-primary"
              >
                {_t('Exercises book')}&nbsp;📙
              </span>
            </div>
            <div className="h-[28px] border-r border-r-input-outline-shadow" />
            <div className="text-[14px] leading-[17px] text-text-dark-2">{exercise?.title}</div>
          </div>
        ) : (
          <>{_t('Exercises book')}&nbsp;📙</>
        )}
      </div>
      <div className={hideIfDetail}>
        <div className="pt-[32px]">
          <div className="flex flex-wrap items-stretch gap-[8px]">
            <div className="flex items-stretch">
              <Button
                className="h-[42px] rounded-[7px] border border-input-outline-shadow bg-white text-green-primary"
                onClick={() => setDisplayFiltersModal(true)}
              >
                <div className="flex items-center gap-[8px] text-[14px] font-semibold leading-[17px]">
                  <FilterIcon />
                  {_t('Filter')}
                </div>
              </Button>
            </div>
            {selectedFilters.map((filter) => (
              <div className="flex items-center" key={filter}>
                <FilterButton
                  filter={filter}
                  removeFilter={() => setSelectedFilters((prev) => prev.filter((p) => p !== filter))}
                />
              </div>
            ))}
            <div className="flex items-center">
              {selectedFilters.length > 0 && (
                <Clickable
                  className="text-[14px] font-medium leading-[17px] text-text-dark underline"
                  onClick={() => setSelectedFilters([])}
                >
                  {_t('delete all filters')}
                </Clickable>
              )}
            </div>
          </div>
        </div>
        <div className="pt-[20px]">
          <div className="grid grid-cols-[100%] gap-[16px] sm:grid-cols-[repeat(auto-fill,minmax(294px,1fr))]">
            {filteredExercises?.map((exercise) => {
              return (
                <StudentExerciseCard
                  key={exercise.exerciseId}
                  exerciseId={exercise.exerciseId}
                  title={exercise.title}
                  description={exercise.description}
                  type={exercise.exerciseType === 'Numerical' ? _t('Numerical problem') : _t('Word problem')}
                  onButtonClick={() => navigate(`/student/exercises/exercise/${exercise.exerciseId}`)}
                />
              );
            })}
          </div>
        </div>
      </div>
      <div className={`mx-auto mt-[32px] rounded-[10px] bg-white p-[24px] lg:pb-[48px] lg:pt-[48px] ${hideIfList}`}>
        <div className="mx-auto max-w-[570px]">
          {assignmentId ? <Assignment assignmentId={assignmentId} loader={loader} /> : loader}
        </div>
      </div>
      <FiltersModal
        opened={displayFiltersModal}
        setOpened={setDisplayFiltersModal}
        selectedFilters={selectedFilters}
        setSelectedFilters={setSelectedFilters}
        allExercises={allExercises || []}
      />
    </div>
  );
}
