/**
 * Assigned Assignments Icon.
 */
export default function AssignedAssignmentsIcon() {
  return (
    <svg width="38" height="42" viewBox="0 0 38 42" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M35 28L18 28" stroke="#353E45" strokeWidth="3" strokeLinecap="round" />
      <path
        d="M30 40H3C2.44771 40 2 39.5523 2 39V3C2 2.44772 2.44772 2 3 2H29C29.5523 2 30 2.44772 30 3V16.5"
        stroke="#353E45"
        strokeWidth="3"
        strokeLinecap="round"
      />
      <path d="M8.5 10H21" stroke="#353E45" strokeWidth="3" strokeLinecap="round" />
      <path d="M8.5 19H15.5" stroke="#353E45" strokeWidth="3" strokeLinecap="round" />
      <path
        d="M30.0001 21.9999L35.2931 27.2929C35.6836 27.6834 35.6836 28.3166 35.2931 28.7071L30.0001 34.0001"
        stroke="#353E45"
        strokeWidth="3"
        strokeLinecap="round"
      />
    </svg>
  );
}
