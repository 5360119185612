import { AxiosInstance } from 'axios';
import { SweetPath } from 'sweet-path';
import dispatchAction from 'api/dispatch-action';

import { SubmitAssignmentSolutionPayload } from 'api/actions/submit-assignment-solution/submit-assignment-solution-payload';
import { SubmitAssignmentSolutionPathParameters } from 'api/actions/submit-assignment-solution/submit-assignment-solution-path-parameters';

import { SubmitAssignmentSolutionResponse } from 'api/actions/submit-assignment-solution/submit-assignment-solution-response';

/**
 * The path of the SubmitAssignmentSolution action.
 */
export const submitAssignmentSolutionActionPath = new SweetPath<keyof SubmitAssignmentSolutionPathParameters>(
  '/assignment/{assignmentId}/solution',
  { path: '{param}' }
);

/**
 * Creates a contextualized SubmitAssignmentSolution actions.
 */
export default function createSubmitAssignmentSolutionAction(axios: AxiosInstance) {
  return function submitAssignmentSolutionAction({
    parameters,
    payload,
  }: {
    parameters: SubmitAssignmentSolutionPathParameters;
    payload: SubmitAssignmentSolutionPayload;
  }) {
    const path = submitAssignmentSolutionActionPath.insert(parameters);

    return dispatchAction(() =>
      axios.post<SubmitAssignmentSolutionResponse, SubmitAssignmentSolutionResponse, SubmitAssignmentSolutionPayload>(
        path,
        payload
      )
    );
  };
}
