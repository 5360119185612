import { Progress } from '@mantine/core';

interface Props {
  progress: number;
}

/**
 * Header progress bar
 */
export default function HeaderProgressBar({ progress }: Props) {
  return (
    <div className="hidden pt-[16px] lg:block">
      <Progress
        size="xs"
        radius="xl"
        value={progress}
        styles={{
          bar: { backgroundColor: '#0AB892' },
          root: { backgroundColor: '#D9E0E6' },
        }}
      />
    </div>
  );
}
