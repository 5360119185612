import { AxiosInstance } from 'axios';
import { SweetPath } from 'sweet-path';
import dispatchAction from 'api/dispatch-action';

import { HealthCheckPathParameters } from 'api/actions/health-check/health-check-path-parameters';

import { HealthCheckResponse } from 'api/actions/health-check/health-check-response';

/**
 * The path of the HealthCheck action.
 */
export const healthCheckActionPath = new SweetPath<keyof HealthCheckPathParameters>('/health-check', {
  path: '{param}',
});

/**
 * Creates a contextualized HealthCheck actions.
 */
export default function createHealthCheckAction(axios: AxiosInstance) {
  return function healthCheckAction() {
    const path = healthCheckActionPath.original;

    return dispatchAction(() => axios.get<HealthCheckResponse, HealthCheckResponse>(path));
  };
}
