/**
 * Right arrow icon
 */
export default function RightArrowIcon() {
  return (
    <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M17.3335 21.3333L21.9597 16.707C22.3502 16.3165 22.3502 15.6833 21.9597 15.2928L17.3335 10.6666"
        stroke="#738491"
        strokeWidth="2"
        strokeLinecap="round"
      />
      <line x1="21" y1="16" x2="7" y2="16" stroke="#738491" strokeWidth="2" strokeLinecap="round" />
    </svg>
  );
}
