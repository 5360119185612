import useApi from 'api/use-api';
import { BUY_PREMIUM_URL } from 'env';
import { useMemo } from 'react';
import { Link } from 'react-router-dom';
import { REGISTER_COURSE_PAGE_PATH } from 'routes/paths';

/**
 * Parameters for the CourseCtaButton.
 */
export interface CourseCtaButtonProps {
  title: string;
}

/**
 * Displays a button for the course CTA.
 */
export default function CourseCtaButton({ title }: CourseCtaButtonProps) {
  const { role, email } = useApi();

  const payUrl = useMemo(() => {
    if (role === 'guest') {
      return REGISTER_COURSE_PAGE_PATH;
    }

    return `${BUY_PREMIUM_URL}?prefilled_email=${encodeURIComponent(email)}`;
  }, [role]);

  return (
    <Link
      className="ml-auto mr-auto flex h-[56px] w-full max-w-[360px] items-center justify-center rounded-[10px] bg-green-primary text-[18px] font-semibold leading-[22px] text-white hover:bg-green-dark active:bg-green-dark lg:ml-0 lg:mr-0"
      to={payUrl}
    >
      {title}
    </Link>
  );
}
