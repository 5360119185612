import { AxiosInstance } from 'axios';
import { SweetPath } from 'sweet-path';
import dispatchAction from 'api/dispatch-action';

import { SubmitStudentHomeworkCommentPayload } from 'api/actions/submit-student-homework-comment/submit-student-homework-comment-payload';
import { SubmitStudentHomeworkCommentPathParameters } from 'api/actions/submit-student-homework-comment/submit-student-homework-comment-path-parameters';

import { SubmitStudentHomeworkCommentResponse } from 'api/actions/submit-student-homework-comment/submit-student-homework-comment-response';

/**
 * The path of the SubmitStudentHomeworkComment action.
 */
export const submitStudentHomeworkCommentActionPath = new SweetPath<keyof SubmitStudentHomeworkCommentPathParameters>(
  '/student/homework/{homeworkId}/comment',
  { path: '{param}' }
);

/**
 * Creates a contextualized SubmitStudentHomeworkComment actions.
 */
export default function createSubmitStudentHomeworkCommentAction(axios: AxiosInstance) {
  return function submitStudentHomeworkCommentAction({
    parameters,
    payload,
  }: {
    parameters: SubmitStudentHomeworkCommentPathParameters;
    payload: SubmitStudentHomeworkCommentPayload;
  }) {
    const path = submitStudentHomeworkCommentActionPath.insert(parameters);

    return dispatchAction(() =>
      axios.post<
        SubmitStudentHomeworkCommentResponse,
        SubmitStudentHomeworkCommentResponse,
        SubmitStudentHomeworkCommentPayload
      >(path, payload)
    );
  };
}
