import useApi from 'api/use-api';
import Assignment from 'components/assignment/Assignment';
import { _t } from 'lang';
import { useEffect, useState } from 'react';

/**
 * The parameters of the ExerciseExample component.
 */
export interface ExerciseExampleProps {
  exerciseId: number;
  language: string;
  count?: number;
}

/**
 * An example of an exercise.
 */
export default function ExerciseExample({ exerciseId, language, count = 1 }: ExerciseExampleProps) {
  const { getAction } = useApi();
  const createAssignmentAction = getAction('CreateAssignment');

  const [assignmentId, setAssignmentId] = useState<string | null>(null);

  useEffect(() => {
    createAssignmentAction({ payload: { exerciseSet: { language, exercises: [{ exerciseId, count }] } } }).success(
      ({ assignmentId }) => setAssignmentId(assignmentId)
    );
  }, [exerciseId, language]);

  if (!assignmentId) {
    return <div>{_t('Loading ...')}</div>;
  }

  return <Assignment assignmentId={assignmentId} />;
}
