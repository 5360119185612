import useApi from 'api/use-api';

interface Props {
  children: JSX.Element;
}

/**
 * Displays the child elements only when to session is ready.
 */
export default function WaitForSession({ children }: Props) {
  const { ready } = useApi();

  return ready ? children : <></>;
}
