import { _t } from 'lang';
import ImageCard from 'pages/presentation-module/who-we-are/ImageCard';

/**
 * Image cards with Video Editors.
 */
export default function VideoEditors() {
  return (
    <>
      <ImageCard
        image="/team/adam-pasecky.jpg" // cspell:disable-line
        name="Adam Pasecký" // cspell:disable-line
        position={_t('Camera man, Video editing')}
        contact=""
        contactName=""
      />
      <ImageCard
        image="/team/vojtech-stary.jpg" // cspell:disable-line
        name="Vojtěch Starý" // cspell:disable-line
        position={_t('Camera man, Video editing')}
        contact=""
        contactName=""
      />
    </>
  );
}
