import Assignment from 'components/assignment/Assignment';
import useCourse from 'data/course/use-course';
import useCourseStep from 'pages/courses-module/course-step/context/use-course-step';
import PretestLabel from 'components/PretestLabel';
import { _t, _tx } from 'lang';
import PretestEvaluation from 'pages/courses-module/course-step/main/content/pretest/PretestEvaluation';
import { IAssignmentEvaluation } from 'data/assignment/assignment';
import sprintf from 'lang/sprintf';
import { useState } from 'react';

/**
 * The parameters of the component.
 */
interface Props {
  assignmentId: string;
  isSolved: boolean;
  steps: number[];
  nextStep?: { stepId: number; sectionSlug: string };
}

/**
 * The pretest part of the course.
 */
export default function CourseStepContentPretest({ assignmentId, isSolved: isSolvedInitial, steps, nextStep }: Props) {
  const [isSolved, setIsSolved] = useState(isSolvedInitial);
  const { updateCourseProgress } = useCourse();
  const { course, currStep } = useCourseStep();

  const introText = sprintf(
    // This should be a single line to avoid a bug in the translation tool.
    _tx('If you complete the test without making a mistake you can skip the next step.', 'If you complete the test without making a mistake you can skip the next %d steps.', steps.length), // prettier-ignore
    steps.length
  );

  /**
   * Handles the submission of the assignment.
   */
  function onSolved(evaluation: IAssignmentEvaluation | undefined) {
    const completedSteps = [{ progress: 1, stepId: currStep!.stepId }];

    if (evaluation && evaluation.passed) {
      steps.forEach((step) => completedSteps.push({ progress: 1, stepId: step }));
    }

    updateCourseProgress({ courseId: course.courseId, steps: completedSteps });
    setIsSolved(true);
  }

  return (
    <div className="ml-auto mr-auto max-w-[618px] pl-[24px] pr-[24px] lg:pb-[56px] lg:pt-[56px]">
      <div className="flex">
        <PretestLabel />
      </div>
      <h2 className="mb-[48px] mt-[7px] text-[24px] font-semibold leading-[34px]">
        <span className="text-pretest-blue">{_t('Welcome to pretest.')}</span>
        <br />
        <span>{introText}</span>
      </h2>
      {!isSolved && <div className="mb-[48px] mt-[48px] h-[1px] w-full bg-input-outline-shadow" />}
      <Assignment
        assignmentId={assignmentId}
        onSolved={onSolved}
        afterEvaluationContent={(evaluation) => (
          <PretestEvaluation evaluation={evaluation} steps={steps} nextStep={nextStep} />
        )}
      />
    </div>
  );
}
