import { _t } from 'lang';
import BasicPageLayout from 'layout/basic-page-layout/BasicPageLayout';

/**
 * The privacy policy page.
 */
export default function PrivacyPolicyPage() {
  return (
    <BasicPageLayout hasFooter hasHeader>
      <div className="react-markdown">
        <div className="flex min-h-[220px] items-center bg-contact-phone bg-cover bg-no-repeat text-white md:bg-contact-desktop">
          <div className="ml-auto mr-auto flex max-w-[818px] flex-col items-center pl-[24px] pr-[24px] text-center">
            <span className="text-[32px] font-semibold leading-[42px] md:text-[44px] md:leading-[54px]">
              {_t('Privacy policy')}
            </span>
          </div>
        </div>

        <div className="ml-auto mr-auto max-w-[918px] pb-[64px] pl-[24px] pr-[24px] pt-[24px] md:pb-[80px] md:pt-[80px]">
          <p className="text-center">
            <strong>
              I.
              <br />
              Základní ustanovení
            </strong>
          </p>
          <ol>
            <li>
              Správcem osobních údajů podle čl. 4 bod 7 nařízení Evropského parlamentu a Rady (EU) 2016/679 o ochraně
              fyzických osob v souvislosti se zpracováním osobních údajů a o volném pohybu těchto údajů (dále jen: „
              <strong>GDPR</strong>”) je Zvědavá mysl s.r.o., IČ 142 85 461 se sídlem Na Folimance 2155/15, Vinohrady,
              120 00 Praha 2 (dále jen: „<strong>správce</strong>“).
            </li>
            <li>
              Kontaktní údaje správce jsou: Email:<a href="mailto:michael@zvedavamysl.cz">michael@zvedavamysl.cz</a>
              Mobil: +420 728 218 583
            </li>
            <li>
              Osobními údaji se rozumí veškeré informace o identifikované nebo identifikovatelné fyzické osobě;
              identifikovatelnou fyzickou osobou je fyzická osoba, kterou lze přímo či nepřímo identifikovat, zejména
              odkazem na určitý identifikátor, například jméno, identifikační číslo, lokační údaje, síťový identifikátor
              nebo na jeden či více zvláštních prvků fyzické, fyziologické, genetické, psychické, ekonomické, kulturní
              nebo společenské identity této fyzické osoby.
            </li>
            <li>Správce jmenoval pověřence pro ochranu osobních údajů. Kontaktními údaji pověřence jsou:</li>
          </ol>
          <p>
            Michael Bohin
            <br />
            Email:<a href="mailto:michael@zvedavamysl.cz">michael@zvedavamysl.cz</a>
            <br />
            Mobil: +420 728 218 583
          </p>
          <p className="pt-[16px] text-center">
            <strong>
              II.
              <br />
              Zdroje a kategorie zpracovávaných osobních údajů
            </strong>
          </p>
          <ol>
            <li>
              Správce zpracovává osobní údaje, které jste mu poskytl/a nebo osobní údaje, které správce získal na
              základě registrace vašeho osobního účtu na doméně www.zvedavamysl.cz.
            </li>
            <li>
              Správce zpracovává Vaše identifikační a kontaktní údaje a údaje nezbytné pro provozování výukového
              portálu.
            </li>
          </ol>
          <p className="pt-[16px] text-center">
            <strong>
              III.
              <br />
              Zákonný důvod a účel zpracování osobních údajů
            </strong>
          </p>
          <p>Zákonným důvodem zpracování osobních údajů je</p>
          <ul>
            <li>plnění smlouvy mezi Vámi a správcem podle čl. 6 odst. 1 písm. b) GDPR,</li>
            <li>
              oprávněný zájem správce na poskytování přímého marketingu (zejména pro zasílání obchodních sdělení a
              newsletterů) podle čl. 6 odst. 1 písm. f) GDPR,
            </li>
          </ul>
          <ol>
            <li>
              Váš souhlas se zpracováním pro účely poskytování přímého marketingu (zejména pro zasílání obchodních
              sdělení a newsletterů) podle čl. 6 odst. 1 písm. a) GDPR ve spojení s § 7 odst. 2 zákona č. 480/2004 Sb.,
              o některých službách informační společnosti v případě, že nedošlo k objednávce zboží nebo služby.
            </li>
            <li>Účelem zpracování osobních údajů je</li>
            <li>
              v případě žáků ukládání stavu vašeho osobního účtu ve věci postupu ve výukových kurzech.
              <br />
            </li>
            <li>
              v případě účtu zřízeného pro učitele ukládání stavu jeho třídy, zejména pak domácí úkoly, které zadal a
              řešení domácích úkolů, které mu žáci poslali nazpět.
            </li>
            <li>
              vyřízení Vaší objednávky a výkon práv a povinností vyplývajících ze smluvního vztahu mezi Vámi a správcem;
              při objednávce jsou vyžadovány osobní údaje, které jsou nutné pro úspěšné vyřízení objednávky (jméno a
              adresa, kontakt), poskytnutí osobních údajů je nutným požadavkem pro uzavření a plnění smlouvy, bez
              poskytnutí osobních údajů není možné smlouvu uzavřít či jí ze strany správce plnit,
            </li>
            <li>zasílání obchodních sdělení a činění dalších marketingových aktivit.</li>
          </ol>
          <p>
            Ze strany správce dochází k automatickému individuálnímu rozhodování ve smyslu čl. 22 GDPR. S takovým
            zpracováním jste poskytl/a svůj výslovný souhlas.
          </p>
          <p className="pt-[16px] text-center">
            <strong>
              IV.
              <br />
              Doba uchovávání údajů
            </strong>
          </p>
          Správce uchovává osobní údaje:
          <ol>
            <li>
              po dobu nezbytnou k výkonu práv a povinností vyplývajících ze smluvního vztahu mezi Vámi a správcem a
              uplatňování nároků z těchto smluvních vztahů (po dobu 15 let od ukončení smluvního vztahu).
              <br />
            </li>
            <li>
              po dobu, než je odvolán souhlas se zpracováním osobních údajů pro účely marketingu, nejdéle 15 let,
              jsou-li osobní údaje zpracovávány na základě souhlasu.
            </li>
            <li>Po uplynutí doby uchovávání osobních údajů správce osobní údaje vymaže.</li>
          </ol>
          <p className="pt-[16px] text-center">
            <strong>
              V.
              <br />
              Příjemci osobních údajů (subdodavatelé správce)
            </strong>
          </p>
          Příjemci osobních údajů jsou osoby:
          <ol>
            <li>
              podílející se na dodání zboží / služeb / realizaci plateb na základě smlouvy,
              <br />
            </li>
            <li>podílející se na zajištění provozu služeb,</li>
            <li>zajišťující marketingové služby.</li>
            <li>
              Správce má v úmyslu předat osobní údaje do třetí země (do země mimo EU) nebo mezinárodní organizaci.
              Příjemci osobních údajů ve třetích zemích jsou poskytovatelé mailingových služeb / cloudových služeb.
            </li>
          </ol>
          <p className="pt-[16px] text-center">
            <strong>
              VI.
              <br />
              Vaše práva
            </strong>
          </p>
          Za podmínek stanovených v GDPR máte:
          <ol>
            <li>
              právo na přístup ke svým osobním údajům dle čl. 15 GDPR,
              <br />
            </li>
            <li>
              právo opravu osobních údajů dle čl. 16 GDPR, popřípadě omezení zpracování dle čl. 18 GDPR.
              <br />
            </li>
            <li>
              právo na výmaz osobních údajů dle čl. 17 GDPR.
              <br />
            </li>
            <li>
              právo vznést námitku proti zpracování dle čl. 21 GDPR a<br />
            </li>
            <li>
              právo na přenositelnost údajů dle čl. 20 GDPR.
              <br />
            </li>
            <li>
              právo odvolat souhlas se zpracováním písemně nebo elektronicky na adresu nebo email správce uvedený v čl.
              III těchto podmínek.
              <br />
            </li>
            <li>
              Dále máte právo podat stížnost u Úřadu pro ochranu osobních údajů v případě, že se domníváte, že bylo
              porušeno Vaší právo na ochranu osobních údajů.
            </li>
          </ol>
          <p className="pt-[16px] text-center">
            <strong>
              VII.
              <br />
              Podmínky zabezpečení osobních údajů
            </strong>
          </p>
          <ol>
            <li>
              Správce prohlašuje, že přijal veškerá vhodná technická a organizační opatření k zabezpečení osobních
              údajů.
            </li>
            <li>
              Správce přijal technická opatření k zabezpečení datových úložišť a úložišť osobních údajů v listinné
              podobě.
            </li>
            <li>Správce prohlašuje, že k osobním údajům mají přístup pouze jím pověřené osoby.</li>
          </ol>
          <p className="pt-[16px] text-center">
            <strong>
              VIII.
              <br />
              Závěrečná ustanovení
            </strong>
          </p>
          <ol>
            <li>
              Odesláním formuláře pro registraci účtu potvrzujete, že jste seznámen/a s podmínkami ochrany osobních
              údajů a že je v celém rozsahu přijímáte.
            </li>
            <li>
              S těmito podmínkami souhlasíte zaškrtnutím souhlasu prostřednictvím internetového formuláře. Zaškrtnutím
              souhlasu potvrzujete, že jste seznámen/a s podmínkami ochrany osobních údajů a že je v celém rozsahu
              přijímáte.
            </li>
            <li>
              Správce je oprávněn tyto podmínky změnit. Novou verzi podmínek ochrany osobních údajů zveřejní na svých
              internetových stránkách, případně Vám zašle novou verzi těchto podmínek na e-mailovou adresu, kterou jste
              správci poskytl/a.
            </li>
          </ol>
          <p>Tyto podmínky nabývají účinnosti dnem 1.1.2023.</p>
        </div>
      </div>
    </BasicPageLayout>
  );
}
