/**
 * Red cross icon.
 */
export default function RedCrossIcon() {
  return (
    <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect
        width="13.9549"
        height="1.1629"
        rx="0.581452"
        transform="matrix(-0.699746 -0.714391 -0.699746 0.714391 11.1785 10.5693)"
        fill="#E82C2C"
      />
      <rect
        width="13.9549"
        height="1.1629"
        rx="0.581452"
        transform="matrix(0.699746 -0.714391 0.699746 0.714391 0.821487 10.5693)"
        fill="#E82C2C"
      />
    </svg>
  );
}
