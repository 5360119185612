import { ValidationError } from 'components/forms/validators/validation-error';
import { _t } from 'lang';

/**
 * Validates fraction input based on regex.
 */
export default function fractionResult(val: string): ValidationError | null {
  if (!val) {
    return null;
  }

  // minus, plus or nothing
  // digits - at least one
  // any number of whitespace characters
  // slash /
  // any number of whitespace characters
  // digits - at least one
  const match = /^[-+]?[0-9]+\s*\/\s*[0-9]+$/.test(val.trim());
  if (!match) {
    return {
      message: _t('Please check the number format.'),
    };
  }

  return null;
}
