import BasicPageLayout from 'layout/basic-page-layout/BasicPageLayout';
import StartExercises from 'components/StartExercises';
import { _t } from 'lang';
import WebDevelopers from 'pages/presentation-module/who-we-are/sections/WebDevelopers';
import YoutubeTeachers from 'pages/presentation-module/who-we-are/sections/YoutubeTeachers';
import Mentors from 'pages/presentation-module/who-we-are/sections/Mentors';
import VideoEditors from 'pages/presentation-module/who-we-are/sections/VideoEditors';
import { Navigate, useParams } from 'react-router-dom';
import { WHO_WE_ARE_PAGE_PATH } from 'routes/paths';
import SectionLink from 'pages/presentation-module/who-we-are/SectionLink';

/**
 * The Who we are page.
 *
 * @see https://www.figma.com/file/SjggpqHVlWxBKa2cIG349A/Math-Exercises?node-id=590%3A467
 */
export default function WhoWeArePage() {
  const { sectionName } = useParams();

  const YOUTUBE_TEACHERS = 'teachers';
  const WEB_DEVELOPERS = 'developers';
  const MENTORS = 'mentors';
  const VIDEO_EDITORS = 'video-editors';

  const YOUTUBE_TEACHERS_HEADING = _t('Lecturers');
  const WEB_DEVELOPERS_HEADING = _t('Developers');
  const MENTORS_HEADING = _t('Mentors');
  const VIDEO_EDITORS_HEADING = _t('Video editors');

  if (!sectionName) {
    return (
      <BasicPageLayout hasFooter hasHeader>
        <>
          <Navigate to={`${WHO_WE_ARE_PAGE_PATH}/${YOUTUBE_TEACHERS}`} />;
        </>
      </BasicPageLayout>
    );
  }

  /**
   * Section heading
   */
  function sectionHeading(heading: string) {
    return <span className="text-[24px] font-semibold leading-[42px] lg:text-[32px]">{heading}</span>;
  }

  return (
    <BasicPageLayout hasFooter hasHeader>
      <div>
        <div className="flex min-h-[220px] items-center bg-who-we-are-phone bg-cover text-white lg:bg-who-we-are">
          <div className="ml-auto mr-auto flex max-w-[818px] flex-col items-center pl-[24px] pr-[24px] text-center">
            <span className="text-[32px] font-semibold leading-[42px] lg:text-[44px] lg:leading-[54px]">
              {_t('Our team')}
            </span>
            <span className="hidden pt-[8px] text-[16px] font-medium leading-[26px] lg:pt-[12px]">
              Google Fonts is a library of 1,451 open source font families and APIs for convenient use via CSS and
              Android. The library also has delightful and beautifully crafted.
            </span>
          </div>
        </div>
        <div className="ml-auto mr-auto max-w-[1218px] pb-[64px] pl-[24px] pr-[24px] pt-[24px] lg:pb-[80px] lg:pt-[80px]">
          <div className="md:flex md:justify-between">
            {sectionName === YOUTUBE_TEACHERS && sectionHeading(YOUTUBE_TEACHERS_HEADING)}
            {sectionName === WEB_DEVELOPERS && sectionHeading(WEB_DEVELOPERS_HEADING)}
            {sectionName === MENTORS && sectionHeading(MENTORS_HEADING)}
            {sectionName === VIDEO_EDITORS && sectionHeading(VIDEO_EDITORS_HEADING)}
            <div className="left:right-0 relative left-[50%] right-[50%] ml-[-50vw] mr-[-50vw] w-[100vw] overflow-auto pl-[24px] pr-[24px] md:static md:left-0 md:ml-0 md:mr-0 md:flex md:w-auto md:pl-0 md:pr-0">
              <div className="flex items-center gap-[8px] lg:gap-[16px]">
                <SectionLink to={`${WHO_WE_ARE_PAGE_PATH}/${YOUTUBE_TEACHERS}`} heading={YOUTUBE_TEACHERS_HEADING} />
                <SectionLink to={`${WHO_WE_ARE_PAGE_PATH}/${WEB_DEVELOPERS}`} heading={WEB_DEVELOPERS_HEADING} />
                <SectionLink to={`${WHO_WE_ARE_PAGE_PATH}/${MENTORS}`} heading={MENTORS_HEADING} />
                <SectionLink to={`${WHO_WE_ARE_PAGE_PATH}/${VIDEO_EDITORS}`} heading={VIDEO_EDITORS_HEADING} />
              </div>
            </div>
          </div>
          <div className="min-h-[820px]">
            <div className="grid grid-cols-[repeat(auto-fit,157px)] justify-center gap-x-[12px] gap-y-[24px] pt-[32px] md:grid-cols-[repeat(auto-fit,270px)] lg:justify-start lg:gap-x-[30px] lg:gap-y-[32px]">
              {sectionName === YOUTUBE_TEACHERS && <YoutubeTeachers />}
              {sectionName === WEB_DEVELOPERS && <WebDevelopers />}
              {sectionName === MENTORS && <Mentors />}
              {sectionName === VIDEO_EDITORS && <VideoEditors />}
            </div>
          </div>

          <div className="pt-[64px] lg:pt-[160px]">
            <StartExercises />
          </div>
        </div>
      </div>
    </BasicPageLayout>
  );
}
