import { AxiosInstance } from 'axios';
import { SweetPath } from 'sweet-path';
import dispatchAction from 'api/dispatch-action';

import { AssignClassroomHomeworkPayload } from 'api/actions/assign-classroom-homework/assign-classroom-homework-payload';
import { AssignClassroomHomeworkPathParameters } from 'api/actions/assign-classroom-homework/assign-classroom-homework-path-parameters';

import { AssignClassroomHomeworkResponse } from 'api/actions/assign-classroom-homework/assign-classroom-homework-response';

/**
 * The path of the AssignClassroomHomework action.
 */
export const assignClassroomHomeworkActionPath = new SweetPath<keyof AssignClassroomHomeworkPathParameters>(
  '/classroom/{classroomId}/homework',
  { path: '{param}' }
);

/**
 * Creates a contextualized AssignClassroomHomework actions.
 */
export default function createAssignClassroomHomeworkAction(axios: AxiosInstance) {
  return function assignClassroomHomeworkAction({
    parameters,
    payload,
  }: {
    parameters: AssignClassroomHomeworkPathParameters;
    payload: AssignClassroomHomeworkPayload;
  }) {
    const path = assignClassroomHomeworkActionPath.insert(parameters);

    return dispatchAction(() =>
      axios.post<AssignClassroomHomeworkResponse, AssignClassroomHomeworkResponse, AssignClassroomHomeworkPayload>(
        path,
        payload
      )
    );
  };
}
