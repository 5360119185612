import { _t } from 'lang';

/**
 * The parameters of the component.
 */
interface Props {
  className?: string;
}

/**
 * The label for a pretest course step.
 */
export default function PretestLabel({ className = '' }: Props) {
  return (
    <div
      className={`rounded-[3px] border-[1px] border-pretest-purple bg-pretest-blue pb-[3px] pl-[6px] pr-[6px] pt-[3px] text-[10px] font-semibold leading-[12px] text-white ${className}`}
    >
      {_t('Pretest')}
    </div>
  );
}
