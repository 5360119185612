import PageHeader from 'layout/parts/header/PageHeader';
import PageFooter from 'layout/parts/footer/PageFooter';
import BasicPageLayoutProvider from 'layout/basic-page-layout/BasicPageLayoutProvider';

/**
 * The properties of the basic page layout.
 */
interface Props {
  hasHeader?: boolean;
  hasFooter?: boolean;
  className?: string;
  children?: JSX.Element;
}

/**
 * The layout of a basic page.
 */
export default function BasicPageLayout({ hasHeader = false, hasFooter = false, className, children }: Props) {
  const contentPadding = hasHeader ? 'pt-[66px] lg:pt-[90px]' : '';

  return (
    <BasicPageLayoutProvider>
      <div>
        {hasHeader && <PageHeader />}
        <div className={`lg:min-h-screen ${contentPadding} ${className}`}>{children}</div>
        {hasFooter && <PageFooter />}
      </div>
    </BasicPageLayoutProvider>
  );
}
