interface Props {
  stroke?: string;
  strokeWidth?: string;
}

/**
 * Plus Icon
 */
export default function PlusIcon({ stroke = '#FFF', strokeWidth = '2' }: Props) {
  return (
    <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M7 1V13" stroke={stroke} strokeWidth={strokeWidth} strokeLinecap="round" />
      <path d="M1 7L13 7" stroke={stroke} strokeWidth={strokeWidth} strokeLinecap="round" />
    </svg>
  );
}
