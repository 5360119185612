import { _t } from 'lang';
import Emoji from 'components/Emoji';
import MainCtaButton from 'components/MainCtaButton';

/**
 * Component with CTA button and image.
 */
export default function StartExercises() {
  const text = _t('Start using Curious Mind when teaching mathematics and save time when assigning and correcting homework.'); // prettier-ignore

  return (
    <div className="flex flex-col-reverse items-center lg:flex-row lg:justify-between">
      <div className="pt-[32px] lg:pt-0">
        <div className="text-dark pb-[24px] text-center text-[24px] font-semibold leading-[34px] lg:max-w-[519px] lg:pb-[32px] lg:text-left lg:text-[32px] lg:leading-[42px]">
          <span className="md:whitespace-pre-line">{text}</span> <Emoji symbol="🔢" label={_t('input-numbers')} />
        </div>
        <MainCtaButton />
      </div>
      <div>
        <img src="/img/pencils-notes-phone.svg" alt="Pencils and notes" className="lg:hidden" />
        <img src="/img/pencils-notes-desktop.svg" alt="Pencils and notes" className="hidden lg:block" />
      </div>
    </div>
  );
}
