import { Accordion, AccordionStylesNames, AccordionStylesParams, Styles } from '@mantine/core';
import ArrowDownIcon from 'components/icons/ArrowDownIcon';
import { _t } from 'lang';
import FaqItem from 'components/faq/FaqItem';
import FaqImage from 'components/faq/content/FaqImage';
import FaqText from 'components/faq/content/FaqText';
import sprintf from 'lang/sprintf';

/**
 * The styles for the Accordion component.
 */
const styles: Styles<AccordionStylesNames, AccordionStylesParams> = {
  item: {
    backgroundColor: '#FFF!important',
    border: 'none',
    marginBottom: '12px',
    borderRadius: '5px',
    '&:last-child': {
      marginBottom: '0',
    },
  },
  chevron: {
    '&[data-rotate]': {
      stroke: '#353E45!important',
    },
  },
  control: {
    backgroundColor: '#FFF!important',
    borderRadius: '5px',
    paddingLeft: '24px',
    paddingRight: '29px',
    paddingTop: '0',
    paddingBottom: '0',
  },
  panel: {
    padding: '24px',
    paddingTop: '8px',
  },
  content: {
    padding: 0,
  },
  itemTitle: {
    fontWeight: 'inherit',
  },
  label: {
    fontWeight: 'inherit',
  },
};

/**
 * FAQ for the home page.
 */
export default function FaqAccordion() {
  return (
    <Accordion multiple chevron={<ArrowDownIcon />} styles={styles}>
      {/* Question 1 */}
      <FaqItem value="q1" question={_t('How can I start using Curious Mind?')}>
        <FaqText
          text={_t('To start using Curious Mind, you need to create an account. You can do this by clicking on the "Register" button at the top right corner of the page.')} // prettier-ignore
        />
        <FaqImage src="01" alt={_t('Register button in the top right corner of the page')} />
        <FaqText
          text={_t('On the registration page, you have to select the "Teacher" option and fill in the required information.')} // prettier-ignore
        />
        <FaqImage src="02" alt={_t('Teacher option on the registration page')} />
        <FaqText
          text={_t('After that, you will be redirected to the teacher dashboard, where you can create a new classroom and add students to it.')} // prettier-ignore
        />
      </FaqItem>

      {/* Question 2 */}
      <FaqItem value="q2" question={_t('How can I create a new classroom?')}>
        <FaqText
          text={_t('To create a new classroom, you need to click on the "Create classroom" button on the teacher dashboard.')} // prettier-ignore
        />
        <FaqImage src="03" alt={_t('Create classroom button on the teacher dashboard')} />
        <FaqText
          text={_t('A modal window will appear, where you can enter the name and the grade of the classroom and click on the "Create classroom" button.')} // prettier-ignore
        />
        <FaqImage src="04" alt={_t('Create classroom modal window')} />
        <FaqText
          text={_t('After that, you will be redirected to the classroom page, where you can add students to the classroom.')} // prettier-ignore
        />
      </FaqItem>

      {/* Question 3 */}
      <FaqItem value="q3" question={_t('How can I add students to the classroom?')}>
        <FaqText
          text={_t('To add students to the classroom, you need to go to the Students page and click on the button "Add students to the classroom".')} // prettier-ignore
        />
        <FaqImage src="05" alt={_t("Add students to the classroom button on the Students's page")} />
        <FaqText
          text={_t('A modal window with an invite code will appear. You can copy this invite code and share it with your students.')} // prettier-ignore
        />
        <FaqImage src="06" alt={_t('Classroom invite code modal window')} />
        <FaqText
          text={_t('If you want to generate a new invite code for the classroom, click on the "Generate a new invite code" button. After generating a new invite code, the old will not work anymore but the students who have joined your classroom will stay in that classroom.')} // prettier-ignore
        />
        <FaqImage src="07" alt={_t('Generate a new invite code')} />
        <FaqText
          text={_t('When your students join your class, you will see them on this page.')} // prettier-ignore
        />
      </FaqItem>

      {/* Question 4 */}
      <FaqItem value="q4" question={_t('How can I see if my students have joined the classroom?')}>
        <FaqText
          text={_t('If your students registered for the webiste and entered the invite code, you will see them on the Students page.')} // prettier-ignore
        />
        <FaqImage src="08" alt={_t('The list of students')} />
        <FaqText
          text={_t('If you do not recognize a name in the list of students, you can remove this student from your classroom.')} // prettier-ignore
        />
      </FaqItem>

      {/* Question 5 */}
      <FaqItem value="q5" question={_t('How can I remove a student from the classroom?')}>
        <FaqText text={_t('You can remove the student from your classroom directly from the list of students.')} />
        <FaqImage src="09" alt={_t('Remove student option - list of students page')} />
        <FaqText text={_t('You can also remove the student from the classroom on their detail page.')} />
        <FaqImage src="10" alt={_t('Remove student option - student detail page')} />
        <FaqText
          text={_t('Whether you will remove the student from the list of students or from their detail, you will be displayed a modal window where you can confirm (or stop) the removal of the student from the classroom')} // prettier-ignore
        />
        <FaqImage src="11" alt={_t('Remove student modal window')} />
        <FaqText
          text={_t('To remove the student from the classroom, click on the "Yes, remove the student from the classroom". If you do not want to remove the student, click on the "No, I do not want to remove the student". When you choose to remove the student from the classroom, you will see a success message on the Students page after the removal is finished.')} // prettier-ignore
        />
        <FaqImage src="12" alt={_t('Student was successfully removed')} />
        <FaqText
          text={_t('When you are sure that the students who joined are your students, you can assign them homework.')} // prettier-ignore
        />
      </FaqItem>

      {/* Question 6 */}
      <FaqItem value="q6" question={_t('How can I assign a new homework to my students?')}>
        <FaqText text={_t('You can assign a new homework to your students from the "Exercises" page.')} />
        <FaqImage src="13" alt={_t('"Exercises" page')} />
        <FaqText
          text={_t('If you want to assign homework for a certain topic, you can filter the exercises by the topic.')} // prettier-ignore
        />
        <FaqImage src="14" alt={_t('Filters on the "Exercises" page')} />
        <FaqText
          text={_t('The topics will be then displayed above the viable exercises.')} // prettier-ignore
        />
        <FaqImage src="15" alt={_t('Selected filters on the "Exercises" page')} />
        <FaqText
          text={_t('The exercises create the homework. You can choose any number of exercises as well as any number of the exercise variants. To add given exercise to the homework, click on the "Add (number of variants)" button.')} // prettier-ignore
        />
        <FaqImage src="16" alt={_t('Selecting a number of variants of the exercise')} />
        <FaqText
          text={_t('When you choose the exercises, you can click on the "Preview homework" button. You will be displayed a modal window with exercises and their variants.')} // prettier-ignore
        />
        <FaqImage src="17" alt={_t('"Preview homework" modal window')} />
        <FaqText
          text={_t('If you are satisfied with the homework, you can click on the "Assign homework" button.')} // prettier-ignore
        />
        <FaqImage src="18" alt={_t('Assign homework from "Preview homework" modal window')} />
        <FaqText
          text={_t('You can also assign the homework without looking at the preview by clicking on the "Assign homework" button on the "Exercises" page.')} // prettier-ignore
        />
        <FaqImage src="19" alt={_t('Assign homework from "Exercises" page')} />
        <FaqText
          text={_t('To assign the homework, you will be displayed a modal window where you can choose the deadline for the homework.')} // prettier-ignore
        />
        <FaqImage src="20" alt={_t('Selecting a homework deadline')} />
        <FaqText
          text={_t('You can also choose whether you will assign the homework to the whole class or just to some students.')} // prettier-ignore
        />
        <FaqImage src="21" alt={_t('Assigning homework to the classroom/individuals')} />
        <FaqText text={_t('To assign the homework, click on the "Assign" button.')} />
        <FaqImage src="22" alt={_t('Assig homework button')} />
        <FaqText
          text={_t('When the homework was successfully assigned, you will be redirected to the classroom home page and you will see a success message.')} // prettier-ignore
        />
        <FaqImage src="23" alt={_t('Successfully assigned homework')} />
        <FaqText
          text={_t('If your students submit the homework, you will see their solution on the "Homework detail" page.')} // prettier-ignore
        />
      </FaqItem>

      {/* Question 7 */}
      <FaqItem value="q7" question={_t('How can I check the homework solution of a student?')}>
        <FaqText
          text={_t('If you want to see how your student did on a certain homework, go to the "Homework" page and find the homework you are interested in and click on the "Homework detail" button.')} // prettier-ignore
        />
        <FaqImage src="24" alt={_t('"Homework" page')} />
        <FaqText
          text={_t('In the "Homework detail" page you will see a list of students who have been assigned this homework and their results.')} // prettier-ignore
        />
        <FaqImage src="25" alt={_t('List of students who have been assigned the homework')} />
        <FaqText
          text={_t('If you wish to see how the student did - what mistakes they did, or what variants of the exercises the student got, click on the "Check homework" button on the "Homework detail" page.')} // prettier-ignore
        />
        <FaqImage src="26" alt={_t('"Check homework" button')} />
        <FaqImage src="27" alt={_t("Detail of the student's homework")} />
        <FaqText text={_t('You can also check how the student did on all their homework.')} />
      </FaqItem>

      {/* Question 8 */}
      <FaqItem value="q8" question={_t('How can I inspect the results of my students?')}>
        <FaqText
          text={_t('To see how the student did on all their homework, go to the "Students" page and click on the "More information about the student" on the student you want to inspect.')} // prettier-ignore
        />
        <FaqImage src="28" alt={_t('"More information about the student" button')} />
        <FaqText
          text={_t('On the "Student detail" page you will see the homework assigned to the student, their average success rate and the number of non-submitted homework.')} // prettier-ignore
        />
        <FaqImage src="29" alt={_t('The "Student detail" page')} />
      </FaqItem>

      {/* Question 9 */}
      <FaqItem value="q9" question={_t('How will my students solve their homework?')}>
        <FaqText
          text={_t('When the student logs in, they will see their active and past homework. The student will also see their homework on the "Homework" page. If the student wants to solve the homework, they will click on the "Solve homework" button.')} // prettier-ignore
        />
        <FaqImage src="30" alt={_t('"Solve homework" button')} />
        <FaqText
          text={_t('After clicking on that button, the student is directed to the page with the homework exercises. They solve the homework by typing the result in the input boxes or by selecting one or more options. After the student is satisfied with their results, they click on the "Evaluate" button - the button is at the bottom of the page.')} // prettier-ignore
        />
        <FaqImage src="31" alt={_t('Solving a homework')} />
        <FaqText
          text={_t('After submitting the homework, the student is immediately displayed their results.')} // prettier-ignore
        />
      </FaqItem>

      {/* Question 10 */}
      <FaqItem value="q10" question={_t('When will my students see their results?')}>
        <FaqText text={_t('After the student submits the homework, they immediately see their results.')} />
        <FaqImage src="32" alt={_t("Student's homework results")} />
        <FaqText
          text={_t('If the student feels like they need more practice, they can solve more exercises on the "Exercises" page.')} // prettier-ignore
        />
      </FaqItem>

      {/* Question 11 */}
      <FaqItem value="q11" question={_t('How can my students solve exercises on their own?')}>
        <FaqText text={_t('The students can solve various exercises on their own on the "Exercises" page.')} />
        <FaqImage src="33" alt={_t('"Exercises" page')} />
        <FaqText text={_t('On this page, the student can filter the exercises by topics they want to practice.')} />
        <FaqImage src="34" alt={_t('Filters modal window on the "Exercises" page')} />
        <FaqText
          text={_t('To practice an exercise, the student simply clicks on the "Solve exercise" button.')} // prettier-ignore
        />
        <FaqImage src="35" alt={_t('Selecting an exercise')} />
        <FaqText
          text={_t('On the exercise page, the student solves the exercise and clicks on the "Evaluate" button.')} // prettier-ignore
        />
        <FaqImage src="36" alt={_t('"Evaluate" exercise button')} />
        <FaqText
          text={_t('After evaluation, the student can practice on another variant of the exercise by clicking on the "Try again with different exercises" button. This way, the student can practice the exercise how many times they want.')} // prettier-ignore
        />
        <FaqImage src="37" alt={_t('"Try again with different exercises" button')} />
        <FaqText
          text={_t('If the student wants to solve another exercise, they simply go back to the "Exercises" page and select another exercise.')} // prettier-ignore
        />
      </FaqItem>

      {/* Question 12 */}
      <FaqItem value="q12" question={_t('Are there any fees for using the platform?')}>
        <FaqText
          text={
            _t('The Curious Mind platform is free for all teachers and students and will always remain free. Our revenue comes from the ads that are displayed on the platform. These ads are not intrusive and do not affect the user experience nor degrade the learning process in any way.') // prettier-ignore
          }
        />
      </FaqItem>

      {/* Question 13 */}
      <FaqItem value="q13" question={_t('How can I provide feedback?')}>
        <FaqText
          text={
            sprintf(_t('Everyone is more than welcome to provide feedback. Please, send your thoughts, ideas, and suggestions to our email address %s'), 'info@zvedavamysl.cz') // prettier-ignore
          }
        />
      </FaqItem>
    </Accordion>
  );
}
