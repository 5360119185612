import createMonogram from 'utils/create-monogram';

interface Props {
  size: number;

  label: string;

  style?: Object;
}

/**
 * User avatar
 */
export default function Avatar({ size, label, style }: Props) {
  return (
    <div
      style={{
        height: `${size}px`,
        width: `${size}px`,
        fontSize: `${Math.min(16, size / 2)}px`,
        ...style,
      }}
      className="flex items-center justify-center rounded-full bg-text-dark font-semibold text-bg-shadow"
    >
      <span>{createMonogram(label)}</span>
    </div>
  );
}
