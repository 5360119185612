import StudentDashboardLayout from 'layout/student-dashboard-layout/StudentDashboardLayout';
import StudentExercisesContent from 'pages/student-module/exercises/StudentExercisesContent';

/**
 * Students' exercises page.
 */
export default function StudentExercisesPage() {
  return (
    <StudentDashboardLayout hasHeader hasSidebar>
      <StudentExercisesContent />
    </StudentDashboardLayout>
  );
}
