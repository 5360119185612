import { _t } from 'lang';

interface Props {
  answer: string;
  result: string;
}

/**
 * Displays the letters of correct and my answers next to the Select and MultiSelect questions.
 */
export default function AnswerStats({ answer, result }: Props) {
  return (
    <div className="text-dark flex flex-col pb-[16px] text-[14px] font-medium leading-[21px]">
      <span>
        <span className="inline-block min-w-[140px]">{_t('Your answer')}:</span>{' '}
        <span className="font-semibold">{answer.toUpperCase()}</span>
      </span>

      <span>
        <span className="inline-block min-w-[140px]">{_t('Correct answer')}:</span>{' '}
        <span className="font-semibold">{result.toUpperCase()}</span>
      </span>
    </div>
  );
}
