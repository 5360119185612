import { Button } from '@mantine/core';
import { GetStudentDashboardResponse } from 'api/actions/get-student-dashboard/get-student-dashboard-response';
import PaperIcon from 'components/icons/PaperIcon';
import { _t } from 'lang';
import HomeworkRemainingTime from 'components/HomeworkRemainingTime';
import HomeworkStatus from 'components/HomeworkStatus';
import HomeworkResultView from 'components/HomeworkResultView';
import { useNavigate } from 'react-router-dom';

interface Props {
  homeworkId: any;
  isActive: boolean;
  deadline: string;
  assigned: string;
  name: string;
  exercises: GetStudentDashboardResponse['homework'][number]['homework']['exerciseSet']['exercises'];
  successRate?: number;
  assignmentId?: string;
}

/**
 * Homework card for students
 */
export default function StudentHomeworkCard({
  homeworkId,
  isActive,
  deadline,
  assigned,
  name,
  exercises,
  successRate,
  assignmentId,
}: Props) {
  const navigate = useNavigate();

  const solveHomeworkPage = `/student/homework/homework/${homeworkId}/solve`;
  const checkHomeworkPage = `/student/homework/homework/${homeworkId}/solve`;

  return isActive && successRate === undefined ? (
    <div className="grid grid-cols-1 grid-rows-[auto_auto_auto] rounded-[10px] bg-white p-[16px] lg:min-h-[155px] lg:grid-cols-[1fr_1fr] lg:grid-rows-[auto_auto] lg:pb-[24px] lg:pl-[24px] lg:pr-[24px] lg:pt-[24px]">
      {/* Title and content */}
      <div className="col-start-1 col-end-2 row-start-1 row-end-2 lg:col-start-1 lg:col-end-2 lg:row-start-1 lg:row-end-3">
        <div className="flex items-center justify-between lg:justify-start lg:gap-[12px]">
          <div className="text-[18px] font-semibold leading-[22px]">{name}</div>
          <HomeworkStatus status="ongoing" />
        </div>
        <div className="overflow-hidden pt-[8px] text-[14px] font-medium leading-[24px] text-text-dark-2">
          {/* TODO the linear gradient `homework-fade` */}
          <ul className="list-inside list-disc pl-[0.25em]">
            {exercises.map((exercise) => (
              <li key={exercise.exerciseId}>{exercise.exerciseTitle}</li>
            ))}
          </ul>
        </div>
      </div>

      {/* Buttons */}
      <div className="col-start-1 col-end-2 row-start-3 row-end-4 pt-[24px] lg:col-start-2 lg:col-end-3 lg:row-start-1 lg:row-end-2 lg:flex lg:justify-end lg:gap-[16px] lg:pt-0">
        {/* <Button
              className="h-[42px] w-[208px] rounded-[7px] bg-green-primary-opacity-01 text-[12px] font-semibold leading-[15px] text-green-primary hover:bg-green-primary-opacity-02 active:bg-green-primary-opacity-02"
              // TODO what should this button do
              onClick={() => {}}
            >
              {_t('Practice')}
            </Button> */}
        <Button
          className="h-[42px] w-full min-w-[208px] rounded-[7px] bg-green-primary text-[12px] font-semibold leading-[15px] text-white hover:bg-green-dark active:bg-green-dark lg:w-auto"
          onClick={() => navigate(solveHomeworkPage)}
        >
          {_t('Solve homework')}
        </Button>
      </div>

      {/* Metadata */}
      <div className="col-start-1 col-end-2 row-start-2 row-end-3 mt-[24px] lg:col-start-2 lg:col-end-3 lg:row-start-2 lg:row-end-3 lg:flex lg:justify-end lg:pb-[6px]">
        <div className="flex flex-col gap-[15px] lg:flex-row lg:items-end lg:gap-[32px]">
          <div className="grid grid-cols-[1fr_150px] gap-[5px] text-[12px] leading-[15px] text-text-shadow lg:flex lg:items-center lg:gap-[12px]">
            <div>{_t('Number of exercises')}</div>
            <div className="flex items-center justify-start gap-[8px]">
              <PaperIcon stroke="#353E45" height="16" width="14" />
              <span className="font-bold text-text-dark">{exercises.length}</span>
            </div>
          </div>
          <div className="lg:translate-y-[6px]">
            <HomeworkRemainingTime assigned={new Date(assigned)} deadline={new Date(deadline)} shouldTranslate={true} />
          </div>
        </div>
      </div>
    </div>
  ) : (
    <div className="h-[170px] rounded-[10px] bg-white p-[16px] lg:flex lg:h-[66px] lg:grid-cols-3 lg:items-center lg:justify-between lg:p-0 lg:pl-[24px] lg:pr-[12px]">
      <div className="flex items-center justify-between border-b border-b-input-outline-shadow pb-[15px] lg:w-[250px] lg:justify-start lg:gap-[12px] lg:border-none lg:pb-0">
        <div className="text-[18px] font-semibold leading-[22px]">{name}</div>
        <HomeworkStatus status="finished" />
      </div>
      <div className="pt-[22px] lg:w-[194px] lg:pt-0">
        <HomeworkResultView successRate={successRate} active={isActive} hasSuccessText={true} />
      </div>
      <Button
        disabled={successRate === undefined}
        className="mt-[11px] h-[42px] w-full min-w-[220px] rounded-[7px] bg-green-primary-opacity-01 hover:bg-green-primary-opacity-02 focus:bg-green-primary-opacity-02 disabled:bg-green-primary-opacity-01 disabled:opacity-[0.4] lg:mt-0 lg:w-auto"
        onClick={() => {
          navigate(checkHomeworkPage, { state: { assignmentId } });
        }}
      >
        <div className="flex items-center justify-between gap-[28px] text-[12px] font-semibold leading-[15px]  text-green-primary">
          <div className="ml-auto mr-auto">{_t('Check homework')}</div>
        </div>
      </Button>
    </div>
  );
}
