import { Center, Stack } from '@mantine/core';
import useApi from 'api/use-api';
import { BUY_PREMIUM_URL } from 'env';
import { _t } from 'lang';
import { useMemo } from 'react';
import { Link } from 'react-router-dom';
import { REGISTER_COURSE_PAGE_PATH } from 'routes/paths';

/**
 * Displays a paywall for a course step.
 */
export default function CourseStepPaywall() {
  const { role, email } = useApi();

  const payUrl = useMemo(() => {
    if (role === 'guest') {
      return REGISTER_COURSE_PAGE_PATH;
    }

    return `${BUY_PREMIUM_URL}?prefilled_email=${encodeURIComponent(email)}`;
  }, [role]);

  return (
    <div className="h-full w-full lg:pb-[66px] lg:pl-[48px] lg:pr-[48px] lg:pt-[66px]">
      <div className="flex h-full w-full items-center justify-center">
        <Stack spacing={24}>
          <h1 className="text-center text-2xl font-bold">This content is premium</h1>
          <p className="text-center text-lg">You need to be a premium member to access this content.</p>
          <Center>
            <Link
              className="ml-auto mr-auto flex h-[56px] w-full max-w-[327px] items-center justify-center rounded-[10px] bg-green-primary text-[18px] font-semibold leading-[22px] text-white hover:bg-green-dark active:bg-green-dark lg:ml-0 lg:mr-0 lg:w-[254px]"
              to={payUrl}
            >
              {_t('Buy Curious Mind')}
            </Link>
          </Center>
          <Center>
            <div className="mt-4 w-[500px]">
              <img src="/img/pencils-notes-phone.svg" alt="Pencils and notes" className="lg:hidden" />
              <img src="/img/pencils-notes-desktop.svg" alt="Pencils and notes" className="hidden w-[500px] lg:block" />
            </div>
          </Center>
        </Stack>
      </div>
    </div>
  );
}
