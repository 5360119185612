import FourDotsIcon from 'components/icons/FourDotsIcon';
import { _t } from 'lang';

/**
 * Loading screen after selecting a classroom.
 */
export default function ClassroomLoader() {
  return (
    <div className="flex min-h-screen w-full items-center bg-white">
      <div className="ml-auto mr-auto flex w-[350px] flex-col items-center justify-center gap-[48px]">
        <FourDotsIcon />
        <div className="text-[24px] font-medium leading-[29px] text-green-primary">
          {_t('Loading another classroom ...')}
        </div>
      </div>
    </div>
  );
}
