import { IExerciseQuestion, IExerciseQuestionId } from 'data/assignment/assignment';

/**
 * Map from question id to validator specific to that question's result type.
 */
export type AnswersMap = Record<IExerciseQuestionId, string | string[] | undefined>;

/**
 * Computes the initial value of a single question.
 */
function computeInitialValue({ answer, resultType }: IExerciseQuestion): string | string[] | undefined {
  if (!answer) {
    return resultType === 'MultiSelect' ? [] : '';
  }

  if (resultType === 'Fraction') {
    return `${answer.numerator}/${answer.denominator}`;
  }

  return answer;
}

/**
 * Creates the initial values for the exercise form.
 */
export default function createInitialValues(questions: IExerciseQuestion[]): AnswersMap {
  return questions.reduce(
    (agg, question) => ({ ...agg, [question.questionId]: computeInitialValue(question) }),
    {} as AnswersMap
  );
}
