import { AlertProps, Alert as MantineAlert } from '@mantine/core';

/**
 * The parameters of the component.
 */
interface Props extends AlertProps {
  type: 'success' | 'error';
}

/**
 * Alert
 */
export default function Alert({ type, className, children, ...otherProps }: Props) {
  const classes =
    type === 'success'
      ? 'border-green-primary-opacity-02 border-l-green-primary bg-green-primary-opacity-005'
      : 'border-red-opacity-02 border-l-red bg-red-opacity-005';

  const childrenColor = type === 'success' ? '#0AB892' : '#E82C2C';

  return (
    <MantineAlert
      className={`rounded-none rounded-r-[5px] border-l-2 border-t ${classes} ${className}`}
      styles={{
        message: { fontSize: '14px', fontWeight: 500, lineHeight: '20px', color: childrenColor },
      }}
      {...otherProps}
    >
      {children}
    </MantineAlert>
  );
}
