import { useDisclosure } from '@mantine/hooks';
import ClassroomPackUnpackIcon from 'components/icons/ClassroomPackUnpackIcon';
import PackUnpackIcon from 'components/icons/PackUnpackIcon';
import ZvedavaMyslIcon from 'components/icons/ZvedavaMyslIcon';
import Clickable from 'components/misc/Clickable';
import { _t } from 'lang';
import { noop } from 'lodash';
import CreateClassroomModal from 'pages/teacher-module/classrooms/modal/CreateClassroomModal';
import { useState } from 'react';
import { Link, NavLink, useParams } from 'react-router-dom';
import { CLASSROOMS_PAGE_PATH, HOME_PAGE_PATH } from 'routes/paths';
import TeacherDesktopDrawer from 'layout/teacher-dashboard-layout/parts/header/TeacherDesktopDrawer';
import useClassrooms from 'data/classrooms/use-classrooms';

/**
 * The phone header of the teachers' pages.
 */
export default function TeacherDesktopHeader() {
  const [opened, { open, close }] = useDisclosure(false);
  const { classroomId } = useParams();
  const { getClassroom } = useClassrooms();
  const classroomName = getClassroom({ classroomId: classroomId! })?.name || _t('Select a classroom');
  const [displayCreateClassroomModal, setDisplayCreateClassroomModal] = useState(false);

  return (
    <div>
      <div className="z-30 hidden h-[90px] w-screen items-center justify-between bg-white pb-[37px] pl-[32px] pr-[40px] pt-[34px] shadow-3xl lg:fixed lg:flex">
        <div>
          <Link className="m-[-10px] block p-[10px]" to={HOME_PAGE_PATH}>
            <ZvedavaMyslIcon />
          </Link>
        </div>
        <div className="flex items-center justify-between gap-[64px]">
          <div className="flex items-center gap-[32px]">
            <div
              onClick={noop}
              className="ml-[-12px] mr-[-12px] cursor-pointer text-[16px] font-medium leading-[19px] text-text-shadow lg:m-[-10px] lg:p-[10px]"
            >
              <NavLink className="block w-full pb-[12px] pl-[12px] pr-[12px] pt-[12px]" to={CLASSROOMS_PAGE_PATH}>
                {({ isActive }) => {
                  const activeStyle =
                    isActive && window.location.pathname === CLASSROOMS_PAGE_PATH ? 'underline text-text-dark' : '';

                  return (
                    <div className="flex items-center gap-[10px]">
                      <PackUnpackIcon />
                      <span className={activeStyle}>{_t('My classrooms')}</span>
                    </div>
                  );
                }}
              </NavLink>
            </div>
          </div>
          <Clickable className="flex items-center gap-[53px]" onClick={open}>
            <span className="text-[24px] font-semibold leading-[29px]">{classroomName}</span>
            <ClassroomPackUnpackIcon />
          </Clickable>
        </div>
      </div>
      <TeacherDesktopDrawer
        opened={opened}
        open={open}
        close={close}
        displayCreateClassroomModal={setDisplayCreateClassroomModal}
        classroomName={classroomName}
      />
      <CreateClassroomModal opened={displayCreateClassroomModal} setOpened={setDisplayCreateClassroomModal} />
    </div>
  );
}
