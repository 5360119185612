import { Tooltip } from '@mantine/core';
import { useClipboard } from '@mantine/hooks';
import CopyIcon from 'components/icons/CopyIcon';
import { _t } from 'lang';

interface Props {
  inviteCode: string;
}

/**
 * Copy invite code from the sidebar.
 */
export default function CopyInviteCode({ inviteCode }: Props) {
  const clipboard = useClipboard({ timeout: 1000 });

  return (
    <div className="flex h-[42px] items-center justify-between rounded-[10px] bg-bg-shadow text-[14px] font-semibold leading-[17px] text-text-dark-2 lg:pl-[12px] lg:pr-[13px]">
      <span>{inviteCode}</span>
      <Tooltip label={clipboard.copied ? _t('Text copied!') : _t('Copy invite code')} withArrow>
        <div onClick={() => clipboard.copy(inviteCode)} className="cursor-pointer">
          <CopyIcon stroke="#0AB892" />
        </div>
      </Tooltip>
    </div>
  );
}
