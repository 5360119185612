import { MantineProvider } from '@mantine/core';
import { ModalsProvider } from '@mantine/modals';
import { NotificationsProvider } from '@mantine/notifications';
import ApiProvider from 'api/api-provider';
import FileManagerProvider from 'api/file-manager';
import ClassroomsProvider from 'data/classrooms/classrooms-provider';
import CourseProvider from 'data/course/course-provider';
import StudentDashboardProvider from 'data/student-dashboard/student-dashboard-provider';
import React from 'react';
import { BrowserRouter } from 'react-router-dom';
import AppRoutes from 'routes/AppRoutes';
import ScrollToTop from 'routes/ScrollToTop';
import theme from 'theme';

/**
 * The entry point of the application.
 */
export default function App() {
  return (
    <React.StrictMode>
      <BrowserRouter>
        <ScrollToTop />
        <MantineProvider withGlobalStyles withNormalizeCSS theme={theme}>
          <NotificationsProvider autoClose={12000}>
            <ModalsProvider>
              <ApiProvider>
                <FileManagerProvider>
                  <CourseProvider>
                    <ClassroomsProvider>
                      <StudentDashboardProvider>
                        <AppRoutes />
                      </StudentDashboardProvider>
                    </ClassroomsProvider>
                  </CourseProvider>
                </FileManagerProvider>
              </ApiProvider>
            </ModalsProvider>
          </NotificationsProvider>
        </MantineProvider>
      </BrowserRouter>
    </React.StrictMode>
  );
}
