import { Accordion, Button, Modal, ScrollArea } from '@mantine/core';
import CrossIcon from 'components/icons/CrossIcon';
import FilterIcon from 'components/icons/FilterIcon';
import RedCrossIcon from 'components/icons/RedCrossIcon';
import TickIcon from 'components/icons/TickIcon';
import Clickable from 'components/misc/Clickable';
import { _t } from 'lang';
import { noop } from 'lodash';
import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import FilterRow from 'components/modals/filter/FilterRow';
import { useMediaQuery, useViewportSize } from '@mantine/hooks';
import { Exercise } from 'api/actions/get-exercises/get-exercises-response';
import getGradeName from 'utils/get-grade-name';

/**
 * Finds unique topics from all exercises.
 */
function getTopics(allExercises: Exercise[]) {
  return Array.from(new Set(allExercises.flatMap((exercise) => exercise.topics))).sort((a, b) => a.localeCompare(b));
}

/**
 * Finds unique grades from all exercises.
 */
function getGrades(allExercises: Exercise[]) {
  return Array.from(new Set(allExercises.flatMap((exercise) => exercise.grades))).sort((a, b) => a - b);
}

interface Props {
  opened: boolean;
  setOpened: Dispatch<SetStateAction<boolean>>;
  // Record<string, { label: string; group: string }>[]
  selectedFilters: string[];
  setSelectedFilters: Dispatch<SetStateAction<string[]>>;
  allExercises: Exercise[];
}

/**
 * The modal that displays the filters.
 *
 * @see https://www.figma.com/file/SjggpqHVlWxBKa2cIG349A/Math-Exercises?node-id=629-6767&t=Fxvlb8iK3Wn6mZDr-0
 */
export default function FiltersModal({ opened, setOpened, selectedFilters, setSelectedFilters, allExercises }: Props) {
  const [filters, setFilters] = useState<string[]>(selectedFilters);
  const [openedFilters, setOpenedFilters] = useState<string[]>(['year', 'syllabus']);
  const [topics, setTopics] = useState<string[]>([]);
  const [grades, setGrades] = useState<number[]>([]);
  const [fill, setFill] = useState('#738491');
  const isMobile = useMediaQuery('(max-width: 1239px)');
  const { height: windowHeight } = useViewportSize();

  useEffect(() => {
    setTopics(getTopics(allExercises));
    setGrades(getGrades(allExercises));
  }, [allExercises]);

  useEffect(() => {
    if (opened) {
      setFilters(selectedFilters);
    }
  }, [opened]);

  return (
    <Modal
      opened={opened}
      onClose={noop} // this is a required prop
      centered
      fullScreen={isMobile}
      size="auto"
      withCloseButton={false}
      radius={10}
      styles={{
        root: {
          padding: '0!important',
          zIndex: 1000,
        },
        body: {
          padding: '0!important',
          height: '100%',
        },
        modal: {
          padding: '0!important',
        },
      }}
    >
      <div className="flex h-full flex-col rounded-[10px] bg-white lg:h-[815px] lg:w-[570px]">
        <div className="h-full">
          <div className="flex h-[74px] items-center justify-between rounded-[10px_10px_0_0] border-b border-b-input-outline-shadow bg-[#FCFCFD] pl-[32px] pr-[28px] lg:h-[90px] lg:pb-[33px] lg:pl-[39px] lg:pr-[35px] lg:pt-[33px]">
            <div className="flex items-center gap-[9px] text-[20px] font-semibold leading-[24px] text-text-dark">
              <FilterIcon stroke="#353E45" />
              {_t('Filter')}
            </div>
            <Clickable
              onClick={() => setOpened(false)}
              className="flex h-[42px] w-[42px] items-center justify-center rounded-[7px] p-[8px] hover:bg-bg-shadow"
              onMouseEnter={() => {
                setFill('#353E45');
              }}
              onMouseLeave={() => {
                setFill('#738491');
              }}
            >
              <CrossIcon fill={fill} />
            </Clickable>
          </div>
          <ScrollArea.Autosize
            // TODO: Do this better
            maxHeight={isMobile ? windowHeight - 143 : 619}
          >
            <div className="min-h-[518px] pl-[16px] pr-[16px] pt-[27px] lg:min-h-[619px] lg:pb-[32px] lg:pl-[64px] lg:pr-[64px] lg:pt-[32px]">
              <Accordion
                multiple={true}
                value={openedFilters}
                onChange={setOpenedFilters}
                styles={{
                  control: {
                    padding: '0!important',
                    height: '40px!important',
                    paddingLeft: '12px!important',
                    paddingRight: '18px!important',
                    display: 'flex!important',
                    alignItems: 'center!important',
                    borderRadius: '7px!important',
                  },
                  label: {
                    color: '#353E45!important',
                    fontsize: '16px!important',
                    fontWeight: 600,
                    lineHeight: '19px!important',
                  },
                  item: {
                    border: 'none',
                  },
                }}
              >
                <Accordion.Item value="year">
                  <Accordion.Control>{_t('Grade')}</Accordion.Control>
                  <Accordion.Panel>
                    <div className="flex flex-col gap-[4px]">
                      {grades.map((grade) => (
                        <FilterRow
                          key={`grade-${grade}`}
                          label={getGradeName(grade)}
                          value={String(grade)}
                          checked={filters.includes(String(grade))}
                          setChecked={setFilters}
                        />
                      ))}
                    </div>
                  </Accordion.Panel>
                </Accordion.Item>
                <Accordion.Item value="syllabus">
                  <Accordion.Control>{_t('Syllabus')}</Accordion.Control>
                  <Accordion.Panel>
                    <div>
                      {topics.map((val) => (
                        <FilterRow key={val} value={val} checked={filters.includes(val)} setChecked={setFilters} />
                      ))}
                    </div>
                  </Accordion.Panel>
                </Accordion.Item>
              </Accordion>
            </div>
          </ScrollArea.Autosize>
        </div>
        <div className="flex h-[74px] items-center justify-between border-t border-t-input-outline-shadow pl-[24px] pr-[24px] lg:h-[106px] lg:pb-[32px] lg:pl-[46px] lg:pr-[24px] lg:pt-[32px]">
          <Button
            className="hidden h-[42px] border border-red bg-white text-red hover:border-red hover:bg-bg-red-menu-item xs:block lg:border-transparent"
            onClick={() => {
              setFilters([]);
              setSelectedFilters([]);
            }}
            radius={7}
          >
            <div className="flex gap-[10px]">
              <RedCrossIcon />
              <div>{_t('Remove all filters')}</div>
            </div>
          </Button>
          <Button
            className="ml-auto h-[42px] rounded-[7px] bg-green-primary xs:ml-0"
            onClick={() => {
              setSelectedFilters(filters);
              setOpened(false);
            }}
          >
            <div className="flex items-center justify-center gap-[9px] text-[14px] font-semibold leading-[17px] text-white ">
              <TickIcon />
              {_t('Apply')}
            </div>
          </Button>
        </div>
      </div>
    </Modal>
  );
}
