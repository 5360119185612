/**
 * Thumbs Up Icon.
 */
export default function ThumbsUpIcon() {
  return (
    <svg width="17" height="14" viewBox="0 0 17 14" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M4.92603 6.18678H6.36354C6.53462 6.18678 6.69383 6.09931 6.78557 5.95491L9.35672 1.90785C9.68655 1.3887 10.2589 1.07422 10.874 1.07422V1.07422C11.4299 1.07422 11.8525 1.57387 11.7603 2.12212L11.143 5.79172H15.4137C15.7336 5.79172 15.9712 6.08802 15.9017 6.40031L14.5369 12.5347C14.486 12.7634 14.2832 12.9261 14.0489 12.9261H7.56278C7.29999 12.9261 7.04775 12.8226 6.86064 12.6381L6.07784 11.8662C5.89072 11.6817 5.63848 11.5782 5.37569 11.5782H4.92603"
        stroke="#353E45"
        strokeWidth="2"
        strokeLinecap="round"
      />
      <path
        d="M1.50012 12.5556H4.72604C4.8365 12.5556 4.92604 12.466 4.92604 12.3556V5.71855C4.92604 5.6081 4.8365 5.51855 4.72604 5.51855H1.50012"
        stroke="#353E45"
        strokeWidth="2"
        strokeLinecap="round"
      />
    </svg>
  );
}
