/**
 * Left-facing chevron.
 */
export default function ChevronLeftIcon() {
  return (
    <svg width="8" height="14" viewBox="0 0 8 14" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M6.6665 12.3333L2.04028 7.70703C1.64975 7.3165 1.64975 6.68334 2.04028 6.29281L6.6665 1.66658"
        stroke="#F3F6F8"
        strokeWidth="2"
        strokeLinecap="round"
      />
    </svg>
  );
}
