import { AxiosInstance } from 'axios';
import { SweetPath } from 'sweet-path';
import dispatchAction from 'api/dispatch-action';

import { SubmitClassroomHomeworkCommentPayload } from 'api/actions/submit-classroom-homework-comment/submit-classroom-homework-comment-payload';
import { SubmitClassroomHomeworkCommentPathParameters } from 'api/actions/submit-classroom-homework-comment/submit-classroom-homework-comment-path-parameters';

import { SubmitClassroomHomeworkCommentResponse } from 'api/actions/submit-classroom-homework-comment/submit-classroom-homework-comment-response';

/**
 * The path of the SubmitClassroomHomeworkComment action.
 */
export const submitClassroomHomeworkCommentActionPath = new SweetPath<
  keyof SubmitClassroomHomeworkCommentPathParameters
>('/classroom/{classroomId}/homework/{homeworkId}/comment', { path: '{param}' });

/**
 * Creates a contextualized SubmitClassroomHomeworkComment actions.
 */
export default function createSubmitClassroomHomeworkCommentAction(axios: AxiosInstance) {
  return function submitClassroomHomeworkCommentAction({
    parameters,
    payload,
  }: {
    parameters: SubmitClassroomHomeworkCommentPathParameters;
    payload: SubmitClassroomHomeworkCommentPayload;
  }) {
    const path = submitClassroomHomeworkCommentActionPath.insert(parameters);

    return dispatchAction(() =>
      axios.post<
        SubmitClassroomHomeworkCommentResponse,
        SubmitClassroomHomeworkCommentResponse,
        SubmitClassroomHomeworkCommentPayload
      >(path, payload)
    );
  };
}
