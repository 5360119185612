import { createContext } from 'react';
import createLoginAction from 'api/actions/login/login-action';
import createRegisterAction from 'api/actions/register/register-action';
import { GetActionMap } from 'api/actions/actions';

/**
 * The role of a user.
 */
export type UserRole = 'teacher' | 'student' | 'guest';

/**
 * Interface of the session stored in local storage.
 */
export interface ISession {
  /**
   * The JWT token.
   */
  jwt: string | null;

  /**
   * The role of the user.
   */
  role: UserRole;

  /**
   * The first name of the user.
   */
  firstName: string;

  /**
   * The last name of the user.
   */
  lastName: string;

  /**
   * The email of the user.
   */
  email: string;
}

/**
 * The session context interface.
 */
interface ApiContext extends ISession {
  /**
   * Whether the session is ready.
   */
  ready: boolean;

  /**
   * The logout action
   */
  logout: () => void;

  /**
   * The login action.
   */
  login: ReturnType<typeof createLoginAction>;

  /**
   * The register action.
   */
  register: ReturnType<typeof createRegisterAction>;

  /**
   * Returns the action with the given operationId.
   */
  getAction: GetActionMap;
}

/**
 * The session context is used to store the current session.
 *
 * The session context will be provided in <App>.
 */
export default createContext<ApiContext>(undefined!);
