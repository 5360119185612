interface Props {
  width?: number;
  height?: number;
  active: boolean;
}

/**
 * Video icon.
 */
export default function VideoIcon({ width = 12, height = 12, active }: Props) {
  return (
    <svg width={width} height={height} viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect width="12" height="12" rx="6" fill={active ? '#353E45' : '#738491'} />
      <path
        d="M4.68262 3.066C4.38002 2.87965 4 3.11008 4 3.47992V8.52008C4 8.88992 4.38002 9.12035 4.68262 8.934L8.7748 6.41392C9.07507 6.22901 9.07507 5.77099 8.7748 5.58608L4.68262 3.066Z"
        fill="white"
      />
    </svg>
  );
}
