import PlayIcon from 'components/icons/PlayIcon';
import { _t } from 'lang';
import PersonView from 'components/webinars/PersonView';
import WhiteStarIcon from 'components/icons/WhiteStarIcon';
import { isArray } from 'lodash';

/**
 * The props of the Webinar Card.
 */
interface Props {
  isInPast: boolean;
  name: string;
  description: string | JSX.Element;
  lecturers: Array<{ name: string; avatar?: string }>;
  recordingUrl?: string;
  onDay?: string;
  startsAt?: string;
  duration?: string;
  webinarUrl?: string;
  subscribeUrl?: string;
  exercisesUrl?: string | string[];
}

/**
 * The webinar card.
 */
export default function WebinarCard({
  isInPast,
  name,
  description,
  lecturers,
  recordingUrl,
  onDay,
  startsAt,
  duration,
  webinarUrl,
  subscribeUrl,
  exercisesUrl,
}: Props) {
  return (
    <div className="ml-auto mr-auto flex w-full max-w-[360px] flex-col rounded-[10px] bg-background-user p-[24px] shadow-webinar-card-shadow md:max-w-[570px] md:p-[40px]">
      <PersonView persons={lecturers} className="md:hidden" />
      <PersonView persons={lecturers} className="hidden md:grid" avatarSize={80} />
      <div className="pt-[16px]">
        <div className="text-[18px] font-semibold leading-[28px] md:text-[24px] md:leading-[34px]">{name}</div>
        <div className="pt-[8px] text-[14px] leading-[24px] text-text-dark">
          <div>{description}</div>
        </div>
        <div className="mt-[16px] rounded-[7px] bg-bg-shadow pb-[12px] pl-[16px] pr-[16px] pt-[12px] md:mt-[24px]">
          <div className="flex gap-x-[16px] text-[15px] font-semibold leading-[22px] text-text-dark md:gap-x-[48px]">
            <div>
              <div className="text-[12px] font-normal leading-[15px] text-text-dark-2">{_t('Happens on')}</div>
              <div className="mt-[4px]">{onDay}</div>
            </div>
            <div>
              <div className="text-[12px] font-normal leading-[15px] text-text-dark-2">{_t('Starts')}</div>
              <div className="mt-[4px]">{startsAt}</div>
            </div>
            <div>
              <div className="text-[12px] font-normal leading-[15px] text-text-dark-2">{_t('Duration')}</div>
              <div className="mt-[4px]">{duration}</div>
            </div>
          </div>
        </div>
        <div className="pl-[16px] pt-[20px]">
          <p className="text-[12px] leading-[15px] text-text-dark-2">
            {isInPast ? _t('Link to recording') : _t('Link to webinar')}
          </p>
          <a
            rel="external noreferrer"
            target="_blank"
            href={webinarUrl!}
            className="text-[15px] font-medium leading-[22px] text-green-primary underline md:text-[16px] md:leading-[24px]"
            style={{ overflowWrap: 'break-word' }}
          >
            {(isInPast ? recordingUrl : webinarUrl)?.replace(/https:\/\/(www\.)?/, '')}
          </a>
        </div>
        {exercisesUrl && (
          <div className="pl-[16px] pt-[20px]">
            <p className="text-[12px] leading-[15px] text-text-dark-2">{_t('Link to exercises')}</p>
            {(isArray(exercisesUrl) ? exercisesUrl : [exercisesUrl]).map((exerciseUrl, i) => (
              <div className={i > 0 ? 'mt-[3px]' : ''}>
                <a
                  rel="external noreferrer"
                  target="_blank"
                  href={exerciseUrl}
                  className="text-[15px] font-medium leading-[22px] text-green-primary underline md:text-[16px] md:leading-[24px]"
                >
                  {exerciseUrl?.replace(/https:\/\/(www\.)?/, '')}
                </a>
              </div>
            ))}
          </div>
        )}
      </div>
      <div>
        {isInPast ? (
          recordingUrl && (
            <a
              rel="external noreferrer"
              target="_blank"
              href={recordingUrl!}
              className="ml-auto mr-auto mt-[36px] flex h-[42px] w-full max-w-[279px] items-center justify-center rounded-[7px] bg-green-primary-opacity-01 hover:bg-green-primary-opacity-02 active:bg-green-primary-opacity-02 md:m-0 md:mt-[36px] md:w-[220px]"
            >
              <div className="flex items-center gap-[10px]">
                <PlayIcon />
                <span className="text-[14px] font-semibold leading-[17px] text-green-primary">
                  {_t('Watch recording')}
                </span>
              </div>
            </a>
          )
        ) : (
          <a
            rel="external noreferrer"
            target="_blank"
            href={subscribeUrl!}
            className="ml-auto mr-auto mt-[36px] flex h-[42px] w-full max-w-[279px] items-center justify-center rounded-[7px] bg-green-primary hover:bg-green-dark active:bg-green-dark md:ml-0 md:mr-0 md:w-[220px]"
          >
            <div className="flex items-center gap-[9px]">
              <WhiteStarIcon />
              <span className="text-[14px] font-semibold leading-[17px] text-white">{_t('I am interested')}</span>
            </div>
          </a>
        )}
      </div>
    </div>
  );
}
