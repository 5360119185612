import { Button, Menu, Skeleton } from '@mantine/core';
import useApi from 'api/use-api';
import Avatar from 'components/Avatar';
import ArrowDownIcon from 'components/icons/ArrowDownIcon';
import LockIcon from 'components/icons/LockIcon';
import LogoutIcon from 'components/icons/LogoutIcon';
import FileIcon from 'components/icons/FileIcon';
import ZvedavaMyslIcon from 'components/icons/ZvedavaMyslIcon';
import UserDetail from 'layout/parts/header/UserDetail';
import { _t, lang } from 'lang';
import { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import {
  CHANGE_PASSWORD_PAGE_PATH,
  CLASSROOMS_PAGE_PATH,
  CONTACT_PAGE_PATH,
  EXERCISES_PAGE_PATH,
  HOME_PAGE_PATH,
  LOGIN_PAGE_PATH,
  REGISTER_PAGE_PATH,
  STUDENT_DASHBOARD_PAGE_PATH,
  WEBINARS_PAGE_PATH,
  WHO_WE_ARE_PAGE_PATH,
} from 'routes/paths';
import ContinueCourseLink from 'layout/parts/header/ContinueCourseLink';
import HeaderMenuLink from 'layout/parts/header/HeaderMenuLink';
import { FEATURE_TOGGLE_WEBINARS_PAGE } from 'env';
import PlannedWebinarsDot from 'layout/parts/header/PlannedWebinarsDot';

/**
 * Desktop header.
 */
export default function DesktopHeader() {
  const navigate = useNavigate();
  const [lockIconStroke, setLockIconStroke] = useState<string>('#738491');
  const [fileIconStroke, setFileIconStroke] = useState<string>('#738491');
  const [logoutIconStroke, setLogoutIconStroke] = useState<string>('#738491');

  const { firstName, lastName, email, ready, role, logout } = useApi();

  return (
    <div className="z-30 hidden h-[90px] w-screen items-center justify-between bg-white pb-[37px] pl-[24px] pr-[24px] pt-[34px] shadow-3xl lg:fixed lg:flex xl:pl-[32px] xl:pr-[40px]">
      <div>
        <Link className="m-[-10px] block p-[10px]" to={HOME_PAGE_PATH}>
          <ZvedavaMyslIcon />
        </Link>
      </div>
      <div className="flex items-center justify-between gap-[16px] xl:gap-[52px]">
        <div className="flex items-baseline gap-[8px] pt-[10px]">
          <HeaderMenuLink
            to={EXERCISES_PAGE_PATH}
            text={(isActive) => (
              <div className="inline-block font-medium text-text-dark">
                <span className={isActive ? 'underline' : ''}>{_t('Exercises book')}</span>
                <span> 📙</span>
              </div>
            )}
          />
          {lang === 'cs' && <ContinueCourseLink className="m-[-10px] p-[10px] pr-[22px]" />}
          {FEATURE_TOGGLE_WEBINARS_PAGE && (
            <HeaderMenuLink
              to={WEBINARS_PAGE_PATH}
              text={(isActive) => (
                <div className="inline-block">
                  <span className={isActive ? 'underline' : ''}>{_t('Webinars')}</span>
                  <PlannedWebinarsDot />
                </div>
              )}
            />
          )}
          <HeaderMenuLink to={WHO_WE_ARE_PAGE_PATH} text={_t('Who we are')} />
          <HeaderMenuLink to={CONTACT_PAGE_PATH} text={_t('Contact')} />
        </div>
        {role !== 'guest' ? (
          <Menu
            width={295}
            position="bottom-end"
            offset={40}
            styles={{
              item: {
                '&:hover': { backgroundColor: '#F3F6F8', color: '#0AB892', borderRadius: '10px' },
              },
              dropdown: {
                boxShadow: '0px 4px 14px rgba(22, 26, 29, 0.07)',
                borderRadius: '10px',
                paddingLeft: '8px!important',
                paddingTop: '8px!important',
                paddingRight: '8px!important',
                paddingBottom: '8px!important',
              },
              divider: {
                borderTopColor: '#D9E0E6',
                marginTop: '8px!important',
                marginBottom: '8px!important',
              },
              itemIcon: {
                marginRight: '0',
              },
            }}
          >
            <Menu.Target>
              <div className="flex cursor-pointer items-center">
                {!ready ? <Skeleton height={42} circle /> : <Avatar label={`${firstName} ${lastName}`} size={42} />}
                <div className="pb-[7px] pl-[10px] pr-[10px] pt-[7px]">
                  <ArrowDownIcon />
                </div>
              </div>
            </Menu.Target>
            <Menu.Dropdown className="border-0">
              <Menu.Item className="pb-[16px] pl-[16px] pr-[16px] pt-[16px]">
                {!ready ? (
                  <div className="flex items-center gap-[16px]">
                    <Skeleton height={42} circle />
                    <div className="flex w-[80%] flex-col gap-[5px]">
                      <Skeleton height={8} radius="xl" width="40%" />
                      <Skeleton height={8} radius="xl" width="40%" />
                    </div>
                  </div>
                ) : (
                  <div>
                    <UserDetail name={`${firstName} ${lastName}`} email={email} />
                  </div>
                )}
              </Menu.Item>
              <Menu.Divider />
              <Menu.Item
                className="pb-[15px] pl-[22px] pr-[22px] pt-[14px] text-[16px] font-medium text-text-shadow"
                icon={<FileIcon className="mr-[16px]" stroke={fileIconStroke} />}
                component={Link}
                to={role === 'student' ? STUDENT_DASHBOARD_PAGE_PATH : CLASSROOMS_PAGE_PATH}
                onMouseEnter={() => setFileIconStroke('#0AB892')}
                onMouseLeave={() => setFileIconStroke('#738491')}
              >
                {role === 'student' ? _t('Student dashboard') : _t('Teacher dashboard')}
              </Menu.Item>
              <Menu.Divider />
              <Menu.Item
                className="pb-[15px] pl-[22px] pr-[22px] pt-[14px] text-[16px] font-medium text-text-shadow"
                icon={<LockIcon className="mr-[18px]" stroke={lockIconStroke} />}
                component={Link}
                to={CHANGE_PASSWORD_PAGE_PATH}
                onMouseEnter={() => setLockIconStroke('#0AB892')}
                onMouseLeave={() => setLockIconStroke('#738491')}
              >
                {_t('Change password')}
              </Menu.Item>
              <Menu.Item
                className="pb-[15px] pl-[22px] pr-[22px] pt-[14px] text-[16px] font-medium text-text-shadow"
                onClick={logout}
                icon={<LogoutIcon className="mr-[14px]" stroke={logoutIconStroke} />}
                onMouseEnter={() => setLogoutIconStroke('#0AB892')}
                onMouseLeave={() => setLogoutIconStroke('#738491')}
              >
                {_t('Log out')}
              </Menu.Item>
            </Menu.Dropdown>
          </Menu>
        ) : (
          <div className="flex gap-[8px]">
            <Button
              radius={7}
              className="active:bg-green-primary-opacity-02r h-[42px] w-[117px] bg-green-primary-opacity-01 px-0 text-[16px] font-semibold leading-[19px] text-green-primary hover:bg-green-primary-opacity-02 xl:w-[147px]"
              onClick={() => navigate(LOGIN_PAGE_PATH)}
            >
              {_t('Log in')}
            </Button>
            <Button
              radius={7}
              className="h-[42px] w-[125px] bg-green-primary px-0 text-[16px] font-semibold leading-[19px] hover:bg-green-dark active:bg-green-dark xl:w-[159px]"
              onClick={() => navigate(REGISTER_PAGE_PATH)}
            >
              {_t('Register')}
            </Button>
          </div>
        )}
      </div>
    </div>
  );
}
