/**
 * FAQ Icon
 */
export default function FAQIcon() {
  return (
    <div className="flex h-[40px] w-[40px] items-center justify-center rounded-[20px] bg-text-dark text-[16px] font-semibold leading-[20px] text-white">
      ?
    </div>
  );
}
