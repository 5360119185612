import { Skeleton } from '@mantine/core';
import useApi from 'api/use-api';
import UserDetail from 'layout/parts/header/UserDetail';
import { _t, lang } from 'lang';
import {
  CHANGE_PASSWORD_PAGE_PATH,
  CONTACT_PAGE_PATH,
  EXERCISES_PAGE_PATH,
  LOGIN_PAGE_PATH,
  REGISTER_PAGE_PATH,
  WEBINARS_PAGE_PATH,
  WHO_WE_ARE_PAGE_PATH,
} from 'routes/paths';
import ContinueCourseLink from 'layout/parts/header/ContinueCourseLink';
import { useEffect, useState } from 'react';
import HeaderMenuLink from 'layout/parts/header/HeaderMenuLink';
import ArrowDownIcon from 'components/icons/ArrowDownIcon';
import ArrowUpIcon from 'components/icons/ArrowUpIcon';
import FileIcon from 'components/icons/FileIcon';
import LockIcon from 'components/icons/LockIcon';
import LogoutIcon from 'components/icons/LogoutIcon';
import PhoneMenuLink from 'layout/parts/header/PhoneMenuLink';
import { FEATURE_TOGGLE_WEBINARS_PAGE } from 'env';
import PlannedWebinarsDot from 'layout/parts/header/PlannedWebinarsDot';

interface Props {
  setDisplayMenu: (display: boolean) => void;
  className?: string;
}

/**
 * The phone header menu
 */
export default function PhoneMenu({ setDisplayMenu, className = '' }: Props) {
  const { firstName, lastName, email, ready, role, logout } = useApi();
  const [isDisplayDropdown, setDisplayDropdown] = useState<boolean>(false);
  const [lockIconStroke, setLockIconStroke] = useState<string>('#738491');
  const [fileIconStroke, setFileIconStroke] = useState<string>('#738491');
  const [logoutIconStroke, setLogoutIconStroke] = useState<string>('#738491');

  /**
   * Function to hide the menu.
   */
  const hideMenu = () => setDisplayMenu(false);

  // Set the overflow to hidden when the component is mounted.
  useEffect(() => {
    document.body.style.overflow = 'hidden';
  }, []);

  // Remove the overflow hidden when the component unmounts.
  useEffect(
    () => () => {
      document.body.style.overflow = 'auto';
    },
    []
  );

  return (
    <div className={`fixed top-[66px] z-[100] h-[calc(100vh-66px)] w-full bg-white ${className}`}>
      {role !== 'guest' ? (
        <div className="flex flex-col border-b border-t border-b-input-outline-shadow border-t-input-outline-shadow">
          <div className="flex w-full cursor-pointer items-center">
            {!ready ? (
              <div className={`pl-[40px] pr-[37px] pt-[35px] ${isDisplayDropdown ? 'pb-[18px]' : 'pb-[32px]'}`}>
                <Skeleton height={64} circle />
              </div>
            ) : (
              <div
                className={`w-full gap-[40px] pl-[40px] pr-[27px] pt-[32px] ${
                  isDisplayDropdown ? 'pb-[18px]' : 'pb-[32px]'
                }`}
              >
                <div
                  className="flex cursor-pointer items-center justify-between"
                  onClick={() => setDisplayDropdown((isDisplayDropdown) => !isDisplayDropdown)}
                >
                  <UserDetail name={`${firstName} ${lastName}`} email={email} />
                  <div className="p-[10px]">{isDisplayDropdown ? <ArrowUpIcon /> : <ArrowDownIcon />}</div>
                </div>
              </div>
            )}
          </div>
          {isDisplayDropdown && (
            <div className="flex flex-col pb-[19px] pl-[20px] pr-[20px] text-[16px] font-medium leading-[19px] text-text-shadow">
              <div onMouseEnter={() => setFileIconStroke('#0AB892')} onMouseLeave={() => setFileIconStroke('#738491')}>
                <PhoneMenuLink
                  to="/course/priprava-na-prijimacky"
                  linkClass="block pb-[16px] pl-[26px] pt-[16px] active:rounded-[10px] active:bg-bg-shadow active:text-green-primary"
                  hideMenu={hideMenu}
                >
                  <div className="flex flex-row items-center">
                    <FileIcon className="mr-[16px]" stroke={fileIconStroke} />
                    <span>{_t('Continue Learning')}</span>
                  </div>
                </PhoneMenuLink>
              </div>
              <div onMouseEnter={() => setLockIconStroke('#0AB892')} onMouseLeave={() => setLockIconStroke('#738491')}>
                <PhoneMenuLink
                  to={CHANGE_PASSWORD_PAGE_PATH}
                  linkClass="block pb-[16px] pl-[26px] pt-[16px] active:rounded-[10px] active:bg-bg-shadow active:text-green-primary"
                  hideMenu={hideMenu}
                >
                  <div className="flex flex-row items-center">
                    <LockIcon className="mr-[18px]" stroke={lockIconStroke} />
                    <span>{_t('Change password')}</span>
                  </div>
                </PhoneMenuLink>
              </div>
              <div
                onMouseEnter={() => setLogoutIconStroke('#0AB892')}
                onMouseLeave={() => setLogoutIconStroke('#738491')}
                className="cursor-pointer pb-[16px] pl-[26px] pt-[16px] active:rounded-[10px] active:bg-bg-shadow"
                onClick={logout}
              >
                <div className="flex flex-row items-center active:text-green-primary">
                  <LogoutIcon className="mr-[14px]" stroke={logoutIconStroke} />
                  <span>{_t('Log out')}</span>
                </div>
              </div>
            </div>
          )}
        </div>
      ) : (
        <div className="flex flex-col gap-[8px] border-b border-t border-b-input-outline-shadow border-t-input-outline-shadow pb-[32px] pl-[40px] pr-[40px] pt-[32px]">
          <PhoneMenuLink
            to={LOGIN_PAGE_PATH}
            className="ml-[-12px] mr-[-12px] mt-[-12px] text-[16px] font-semibold leading-[19px] text-green-primary"
            linkClass="block w-full pt-[12px] pb-[12px] pl-[12px] pr-[12px]"
            hideMenu={hideMenu}
          >
            <span>{_t('Log in')}</span>
          </PhoneMenuLink>
          <PhoneMenuLink
            to={REGISTER_PAGE_PATH}
            className="mb-[-12px] ml-[-12px] mr-[-12px] text-[16px] font-semibold leading-[19px] text-green-primary"
            linkClass="block w-full pt-[12px] pb-[12px] pl-[12px] pr-[12px]"
            hideMenu={hideMenu}
          >
            <span>{_t('Register')}</span>
          </PhoneMenuLink>
        </div>
      )}
      <div className="flex flex-col gap-[8px] pb-[32px] pl-[40px] pr-[40px] pt-[32px]">
        <div className="ml-[-12px] mr-[-12px] mt-[-12px] cursor-pointer" onClick={hideMenu}>
          {lang === 'cs' && <ContinueCourseLink className="block w-full pb-[12px] pl-[12px] pr-[12px] pt-[12px]" />}
        </div>
        <HeaderMenuLink
          to={EXERCISES_PAGE_PATH}
          text={(isActive) => (
            <div className="inline-block">
              <span className={isActive ? 'underline' : ''}>{_t('Exercises book')}</span>
              <span> 📙</span>
            </div>
          )}
          hideMenu={hideMenu}
        />
        {FEATURE_TOGGLE_WEBINARS_PAGE && (
          <HeaderMenuLink
            to={WEBINARS_PAGE_PATH}
            text={(isActive) => (
              <div className="inline-block">
                <span className={isActive ? 'underline' : ''}>{_t('Webinars')}</span>
                <PlannedWebinarsDot />
              </div>
            )}
          />
        )}
        <HeaderMenuLink to={WHO_WE_ARE_PAGE_PATH} text={_t('Who we are')} hideMenu={hideMenu} />
        <HeaderMenuLink to={CONTACT_PAGE_PATH} text={_t('Contact')} hideMenu={hideMenu} />
      </div>
    </div>
  );
}
