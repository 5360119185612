/**
 * Eye icon off
 */
export default function EyeIconOff() {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M20.4 22.4L16.2 18.275C15.6167 18.5083 14.9583 18.6875 14.225 18.8125C13.4917 18.9375 12.75 19 12 19C9.56667 19 7.35833 18.3208 5.375 16.9625C3.39167 15.6042 1.93333 13.7833 1 11.5C1.33333 10.6333 1.79583 9.7875 2.3875 8.9625C2.97917 8.1375 3.7 7.35 4.55 6.6L1.4 3.45L2.45 2.375L21.375 21.3L20.4 22.4ZM12 15.75C12.2333 15.75 12.4833 15.7292 12.75 15.6875C13.0167 15.6458 13.2417 15.5833 13.425 15.5L8 10.075C7.91667 10.275 7.85417 10.5 7.8125 10.75C7.77083 11 7.75 11.25 7.75 11.5C7.75 12.7 8.16667 13.7083 9 14.525C9.83333 15.3417 10.8333 15.75 12 15.75ZM18.95 16.75L15.725 13.525C15.8917 13.2583 16.0208 12.9458 16.1125 12.5875C16.2042 12.2292 16.25 11.8667 16.25 11.5C16.25 10.3167 15.8375 9.3125 15.0125 8.4875C14.1875 7.6625 13.1833 7.25 12 7.25C11.6333 7.25 11.275 7.29167 10.925 7.375C10.575 7.45833 10.2583 7.59167 9.975 7.775L7.225 5C7.80833 4.73333 8.55417 4.5 9.4625 4.3C10.3708 4.1 11.2583 4 12.125 4C14.5083 4 16.6875 4.67917 18.6625 6.0375C20.6375 7.39583 22.0833 9.21667 23 11.5C22.5667 12.5667 22.0083 13.5417 21.325 14.425C20.6417 15.3083 19.85 16.0833 18.95 16.75ZM14.625 12.425L11.075 8.875C11.5583 8.69167 12.0583 8.65417 12.575 8.7625C13.0917 8.87083 13.5417 9.10833 13.925 9.475C14.3083 9.85833 14.575 10.2875 14.725 10.7625C14.875 11.2375 14.8417 11.7917 14.625 12.425Z"
        fill="#4C5863"
      />
    </svg>
  );
}
