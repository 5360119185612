import { Loader } from '@mantine/core';
import useClassrooms from 'data/classrooms/use-classrooms';
import NoClassrooms from 'pages/teacher-module/classrooms/content/NoClassrooms';
import ClassroomRow from 'pages/teacher-module/classrooms/content/ClassroomRow';

/**
 * List which displays all classrooms of the teacher.
 */
export function ClassroomsList() {
  const { getClassrooms, loading } = useClassrooms();
  const classrooms = getClassrooms();

  if (loading) {
    return <Loader className="mx-auto mt-[80px]" color="green" type="dots" />;
  }

  if (classrooms.length === 0) {
    return <NoClassrooms />;
  }

  return (
    <div className="flex flex-col items-center gap-[8px] lg:pt-[38px]">
      {classrooms.map((cls) => (
        <ClassroomRow
          key={cls.classroomId}
          classroomId={cls.classroomId}
          name={cls.name}
          numberOfStudents={cls.numberOfStudents}
          activeHomework={cls.activeHomework}
        />
      ))}
    </div>
  );
}
