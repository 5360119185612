import { Button, Loader, Modal } from '@mantine/core';
import { GetExercisesResponse } from 'api/actions/get-exercises/get-exercises-response';
import useApi from 'api/use-api';
import FilterIcon from 'components/icons/FilterIcon';
import Clickable from 'components/misc/Clickable';
import { _t, lang } from 'lang';
import BasicPageLayout from 'layout/basic-page-layout/BasicPageLayout';
import { intersection, isEmpty, sortBy } from 'lodash';
import FilterButton from 'components/buttons/FilterButton';
import { useEffect, useState } from 'react';
import FiltersModal from 'components/modals/filter/FiltersModal';
import StudentExerciseCard from 'components/StudentExerciseCard';
import { useNavigate, useParams } from 'react-router-dom';
import Assignment from 'components/assignment/Assignment';
import ArrowLeftIcon from 'components/icons/ArrowLeftIcon';

/**
 * Page where the user can try out different exercises.
 */
export default function ExercisesPage() {
  const navigate = useNavigate();

  const { getAction } = useApi();
  const getExercisesAction = getAction('GetExercises');
  const createAssignmentAction = getAction('CreateAssignment');

  const { exerciseId } = useParams();
  const [assignmentId, setAssignmentId] = useState<string | undefined>(undefined);

  // exercises
  const [allExercises, setAllExercises] = useState<GetExercisesResponse['exercises']>();
  const exercise = allExercises?.find((exercise) => exercise.exerciseId === Number(exerciseId));

  // filters
  const [selectedFilters, setSelectedFilters] = useState<string[]>([]);

  // modals
  const [displayFiltersModal, setDisplayFiltersModal] = useState<boolean>(false);
  const [animation, setAnimation] = useState<null | string>(null);

  // Apply filters.
  let filteredExercises = allExercises || [];
  if (!isEmpty(selectedFilters) && allExercises) {
    filteredExercises = allExercises.filter((exercise) => {
      const { topics } = exercise;
      const grades = exercise.grades.map((grade) => String(grade));

      return intersection(topics, selectedFilters).length > 0 || intersection(grades, selectedFilters).length > 0;
    });
  }

  // Fetch exercises.
  useEffect(() => {
    getExercisesAction({ query: { language: lang } }).success(({ exercises }) =>
      setAllExercises(sortBy(exercises, 'exerciseId'))
    );
  }, []);

  // Generate assignment.
  useEffect(() => {
    if (exerciseId) {
      createAssignmentAction({
        payload: { exerciseSet: { language: lang, exercises: [{ exerciseId: Number(exerciseId), count: 1 }] } },
      }).success(({ assignmentId }) => setAssignmentId(assignmentId));
    }
  }, [exerciseId]);

  // Unset assignment id when navigating away.
  useEffect(() => {
    if (!exerciseId) {
      setAssignmentId(undefined);
    }
  }, [exerciseId]);

  const hideIfDetail = exerciseId ? 'hidden' : '';
  const hideIfList = exerciseId ? '' : 'hidden';
  const loader = <Loader className="mx-auto" variant="oval" color="green" />;

  return (
    <BasicPageLayout hasHeader hasFooter>
      <div className="min-h-[calc(100vh-90px)] bg-bg-teacher">
        <div className="max-w-[1568px] lg:ml-auto lg:mr-auto">
          <div className="p-[24px] pb-[64px] lg:pl-[64px] lg:pr-[64px] lg:pt-[39px]">
            <div className="text-[24px] font-semibold leading-[29px]">
              {exerciseId ? (
                <div className="flex items-center gap-[16px]">
                  <div className="flex items-center gap-[9px]">
                    <ArrowLeftIcon />
                    <span
                      onClick={() => navigate(-1)}
                      className="cursor-pointer text-[14px] font-medium leading-[17px] text-green-primary"
                    >
                      {_t('Exercises book')}&nbsp;📙
                    </span>
                  </div>
                  <div className="h-[28px] border-r border-r-input-outline-shadow" />
                  <div className="text-[14px] leading-[17px] text-text-dark-2">{exercise?.title}</div>
                </div>
              ) : (
                <>{_t('Exercises book')}&nbsp;📙</>
              )}
            </div>
            <div className={hideIfDetail}>
              <div className="pt-[32px]">
                <div className="flex flex-wrap items-stretch gap-[8px]">
                  <div className="flex items-stretch">
                    <Button
                      className="h-[42px] rounded-[7px] border border-input-outline-shadow bg-white text-green-primary"
                      onClick={() => setDisplayFiltersModal(true)}
                    >
                      <div className="flex items-center gap-[8px] text-[14px] font-semibold leading-[17px]">
                        <FilterIcon />
                        {_t('Filter')}
                      </div>
                    </Button>
                  </div>
                  {selectedFilters.map((filter) => (
                    <div className="flex items-center" key={filter}>
                      <FilterButton
                        filter={filter}
                        removeFilter={() => setSelectedFilters((prev) => prev.filter((p) => p !== filter))}
                      />
                    </div>
                  ))}
                  <div className="flex items-center">
                    {selectedFilters.length > 0 && (
                      <Clickable
                        className="text-[14px] font-medium leading-[17px] text-text-dark underline"
                        onClick={() => setSelectedFilters([])}
                      >
                        {_t('delete all filters')}
                      </Clickable>
                    )}
                  </div>
                </div>
              </div>
              <div className="pt-[20px]">
                {selectedFilters.length === 0 && (
                  <div className="mb-[16px] grid grid-cols-[100%] gap-[16px] sm:grid-cols-[repeat(auto-fill,minmax(294px,1fr))]">
                    <StudentExerciseCard
                      exerciseId={1000001}
                      title={_t('Shortest path')}
                      description={
                        _t('Learn what a shortest path is and how to find it by exploring flights between cities in Europe.') // prettier-ignore
                      }
                      type={_t('⭐ Mini game')}
                      onButtonClick={() => setAnimation('shortest%20path%20II/game.html')}
                    />
                    <StudentExerciseCard
                      exerciseId={1000002}
                      title={_t('Binary search tree')}
                      description={_t('Learn how to find a number in a sorted list by exploring a binary search tree.')}
                      type={_t('⭐ Mini game')}
                      onButtonClick={() => setAnimation('binary%20search%20tree/BST.html')}
                    />
                    <StudentExerciseCard
                      exerciseId={1000003}
                      title={_t('SpaceX rocket launch')}
                      description={_t('Analyze the SpaceX rocket launch by computing multiple measures from frames of the video.')} // prettier-ignore
                      type={_t('⭐ Mini game')}
                      onButtonClick={() => setAnimation('spacex/index.html')}
                      previewSuffix="jpg"
                    />
                    <StudentExerciseCard
                      exerciseId={1000004}
                      title={_t('Bitbox')}
                      description={_t('Learn how to convert numbers between decimal and binary representation in a game where you have to set the bits of a number to match the decimal representation.')} // prettier-ignore
                      type={_t('⭐ Mini game')}
                      onButtonClick={() => setAnimation('bitbox/index.html')}
                    />
                  </div>
                )}
                <div className="grid grid-cols-[100%] gap-[16px] sm:grid-cols-[repeat(auto-fill,minmax(294px,1fr))]">
                  {filteredExercises?.map((exercise) => {
                    return (
                      <StudentExerciseCard
                        key={exercise.exerciseId}
                        exerciseId={exercise.exerciseId}
                        title={exercise.title}
                        description={exercise.description}
                        type={exercise.exerciseType === 'Numerical' ? _t('Numerical problem') : _t('Word problem')}
                        onButtonClick={() => navigate(`/exercises/exercise/${exercise.exerciseId}`)}
                      />
                    );
                  })}
                </div>
              </div>
            </div>
            <div
              className={`mx-auto mt-[32px] rounded-[10px] bg-white p-[24px] lg:pb-[48px] lg:pt-[48px] ${hideIfList}`}
            >
              <div className="mx-auto max-w-[570px]">
                {assignmentId ? <Assignment assignmentId={assignmentId} loader={loader} /> : loader}
              </div>
            </div>
            <FiltersModal
              opened={displayFiltersModal}
              setOpened={setDisplayFiltersModal}
              selectedFilters={selectedFilters}
              setSelectedFilters={setSelectedFilters}
              allExercises={allExercises || []}
            />
            <Modal
              title={_t('⭐ Mini game')}
              opened={animation != null}
              onClose={() => setAnimation(null)}
              styles={{ body: { width: '85vw', height: '80vh' } }}
              size="auto"
            >
              <iframe
                style={{ width: '100%', height: '100%' }}
                title="Animated"
                width={1920}
                height={1080}
                src={`https://dev.zvedavamysl.cz/algo-games/${animation}`}
              ></iframe>
            </Modal>
          </div>
        </div>
      </div>
    </BasicPageLayout>
  );
}
