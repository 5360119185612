import BookIcon from 'components/icons/BookIcon';
import HomeIcon from 'components/icons/HomeIcon';
import InfoIcon from 'components/icons/InfoIcon';
import PaperIcon from 'components/icons/PaperIcon';
import StudentIcon from 'components/icons/StudentIcon';
import useClassrooms from 'data/classrooms/use-classrooms';
import { _t, _tx } from 'lang';
import TeacherNavigationItem from 'layout/teacher-dashboard-layout/parts/sidebar/TeacherNavigationItem';
import TeacherMenu from 'layout/teacher-dashboard-layout/parts/sidebar/TeacherMenu';
import { useParams } from 'react-router-dom';
import CopyInviteCode from 'layout/teacher-dashboard-layout/parts/sidebar/CopyInviteCode';
import sprintf from 'lang/sprintf';
import { useState } from 'react';
import Clickable from 'components/misc/Clickable';
import NumberInCircle from 'components/NumberInCircle';

/**
 * TeacherSidebar
 */
export default function TeacherSidebar() {
  const { classroomId } = useParams();
  const { getClassroom } = useClassrooms();
  const classroom = getClassroom({ classroomId: classroomId! });
  const [displayInvite, setDisplayInvite] = useState(false);

  const numberOfStudents = classroom?.numberOfStudents ?? 0;
  const activeHomeworkLength = classroom?.activeHomework.length ?? 0;

  return (
    <div className="fixed flex flex-col bg-white shadow-3xl lg:min-h-screen lg:w-[300px] lg:p-[16px] xl:w-[358px] xl:p-[32px]">
      <div className="flex flex-col gap-[8px]">
        <TeacherNavigationItem
          name={_t('Home')}
          icon={<HomeIcon stroke="#535F6A" />}
          activeIcon={<HomeIcon stroke="#353E45" />}
          to={`/teacher/classrooms/classroom/${classroomId}/detail`}
        />
        <TeacherNavigationItem
          // so that the czechs can have correct translation
          name={_tx('Homework', 'Homework', 2)}
          icon={<PaperIcon stroke="#535F6A" />}
          activeIcon={<PaperIcon stroke="#353E45" />}
          to={`/teacher/classrooms/classroom/${classroomId}/homework/active`}
          basePath={`/teacher/classrooms/classroom/${classroomId}/homework`}
          supplementaryItemInfo={
            <div className="flex items-center gap-[4px]">
              <NumberInCircle value={activeHomeworkLength} active={activeHomeworkLength > 0} />
              {/* Translators: Number of active homework in a classroom. E.g. 2 active */}
              {_tx('active', 'active', activeHomeworkLength)}
            </div>
          }
        />
        <TeacherNavigationItem
          name={_t('Students')}
          icon={<StudentIcon stroke="#535F6A" />}
          activeIcon={<StudentIcon stroke="#353E45" />}
          to={`/teacher/classrooms/classroom/${classroomId}/students`}
          supplementaryItemInfo={sprintf(_tx('%d student', '%d students', numberOfStudents), numberOfStudents)}
        />
        <TeacherNavigationItem
          name={_t('Exercises book')}
          icon={<BookIcon stroke="#535F6A" />}
          activeIcon={<BookIcon stroke="#353E45" />}
          to={`/teacher/classrooms/classroom/${classroomId}/exercises`}
        />
      </div>
      {displayInvite ? (
        <div className="fixed bottom-[150px] hidden lg:w-[268px] xl:w-[294px]">
          <div className="flex items-center justify-between">
            <div className="text-[16px] font-semibold leading-[19px] text-text-dark-2">
              {_t('Invite students to the classroom')}
            </div>
            <Clickable
              onClick={() => {
                setDisplayInvite(false);
              }}
              className="text-[12px] font-semibold leading-[20px] text-text-shadow underline"
            >
              {_t('Hide')}
            </Clickable>
          </div>
          <div className="pt-[4px] text-[12px] leading-[18px] text-text-shadow">
            {_t('Share the invite code with the students who should join the classroom.')}
          </div>
          <div className="pt-[16px]">
            <CopyInviteCode inviteCode={classroom ? classroom.inviteCode : ''} />
          </div>
          <div className="flex flex-row items-center justify-center gap-[15px] pt-[16px]">
            <div>
              <InfoIcon />
            </div>
            <div className="text-[12px] leading-[18px] text-text-shadow">
              {_t('The student will input this code during the registration or after they have registered.')}{' '}
              {_t('The student will then be added to this classroom.')}
            </div>
          </div>
        </div>
      ) : (
        <div className="fixed bottom-[150px] hidden items-center justify-between lg:w-[268px] xl:w-[294px]">
          <div className="text-[16px] font-semibold leading-[19px] text-text-dark-2">
            {_t('Invite students to the classroom')}
          </div>
          <Clickable
            onClick={() => {
              setDisplayInvite(true);
            }}
            className="text-[12px] font-semibold leading-[20px] text-text-shadow underline"
          >
            {_t('Show')}
          </Clickable>
        </div>
      )}
      <div className="fixed bottom-[100px] border-t border-t-input-outline-shadow pt-[12px] lg:w-[268px] xl:w-[294px]"></div>
      <TeacherMenu />
    </div>
  );
}
